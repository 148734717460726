import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import './icon.scss';

const IconTypeClassMap = {
    add: 'fa-plus',
    burger: 'fa-bars',
    absence: 'fa-suitcase',
    category: 'fa-cubes',
    checkbox: 'fa-square-o',
    'checkbox-checked': 'fa-check-square',
    'checkbox-unchecked': 'fa-check-square',
    claim: 'fa-retweet',
    close: 'fa-times-circle-o',
    contact: 'fa-id-card-o',
    dashboard: 'fa-tachometer',
    delete: 'fa-trash',
    'delete-x': 'fa-times',
    email: 'fa-envelope',
    document: 'fa-file-o',
    'document-solid': 'fa-file',
    download: 'fa-download',
    edit: 'fa-pencil',
    file: 'fa-file-o',
    'file-audio': 'fa-file-audio-o',
    'file-archive': 'fa-file-archive-o',
    'file-code': 'fa-file-code-o',
    'file-excel': 'fa-file-excel-o',
    'file-image': 'fa-file-image-o',
    'file-pdf': 'fa-file-pdf-o',
    'file-powerpoint': 'fa-file-powerpoint-o',
    'file-text': 'fa-file-text-o',
    'file-video': 'fa-file-video-o',
    'file-word': 'fa-file-word-o',
    error: 'fa-exclamation-triangle',
    home: 'fa-home',
    'load-more': 'fa-refresh',
    location: 'fa-map-marker',
    login: 'fa-sign-in',
    logout: 'fa-sign-out',
    manufacturer: 'fa-building',
    news: 'fa-newspaper-o',
    phone: 'fa-phone',
    refresh: 'fa-refresh',
    search: 'fa-search',
    settings: 'fa-cog',
    sickReport: 'fa-ambulance',
    stock: 'fa-bars',
    task: 'fa-check-square',
    training: 'fa-graduation-cap',
    unknown: 'fa-question-circle',
    upload: 'fa-upload',
    user: 'fa-user',
    usergroup: 'fa-users',
    vacation: 'fa-plane',
    'vacation-pending': 'fa-sticky-note',
    'vacation-accepted': 'fa-check',
    'vacation-old': 'fa-history',
    'load-more-animated': 'fa-spin fa-refresh',
    'calendar': 'fa-calendar',
    'ban': 'fa-ban',
    'warning': 'fa-exclamation-triangle',
    'send': 'fa fa-paper-plane',
    'archive': 'fa-archive'
};

class Icon extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        hidden: PropTypes.bool,
        solid: PropTypes.bool,
        type: (props, propName) =>
            props[propName] === '' ||
            props[propName] === undefined ||
            IconTypeClassMap[props[propName]] === undefined
                ? new Error(`Invalid value '${props[propName]}' supplied to prop 'type'.`)
                : null,
    };

    render() {
        const { href, solid, ...props } = this.props;
        props.className = classNames(
            this.props.className,
            'icon',
            {
                fa: !this.props.solid,
                fas: this.props.solid,
                'icon--clickable': this.props.onClick,
                'icon--hidden': this.props.hidden,
            },
            IconTypeClassMap[this.props.type],
        );
        if (!props.role) {
            props.role = props.onClick ? 'button' : 'presentation';
        }

        return <i {...props} />;
    }
}

export default Icon;
