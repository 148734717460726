import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from '../Redux/index';

const colorStyle = {
    width: '20px',
    minWidth: '20px',
    height: '20px',
    position: 'relative',
    left: 0,
    marginRight: '5px'
}

const mapStateToProps = (state) => {
    return {
        dropdownPaths: state.data.filter.paths
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        updateAllLocationFilters: (filter) => dispatch(actions.Data.updateAllLocationFilters(filter)),
    };
};

class FilterDropdown extends PureComponent {
    static propTypes = {
        updateFilter: PropTypes.func.isRequired,
    };

    onBlur(e) {
        const currentTarget = e.currentTarget;

        setTimeout((e) => {
            if(!currentTarget.contains(document.activeElement)) {
                this.props.updateFilter({
                    path: this.props.filter.path,
                    prop: 'view',
                    val: false,
                });
            }
        },0)
    }

    closeDropdowns() {
        this.props.updateFilter({
            path: this.props.filter.path,
            prop: 'view',
            val: !this.props.filter.view,
        });

        this.props.dropdownPaths.map((filter) => {
            if(filter !== this.props.filter.path) {
                this.props.updateFilter({
                    path: filter,
                    prop: 'view',
                    val: false
                });
            }
            return filter;
        })
    }

    render() {
        return (

            <div className="dropdownFilter">
                <ul>
                    <li className={this.props.filter.view ? 'active' : 'inactive'}>
                        <a href="/#-" onClick={(e) => {
                            e.preventDefault();
                            this.closeDropdowns();
                        }}
                          onBlur={(e) => this.onBlur(e)}
                        >{this.props.filter.selectedLabel || this.props.defaultSelectedLabel}</a>
                        <ul>
                            {this.props.resetFilterLabel !== true &&
                            <li><a href="/#-"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       this.props.updateFilter({
                                           path: this.props.filter.path,
                                           prop: 'selectedId',
                                           val: '',
                                       });
                                       this.props.updateFilter({
                                           path: this.props.filter.path,
                                           prop: 'selectedLabel',
                                           val: '',
                                       });
                                       this.props.updateFilter({
                                           path: this.props.filter.path,
                                           prop: 'view',
                                           val: false,
                                       });
                                       this.props.updateAllLocationFilters({'path': this.props.filter.path, 'selectedLabel': '', 'selectedId': ''})
                                       setTimeout(() => {
                                           if (this.props.actionToExecute) {
                                               this.props.actionToExecute(this.props.paramsToExecute || true);
                                           }
                                           if (this.props.secondActionToExecute) {
                                               this.props.secondActionToExecute(this.props.secondParamsToExecute || true);
                                           }
                                       }, 10);

                                   }}
                            >Alle</a></li>
                            }

                            {this.props.list.map((item, index) => (
                                <li key={index}><a href="/#-"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       this.props.updateFilter({
                                                           path: this.props.filter.path,
                                                           prop: 'selectedId',
                                                           val: item[this.props.itemIdProp],
                                                       });
                                                       this.props.updateFilter({
                                                           path: this.props.filter.path,
                                                           prop: 'selectedLabel',
                                                           val: item[this.props.itemLabelProp],
                                                       });
                                                       this.props.updateFilter({
                                                        path: this.props.filter.path,
                                                        prop: 'view',
                                                        val: false,
                                                    });
                                                       this.props.updateAllLocationFilters({'path': this.props.filter.path, 'selectedLabel': item[this.props.itemLabelProp], 'selectedId': item[this.props.itemIdProp]})
                                                       setTimeout(() => {
                                                           if (this.props.actionToExecute) {
                                                               this.props.actionToExecute(this.props.paramsToExecute || true);
                                                           }
                                                           if (this.props.secondActionToExecute) {
                                                               this.props.secondActionToExecute(this.props.secondParamsToExecute || true);
                                                           }
                                                       }, 10);

                                                   }}
                                >{this.props.color && <span className="filter-dropdown-color" style={{...colorStyle, backgroundColor: item.hex}}></span>}
                                    {item[this.props.itemLabelProp]}
                                </a></li>
                            ))}

                        </ul>
                    </li>
                </ul>
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdown);
