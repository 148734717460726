import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon/Icon';
import './card.scss';

export default class CardHeader extends React.Component {
    static propTypes = {
        icon: PropTypes.string,
        title: PropTypes.string,
        extra: PropTypes.node,
    };

    render() {
        if (!this.props.icon && !this.props.title && !this.props.extra) {
            return null;
        }
        return (
            <div className={classNames('card__header', this.props.className)}>
                {this.props.icon && <Icon type={this.props.icon} className="card__header_icon" />}
                {this.props.title && <span className="card__header_title">{this.props.title}</span>}
                {this.props.extra && <span className="card__header_extra">{this.props.extra}</span>}
            </div>
        );
    }
}
