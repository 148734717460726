import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CategoryPage from '../Pages/CategoryPage';
import ClaimsPage from '../Pages/ClaimsPage';
import { actions } from '../Redux/index';
import Absence from './Absence';
import SickReport from './SickReport';
import AbsenceRecord from './AbsenceRecord';
import AbsenceRequestForm from './AbsenceRequestForm';
import Alert from './Alert';
import Confirm from './Confirm';
import CurrentNews from './CurrentNews';
import Dashboard from './Dashboard';
import Documents from './Documents';
import Advertising from './Advertising';

import LeftMenu from './LeftMenu';
import News from './News';
import Settings from './Settings';
import Stock from './Stock';
import Task from './Task';
import Training from './Training';
import UserData from './UserData';
import GoogleSheetPage from "../Pages/GoogleSheetPage";


const mapStateToProps = (state) => {
    return {
        view: state.ui.view,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (token) => dispatch(actions.Ui.updateToken(token)),
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
    };
};

class Main extends PureComponent {
    static propTypes = {
        logout: PropTypes.func.isRequired,
        view: PropTypes.string.isRequired,
    };

    static year() {
        return new Date().getFullYear();
    }

    componentDidMount() {
        const url = window.location.href; // Get the current URL
        const keyword = '/slf/';

        // Check if URL contains '/slf/'
        if (url.includes(keyword)) {
            // Extract the part after '/slf/'
            // const extractedPart = url.split(keyword)[1];
            this.props.updateView('Documents')

        }
    }

    getMainId() {
        switch (this.props.view) {
            case 'Dashboard' :
                return <Dashboard/>;
            case 'Task' :
                return <Task/>;
            case 'News' :
                return <News/>;
            case 'CurrentNews' :
                return <CurrentNews/>; // Single News View
            case 'StockPage' :
                return <Stock/>;
            case 'CategoryPage' :
                return <CategoryPage/>;
            case 'AbsenceRecord' :
                return <AbsenceRecord/>;
            case 'Documents' :
                return <Documents/>;
        case 'ClaimsPage' :
            return <ClaimsPage />;
            case 'Training' :
                return <Training/>;
            case 'Settings' :
                return <Settings/>;
            case 'AbsenceRequestForm' :
                return <AbsenceRequestForm/>;
            case 'UserData' :
                return <UserData/>;
            case 'Absence' :
                return <Absence/>;
            case 'SickReport' :
                return <SickReport/>;
            case 'Advertising' :
                return <Advertising/>;
            case 'GoogleSheetsPage' :
                return <GoogleSheetPage/>;
            default:
                return <Dashboard/>;
        }
    }

    render() {
        return (
            <div className={'page-wrapper'}>
                <Alert/>
                <Confirm/>
                <LeftMenu/> {
                this.getMainId()
            }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
