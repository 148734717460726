import LocationSelect from 'Components/LocationSelect';
import FilterSelect from 'Components/UI/FilterSelect';
import React from 'react';

class LocationFilterSelect extends React.Component {
    static propTypes = LocationSelect.propTypes;

    render() {
        return <LocationSelect {...this.props} selectComponent={FilterSelect} />;
    }
}

export default LocationFilterSelect;
