import ClaimBaseForm from 'Components/Claims/ClaimBaseForm';
import ClaimChecklist from 'Components/Claims/ClaimChecklist';
import Button from 'Components/UI/Button';
import LoadingAnimation from 'Components/UI/LoadingAnimation';
import Modal from 'Components/UI/Modal/Modal';
import { Form, Formik } from 'formik';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { bindActionCreators } from 'redux';
import { actions, selectors } from '../../Redux';
import Alert from '../../Containers/Alert';

const initialChecklist = {
    acceptedBySupplier: '',
    baNumber: '',
    complaintForm: null,
    customerInvoiceUpload:null,
    customerInvoiceUploads:[],
    completed: '',
    forwardedToSupplier: '',
    lueckentext: {
        complaintNumber: '',
        quality: '',
        articleNumber: '',
        articleName: '',
        packageContent: '',
        packageCount: '',
        surfaceSize: '',
        reason: '',
        treatment: '',
        stockNeedsUpdate: '',
        supplierName: '',
        name: '',
        additionalArticles: []
    },
    pictures: [],
    scenario: '',
    stockUpdated: '',
    supplierClaimNumber: '',
    customerName: '',
    workflowDone: '',
    additionalInfo: '',
    goodWill: '',
    email: '',
    coupon: '',
};

const initialClaim = {
    checklist: { ...initialChecklist },
    createdAt: '',
    createdBy: '',
    customerInvoice: '',
    customerName: '',
    locationId: '',
    number: '',
    supplierName: '',
    type: '',
    haulerName: '',
    orderNumberOnlineshop: '',
    'status': '',
};

const infoTab = 0;
const checklistTab = 1;

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.data.currentUser,
        map: state.claims.map,
        newClaimId: state.claims.newClaimId,
        claimIsCreating: state.claims.claimIsCreating,
        claimIsLoading: state.claims.claimIsLoading,
        claimIsUpdating: state.claims.claimIsUpdating,
        claimIsUpdatingChecklist: state.claims.claimIsUpdatingChecklist,
        locationList: state.data.allLocations,
        claim: selectors.Claims.claimById(state, ownProps.claimId),
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions.Claims, dispatch),
});

class ClaimForm extends React.Component {
    static propTypes = {
        claimId: PropTypes.string,
        isOpen: PropTypes.bool,
        onCancel: PropTypes.func,

        actions: PropTypes.object,
        claimIsLoading: PropTypes.bool,
        claimIsCreating: PropTypes.bool,
        claimIsUpdating: PropTypes.bool,
        claimIsUpdatingChecklist: PropTypes.bool,
        currentUser: PropTypes.object,
        locationList: PropTypes.arrayOf(PropTypes.object),
        map: PropTypes.object,
    };

    state = {
        createMode: !this.props.claimId,
        tabIndex: infoTab,
    };

    checklistTab = React.createRef();

    componentDidMount = () => {
        if (this.props.claimId) {
            this.loadClaim(this.props.claimId);
        } else {
            this.setCreateMode();
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.claimId !== this.props.claimId) {
            if (this.props.claimId) {
                this.loadClaim(this.props.claimId);
            } else {
                this.setCreateMode();
            }
            return;
        }

        if (prevProps.claimIsLoading && !this.props.claimIsLoading) {
            this.setState({ claim: this.props.map[this.props.claimId] });
        }
        if (prevProps.claimIsCreating && !this.props.claimIsCreating) {
            this.setState({
                claim: this.props.map[this.props.newClaimId],
                createMode: false,
                tabIndex: checklistTab,
            });
        }
        if (prevProps.claimIsUpdating && !this.props.claimIsUpdating) {
            this.setState({ claim: this.props.map[this.props.claimId || this.props.newClaimId] });
        }
        if (prevProps.claimIsUpdatingChecklist && !this.props.claimIsUpdatingChecklist) {
            this.setState({ claim: this.props.map[this.props.claimId || this.props.newClaimId] });
        }
    };

    loadClaim = (claimId) => {
        this.props.actions.loadClaim(claimId);
        this.setState({
            claim: this.newClaim(),
            createMode: false,
            tabIndex: checklistTab,
        });
    };

    setCreateMode = () => {
        this.setState({
            claim: this.newClaim(),
            createMode: true,
            tabIndex: infoTab,
        });
    };

    newClaim = (from = {}) => {
        const claim = { ...initialClaim, checklist: { ...initialChecklist }, ...from };
        claim.locationId = this.props.currentUser.currentLocation.id;
        claim.createdAt = new Date().toISOString();
        return claim;
    };

    getFormTitle = () => {
        if (this.state.createMode) {
            return 'Neue Beanstandung anlegen';
        }
        let title = 'Beanstandung bearbeiten';
        if (this.state.claim && this.state.claim.number) {
            title = title + ': ' + this.state.claim.number;
        }
        return title;
    };

    handleCancel = () => {
        if (
            this.formik &&
            this.formik.getFormikComputedProps().dirty &&
            !window.confirm('Alle Änderungen verwerfen?')
        ) {
            return;
        }
        this.setCreateMode();
        this.props.onCancel && this.props.onCancel();
    };


    handleSubmit = (values, actions) => {
        if (this.state.createMode) {
            this.props.actions.createClaim(values);
        } else {
            if (this.state.tabIndex === infoTab) {
                this.props.actions.updateClaim(values);
            }
            if (this.state.tabIndex === checklistTab) {
                this.props.actions.updateClaimChecklist(values.checklist);
            }
        }
        actions.resetForm(values);
    };

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    isAccounting = () => this.can(
        'beanstandungen_buchen'
    )

    validate = (values) => {
        let errors = {};
        if (this.state.tabIndex === infoTab) {
            errors = this.validateBaseForm(values);
        }
        if (this.state.tabIndex === checklistTab) {
            errors = this.validateChecklist(values);
        }

        return errors;
    };

    validateBaseForm = (values, prevErrors) => {
        const errors = { ...prevErrors };
        if (!['customer-item', 'stock-item', 'hauler-item'].includes(values.type)) {
            errors.type = 'Pflichtfeld!';
        }
        if (['customer-item', 'hauler-item'].includes(values.type)&& !values.customerName) {
            errors.customerName = 'Pflichtfeld!';
        }
        if (values.type === 'stock-item' && !values.supplierName) {
            errors.supplierName = 'Pflichtfeld!';
        }
        if (values.type === 'hauler-item' && !values.haulerName) {
            errors.haulerName = 'Pflichtfeld!';
        }
        return errors;
    };

    validateChecklist = (values, prevErrors) => {
        const checklistErrors = {};
       /*
        if (!values.checklist.scenario && values.checklist.completed) {
            checklistErrors.scenario = 'Pflichtfeld';
            alert('Szenario ist ein Pflichtfeld');
        } */

        const errors = { ...prevErrors };
        if (Object.keys(checklistErrors).length > 0) {
            errors.checklist = {
                ...errors.checklist,
                ...checklistErrors,
            };
        }
        return errors;
    };

    render() {
        return (
            <>
            <Alert/>
            <Modal
                icon="claim"
                isOpen={this.props.isOpen}
                title={this.getFormTitle()}
                onClose={this.handleCancel}
            >
                {this.props.claimIsLoading ? (
                    <div style={{ textAlign: 'center' }}>
                        <LoadingAnimation show={this.props.claimIsLoading} />
                        <p>Beanstandung wird geladen...</p>
                    </div>
                ) : (
                    <Formik
                        initialValues={this.state.claim}
                        enableReinitialize={true}
                        validate={this.validate}
                        onSubmit={this.handleSubmit}
                        handleChangeSingleProp={this.handleChandeSingleProp}
                        ref={(node) => {
                            this.formik = node;
                        }}
                    >
                        {(formikProps) => (
                            <Form className="claim-form">
                                <Tabs
                                    selectedIndex={this.state.tabIndex}
                                    onSelect={(tabIndex) => this.setState({ tabIndex })}
                                >
                                    <TabList>
                                        <Tab>Informationen</Tab>
                                        <Tab
                                            ref={this.checklistTab}
                                            disabled={this.state.createMode}
                                        >
                                            Checkliste
                                        </Tab>
                                    </TabList>

                                    <TabPanel>
                                        <ClaimBaseForm
                                            isAccounting={this.isAccounting()}
                                            {...formikProps}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <ClaimChecklist
                                            isAccounting={this.isAccounting()}
                                            {...formikProps}
                                        />
                                    </TabPanel>
                                </Tabs>
                                <div className="small-12 medium-12 large-12">
                                    <Button
                                        label={
                                            this.state.createMode
                                                ? 'Beanstandung anlegen'
                                                : 'Änderungen speichern'
                                        }
                                        inverted
                                        disabled={
                                            formikProps.isSubmitting ||
                                            this.props.claimIsCreating ||
                                            this.props.claimIsUpdating ||
                                            this.props.claimIsUpdatingChecklist
                                        }
                                        onClick={(e) => { formikProps.submitForm(e);

                                            //formikProps.submitForm(e)
                                        }}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </Modal>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClaimForm);
