import * as PropTypes from 'prop-types';
import React from 'react';
import { bytesToString } from '../../../Utility/UIHelper';
import FileIcon from '../FileIcon';
import Icon from '../Icon/Icon';
import { host } from '../../../Sagas/host';

class FileListItem extends React.PureComponent {

    constructor() {
        super();
        this.downloadForm = [];
    }

    static propTypes = {
        file: PropTypes.object.isRequired,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        hideDownloadIcon: PropTypes.bool,
        token: PropTypes.string,
    };

    static defaultProps = {
        hideDownloadIcon: false,
    };

    handleDelete = () => {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.file);
        }
    };

    getLink(document) {
        return host + "/download/"+document.id;
    }

    render() {
        return (
            <div className="dokument-item">
                <div className="dokument-item-left">
                    <a href="/" onClick={(e) => {e.preventDefault(); this.downloadForm[0].submit()}}>
                        <FileIcon fileType={this.props.file.type} />
                        <span>{this.props.file.name}</span>
                    </a>
                    <form  action={this.getLink(this.props.file)} target="_blank"  method="post" ref={(ref) => { this.downloadForm[0] = ref }}>
                        <input type="hidden" name="authTok" value={'Bearer ' + this.props.token}  />
                    </form>
                </div>
                <div className="dokument-item-right">
                    <span>
                        {this.props.file.type} – {bytesToString(this.props.file.size, 0)}
                    </span>
                    {!this.props.hideDownloadIcon && (
                        <a href="/" onClick={(e) => {e.preventDefault(); this.downloadForm[0].submit()}}>
                            <i className="fa fa-download" aria-hidden="true" />
                        </a>
                    )}
                    {this.props.onDelete && <Icon type="delete" onClick={this.handleDelete} />}
                </div>
            </div>
        );
    }
}

export default FileListItem;
