import { ErrorMessage, Field } from 'formik';
import * as PropTypes from 'prop-types';
import React from 'react';
import FormInput from './FormInput';

export default class FormikInput extends React.Component {
    static propTypes = {
        component: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.elementType,
            PropTypes.string,
        ]),
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        size: PropTypes.string,
    };

    handleOnChange = (event, onChange) => {
        if (!event || !event.target) {
            console.warn('FormikInput: empty event', event);
        }
        onChange && onChange(event);
    };

    render() {
        const { name, component, ...childProps } = this.props;
        return (
            <Field name={name}>
                {({ field }) => (
                    <FormInput
                        {...field}
                        component={component}
                        errorMessage={<ErrorMessage name={this.props.name} component="div" />}
                        {...childProps}
                        onChange={(event) => this.handleOnChange(event, field.onChange)}
                    />
                )}
            </Field>
        );
    }
}
