import classNames from 'classnames';
import Select from 'Components/UI/Form/Select';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class LocationSelect extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        allLocations: PropTypes.arrayOf(PropTypes.object),
        multiple: PropTypes.bool,
        readOnly: PropTypes.bool,
        value: PropTypes.string,
        selectComponent: PropTypes.elementType,
    };

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    filteredLocations() {

      if( this.can('beanstandungen_offene_alle_sehen',
          'beanstandungen_abgeschlossene_und_nicht_gebuchte_alle_als_offene_sehen'
      )) {
            return this.props.allLocations
        }

        let locations = [this.props.currentUser.currentLocation.id]

        if (!this.props.currentUser.allowedLocationsClaim) {
            return this.props.allLocations.filter(location => locations.includes(location.id))
        }
        if (!this.props.currentUser.allowedLocationsClaim.length) {
            return this.props.allLocations.filter(location => locations.includes(location.id))
        }
        return this.props.allLocations.filter(location => this.props.currentUser.allowedLocationsClaim.includes(location.id))
    }



    render() {
        const { className, options, ...childProps } = this.props;
        // noinspection LocalVariableNamingConventionJS
        const SelectComponent = this.props.selectComponent
            ? this.props.selectComponent
            : Select;
        return (
            <SelectComponent
                className={classNames('location-select', this.props.className)}
                {...childProps}
                options={this.filteredLocations()}
                placeholder="Filiale wählen"
                value={this.props.value}
                labelFunc={(option) => option.name}
                valueFunc={(option) => option.id}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allLocations: state.data.allLocations,
        currentUser: state.data.currentUser
    };
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationSelect);
