import {put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import {actions, actionTypes, selectors} from '../Redux/index';
import {host} from './host';

function* buildRequest(method = 'GET', body = undefined) {
    yield select(selectors.Ui.token);
    const token = yield select(selectors.Ui.token);
    if (!token) {
        throw Error('Not logged in.');
    }
    const headers = getHeaders(token);
    return {
        method: method.toUpperCase(),
        headers: headers,
        body: body,
    };
}

function handleFetchResponse(response) {
    if (!response.ok) {
        throw Error('fetch failed: ' + response.statusText);
    }
    if (!response.json) {
        throw Error('no json response: ' + response.data);
    }
    return response;
}

function* loadCategoryList() {
    yield takeLatest(actionTypes.Category.LOAD_LIST, function* (action) {
        let responseJSON = {};
        const page = action.payload.category.page ? action.payload.category.page : '';
        yield fetch(host + '/category/list/' + page, yield buildRequest())
            .then(response => handleFetchResponse(response))
            .then(response => response.json())
            .then(json => {
                responseJSON = json;
            })
            .catch(error => console.error('Error: loadCategoryListSaga:', error));
        yield put(actions.Category.updateList(responseJSON.categories));
    });
}

function* createCategory() {
    yield takeEvery(actionTypes.Category.CREATE, function* (action) {
        yield fetch(host + '/category', yield buildRequest('POST', JSON.stringify(action.payload.category)))
            .then(handleFetchResponse)
            .catch(error => console.error('Error: createCategorySaga:', error));
    });
}

function* updateCategory() {
    yield takeEvery(actionTypes.Category.UPDATE, function* (action) {
        const id = action.payload.category.id;
        yield fetch(host + '/category/' + id, yield buildRequest('PUT', JSON.stringify(action.payload.category)))
            .then(handleFetchResponse)
            .catch(error => console.error('Error: updateCategorySaga:', error));
    });
}

function* deleteCategory() {
    yield takeEvery(actionTypes.Category.DELETE, function* (action) {
        const id = action.payload.category.id;
        yield fetch(host + '/category/' + id, yield buildRequest('DELETE'))
            .then(handleFetchResponse)
            .catch(error => console.error('Error: deleteCategorySaga:', error));
    });
}

function* addCategoryFolder() {
    yield takeLatest(actionTypes.Category.ADD_CATEGORY_FOLDER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const folderToRename = yield select(selectors.Category.folderToRename);
            const headers = getHeaders(token);

            let folder =  {
                'folder': {
                    'name': action.payload.folder.name,
                    'parent': action.payload.folder.parent,
                }
            }
            if (folderToRename !== '') {
                folder.folder['id'] = folderToRename;
            }
            const result = yield fetch(host + '/addcategoryfolder', {
                method: "POST",
                headers: headers,
                body: JSON.stringify(folder),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const category = yield result.json();
                yield put(actions.Category.updateFolderToRename(''));
                yield put(actions.Category.updateCurrentCategory(category));
                yield put(actions.Category.loadList({ page: 0 }));

            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* addCategoryFile() {
    yield takeLatest(actionTypes.Category.ADD_CATEGORY_FILE, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const file =  {
                'file': {
                    'name': action.payload.file.name,
                    'parent': action.payload.file.parent,
                    'file': action.payload.file.file,
                }
            }
            const result = yield fetch(host + '/addcategoryfile', {
                method: "POST",
                headers,
                body: JSON.stringify(file),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const category = yield result.json();
                yield put(actions.Category.updateCurrentCategory(category));
                yield put(actions.Category.loadList({ page: 0 }));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* deleteCategoryFileOrFolder() {
    yield takeLatest(actionTypes.Category.DELETE_CATEGORY_FILE_OR_FOLDER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const item =  {
                'item': {
                    'id': action.payload.item.id,
                    'type': action.payload.item.type,
                    'parent': action.payload.item.parent,
                }
            }
            const result = yield fetch(host + '/deletecategoryfileorfolder', {
                method: "POST",
                headers,
                body: JSON.stringify(item),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const category = yield result.json();
                yield put(actions.Category.updateCurrentCategory(category));
                yield put(actions.Category.loadList({ page: 0 }));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* moveFile() {
    yield takeLatest(actionTypes.Category.MOVE_FILE, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const fileToMove = yield select(selectors.Category.fileToMove);
            const headers = getHeaders(token);
            const file =  {
                'file': {
                    'id': fileToMove,
                    'parent': action.payload.file.parent,
                }
            }
            const result = yield fetch(host + '/movefile', {
                method: "POST",
                headers,
                body: JSON.stringify(file),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const category = yield result.json();
                yield put(actions.Category.updateCurrentCategory(category));
                yield put(actions.Category.loadList({ page: 0 }));
                yield put(actions.Category.updateFileToMove(''));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [loadCategoryList, createCategory, updateCategory, deleteCategory, addCategoryFolder, addCategoryFile, deleteCategoryFileOrFolder, moveFile];
