import Checkbox from 'Components/UI/Form/Checkbox';
import moment from 'moment';
import React from 'react';

class DateCheckbox extends React.Component {
    static propTypes = Checkbox.propTypes;

    defaultValue = () => this.props.defaultValue || new Date().toISOString();
/*
    defaultValue = () => {
        if (this.props.value === 'requiredScenario') {
            console.log(this.props.defaultValue)
          return false;
        } else {
           return this.props.defaultValue || new Date().toISOString()
        }
       // return dateString ? moment(dateString).format('dd., DD. MMM YYYY, H:mm [Uhr]') : '';
    };
*/

    displayFunc = (dateString) => {
        return dateString ? moment(dateString).format('dd., DD. MMM YYYY, H:mm [Uhr]') : '';
    };

    render() {
        return (
            <Checkbox
                defaultValue={this.defaultValue()}
                displayFunc={this.displayFunc}
                placeholder="– – – – – – – –"
                {...this.props}
            />
        );
    }
}

export default DateCheckbox;
