import { createAction } from 'redux-actions';
import { handleActions } from '../Utility/HandleActions';
import { $get, $set } from 'plow-js';

const UPDATE_PERMISSIONS_VIEW = 'Settings/UpdatePermissionsView';
const UPDATE_TEAM_TO_EDIT = 'Settings/UpdateTeamToEdit';
const LOAD_PERMISSIONS = 'Settings/LoadPermisssions';
const UPDATE_PERMISSIONS = 'Settings/UpdatePermissions';
const UPDATE_PERMISSION_CATEGORIES = 'Settings/UpdatePermissionCategories';
const UPDATE_PNS = 'Settings/UpdatePns';
const UPDATE_SORT = 'Absence/UpdateSort';
const RESET_SORT = 'Absence/ResetSort';

const actionTypes = {
    UPDATE_PERMISSIONS_VIEW,
    UPDATE_TEAM_TO_EDIT,
    LOAD_PERMISSIONS,
    UPDATE_PERMISSIONS,
    UPDATE_PERMISSION_CATEGORIES,
    UPDATE_PNS,
    UPDATE_SORT,
    RESET_SORT
};

const updatePermissionsView = createAction(UPDATE_PERMISSIONS_VIEW, value => ({value}));
const updateTeamToEdit = createAction(UPDATE_TEAM_TO_EDIT, team => ({team}));
const loadPermissions = createAction(LOAD_PERMISSIONS, value => ({value}));
const updatePermissions = createAction(UPDATE_PERMISSIONS, list => ({list}));
const updatePermissionCategories = createAction(UPDATE_PERMISSION_CATEGORIES, list => ({list}));
const updatePns = createAction(UPDATE_PNS, list => ({list}));
const updateSort = createAction(UPDATE_SORT, sort => ({sort}));
const resetSort = createAction(RESET_SORT, sort => ({sort}));


const actions = {
    updatePermissionsView,
    updateTeamToEdit,
    loadPermissions,
    updatePermissions,
    updatePermissionCategories,
    updatePns,
    updateSort,
    resetSort
};

const initialState = {
    permissionsView: false,
    teamToEdit: {},
    permissions:[],
    permissionCategories:[],
    pns: [],
    userSort: '',
    groupSort: '',
    specialAppointmentSort: '',
    locationSort: '',
    loginlogSort: '',
    colorSort: '',
    statusSort: '',
    claimDropdownManufacturerSort: '',
    claimDropdownQualitySort: '',
    claimDropdownReasonSort: '',
};

const reducer = handleActions({
    [UPDATE_PERMISSIONS_VIEW]: ({ value }) => oldState => {
        return $set('settings.permissionsView', value, oldState);
    },
    [UPDATE_TEAM_TO_EDIT]: ({ team }) => oldState => $set('settings.teamToEdit', team, oldState),
    [UPDATE_PERMISSIONS]: ({ list }) => oldState => $set('settings.permissions', list, oldState),
    [UPDATE_PERMISSION_CATEGORIES]: ({ list }) => oldState => $set('settings.permissionCategories', list, oldState),
    [UPDATE_PNS]: ({ list }) => oldState => $set('settings.pns', list, oldState),
    [UPDATE_SORT]: ({ sort }) => oldState => {
        const s = sort.value.replace('-', '');
        const list = oldState.settings[sort.name].includes(`-${s}`) ? [] :  (oldState.settings[sort.name].includes(s) ? [`-${s}`] : [s]);
        return $set('settings.'+sort.name, list, oldState);

    },
    [RESET_SORT]: ({sort}) => oldState => {
        return $set('settings.'+sort.name, '', oldState);
    },

});

const selectors = {
    settings: $get('settings'),
    userSort: $get('settings.userSort'),
    specialAppointmentSort: $get('settings.specialAppointmentSort'),
    locationSort: $get('settings.locationSort'),
    loginlogSort: $get('settings.loginlogSort'),
    colorSort: $get('settings.colorSort'),
    statusSort: $get('settings.statusSort'),
    claimDropdownQualitySort: $get('settings.claimDropdownQualitySort'),
    claimDropdownReasonSort: $get('settings.claimDropdownReasonSort'),
    claimDropdownManufacturerSort: $get('settings.claimDropdownManufacturerSort'),
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
