import React from 'react';

export class UserSearchBox extends React.Component {
    render() {
        return (
            <div className="small-12 medium-6 large-6">
                <div className="group">
                    <input type="text" value={this.props.userSearch || ''} onChange={(e) => {
                        this.props.updateUserSearch(e.target.value);
                        if (e.target.value) {
                            this.props.updateDropdown({
                                dropdown: this.props.userDropdown.path,
                                prop: 'view',
                                val: true,
                            });
                        } else {
                            this.props.updateDropdown({
                                dropdown: this.props.userDropdown.path,
                                prop: 'view',
                                val: false,
                            });
                        }

                    }}
                    />
                    <label>Suche Benutzer</label>
                </div>
            </div>
        );
    }

}
