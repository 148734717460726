import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '../Redux/index';

const mapStateToProps = (state) => {
    return {
        alert: state.ui.alert
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAlert: (alert) => dispatch(actions.Ui.updateAlert(alert)),
    };
};

class Alert extends PureComponent {
    static propTypes = {
        alert: PropTypes.object,
        updateAlert: PropTypes.func,
    };

    getType() {
        const type = {
            'info' : 'Info',
            'error': 'Fehler',
            'warning': 'Warnung'
        }
        return type[this.props.alert.type.toLowerCase()];
    }

    render() {
        return (
            <div className={this.props.alert.type + ' '+ this.props.alert.view + ' popup'}>
                <span>{this.getType()}</span>
                <a className="close"
                   href="/#-"
                   onClick={(e) => {
                       e.preventDefault();
                       this.props.updateAlert({view: 'hidden', type: 'info', message: ''});
                   }}
                >&times;</a>
                <div className="content">
                    {this.props.alert.message}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
