import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* globalSettingsSaga() {
    yield takeLatest(actionTypes.Data.LOAD_GLOBAL_SETTINGS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/globalsettings', {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const globalSettings = yield result.json();
                console.log(globalSettings)
                yield put(actions.Data.updateGlobalSettings(globalSettings));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* saveGlobalSettingsSaga() {
    yield takeLatest(actionTypes.Data.SAVE_GLOBAL_SETTINGS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            let globalSettings = action.payload;
            const id = globalSettings.globalSettings.id;
            delete globalSettings.globalSettings.id;

            const headers = getHeaders(token);
            const result = yield fetch(host + '/globalsettings/'+id, {
                method: "PUT",
                headers,
                body: JSON.stringify(globalSettings),
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}



export default [globalSettingsSaga, saveGlobalSettingsSaga];
