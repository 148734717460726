import { $get, $set } from 'plow-js';
import { createAction } from 'redux-actions';
import { handleActions } from '../Utility/HandleActions';

const DELETE_SICK_REPORT = 'SickReport/DeleteSickReport';
const UPDATE_SICK_REPORT = 'SickReport/UpdateSickReport';
const SAVE_SICK_REPORT = 'SickReport/SaveSickReport';
const LOAD_SICK_REPORT_LIST = 'SickReport/LoadSickReportList';
const UPDATE_SICK_REPORT_LIST = 'SickReport/UpdateSickReportList';
const UPDATE_LIST_COUNT = 'SickReport/UpdateListCount';
const UPDATE_LOADING_ANIMATION = 'SickReport/UpdateLoadingAnimation';
const UPDATE_VIEW = 'SickReport/UpdateView';
const RESET_SICK_REPORT = 'SickReport/ResetSickReport';
const RESET_STATE = 'SickReport/ResetState';

const actionTypes = {
    DELETE_SICK_REPORT,
    UPDATE_SICK_REPORT,
    SAVE_SICK_REPORT,
    LOAD_SICK_REPORT_LIST,
    UPDATE_SICK_REPORT_LIST,
    UPDATE_LOADING_ANIMATION,
    UPDATE_VIEW,
    RESET_SICK_REPORT,
    RESET_STATE
};

const deleteSickReport = createAction(DELETE_SICK_REPORT, (sickReport) => ({sickReport}));
const updateSickReport = createAction(UPDATE_SICK_REPORT, (sickReport) => ({sickReport}));
const saveSickReport = createAction(SAVE_SICK_REPORT, (sickReport) => ({sickReport}));
const loadSickReportList = createAction(LOAD_SICK_REPORT_LIST, (list) => ({list}));
const updateSickReportList = createAction(UPDATE_SICK_REPORT_LIST, (list) => ({list}));
const updateListCount = createAction(UPDATE_LIST_COUNT, (count) => ({count}));
const updateLoadingAnimation = createAction(UPDATE_LOADING_ANIMATION, (value) => ({value}));
const updateView = createAction(UPDATE_VIEW, (value) => ({value}));
const resetSickReport = createAction(RESET_SICK_REPORT, (sickReport) => ({sickReport}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));

const actions = {
    deleteSickReport,
    updateSickReport,
    saveSickReport,
    loadSickReportList,
    updateSickReportList,
    updateListCount,
    updateLoadingAnimation,
    updateView,
    resetSickReport,
    resetState
};

const initialState = {
    sickReport: {
        "startDate": "",
        "endDate": "",
        "userId": "",
        "status": "2", //possible values 1 abwesend 2 krank 3 urlaub 7 anwesend
        "approved": true,
        "statusLabel": "Krank",
        "specialHoliday": false,
        "reason": "",
        "overtimeDeduction": false,
        "overtime": ""
    },
    view: '2',
    list: [],
    listCount: 0,
    loadingAnimation: false,
};

const reducer = handleActions({
    [UPDATE_SICK_REPORT]: (payload) => oldState => {
        return $set('sickReport.sickReport.' + payload.sickReport.prop, payload.sickReport.val, oldState);
    },
    [RESET_SICK_REPORT]: (payload) => oldState => {
        return $set('sickReport.sickReport', payload.sickReport, oldState);
    },
    [UPDATE_VIEW]: (payload) => oldState => {
        return $set('sickReport.view', payload.value, oldState);
    },
    [UPDATE_SICK_REPORT_LIST]: (payload) => oldState => {
        if(payload.list.reset === true) {
            return $set('sickReport.list', [], oldState);
        } else {
            let list = [...oldState.sickReport.list, ...payload.list.list];
            return $set('sickReport.list', list, oldState);
        }
    },
    [UPDATE_LIST_COUNT]: (payload) => oldState => {
        return $set('sickReport.listCount', payload.count, oldState);
    },
    [UPDATE_LOADING_ANIMATION]: (payload) => oldState => {
        return $set('sickReport.loadingAnimation', payload.value, oldState);
    },
    [DELETE_SICK_REPORT]: (payload) => oldState => {
        let list = [...oldState.sickReport.list];
        list = list.filter(sickReport => sickReport.id !== payload.sickReport.id)        
        return $set('sickReport.list', list, oldState);
    },
    [RESET_STATE]: (payload) => oldState => {
        return $set('sickReport', initialState, oldState);
    },
});

const selectors = {
    sickReport: $get('sickReport'),
};


export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
