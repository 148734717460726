import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import ReactHtmlParser from 'react-html-parser';
import RichTextEditor from "react-rte";
import LikesModal from "../Components/LikesModal";
import {getPopupUser} from "./Util";


const mapStateToProps = (state) => {
    return {
        newsList: state.data.newsList,
        currentNews: state.data.currentNews,
        currentUser: state.data.currentUser,
        newsModal: state.data.modal.news.news,
        countedLikes: state.data.countLikes,
        loadingAnimation: state.advertising.loadingAnimation,
        commentToEdit: state.data.commentToEdit,
        comments: state.data.comments,
        userPopup: state.data.userPopup,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        loadNewsList: (list) => dispatch(actions.Data.loadNewsList(list)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        countLikes: (value) => dispatch(actions.Data.countLikes(value)),
        toggleLike: (value) => dispatch(actions.Data.toggleLike(value)),
        updateCommentToEdit: comment => dispatch(actions.Data.updateCommentToEdit(comment)),
        updateCommentList: list => dispatch(actions.Data.updateCommentList(list)),
        loadComments: value => dispatch(actions.Data.loadComments(value)),
        addComment: comment => dispatch(actions.Data.addComment(comment)),
        updateComment: comment => dispatch(actions.Data.updateComment(comment)),
        deleteComment: comment => dispatch(actions.Data.deleteComment(comment)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        loadLikes: (value) => dispatch(actions.Data.likes(value)),
    };
};

class CurrentNews extends PureComponent {
    static propTypes = {
        updateView: PropTypes.func.isRequired,
        currentNews: PropTypes.object,
        updateModal: PropTypes.func.isRequired,
        newsModal: PropTypes.object,
        countLikes: PropTypes.func,
        countedLikes: PropTypes.array,
        updateCommentToEdit: PropTypes.func,
        updateCommentList:PropTypes.func,
        loadComments: PropTypes.func,
        commentToEdit: PropTypes.object,
        addComment: PropTypes.func,
        updateComment: PropTypes.func,
        deleteComment: PropTypes.func,
        loadLikes: PropTypes.func,
    }
    state = {
        comment: RichTextEditor.createEmptyValue(),
        likesModalIsVisible: false,
        newsForLikes: null
    }

    executeScroll = () => {
        setTimeout(() => {
            this.commentInput.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "start"
            })
        }, "30");

    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    getThumbsClass(news) {
        let className = "fa fa-thumbs-o-up";
        const newsWithLikes = this.props.countedLikes.find(entityWithLikes => entityWithLikes.id === news.id)
        if(newsWithLikes) {
            className = newsWithLikes.likedByCurrentUser ? "fa fa-thumbs-up" : "fa fa-thumbs-o-up"
        }
        return this.props.loadingAnimation ? 'fa fa-spinner fa-pulse fa-fw' : className
    }

    componentDidMount() {
        if(!this.props.currentNews.title) {
            this.props.updateView('Dashboard');
        }
        this.props.countLikes('News')
    }
    showComments(news, commentId = null) {
        this.executeScroll()
        this.props.updateCommentList([]);
        this.props.loadComments({entityId: news.id, entity: 'News'})
        this.props.updateCommentToEdit({
            ...this.props.commentToEdit,
            entity: 'News',
            entityId: news.id,
            id: commentId,
        })
    }
    addComment(e) {
        e.preventDefault()
        if(!this.props.commentToEdit.comment.length) {
            return alert('Kommentar darf nicht leer sein')
        }
        if(this.props.commentToEdit.id) {
            this.props.updateComment(this.props.commentToEdit)
        } else {
            this.props.addComment(this.props.commentToEdit)
        }
        this.props.updateCommentToEdit({...this.props.commentToEdit, comment: '', id: null})
    }
    editComment(comment) {
        this.props.updateCommentToEdit(comment)
    }
    closeComments() {
        this.props.updateNewsToEdit({prop: 'resetObj',val: ''})
        this.props.updatePreview(false)
        this.props.updateCommentToEdit({})
        this.closeEditModal()
    }

    deleteComment(comment) {
        // const confirmResult = window.confirm('Möchten Sie wirklich die Werbung löschen?');
        // if (confirmResult === true) {
        //     this.props.deleteAdvertising(advertising);
        // }
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Kommentar löschen'})
        this.props.updateConfirmModal({prop: 'text', value: 'Möchten Sie wirklich den Kommentar löschen?'})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteComment', param: comment}})
    }

    getCountLikes(news, showLikesModal = false) {
        let count = 0;
        const newsWithLikes = this.props.countedLikes.find(entityWithLikes => entityWithLikes.id === news.id)
        if(newsWithLikes) {
            count = newsWithLikes.count
        }
        return (<small onClick={() => {
            if(showLikesModal && this.can('neuigkeiten_like_benutzernamen_sehen')) {
                this.showLikesWithUsers(news)
            }

        }} className="count-likes-or-comments">{count}</small>)
    }

    showLikesWithUsers = (news) => {
        this.props.loadLikes({entity:'News', entityId: news.id})
        this.toggleVisibilityLikesModal(news)
    }

    toggleVisibilityLikesModal = (news) => {
        this.setState(prevState => ({
            likesModalIsVisible: !prevState.likesModalIsVisible,
            newsForLikes: news
        }));
    }

    render() {
        const newsUser = getPopupUser(this.props.userPopup, this.props.currentNews.createdById)
        return (
            <div id="main-side">
                <Breadcrumb path={['Neuigkeiten', this.props.currentNews.title || '']}>
                    {this.can('neuigkeiten_erstellen') &&
                    <Button label="Neue Nachricht"
                            onClick={() => {
                                this.props.updateView('News');
                                this.props.updateModal({
                                    path: 'news.news',
                                    prop: 'view',
                                    val: !this.props.newsModal.view,
                                });
                            }}
                        />}
                </Breadcrumb>

                <main>
                    <LikesModal
                        toggleVisibilityLikesModal={this.toggleVisibilityLikesModal}
                        likesModalIsVisible={this.state.likesModalIsVisible}
                        news={this.state.newsForLikes}
                    />
                    <div className="content-wrapper banner-big">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                                            <span><i className="fa fa-newspaper-o" aria-hidden="true"></i> Neuigkeiten</span>
                                        </div>
                                        <div className="news-bild" >
                                            {
                                                this.props.currentNews.resourceUri &&
                                                <div className="img-wrapper">
                                                    <a href={'/download/resource/'+ this.props.currentNews.id} target="_blank" rel="noopener noreferrer" >
                                                        <img style={{'maxWidth': '100%'}} src={this.props.currentNews.resourceUri} alt=""/>
                                                    </a>
                                                </div>

                                            }
                                            <div className="news-bild-content">
                                                <span>{this.props.currentNews.dateFormated || this.props.currentNews.date} &nbsp;
                                                    {
                                                        this.props.currentNews.createdBy &&
                                                        <u className='hoverable-user'>
                                                            von {this.props.currentNews.createdBy}
                                                            <div
                                                                className="user-info-popup">
                                                                {newsUser.resourceUri ? (
                                                                    <img
                                                                        src={newsUser.resourceUri}
                                                                        alt={newsUser.fullName}
                                                                        className="user-avatar"
                                                                    />
                                                                ) : (
                                                                    <i className="fa fa-user user-icon"></i> // Font Awesome icon
                                                                )}
                                                                <div
                                                                    className="user-details">
                                                                    <h4>{newsUser.fullName}</h4>
                                                                    <p>Benutzergruppe: {newsUser.roleLabel}</p>
                                                                    <p>Standort: {newsUser.locationLabel}</p>
                                                                </div>
                                                            </div>
                                                        </u>
                                                    }
                                                        </span>
                                                <span className="headline-news">{this.props.currentNews.title}
                                                    &nbsp;
                                                    <i style={{'cursor': 'pointer'}} onClick={
                                                        () => this.props.toggleLike({
                                                            entityId: this.props.currentNews.id,
                                                            entity: 'News'
                                                        })
                                                    }
                                                       className={this.getThumbsClass(this.props.currentNews)}
                                                    />
                                                    {this.can('likes_can_see') && this.getCountLikes(this.props.currentNews, true)}
                                                    <i style={{'cursor': 'pointer'}}
                                                       onClick={() => this.showComments(this.props.currentNews)}
                                                       className="fa fa-comments-o"/>
                                                    <small
                                                        className="count-likes-or-comments">{this.props.currentNews.commentCount}</small>
                                                </span>
                                                <p> {ReactHtmlParser(this.props.currentNews.text)} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.commentToEdit.entityId &&

                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div className="site-wrapper">
                                            <div className="group" style={{paddingRight: '30px'}}>
                                                    <textarea className="small-textarea"
                                                              maxLength="300"
                                                              rows="3"
                                                              value={this.props.commentToEdit.comment || ''}
                                                              onChange={(e) => this.props.updateCommentToEdit({
                                                                  ...this.props.commentToEdit,
                                                                  comment: e.target.value
                                                              })}
                                                    />
                                                {this.props.commentToEdit.id &&
                                                    <label>Kommentar
                                                        von {this.props.commentToEdit.createdAtFormated} bearbeiten</label>
                                                }
                                                {!this.props.commentToEdit.id &&
                                                    <label>Kommentar</label>
                                                }

                                                <button className="add-comment on-dashboard"
                                                        onClick={(e) => this.addComment(e)}
                                                ><i className={'fa fa-paper-plane fa-2x'}></i></button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="small-12 medium-12 large-12">
                                        {this.props.loadingAnimation &&
                                            <div>
                                            <p>Kommentare werden geladen <i
                                            className={'fa fa-spinner fa-pulse fa-fw'}></i></p>
                                    </div>
                                    }
                                    {
                                        !this.props.loadingAnimation && !this.props.comments &&
                                        <div>
                                            <p>Noch keine Kommentare</p>
                                        </div>
                                    }
                                    {this.props.comments.map(comment =>
                                        <div className="comment">
                                            <div className="comment-title">
                                                {comment.userName} {comment.createdAtFormated}
                                                &nbsp;
                                                {(
                                                    ((this.props.currentUser.id === comment.userId) && this.can('comments_can_edit_own'))
                                                    || this.can('comments_can_edit_global')
                                                ) &&
                                                <i className="fa fa-pencil" on onClick={() =>  this.props.updateCommentToEdit(comment)}></i>
                                                }
                                                &nbsp;
                                                {
                                                    this.can('comments_can_delete') &&
                                                    <i className="fa fa-trash" on onClick={() =>  this.deleteComment(comment)}></i>
                                                }
                                            </div>
                                            <div className="comment-content">{comment.comment}</div>
                                        </div>
                                    )}

                                </div>

                            </div>
                            }
                        </div>

                    </div>
            </main>
            <footer id="footer-main" ref={ (ref) => this.commentInput=ref }>

            </footer>
        </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentNews);
