import Card from 'Components/Settings/Card';
import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions} from '../Redux/index';
import Dropdown from './Dropdown';
import '../assets/styles/_task.scss';
import * as moment from 'moment';
import DateInput from 'Components/UI/Form/DateInput';
import { regions } from 'Utility/RegionHelper'
// import { TwitterPicker } from 'react-color';
import Permissions from 'Components/Settings/Permissions';
import Pagination from 'Components/Settings/Pagination';
import IconLink from '../Components/UI/IconLink';
import {host} from "../Sagas/host";
//import GlobalSettings from "../Components/Settings/GlobalSettings";

const mapStateToProps = (state) => {
    return {
        user: state.data.user,
        token: state.ui.token,
        taskList: state.data.taskList,
        newsList: state.data.newsList,
        userList: state.data.userList,
        userGroupList: state.data.userGroupList,
        locationList: state.data.locationList,
        loginHistoryList: state.data.loginHistoryList,
        locationModal: state.ui.locationModal,
        locationToEdit: state.data.locationToEdit,
        locationModalInput: state.data.locationModalInput,
        locationModalAction: state.data.locationModalAction,
        locationCount: state.ui.locationCount,
        loginLogCount: state.ui.loginLogCount,
        userCount: state.ui.userCount,
        manufacturerCount: state.ui.manufacturerCount,
        locationListPage: state.ui.locationListPage,
        loginLogListPage: state.ui.loginLogListPage,
        userListPage: state.ui.userListPage,
        manufacturerListPage: state.ui.manufacturerListPage,
        newManufacturerModal: state.ui.newManufacturerModal,
        manufacturerModalAction: state.data.manufacturerModalAction,
        manufacturerModalInput: state.data.manufacturerModalInput,
        manufacturerToEdit: state.data.manufacturerToEdit,
        manufacturers: state.data.manufacturers,
        userToEdit: state.data.userToEdit,
        userModal: state.ui.newUserModal,
        userModalAction: state.data.userModalAction,
        locationDropdown: state.data.dropdown.settings.user.location,
        regionsDropdown: state.data.dropdown.settings.location.regions,
        locationDropdownSpecialAppointment: state.data.dropdown.news.location,
        userAdditionalLocationsDropdown: state.data.dropdown.settings.userAdditionalLocations,
        userAllowedLocationsClaimDropdown: state.data.dropdown.settings.userAllowedLocationsClaim,
        userGroupDropdown: state.data.dropdown.settings.user.usergroup,
        currentUser: state.data.currentUser,
        allLocations: state.data.allLocations,
        userSearch: state.ui.userSearch,
        teamToEdit: state.settings.teamToEdit,
        locationSpecialAppointmentModal: state.data.modal.settings.locationSpecialAppointment,
        locationSpecialAppointment: state.data.locationSpecialAppointment,
        locationSpecialAppointmentList: state.data.locationSpecialAppointmentList,
        locationSpecialAppointmentListPage: state.ui.locationSpecialAppointmentListPage,
        locationSpecialAppointmentListCount: state.ui.locationSpecialAppointmentListCount,
        loginSearchTerm: state.data.loginSearchTerm,
        locationSpecialAppointmentViewOld: state.ui.locationSpecialAppointmentViewOld,
        locationSpecialAppointmentSearchTerm: state.data.locationSpecialAppointmentSearchTerm,
        locationSpecialAppointmentExists: state.ui.locationSpecialAppointmentExists,
        colorCount: state.ui.colorCount,
        colorListPage: state.ui.colorListPage,
        colorModal: state.ui.colorModal,
        colorModalAction: state.data.colorModalAction,
        colorToEdit: state.data.colorToEdit,
        colors: state.data.colors,
        statusCount: state.ui.statusCount,
        statusListPage: state.ui.statusListPage,
        statusModal: state.ui.statusModal,
        statusModalAction: state.data.statusModalAction,
        statusToEdit: state.data.statusToEdit,
        statuses: state.data.statuses,

        permissionsView: state.settings.permissionsView,
        subgroupsDropdown: state.data.dropdown.settings.subgroups,
        pns: state.settings.pns,
        userSort: state.settings.userSort,
        groupSort: state.settings.groupSort,
        specialAppointmentSort: state.settings.specialAppointmentSort,
        locationSort: state.settings.locationSort,
        loginlogSort: state.settings.loginlogSort,
        colorSort: state.settings.colorSort,
        statusSort: state.settings.statusSort,

        claimDropdownManufacturerCount: state.ui.claimDropdownManufacturerCount,
        claimDropdownManufacturerListPage: state.ui.claimDropdownManufacturerListPage,
        claimDropdownManufacturerModal: state.ui.claimDropdownManufacturerModal,
        claimDropdownManufacturerModalAction: state.data.claimDropdownManufacturerModalAction,
        claimDropdownManufacturerToEdit: state.data.claimDropdownManufacturerToEdit,
        claimDropdownManufacturers: state.data.claimDropdownManufacturers,
        claimDropdownManufacturerSort: state.settings.claimDropdownManufacturerSort,

        claimDropdownReasonCount: state.ui.claimDropdownReasonCount,
        claimDropdownReasonListPage: state.ui.claimDropdownReasonListPage,
        claimDropdownReasonModal: state.ui.claimDropdownReasonModal,
        claimDropdownReasonModalAction: state.data.claimDropdownReasonModalAction,
        claimDropdownReasonToEdit: state.data.claimDropdownReasonToEdit,
        claimDropdownReasons: state.data.claimDropdownReasons,
        claimDropdownReasonSort: state.settings.claimDropdownReasonSort,

        claimDropdownQualityCount: state.ui.claimDropdownQualityCount,
        claimDropdownQualityListPage: state.ui.claimDropdownQualityListPage,
        claimDropdownQualityModal: state.ui.claimDropdownQualityModal,
        claimDropdownQualityModalAction: state.data.claimDropdownQualityModalAction,
        claimDropdownQualityToEdit: state.data.claimDropdownQualityToEdit,
        claimDropdownQualities: state.data.claimDropdownQualities,
        claimDropdownQualitySort: state.settings.claimDropdownQualitySort,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTaskList: (task) => dispatch(actions.Data.updateTaskList(task)),
        updateNewsList: (news) => dispatch(actions.Data.updateNewsList(news)),
        updateLocationList: (array) => dispatch(actions.Data.updateLocationList(array)),
        updateLoginHistoryList: (array) => dispatch(actions.Data.updateLoginHistoryList(array)),
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateLocationModal: (value) => dispatch(actions.Ui.updateLocationModal(value)),
        updateManufacturerModal: (value) => dispatch(actions.Ui.updateManufacturerModal(value)),
        updateLocationModalInput: (location) =>
            dispatch(actions.Data.updateLocationModalInput(location)),
        updateLocationModalAction: (value) =>
            dispatch(actions.Data.updateLocationModalAction(value)),
        updateLocationToEdit: (value) => dispatch(actions.Data.updateLocationToEdit(value)),
        addLocation: (value) => dispatch(actions.Data.addLocation(value)),
        updateLocation: (value) => dispatch(actions.Data.updateLocation(value)),
        deleteLocation: (location) => dispatch(actions.Data.deleteLocation(location)),
        deleteUser: (user) => dispatch(actions.Data.deleteUser(user)),
        updateLocationListPage: (value) => dispatch(actions.Ui.updateLocationListPage(value)),
        updateLoginLogListPage: (value) => dispatch(actions.Ui.updateLoginLogListPage(value)),
        updateUserListPage: (value) => dispatch(actions.Ui.updateUserListPage(value)),
        updateManufacturerListPage: (value) =>
            dispatch(actions.Ui.updateManufacturerListPage(value)),
        updateManufacturerToEdit: (value) => dispatch(actions.Data.updateManufacturerToEdit(value)),
        updateManufacturerModalAction: (value) =>
            dispatch(actions.Data.updateManufacturerModalAction(value)),
        updateManufacturerModalInput: (value) =>
            dispatch(actions.Data.updateManufacturerModalInput(value)),
        saveManufacturer: (value) => dispatch(actions.Data.saveManufacturer(value)),
        deleteManufacturer: (manufacturer) =>
            dispatch(actions.Data.deleteManufacturer(manufacturer)),
        updateManufacturer: (manufacturer) =>
            dispatch(actions.Data.updateManufacturer(manufacturer)),
        toggleActivation: (value) => dispatch(actions.Ui.toggleActivation(value)),
        updateUserModalAction: (value) => dispatch(actions.Data.updateUserModalAction(value)),
        updateUserModal: (value) => dispatch(actions.Ui.updateUserModal(value)),
        updateUserToEdit: (user) => dispatch(actions.Data.updateUserToEdit(user)),
        updateUserToEditData: (userdata) => dispatch(actions.Data.updateUserToEditData(userdata)),
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        saveUser: (user) => dispatch(actions.Data.saveUser(user)),
        updateUser: (user) => dispatch(actions.Data.updateUser(user)),
        updateUserSearch: (search) => dispatch(actions.Ui.updateUserSearch(search)),
        updateTeamToEdit: team => dispatch(actions.Settings.updateTeamToEdit(team)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        saveTeam: (team) => dispatch(actions.Data.saveTeam(team)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        loadLocationSpecialAppointmentList: (page) => dispatch(actions.Data.loadLocationSpecialAppointmentList(page)),
        updateLocationSpecialAppointmentListPage: (value) => dispatch(actions.Ui.updateLocationSpecialAppointmentListPage(value)),
        updateLocationSpecialAppointment: (locationSpecialAppointment) => dispatch(actions.Data.updateLocationSpecialAppointment(locationSpecialAppointment)),
        addLocationSpecialAppointment: (locationSpecialAppointment) => dispatch(actions.Data.addLocationSpecialAppointment(locationSpecialAppointment)),
        saveLocationSpecialAppointment: (locationSpecialAppointment) => dispatch(actions.Data.saveLocationSpecialAppointment(locationSpecialAppointment)),
        deleteLocationSpecialAppointment: (locationSpecialAppointment) => dispatch(actions.Data.deleteLocationSpecialAppointment(locationSpecialAppointment)),
        updateLoginSearchTerm: (term) => dispatch(actions.Data.updateLoginSearchTerm(term)),
        updateLocationSpecialAppointmentSearchTerm: (term) => dispatch(actions.Data.updateLocationSpecialAppointmentSearchTerm(term)),
        updateLocationSpecialAppointmentViewOld: (value) => dispatch(actions.Ui.updateLocationSpecialAppointmentViewOld(value)),
        updateLocationSpecialAppointmentExists: (value) => dispatch(actions.Ui.updateLocationSpecialAppointmentExists(value)),
        checkExistingLocationSpecialAppointment: (value) => dispatch(actions.Ui.checkExistingLocationSpecialAppointment(value)),
        updateColorModalAction: (value) => dispatch(actions.Data.updateColorModalAction(value)),
        updateColorModal: (value) => dispatch(actions.Ui.updateColorModal(value)),
        updateColorToEdit: (color) => dispatch(actions.Data.updateColorToEdit(color)),
        saveColor: (color) => dispatch(actions.Data.saveColor(color)),
        deleteColor: (color) => dispatch(actions.Data.deleteColor(color)),
        updateColor: (color) => dispatch(actions.Data.updateColor(color)),
        updatePermissionsView: value => dispatch(actions.Settings.updatePermissionsView(value)),
        updateSort: (sort) => dispatch(actions.Settings.updateSort(sort)),
        loadColors: (value) => dispatch(actions.Data.loadColors(value)),
        updateColorListPage: (page) => dispatch(actions.Ui.updateColorListPage(page)),

        updateStatusModalAction: (value) => dispatch(actions.Data.updateStatusModalAction(value)),
        updateStatusModal: (value) => dispatch(actions.Ui.updateStatusModal(value)),
        updateStatusToEdit: (status) => dispatch(actions.Data.updateStatusToEdit(status)),
        saveStatus: (status) => dispatch(actions.Data.saveStatus(status)),
        deleteStatus: (status) => dispatch(actions.Data.deleteStatus(status)),
        updateStatus: (status) => dispatch(actions.Data.updateStatus(status)),
        loadStatuses: (value) => dispatch(actions.Data.loadStatuses(value)),
        updateStatusListPage: (page) => dispatch(actions.Ui.updateStatusListPage(page)),

        updateClaimDropdownManufacturerModalAction: (value) => dispatch(actions.Data.updateClaimDropdownManufacturerModalAction(value)),
        updateClaimDropdownManufacturerModal: (value) => dispatch(actions.Ui.updateClaimDropdownManufacturerModal(value)),
        updateClaimDropdownManufacturerToEdit: (claimDropdownManufacturer) => dispatch(actions.Data.updateClaimDropdownManufacturerToEdit(claimDropdownManufacturer)),
        saveClaimDropdownManufacturer: (claimDropdownManufacturer) => dispatch(actions.Data.saveClaimDropdownManufacturer(claimDropdownManufacturer)),
        deleteClaimDropdownManufacturer: (claimDropdownManufacturer) => dispatch(actions.Data.deleteClaimDropdownManufacturer(claimDropdownManufacturer)),
        updateClaimDropdownManufacturer: (claimDropdownManufacturer) => dispatch(actions.Data.updateClaimDropdownManufacturer(claimDropdownManufacturer)),
        loadClaimDropdownManufacturers: (value) => dispatch(actions.Data.loadClaimDropdownManufacturers(value)),
        updateClaimDropdownManufacturerListPage: (page) => dispatch(actions.Ui.updateClaimDropdownManufacturerListPage(page)),

        updateClaimDropdownReasonModalAction: (value) => dispatch(actions.Data.updateClaimDropdownReasonModalAction(value)),
        updateClaimDropdownReasonModal: (value) => dispatch(actions.Ui.updateClaimDropdownReasonModal(value)),
        updateClaimDropdownReasonToEdit: (claimDropdownReason) => dispatch(actions.Data.updateClaimDropdownReasonToEdit(claimDropdownReason)),
        saveClaimDropdownReason: (claimDropdownReason) => dispatch(actions.Data.saveClaimDropdownReason(claimDropdownReason)),
        deleteClaimDropdownReason: (claimDropdownReason) => dispatch(actions.Data.deleteClaimDropdownReason(claimDropdownReason)),
        updateClaimDropdownReason: (claimDropdownReason) => dispatch(actions.Data.updateClaimDropdownReason(claimDropdownReason)),
        loadClaimDropdownReasons: (value) => dispatch(actions.Data.loadClaimDropdownReasons(value)),
        updateClaimDropdownReasonListPage: (page) => dispatch(actions.Ui.updateClaimDropdownReasonListPage(page)),

        updateClaimDropdownQualityModalAction: (value) => dispatch(actions.Data.updateClaimDropdownQualityModalAction(value)),
        updateClaimDropdownQualityModal: (value) => dispatch(actions.Ui.updateClaimDropdownQualityModal(value)),
        updateClaimDropdownQualityToEdit: (claimDropdownQuality) => dispatch(actions.Data.updateClaimDropdownQualityToEdit(claimDropdownQuality)),
        saveClaimDropdownQuality: (claimDropdownQuality) => dispatch(actions.Data.saveClaimDropdownQuality(claimDropdownQuality)),
        deleteClaimDropdownQuality: (claimDropdownQuality) => dispatch(actions.Data.deleteClaimDropdownQuality(claimDropdownQuality)),
        updateClaimDropdownQuality: (claimDropdownQuality) => dispatch(actions.Data.updateClaimDropdownQuality(claimDropdownQuality)),
        loadClaimDropdownQualities: (value) => dispatch(actions.Data.loadClaimDropdownQualities(value)),
        updateClaimDropdownQualityListPage: (page) => dispatch(actions.Ui.updateClaimDropdownReasonListPage(page)),
    };
};

class Settings extends PureComponent {
    static propTypes = {
        user: PropTypes.object,
        updateView: PropTypes.func.isRequired,
        token: PropTypes.string,
        taskList: PropTypes.array,
        subTaskList: PropTypes.array,
        updateTaskList: PropTypes.func,
        updateNewsList: PropTypes.func,
        updateLoginHistoryList: PropTypes.func,
        updateLocationList: PropTypes.func,
        userlist: PropTypes.array,
        userGroupList: PropTypes.array,
        loginHistoryList: PropTypes.array,
        locationModal: PropTypes.bool,
        locationModalInput: PropTypes.object,
        locationModalAction: PropTypes.string,
        updateLocationModal: PropTypes.func.isRequired,
        updateLocationModalInput: PropTypes.func.isRequired,
        updateLocationModalAction: PropTypes.func.isRequired,
        updateLocationToEdit: PropTypes.func.isRequired,
        locationToEdit: PropTypes.string,
        deleteLocation: PropTypes.func.isRequired,
        updateLocationListPage: PropTypes.func.isRequired,
        updateLoginLogListPage: PropTypes.func.isRequired,
        updateManufacturerListPage: PropTypes.func.isRequired,
        updateUserListPage: PropTypes.func.isRequired,
        updateManufacturerToEdit: PropTypes.func.isRequired,
        updateManufacturerModalAction: PropTypes.func.isRequired,
        updateManufacturerModalInput: PropTypes.func.isRequired,
        updateManufacturerModal: PropTypes.func.isRequired,
        saveManufacturer: PropTypes.func.isRequired,
        updateManufacturer: PropTypes.func.isRequired,
        toggleActivation: PropTypes.func.isRequired,
        deleteUser: PropTypes.func.isRequired,
        updateUserModal: PropTypes.func.isRequired,
        updateUserModalAction: PropTypes.func.isRequired,
        updateUserToEdit: PropTypes.func.isRequired,
        userToEdit: PropTypes.object,
        updateDropdown: PropTypes.func,
        locationDropdown: PropTypes.object,
        userGroupDropdown: PropTypes.object,
        locationList: PropTypes.array,
        allLocations: PropTypes.array,
        currentUser: PropTypes.object,
        saveUser: PropTypes.func,
        updateUser: PropTypes.func,
        userSearch: PropTypes.string,
        updateUserSearch: PropTypes.func,
        updateModal: PropTypes.func,
        saveTeam: PropTypes.func,
        teamToEdit: PropTypes.object,
        userAdditionalLocationsDropdown: PropTypes.object,
        userAllowedLocationsClaimDropdown: PropTypes.object,
        locationSpecialAppointment: PropTypes.object,
        locationSpecialAppointmentList: PropTypes.array,
        updateLocationSpecialAppointmentListPage: PropTypes.func,
        subgroupsDropdown: PropTypes.object,
        pns: PropTypes.array,
        loadStatuses: PropTypes.func
    };

    componentDidMount() {
        this.props.updateUserSearch('');
        this.props.updateLocationSpecialAppointmentListPage(0);
        this.props.updateUserModal(false);
        this.props.updateUserToEdit({});
        this.props.updateUserListPage(0);
        this.props.loadStatuses();
    }


    getModalStyle(value) {
        if (value) {
            return { display: 'block' };
        } else {
            return { display: 'none' };
        }
    }

    getLocationModalTitle(action) {
        if (action === 'new') {
            return 'Neuen Standort hinzufügen';
        } else {
            return 'Standort bearbeiten';
        }
    }

    getManufacturerModalTitle(action) {
        if (action === 'new') {
            return 'Neuen Hersteller hinzufügen';
        } else {
            return 'Hersteller bearbeiten';
        }
    }

    getUserModalTitle(action) {
        if (action === 'new') {
            return 'Neuen Benutzer hinzufügen';
        } else {
            return 'Benutzer bearbeiten';
        }
    }

    getLocationModalButton(action) {
        if (action === 'new') {
            return 'Erstellen';
        } else {
            return 'Speichern';
        }
    }


    saveLocation(action) {
        if (!this.validateIp(this.props.locationModalInput.ip)) {
            alert('Die Ip Adresse ist nicht gültig');
            return false;
        }

        if (
            this.props.locationModalInput.name &&
            this.props.locationModalInput.street &&
            this.props.locationModalInput.zip &&
            this.props.locationModalInput.city &&
            this.props.regionsDropdown.selectedLabel &&
            this.props.locationModalInput.ip &&
            this.props.locationModalInput.position
        ) {
            if (action === 'new') {
                this.props.addLocation(true);
            } else {
                let loc = this.props.locationModalInput;
                loc.id = this.props.locationToEdit;
                this.props.updateLocation(loc);
            }
            this.props.updateLocationModal(false);
        } else {
            alert('Bitte füllen Sie alle Felder aus.');
        }
    }

    saveManufacturer(action) {
        if (this.props.manufacturerModalInput) {
            if (action === 'new') {
                this.props.saveManufacturer(true);
            } else {
                let manufacturer = {
                    name: this.props.manufacturerModalInput,
                    id: this.props.manufacturerToEdit,
                };
                this.props.updateManufacturer(manufacturer);
            }
            this.props.updateManufacturerModal(false);
        } else {
            alert('Bitte füllen Sie alle Felder aus.');
        }
    }

    triggerFileInput(fileInput) {
        fileInput.click()
    }

    getUrl() {
        if (this.props.userToEdit.file) {
            return this.props.userToEdit.file;
        } else if (this.props.userToEdit.resourceUri) {
            return this.props.userToEdit.resourceUri;
        } else {
            return ''
        }
    }

    getBase64(file) {
        if (!file) {
            return null;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            console.log(file.name);
            this.props.updateUserToEditData({prop:'file', val: reader.result});
            this.props.updateUserToEditData({prop:'fileName', val: file.name.split(".")[0]})
        };
    }
    saveUser(action) {
        if (action === 'new') {
            if (!this.checkInput(this.props.locationDropdown.selectedId)) {
                return;
            }
            if (!this.checkInput(this.props.userGroupDropdown.selectedId)) {
                return;
            }
            if (!this.checkInput(this.props.userToEdit.firstName)) {
                return;
            }
            if (!this.checkInput(this.props.userToEdit.lastName)) {
                return;
            }
            if (!this.checkInput(this.props.userToEdit.personalNumber)) {
                return;
            }
            if (this.props.pns.includes(this.props.userToEdit.personalNumber)) {
                alert('Personalnummer bereits vergeben.');
                return;
            }
            if (!this.checkInput(this.props.userToEdit.email)) {
                return;
            }
            if (!this.validateEmail(this.props.userToEdit.email)) {
                alert('Benutzername ist nicht gültig.');
                return;
            }
            if (!this.checkInput(this.props.userToEdit.password)) {
                return;
            }
            if (!this.checkInput(this.props.userToEdit.passwordRepeat)) {
                return;
            }
            if (
                !this.checkPassword(
                    this.props.userToEdit.password,
                    this.props.userToEdit.passwordRepeat
                )
            ) {
                return;
            }

            console.log(this.props.userAdditionalLocationsDropdown.selectedId)
            console.log(this.props.userAdditionalLocationsDropdown.selectedId !== '' &&
            this.props.userAdditionalLocationsDropdown.selectedId !== []
                ? true
                : this.props.userToEdit.externalLogin || false)

            this.props.saveUser({
                user: {
                    firstName: this.props.userToEdit.firstName,
                    lastName: this.props.userToEdit.lastName,
                    email: this.props.userToEdit.email,
                    phone: this.props.userToEdit.phone || '',
                    location: this.props.locationDropdown.selectedId,
                    additionalLocations:this.props.userAdditionalLocationsDropdown.selectedId || [],
                    allowedLocationsClaim:this.props.userAllowedLocationsClaimDropdown.selectedId || [],
                    availableHolidays: this.props.userToEdit.availableHolidays,
                    holidayContingent: this.props.userToEdit.holidayContingent,
                    externalLogin:
                        this.props.userAdditionalLocationsDropdown.selectedId.length !== 0  ? true
                            : this.props.userToEdit.externalLogin || false,
                    deductionHolidaysCurrentYear:
                        this.props.userToEdit.deductionHolidaysCurrentYear || 0,
                    holidaySurplusPreviousYear:
                        this.props.userToEdit.holidaySurplusPreviousYear || 0,
                    personalNumber: this.props.userToEdit.personalNumber || '',
                    freeDay: this.props.userToEdit.freeDay || '',
                    freeDay2: this.props.userToEdit.freeDay2 || '',
                    freeDay3: this.props.userToEdit.freeDay3 || '',
                    freeDay4: this.props.userToEdit.freeDay4 || '',
                    freeDay5: this.props.userToEdit.freeDay5 || '',
                    freeDay6: this.props.userToEdit.freeDay6 || '',
                    freeDay7: this.props.userToEdit.freeDay7 || '',
                    presence1: this.props.userToEdit.presence1 || '',
                    presence2: this.props.userToEdit.presence2 || '',
                    presence3: this.props.userToEdit.presence3 || '',
                    presence4: this.props.userToEdit.presence4 || '',
                    presence5: this.props.userToEdit.presence5 || '',
                    presence6: this.props.userToEdit.presence6 || '',
                    presence7: this.props.userToEdit.presence7 || '',
                },
                password: this.props.userToEdit.password,
                role: this.props.userGroupDropdown.selectedId,
            });
        } else {
            if (!this.checkInput(this.props.locationDropdown.selectedId)) {
                return;
            }
            if (!this.checkInput(this.props.userGroupDropdown.selectedId)) {
                return;
            }
            if (!this.checkInput(this.props.userToEdit.firstName)) {
                return;
            }
            if (!this.checkInput(this.props.userToEdit.lastName)) {
                return;
            }
            if (!this.checkInput(this.props.userToEdit.personalNumber)) {
                return;
            }

            if (!this.checkInput(this.props.userToEdit.email)) {
                return;
            }
            if (!this.validateEmail(this.props.userToEdit.email)) {
                alert('Benutzername ist nicht gültig.');
                return;
            }

            if (this.props.pns.includes(this.props.userToEdit.personalNumber)) {
                const currentUser = this.props.userList.find(user => user.id === this.props.userToEdit.id)
                console.log(currentUser.id);

                console.log(this.props.userToEdit.id);
                console.log(this.props.userToEdit.personalNumber);
                console.log(currentUser.personalNumber);
                if(currentUser.personalNumber !== this.props.userToEdit.personalNumber) {
                    alert('Personalnummer bereits vergeben.');
                    return;
                }
            }
            if (
                !this.checkPassword(
                    this.props.userToEdit.password,
                    this.props.userToEdit.passwordRepeat
                )
            ) {
                return;
            }


            this.props.updateUser({
                user: {
                    firstName: this.props.userToEdit.firstName,
                    lastName: this.props.userToEdit.lastName,
                    email: this.props.userToEdit.email,
                    phone: this.props.userToEdit.phone || '',
                    location: this.props.locationDropdown.selectedId,
                    additionalLocations: this.props.userAdditionalLocationsDropdown.selectedId || [],
                    allowedLocationsClaim: this.props.userAllowedLocationsClaimDropdown.selectedId || [],
                    availableHolidays: this.props.userToEdit.availableHolidays,
                    holidayContingent: this.props.userToEdit.holidayContingent,
                    externalLogin:
                        this.props.userAdditionalLocationsDropdown.selectedId.length !== 0  ? true
                            : this.props.userToEdit.externalLogin || false,
                    deductionHolidaysCurrentYear:
                        this.props.userToEdit.deductionHolidaysCurrentYear || 0,
                    holidaySurplusPreviousYear:
                        this.props.userToEdit.holidaySurplusPreviousYear || 0,
                    personalNumber: this.props.userToEdit.personalNumber || '',
                    freeDay: this.props.userToEdit.freeDay || '',
                    freeDay2: this.props.userToEdit.freeDay2 || '',
                    freeDay3: this.props.userToEdit.freeDay3 || '',
                    freeDay4: this.props.userToEdit.freeDay4 || '',
                    freeDay5: this.props.userToEdit.freeDay5 || '',
                    freeDay6: this.props.userToEdit.freeDay6 || '',
                    freeDay7: this.props.userToEdit.freeDay7 || '',
                    presence1: this.props.userToEdit.presence1 || '',
                    presence2: this.props.userToEdit.presence2 || '',
                    presence3: this.props.userToEdit.presence3 || '',
                    presence4: this.props.userToEdit.presence4 || '',
                    presence5: this.props.userToEdit.presence5 || '',
                    presence6: this.props.userToEdit.presence6 || '',
                    presence7: this.props.userToEdit.presence7 || '',
                },
                password: this.props.userToEdit.password,
                role: this.props.userGroupDropdown.selectedId,
                updateCalendar: this.userCalenderSettingsDiff(),
                image: this.props.userToEdit.file || ''
            });
        }
    }
    userCalenderSettingsDiff() {
        const currentUser = this.props.userList.find(user => user.id === this.props.userToEdit.id)
        if (this.props.userToEdit.freeDay !== currentUser.freeDay) {
            return true
        }
        if (this.props.userToEdit.freeDay2 !== currentUser.freeDay2) {
            return true
        }
        if (this.props.userToEdit.freeDay3 !== currentUser.freeDay3) {
            return true
        }
        if (this.props.userToEdit.freeDay4 !== currentUser.freeDay4) {
            return true
        }
        if (this.props.userToEdit.freeDay5 !== currentUser.freeDay5) {
            return true
        }
        if (this.props.userToEdit.freeDay6 !== currentUser.freeDay6) {
            return true
        }
        if (this.props.userToEdit.freeDay7 !== currentUser.freeDay7) {
            return true
        }
        if (this.props.userToEdit.presence1 !== currentUser.presence1) {
            return true
        }
        if (this.props.userToEdit.presence2 !== currentUser.presence2) {
            return true
        }
        if (this.props.userToEdit.presence3 !== currentUser.presence3) {
            return true
        }
        if (this.props.userToEdit.presence4 !== currentUser.presence4) {
            return true
        }
        if (this.props.userToEdit.presence5 !== currentUser.presence5) {
            return true
        }
        if (this.props.userToEdit.presence6 !== currentUser.presence6) {
            return true
        }
        if (this.props.userToEdit.presence7 !== currentUser.presence7) {
            return true
        }
        return false
    }
    editUser(user) {
        this.props.updateUserModal(true);
        this.props.updateUserModalAction('edit');
        this.props.updateUserToEditData({ prop: 'id', val: user.id });
        this.props.updateUserToEditData({ prop: 'firstName', val: user.firstName });
        this.props.updateUserToEditData({ prop: 'lastName', val: user.lastName });
        this.props.updateUserToEditData({ prop: 'email', val: user.email });
        this.props.updateUserToEditData({ prop: 'phone', val: user.phone });
        this.props.updateUserToEditData({ prop: 'availableHolidays', val: user.availableHolidays });
        this.props.updateUserToEditData({ prop: 'holidayContingent', val: user.holidayContingent });
        this.props.updateUserToEditData({ prop: 'resourceUri', val: user.resourceUri });
        this.props.updateUserToEditData({
            prop: 'deductionHolidaysCurrentYear',
            val: user.deductionHolidaysCurrentYear,
        });
        this.props.updateUserToEditData({
            prop: 'holidaySurplusPreviousYear',
            val: user.holidaySurplusPreviousYear,
        });
        this.props.updateUserToEditData({ prop: 'externalLogin', val: user.externalLogin });
        this.props.updateUserToEditData({ prop: 'personalNumber', val: user.personalNumber });
        this.props.updateUserToEditData({ prop: 'freeDay', val: user.freeDay || '' });
        this.props.updateUserToEditData({ prop: 'freeDay2', val: user.freeDay2 || ''  });
        this.props.updateUserToEditData({ prop: 'freeDay3', val: user.freeDay3 || ''  });
        this.props.updateUserToEditData({ prop: 'freeDay4', val: user.freeDay4 || ''  });
        this.props.updateUserToEditData({ prop: 'freeDay5', val: user.freeDay5 || ''  });
        this.props.updateUserToEditData({ prop: 'freeDay6', val: user.freeDay6 || ''  });
        this.props.updateUserToEditData({ prop: 'freeDay7', val: user.freeDay7 || ''  });
        this.props.updateUserToEditData({ prop: 'presence1', val: user.presence1 });
        this.props.updateUserToEditData({ prop: 'presence2', val: user.presence2 });
        this.props.updateUserToEditData({ prop: 'presence3', val: user.presence3 });
        this.props.updateUserToEditData({ prop: 'presence4', val: user.presence4 });
        this.props.updateUserToEditData({ prop: 'presence5', val: user.presence5 });
        this.props.updateUserToEditData({ prop: 'presence6', val: user.presence6 });
        this.props.updateUserToEditData({ prop: 'presence7', val: user.presence7 });
        this.props.updateUserToEditData({
            prop: 'holidaysTakenThisYear',
            val: user.holidaysTakenThisYear,
        });

        this.props.updateDropdown({
            dropdown: this.props.locationDropdown.path,
            prop: 'selectedId',
            val: user.location ? user.location.id : '',
        });
        this.props.updateDropdown({
            dropdown: this.props.locationDropdown.path,
            prop: 'selectedLabel',
            val: user.locationLabel,
        });
        this.props.updateDropdown({
            dropdown: this.props.userGroupDropdown.path,
            prop: 'selectedId',
            val: user.roleIdentifier,
        });
        this.props.updateDropdown({
            dropdown: this.props.userGroupDropdown.path,
            prop: 'selectedLabel',
            val: user.roleLabel,
        });

        this.props.updateDropdown({
            dropdown: this.props.userAdditionalLocationsDropdown.path,
            prop: 'selectedLabel',
            val: user.additionalLocationsLabel,
        });
        this.props.updateDropdown({
            dropdown: this.props.userAdditionalLocationsDropdown.path,
            prop: 'selectedId',
            val: user.additionalLocations ? user.additionalLocations : '',
        });

        this.props.updateDropdown({
            dropdown: this.props.userAllowedLocationsClaimDropdown.path,
            prop: 'selectedLabel',
            val: user.allowedLocationsClaim,
        });

        this.props.updateDropdown({
            dropdown: this.props.userAllowedLocationsClaimDropdown.path,
            prop: 'selectedId',
            val: user.allowedLocationsClaim ? user.allowedLocationsClaim : ''
        });
    }

    validateEmail(email) {
        //let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let re = /^[0-9a-zA-Z.]+$/;
        return re.test(String(email).toLowerCase());
    }
    validateIp(ip) {
        let ips = ip.replace(/\s+/g, '').trim().split(",");
        console.log(ips);
        let re = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ips.every((singleIp) => re.test(singleIp));
    }
    checkInput(input) {
        if (input) {
            if (input.length >= 1) {
                return true;
            } else {
                alert('Bitte füllen Sie alle Felder aus.');
                return false;
            }
        } else {
            alert('Bitte füllen Sie alle Felder aus.');
            return false;
        }
    }
    checkPassword(password1, password2) {
        if (!password1) {
            return true;
        }
        if (password1 !== password2) {
            alert('Passwörter sind nicht identisch.');
            return false;
        }
        if (password1.length < 6) {
            alert('Passwort muss mindestens 6 zeichen lang sein.');
            return false;
        }
        return true;
    }

    deleteLocation(location) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Standort löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Standort '" + location.name + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteLocation', param: location}})
    }

    deleteManufacturer(manufacturer) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Hersteller löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Hersteller '" + manufacturer.name + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteManufacturer', param: manufacturer}})
    }

    deleteColor(color) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Label löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Label '" + color.name + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteColor', param: color}})
    }

    deleteStatus(status) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Label löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Label '" + status.name + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteStatus', param: status}})
    }
    deleteClaimDropdownManufacturer(claimDropdownManufacturer) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Hersteller löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Hersteller '" + claimDropdownManufacturer.name + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteClaimDropdownManufacturer', param: claimDropdownManufacturer}})
    }
    deleteClaimDropdownReason(claimDropdownReason) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Grund löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Grund '" + claimDropdownReason.name + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteClaimDropdownReason', param: claimDropdownReason}})
    }
    deleteClaimDropdownQuality(claimDropdownQuality) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Qualität löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Qualität '" + claimDropdownQuality.name + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteClaimDropdownQuality', param: claimDropdownQuality}})
    }

    deleteUser(user) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Benutzer löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Benutzer '" + user.lastName + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteUser', param: user}})
    }

    deleteLocationSpecialAppointment(locationSpecialAppointment) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Sondertermin löschen'})
        this.props.updateConfirmModal({prop: 'text', value: "Sondertermin '" + locationSpecialAppointment.info + "' löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteLocationSpecialAppointment', param: locationSpecialAppointment}})
    }

    getLocationShortName(id) {
        const loc =  this.props.allLocations.filter(location => location.id === id)
        if (loc[0]) {
            return loc[0].shortName + ' '
        }
        return ''
    }

    saveLocationSpecialAppointment(){
        if (!this.props.locationSpecialAppointment.date) {
            return alert('Bitte geben Sie ein Datum ein')
        }
        if (!this.props.locationSpecialAppointment.info) {
            return alert('Bitte geben Sie ein Titel ein')
        }
        if (this.props.locationSpecialAppointment.info.length < 1) {
            return alert('Bitte geben Sie ein Titel ein')
        }
        this.props.checkExistingLocationSpecialAppointment(this.props.locationSpecialAppointmentModal.action)
    }

    showSorting({name, column}) {
        if(this.props[name].includes(column)){
            return <i className="fa fa-angle-up" />
        }
        if(this.props[name].includes(`-${column}`)){
            return <i className="fa fa-angle-down" />
        }

    }
    updateSorting({name, value}) {
        this.props.updateSort({
            name, value
        })
        switch (name) {
            case 'userSort' : this.props.updateUserSearch(this.props.userSearch || ''); break;
            case 'specialAppointmentSort' : this.props.updateLocationSpecialAppointmentSearchTerm(this.props.locationSpecialAppointmentSearchTerm || ''); break;
            case 'locationSort' : this.props.updateLocationListPage(0); break;
            case 'loginlogSort' : this.props.updateLoginLogListPage(0); break;
            case 'colorSort' : this.props.updateColorListPage(0); break;
            case 'statusSort' : this.props.updateStatusListPage(0); break;
            case 'claimDropdownManufacturerSort' : this.props.updateClaimDropdownManufacturerListPage(0); break;
            case 'claimDropdownReasonSort' : this.props.updateclaimDropdownReasonListPage(0); break;
            case 'claimDropdownQualitySort' : this.props.updateclaimDropdownQualityListPage(0); break;
            default: //
        }
    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    render() {
        return (
            <div id="main-side">
                <Breadcrumb path="Einstellungen" />
                { this.props.permissionsView && <Permissions /> }
                { !this.props.permissionsView &&
                    <main>
                        <div id="ModalLocation" className="modal" style={this.getModalStyle(this.props.locationModal)}>
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-check-square" aria-hidden="true"/>{' '}
                                    {this.getLocationModalTitle(this.props.locationModalAction)}
                                </span>{' '}
                                    <span className="close">
                                    <a
                                        href="/#-"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.updateLocationModal(false);
                                            this.props.updateLocationToEdit('');
                                            this.props.updateLocationModalInput({
                                                value: '',
                                                prop: 'name',
                                            });
                                            this.props.updateLocationModalInput({
                                                value: '',
                                                prop: 'street',
                                            });
                                            this.props.updateLocationModalInput({
                                                value: '',
                                                prop: 'zip',
                                            });
                                            this.props.updateLocationModalInput({
                                                value: '',
                                                prop: 'city',
                                            });
                                            this.props.updateLocationModalInput({
                                                value: '',
                                                prop: 'shortName',
                                            });
                                            this.props.updateLocationModalInput({
                                                value: '',
                                                prop: 'ip',
                                            });
                                            this.props.updateLocationModalInput({
                                                value: '',
                                                prop: 'position',
                                            });
                                            this.props.updateLocationModalInput({
                                                value: false,
                                                prop: 'hideInSortiment',
                                            });
                                        }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-8 large-8">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={
                                                                this.props.locationModalInput.name || ''
                                                            }
                                                            onChange={(e) =>
                                                                this.props.updateLocationModalInput({
                                                                    value: e.target.value,
                                                                    prop: 'name',
                                                                })
                                                            }
                                                        />
                                                        <label>Name</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-4 large-4">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={
                                                                this.props.locationModalInput
                                                                    .shortName || ''
                                                            }
                                                            onChange={(e) =>
                                                                this.props.updateLocationModalInput({
                                                                    value: e.target.value,
                                                                    prop: 'shortName',
                                                                })
                                                            }
                                                        />{' '}
                                                        <label>Kürzel</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={
                                                                this.props.locationModalInput.street ||
                                                                ''
                                                            }
                                                            onChange={(e) =>
                                                                this.props.updateLocationModalInput({
                                                                    value: e.target.value,
                                                                    prop: 'street',
                                                                })
                                                            }
                                                        />{' '}
                                                        <label>Straße + Nr.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-4 large-4">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={
                                                                this.props.locationModalInput.zip || ''
                                                            }
                                                            onChange={(e) =>
                                                                this.props.updateLocationModalInput({
                                                                    value: e.target.value,
                                                                    prop: 'zip',
                                                                })
                                                            }
                                                        />{' '}
                                                        <label>PLZ</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-8 large-8">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={
                                                                this.props.locationModalInput.city || ''
                                                            }
                                                            onChange={(e) =>
                                                                this.props.updateLocationModalInput({
                                                                    value: e.target.value,
                                                                    prop: 'city',
                                                                })
                                                            }
                                                        />{' '}
                                                        <label>Ort</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                        <input type="text"
                                                               value={this.props.locationModalInput.position || ''}
                                                               onChange={(e) =>
                                                                   this.props.updateLocationModalInput({
                                                                       value: e.target.value,
                                                                       prop: 'position',
                                                                   })
                                                               }
                                                        />{' '}
                                                        <label>Position im Sortiment</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="check-list" style={{boxShadow: 'none'}}>
                                                        <div className="check-list-main">
                                                            <div className="checkbox-wrapper"
                                                                 style={{backgroundColor: '#fff'}}>
                                                                <label>
                                                                    <span
                                                                        className={this.props.locationModalInput.hideInSortiment === true
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                        }
                                                                        onClick={() => this.props.updateLocationModalInput({
                                                                            value: !this.props.locationModalInput.hideInSortiment,
                                                                            prop: 'hideInSortiment',
                                                                        })
                                                                        }
                                                                    />
                                                                </label>
                                                                <div className="checkbox-text">
                                                                    <span className={'prim-text'}>
                                                                        Im Sortiment Verstecken
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-6 large-6">
                                                    <Dropdown
                                                        dropdown={this.props.regionsDropdown}
                                                        dropdownList={regions}
                                                        itemIdProp={'id'}
                                                        itemLabelProp={'label'}
                                                    />
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                    <textarea
                                                        rows={3}
                                                        value={
                                                            this.props.locationModalInput.ip || ''
                                                        }
                                                        onChange={(e) =>
                                                            this.props.updateLocationModalInput({
                                                                value: e.target.value,
                                                                prop: 'ip',
                                                            })
                                                        }
                                                    />{' '}
                                                        <label>Ip Adressen (kommagetrennt)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.getLocationModalButton(
                                                            this.props.locationModalAction
                                                        )}
                                                        inverted
                                                        onClick={() =>
                                                            this.saveLocation(
                                                                this.props.locationModalAction
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div
                            id="ModalManufacturer"
                            className="modal"
                            style={this.getModalStyle(this.props.newManufacturerModal)}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-check-square" aria-hidden="true"/>{' '}
                                    {this.getManufacturerModalTitle(
                                        this.props.manufacturerModalAction
                                    )}
                                </span>{' '}
                                    <span className="close">
                                    <a
                                        href="/#-"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.updateManufacturerModal(false);
                                            this.props.updateManufacturerToEdit('');
                                            this.props.updateManufacturerModalInput('');
                                        }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={
                                                                this.props.manufacturerModalInput || ''
                                                            }
                                                            onChange={(e) =>
                                                                this.props.updateManufacturerModalInput(
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <label>Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.getLocationModalButton(
                                                            this.props.manufacturerModalAction
                                                        )}
                                                        inverted
                                                        onClick={() =>
                                                            this.saveManufacturer(
                                                                this.props.manufacturerModalAction
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div id="ModalColor" className="modal" style={this.getModalStyle(this.props.colorModal)}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-dashboard" aria-hidden="true"/>
                                    {
                                        this.props.colorModalAction === 'new' ?
                                            'Label hinzufügen' :
                                            'Label bearbeiten'
                                    }
                                </span>
                                    <span className="close">
                                    <a
                                        href="/#-"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.updateColorModal(false);
                                            this.props.updateColorToEdit({
                                                id: '',
                                                color: '',
                                                hex: ''
                                            });
                                        }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={this.props.colorToEdit.name || ''}
                                                            onChange={(e) =>
                                                                this.props.updateColorToEdit({
                                                                    ...this.props.colorToEdit,
                                                                    name: e.target.value
                                                                })
                                                            }
                                                        />
                                                        <label>Name</label>
                                                    </div>
                                                </div>
                                                {/*<div className="small-12 medium-12 large-12">*/}
                                                {/*        <TwitterPicker*/}
                                                {/*            width={'100%'}*/}
                                                {/*        color={ this.props.colorToEdit.hex}*/}
                                                {/*        colors={['#e1bee7','#c5cae9', '#c8e6c9', '#fff9c4', '#ffccbc', '#4D4D4D', '#999999', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}*/}
                                                {/*        onChangeComplete = {(color) => this.props.updateColorToEdit({*/}
                                                {/*            ...this.props.colorToEdit,*/}
                                                {/*            hex: color.hex*/}
                                                {/*        }) }/>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.props.colorModalAction === 'new' ? 'Erstellen' : 'Speichern'}
                                                        inverted
                                                        onClick={() => {
                                                            if (this.props.colorToEdit.name === '') {
                                                                return alert('Bitte geben Sie einen Namen ein');
                                                            }
                                                            if (this.props.colorToEdit.hex === '') {
                                                                return alert('Bitte wählen Sie eine Farbe');
                                                            }
                                                            this.props.colorModalAction === 'new' ?
                                                                this.props.saveColor(this.props.colorToEdit)
                                                                : this.props.updateColor(this.props.colorToEdit)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div id="ModalStatus" className="modal" style={this.getModalStyle(this.props.statusModal)}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-dashboard" aria-hidden="true"/>
                                    {
                                        this.props.statusModalAction === 'new' ?
                                            'Status hinzufügen' :
                                            'Status bearbeiten'
                                    }
                                </span>
                                    <span className="close">
                                    <a
                                        href="/#-"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.updateStatusModal(false);
                                            this.props.updateStatusToEdit({
                                                id: '',
                                                color: ''
                                            });
                                        }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={this.props.statusToEdit.name || ''}
                                                            onChange={(e) =>
                                                                this.props.updateStatusToEdit({
                                                                    ...this.props.statusToEdit,
                                                                    name: e.target.value
                                                                })
                                                            }
                                                        />
                                                        <label>Name</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.props.statusModalAction === 'new' ? 'Erstellen' : 'Speichern'}
                                                        inverted
                                                        onClick={() => {
                                                            if (this.props.statusToEdit.name === '') {
                                                                return alert('Bitte geben Sie einen Namen ein');
                                                            }
                                                            if (this.props.statusToEdit.hex === '') {
                                                                return alert('Bitte wählen Sie eine Farbe');
                                                            }
                                                            this.props.statusModalAction === 'new' ?
                                                                this.props.saveStatus(this.props.statusToEdit)
                                                                : this.props.updateStatus(this.props.statusToEdit)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div id="ModalClaimDropdownManufacturer" className="modal"
                             style={this.getModalStyle(this.props.claimDropdownManufacturerModal)}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-dashboard" aria-hidden="true"/>
                                    {
                                        this.props.claimDropdownManufacturerModalAction === 'new' ?
                                            'Label hinzufügen' :
                                            'Label bearbeiten'
                                    }
                                </span>
                                    <span className="close">
                                    <a
                                        href="/#-"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.updateClaimDropdownManufacturerModal(false);
                                            this.props.updateClaimDropdownManufacturerToEdit({
                                                id: '',
                                                name: ''
                                            });
                                        }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={this.props.claimDropdownManufacturerToEdit.name || ''}
                                                            onChange={(e) =>
                                                                this.props.updateClaimDropdownManufacturerToEdit({
                                                                    ...this.props.claimDropdownManufacturerToEdit,
                                                                    name: e.target.value
                                                                })
                                                            }
                                                        />
                                                        <label>Name</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.props.claimDropdownManufacturerModalAction === 'new' ? 'Erstellen' : 'Speichern'}
                                                        inverted
                                                        onClick={() => {
                                                            if (this.props.claimDropdownManufacturerToEdit.name === '') {
                                                                return alert('Bitte geben Sie einen Namen ein');
                                                            }
                                                            this.props.claimDropdownManufacturerModalAction === 'new' ?
                                                                this.props.saveClaimDropdownManufacturer(this.props.claimDropdownManufacturerToEdit)
                                                                : this.props.updateClaimDropdownManufacturer(this.props.claimDropdownManufacturerToEdit)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div id="ModalClaimDropdownReason" className="modal"
                             style={this.getModalStyle(this.props.claimDropdownReasonModal)}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-dashboard" aria-hidden="true"/>
                                    {
                                        this.props.claimDropdownReasonModalAction === 'new' ?
                                            'Label hinzufügen' :
                                            'Label bearbeiten'
                                    }
                                </span>
                                    <span className="close">
                                    <a
                                        href="/#-"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.updateClaimDropdownReasonModal(false);
                                            this.props.updateClaimDropdownReasonToEdit({
                                                id: '',
                                                name: ''
                                            });
                                        }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={this.props.claimDropdownReasonToEdit.name || ''}
                                                            onChange={(e) =>
                                                                this.props.updateClaimDropdownReasonToEdit({
                                                                    ...this.props.claimDropdownReasonToEdit,
                                                                    name: e.target.value
                                                                })
                                                            }
                                                        />
                                                        <label>Name</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.props.claimDropdownReasonModalAction === 'new' ? 'Erstellen' : 'Speichern'}
                                                        inverted
                                                        onClick={() => {
                                                            if (this.props.claimDropdownReasonToEdit.name === '') {
                                                                return alert('Bitte geben Sie einen Namen ein');
                                                            }
                                                            this.props.claimDropdownReasonModalAction === 'new' ?
                                                                this.props.saveClaimDropdownReason(this.props.claimDropdownReasonToEdit)
                                                                : this.props.updateClaimDropdownReason(this.props.claimDropdownReasonToEdit)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div id="ModalClaimDropdownQuality" className="modal"
                             style={this.getModalStyle(this.props.claimDropdownQualityModal)}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-dashboard" aria-hidden="true"/>
                                    {
                                        this.props.claimDropdownQualityModalAction === 'new' ?
                                            'Label hinzufügen' :
                                            'Label bearbeiten'
                                    }
                                </span>
                                    <span className="close">
                                    <a
                                        href="/#-"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.updateClaimDropdownQualityModal(false);
                                            this.props.updateClaimDropdownQualityToEdit({
                                                id: '',
                                                name: ''
                                            });
                                        }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            value={this.props.claimDropdownQualityToEdit.name || ''}
                                                            onChange={(e) =>
                                                                this.props.updateClaimDropdownQualityToEdit({
                                                                    ...this.props.claimDropdownQualityToEdit,
                                                                    name: e.target.value
                                                                })
                                                            }
                                                        />
                                                        <label>Name</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.props.claimDropdownQualityModalAction === 'new' ? 'Erstellen' : 'Speichern'}
                                                        inverted
                                                        onClick={() => {
                                                            if (this.props.claimDropdownQualityToEdit.name === '') {
                                                                return alert('Bitte geben Sie einen Namen ein');
                                                            }
                                                            this.props.claimDropdownQualityModalAction === 'new' ?
                                                                this.props.saveClaimDropdownQuality(this.props.claimDropdownQualityToEdit)
                                                                : this.props.updateClaimDropdownQuality(this.props.claimDropdownQualityToEdit)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div id="ModalUser" className="modal"
                             style={this.getModalStyle(this.props.userModal)}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-check-square" aria-hidden="true"/>{' '}
                                    {this.getUserModalTitle(this.props.userModalAction)}
                                </span>{' '}
                                    <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.updateUserModal(false);
                                           this.props.updateUserToEdit({});
                                           this.props.updateDropdown({
                                               dropdown: this.props.userAllowedLocationsClaimDropdown.path,
                                               prop: 'selectedLabel',
                                               val: '',
                                           });

                                           this.props.updateDropdown({
                                               dropdown: this.props.userAllowedLocationsClaimDropdown.path,
                                               prop: 'selectedId',
                                               val: ''
                                           });
                                       }}
                                    >
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                                </div>
                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                {this.can('user_image_can_edit') &&

                                                    <div className="small-12 medium-12 large-12">
                                                        <a href="/"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               this.triggerFileInput(this.refs.fileInput)
                                                           }}
                                                           className="formimagelink">
                                                            <div className="formimage">
                                                                <div className="content"
                                                                     style={{
                                                                         backgroundImage: "url(" + this.getUrl() + ")",
                                                                         backgroundPosition: "center",
                                                                         borderRadius: "50%",
                                                                         backgroundRepeat: "no-repeat",
                                                                         backgroundSize: "cover",
                                                                     }}
                                                                >
                                                                    <p>
                                                                        Bild hochladen
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <input type="file" ref="fileInput" onChange={(e) => {
                                                            e.preventDefault();
                                                            this.getBase64(e.target.files[0]);
                                                        }}/>
                                                    </div>
                                                }

                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                        <input
                                                            value={this.props.userToEdit.firstName || ''}
                                                            onChange={e =>
                                                                this.props.updateUserToEditData({
                                                                    prop: 'firstName',
                                                                    val: e.target.value,
                                                                })
                                                            }
                                                            disabled={!this.can('einstellungen_benutzer_bearbeiten_alle_felder')}
                                                        />
                                                        <label>Vorname</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                        <input
                                                            value={this.props.userToEdit.lastName || ''}
                                                            disabled={!this.can('einstellungen_benutzer_bearbeiten_alle_felder')}
                                                            onChange={(e) =>
                                                                this.props.updateUserToEditData({
                                                                    prop: 'lastName',
                                                                    val: e.target.value,
                                                                })
                                                            }
                                                        />
                                                        <label>Nachname</label>
                                                    </div>
                                                </div>
                                                {this.can('einstellungen_benutzer_bearbeiten_alle_felder') && (
                                                    <>
                                                        <div className="small-12 medium-6 large-6">
                                                            <div className="group">
                                                                <input disabled={
                                                                    this.can('einstellungen_benutzer_bearbeiten_benutzername') ? '' : 'disabled'
                                                                }
                                                                       value={this.props.userToEdit.email || ''}
                                                                       onChange={e =>
                                                                           this.props.updateUserToEditData(
                                                                               {
                                                                                   prop: 'email',
                                                                                   val: e.target.value,
                                                                               }
                                                                           )
                                                                       }
                                                                />
                                                                <label>Benutzername</label>
                                                            </div>
                                                        </div>
                                                        <div className="small-12 medium-6 large-6">
                                                            <div className="group">
                                                                <input type="text"
                                                                       value={this.props.userToEdit.phone || ''}
                                                                       onChange={(e) => this.props.updateUserToEditData({
                                                                           prop: 'phone',
                                                                           val: e.target.value,
                                                                       })}
                                                                />{' '}
                                                                <label>Telefon</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {this.can('einstellungen_benutzer_bearbeiten_alle_felder') && (
                                        <div className="content-wrapper content-form">
                                            <div className="content-wrapper-box">
                                                <div className="grid-wrapper">
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="password"
                                                                value={
                                                                    this.props.userToEdit.password || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'password',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Passwort</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="password"
                                                                value={
                                                                    this.props.userToEdit
                                                                        .passwordRepeat || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'passwordRepeat',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Passwortwiederholung</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.can('einstellungen_benutzer_bearbeiten_alle_felder') && (
                                        <div className="content-wrapper content-form">
                                            <div className="content-wrapper-box">
                                                <div className="grid-wrapper">
                                                    <div className="small-12 medium-6 large-6">
                                                        <Dropdown
                                                            dropdown={this.props.locationDropdown}
                                                            dropdownList={this.props.allLocations}
                                                            itemIdProp={'id'}
                                                            itemLabelProp={'label'}
                                                            syncDropdownPath={this.props.userAllowedLocationsClaimDropdown.path}
                                                            syncDropdown={this.props.userModalAction === 'new' ? true : false}
                                                        />
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <Dropdown
                                                            dropdown={this.props.userGroupDropdown}
                                                            dropdownList={this.props.userGroupList}
                                                            itemIdProp={'name'}
                                                            itemLabelProp={'label'}
                                                        />
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <Dropdown
                                                            dropdown={this.props.userAdditionalLocationsDropdown}
                                                            dropdownList={this.props.allLocations}
                                                            itemIdProp={'id'}
                                                            itemLabelProp={'label'}
                                                            defaultSelectedLabel={'Bitte wählen'}
                                                            multiple={true}
                                                        />
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <Dropdown
                                                            dropdown={this.props.userAllowedLocationsClaimDropdown}
                                                            dropdownList={this.props.allLocations}
                                                            itemIdProp={'id'}
                                                            itemLabelProp={'label'}
                                                            defaultSelectedLabel={'Bitte wählen'}
                                                            multiple={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/*{this.can('einstellungen_benutzer_bearbeiten_alle_felder') && (*/}
                                    {/*    <div className="content-wrapper content-form">*/}
                                    {/*        <div className="content-wrapper-box">*/}
                                    {/*            <div className="grid-wrapper">*/}
                                    {/*                <div className="small-12 medium-6 large-6">*/}
                                    {/*                    <div className="group">*/}
                                    {/*                        <input*/}
                                    {/*                            type="text"*/}
                                    {/*                            value={*/}
                                    {/*                                this.props.userToEdit*/}
                                    {/*                                    .holidayContingent || ''*/}
                                    {/*                            }*/}
                                    {/*                            onChange={(e) =>*/}
                                    {/*                                this.props.updateUserToEditData({*/}
                                    {/*                                    prop: 'holidayContingent',*/}
                                    {/*                                    val: e.target.value,*/}
                                    {/*                                })*/}
                                    {/*                            }*/}
                                    {/*                        />{' '}*/}
                                    {/*                        <label>Urlaubstage laut Vertrag</label>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="small-12 medium-6 large-6">*/}
                                    {/*                    <div className="group">*/}
                                    {/*                        <input*/}
                                    {/*                            type="text"*/}
                                    {/*                            value={*/}
                                    {/*                                this.props.userToEdit*/}
                                    {/*                                    .availableHolidays || ''*/}
                                    {/*                            }*/}
                                    {/*                            onChange={(e) =>*/}
                                    {/*                                this.props.updateUserToEditData({*/}
                                    {/*                                    prop: 'availableHolidays',*/}
                                    {/*                                    val: e.target.value,*/}
                                    {/*                                })*/}
                                    {/*                            }*/}
                                    {/*                        />{' '}*/}
                                    {/*                        <label>Urlaubstage gesamt für das Jahr {new Date().getFullYear()}</label>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*                /!*<div className="small-12 medium-6 large-6">*!/*/}
                                    {/*                /!*    <div className="group">*!/*/}
                                    {/*                /!*        <input*!/*/}
                                    {/*                /!*            type="text"*!/*/}
                                    {/*                /!*            disabled={true}*!/*/}
                                    {/*                /!*            value={*!/*/}
                                    {/*                /!*                Number(this.props.userToEdit.availableHolidays || 0) -*!/*/}
                                    {/*                /!*                Number(this.props.userToEdit.holidaysTakenThisYear || 0)*!/*/}
                                    {/*                /!*            }*!/*/}
                                    {/*                /!*            onChange={(e) =>*!/*/}
                                    {/*                /!*                this.props.updateUserToEditData({*!/*/}
                                    {/*                /!*                    prop:*!/*/}
                                    {/*                /!*                        'deductionHolidaysCurrentYear',*!/*/}
                                    {/*                /!*                    val: e.target.value,*!/*/}
                                    {/*                /!*                })*!/*/}
                                    {/*                /!*            }*!/*/}
                                    {/*                /!*        />{' '}*!/*/}
                                    {/*                /!*        <label>*!/*/}
                                    {/*                /!*            Abzug in Tagen im aktuellen Jahr*!/*/}
                                    {/*                /!*        </label>*!/*/}
                                    {/*                /!*    </div>*!/*/}
                                    {/*                /!*</div>*!/*/}
                                    {/*                /!*<div className="small-12 medium-6 large-6">*!/*/}
                                    {/*                /!*    <div className="group">*!/*/}
                                    {/*                /!*        <input*!/*/}
                                    {/*                /!*            disabled*!/*/}
                                    {/*                /!*            type="text"*!/*/}
                                    {/*                /!*            value={*!/*/}
                                    {/*                /!*                this.props.userToEdit*!/*/}
                                    {/*                /!*                    .holidaySurplusPreviousYear ||*!/*/}
                                    {/*                /!*                ''*!/*/}
                                    {/*                /!*            }*!/*/}
                                    {/*                /!*            onChange={(e) =>*!/*/}
                                    {/*                /!*                this.props.updateUserToEditData({*!/*/}
                                    {/*                /!*                    prop:*!/*/}
                                    {/*                /!*                        'holidaySurplusPreviousYear',*!/*/}
                                    {/*                /!*                    val: e.target.value,*!/*/}
                                    {/*                /!*                })*!/*/}
                                    {/*                /!*            }*!/*/}
                                    {/*                /!*        />{' '}*!/*/}
                                    {/*                /!*        <label>Urlaubsüberschuss aus Vorjahr</label>*!/*/}
                                    {/*                /!*    </div>*!/*/}
                                    {/*                /!*</div>*!/*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                    {this.can('einstellungen_benutzer_bearbeiten_alle_felder') && (
                                        <div className="content-wrapper content-form">
                                            <div className="content-wrapper-box">
                                                <div className="grid-wrapper">
                                                    <div className="small-12 medium-6 large-6">
                                                        <div
                                                            className="check-list"
                                                            style={{boxShadow: 'none'}}
                                                        >
                                                            <div className="check-list-main">
                                                                <div
                                                                    className="checkbox-wrapper"
                                                                    style={{backgroundColor: '#fff'}}
                                                                >
                                                                    <label>
                                                                    <span
                                                                        className={
                                                                            this.props.userToEdit
                                                                                .externalLogin ===
                                                                            true
                                                                                ? 'boxChecked'
                                                                                : 'boxUnchecked'
                                                                        }
                                                                        onClick={() => {
                                                                            this.props.updateUserToEditData(
                                                                                {
                                                                                    prop:
                                                                                        'externalLogin',
                                                                                    val: !this.props
                                                                                        .userToEdit
                                                                                        .externalLogin,
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                    <span className={'prim-text'}>
                                                                        Externes Login
                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                value={
                                                                    this.props.userToEdit
                                                                        .personalNumber || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'personalNumber',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Personalnummer</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.can(
                                            'einstellungen_benutzer_bearbeiten_alle_felder',
                                            'einstellungen_benutzer_bearbeiten_nur_freie_tage'
                                        ) &&
                                        <div className="content-wrapper content-form">
                                            <div className="content-wrapper-box">
                                                <div className="grid-wrapper">
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="check-list" style={{boxShadow: 'none'}}>
                                                            <div className="check-list-main">
                                                                <div className="checkbox-wrapper"
                                                                     style={{backgroundColor: '#fff'}}>
                                                                    <label>
                                                                <span
                                                                    className={
                                                                        this.props.userToEdit.freeDay !== '' && typeof this.props.userToEdit.freeDay !== "undefined"
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.updateUserToEditData(
                                                                            {
                                                                                prop: 'freeDay',
                                                                                val: this.props.userToEdit.freeDay === '' ? 'Mo' : ''
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                <span className={'prim-text'}>
                                                                    Montag Frei
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                disabled={this.props.userToEdit.freeDay}
                                                                value={
                                                                    this.props.userToEdit.presence1 || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'presence1',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Anwesenheit Montag</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="check-list" style={{boxShadow: 'none'}}>
                                                            <div className="check-list-main">
                                                                <div className="checkbox-wrapper"
                                                                     style={{backgroundColor: '#fff'}}>
                                                                    <label>
                                                                <span
                                                                    className={
                                                                        this.props.userToEdit.freeDay2 !== '' && typeof this.props.userToEdit.freeDay2 !== "undefined"
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.updateUserToEditData(
                                                                            {
                                                                                prop: 'freeDay2',
                                                                                val: this.props.userToEdit.freeDay2 === '' ? 'Di' : ''
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                <span className={'prim-text'}>
                                                                    Dienstag Frei
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                disabled={this.props.userToEdit.freeDay2}
                                                                value={
                                                                    this.props.userToEdit.presence2 || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'presence2',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Anwesenheit Dienstag</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="check-list" style={{boxShadow: 'none'}}>
                                                            <div className="check-list-main">
                                                                <div className="checkbox-wrapper"
                                                                     style={{backgroundColor: '#fff'}}>
                                                                    <label>
                                                                <span
                                                                    className={
                                                                        this.props.userToEdit.freeDay3 !== '' && typeof this.props.userToEdit.freeDay3 !== "undefined"
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.updateUserToEditData(
                                                                            {
                                                                                prop: 'freeDay3',
                                                                                val: this.props.userToEdit.freeDay3 === '' ? 'Mi' : ''
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                <span className={'prim-text'}>
                                                                    Mittwoch Frei
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                disabled={this.props.userToEdit.freeDay3}
                                                                value={
                                                                    this.props.userToEdit.presence3 || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'presence3',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Anwesenheit Mittwoch</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="check-list" style={{boxShadow: 'none'}}>
                                                            <div className="check-list-main">
                                                                <div className="checkbox-wrapper"
                                                                     style={{backgroundColor: '#fff'}}>
                                                                    <label>
                                                                <span
                                                                    className={
                                                                        this.props.userToEdit.freeDay4 !== '' && typeof this.props.userToEdit.freeDay4 !== "undefined"
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.updateUserToEditData(
                                                                            {
                                                                                prop: 'freeDay4',
                                                                                val: this.props.userToEdit.freeDay4 === '' ? 'Do' : ''
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                <span className={'prim-text'}>
                                                                    Donnerstag Frei
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                disabled={this.props.userToEdit.freeDay4}
                                                                value={
                                                                    this.props.userToEdit.presence4 || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'presence4',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Anwesenheit Donnerstag</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="check-list" style={{boxShadow: 'none'}}>
                                                            <div className="check-list-main">
                                                                <div className="checkbox-wrapper"
                                                                     style={{backgroundColor: '#fff'}}>
                                                                    <label>
                                                                <span
                                                                    className={
                                                                        this.props.userToEdit.freeDay5 !== '' && typeof this.props.userToEdit.freeDay5 !== "undefined"
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.updateUserToEditData(
                                                                            {
                                                                                prop: 'freeDay5',
                                                                                val: this.props.userToEdit.freeDay5 === '' ? 'Fr' : ''
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                <span className={'prim-text'}>
                                                                    Freitag Frei
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                disabled={this.props.userToEdit.freeDay5}
                                                                value={
                                                                    this.props.userToEdit.presence5 || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'presence5',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Anwesenheit Freitag</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="check-list" style={{boxShadow: 'none'}}>
                                                            <div className="check-list-main">
                                                                <div className="checkbox-wrapper"
                                                                     style={{backgroundColor: '#fff'}}>
                                                                    <label>
                                                                <span
                                                                    className={
                                                                        this.props.userToEdit.freeDay6 !== '' && typeof this.props.userToEdit.freeDay6 !== "undefined"
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.updateUserToEditData(
                                                                            {
                                                                                prop: 'freeDay6',
                                                                                val: this.props.userToEdit.freeDay6 === '' ? 'Sa' : ''
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                <span className={'prim-text'}>
                                                                    Samstag Frei
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                disabled={this.props.userToEdit.freeDay6}
                                                                value={
                                                                    this.props.userToEdit.presence6 || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'presence6',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Anwesenheit Samstag</label>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="check-list" style={{boxShadow: 'none'}}>
                                                            <div className="check-list-main">
                                                                <div className="checkbox-wrapper"
                                                                     style={{backgroundColor: '#fff'}}>
                                                                    <label>
                                                                <span
                                                                    className={
                                                                        this.props.userToEdit.freeDay7 !== '' && typeof this.props.userToEdit.freeDay7 !== "undefined"
                                                                            ? 'boxChecked'
                                                                            : 'boxUnchecked'
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.updateUserToEditData(
                                                                            {
                                                                                prop: 'freeDay7',
                                                                                val: this.props.userToEdit.freeDay7 === '' ? 'So' : ''
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                <span className={'prim-text'}>
                                                                    Sonntag Frei
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="small-12 medium-6 large-6">
                                                        <div className="group">
                                                            <input
                                                                type="text"
                                                                disabled={this.props.userToEdit.freeDay7}
                                                                value={
                                                                    this.props.userToEdit.presence7 || ''
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.updateUserToEditData({
                                                                        prop: 'presence7',
                                                                        val: e.target.value,
                                                                    })
                                                                }
                                                            />{' '}
                                                            <label>Anwesenheit Sonntag</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.getLocationModalButton(
                                                            this.props.userModalAction
                                                        )}
                                                        inverted
                                                        onClick={() =>
                                                            this.saveUser(this.props.userModalAction)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div id="lsaModal"
                             className={this.props.locationSpecialAppointmentModal.view ? 'modal visible' : 'modal hidden'}
                        >
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-check-square" aria-hidden="true"/>{' '}
                                    Sondertermin {this.props.locationSpecialAppointmentModal.action === 'new' ? 'anlegen' : 'bearbeiten'}
                                </span>{' '}
                                    <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.updateModal({
                                               path: 'settings.locationSpecialAppointment',
                                               prop: 'view',
                                               val: false,
                                           });
                                           this.props.updateLocationSpecialAppointment({prop: 'reset', val: ''});
                                           this.props.updateDropdown({
                                               dropdown: 'dropdown.news.location',
                                               prop: 'selectedId',
                                               val: ''
                                           })
                                           this.props.updateDropdown({
                                               dropdown: 'dropdown.news.location',
                                               prop: 'selectedLabel',
                                               val: ''
                                           })
                                       }}
                                    >
                                        <i className="fa fa-times-circle-o"></i>
                                    </a>
                                </span>
                                </div>

                                <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                        <input type="text"
                                                               value={this.props.locationSpecialAppointment.info || ''}
                                                               onChange={(e) =>
                                                                   this.props.updateLocationSpecialAppointment({
                                                                       prop: 'info',
                                                                       value: e.target.value,
                                                                   })
                                                               }
                                                        />{' '}
                                                        <label>Termin</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                        <div className="group">
                                                            <DateInput
                                                                selected={this.props.locationSpecialAppointment.date
                                                                    ? new Date(this.props.locationSpecialAppointment.date)
                                                                    : null}

                                                                onChange={(date) => {
                                                                    this.props.updateLocationSpecialAppointment({
                                                                        prop: 'date',
                                                                        value: date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : ''
                                                                    })
                                                                    if (!date) {
                                                                        //empty endDate
                                                                        this.props.updateLocationSpecialAppointment({
                                                                            prop: 'endDate',
                                                                            val: '',
                                                                        });
                                                                    }
                                                                }
                                                                }
                                                            />

                                                            <label>Datum</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    {this.props.locationSpecialAppointment.date &&
                                                        <div className="group">
                                                            <div className="group">
                                                                <DateInput
                                                                    minDate={new Date(this.props.locationSpecialAppointment.date)}
                                                                    selected={this.props.locationSpecialAppointment.endDate
                                                                        ? new Date(this.props.locationSpecialAppointment.endDate)
                                                                        : null}

                                                                    onChange={(date) => {
                                                                        this.props.updateLocationSpecialAppointment({
                                                                            prop: 'endDate',
                                                                            value: date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : ''
                                                                        })
                                                                    }
                                                                    }
                                                                />

                                                                <label>Datum Ende</label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="small-12 medium-12 large-12">
                                                    <Dropdown dropdown={this.props.locationDropdownSpecialAppointment}
                                                              dropdownList={this.props.allLocations} itemIdProp={'id'}
                                                              itemLabelProp={'label'} defaultSelectedLabel={'Alle'}
                                                              multiple={true}
                                                    />

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <Button
                                                        label={this.props.locationSpecialAppointmentModal.action === 'new' ? 'Anlegen' : 'Speichern'}
                                                        inverted
                                                        onClick={() => this.saveLocationSpecialAppointment()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.can('einstellungen_benutzer_alle_sehen',
                                'einstellungen_benutzer_aus_eigener_filiale_sehen') &&
                            <Card marginTop
                                  pagination={Pagination(
                                      this.props.userCount,
                                      this.props.userListPage,
                                      this.props.updateUserListPage
                                  )}
                            >
                                <div className="headline-counter">
                            <span>
                                <i className="fa fa-cog" aria-hidden="true"/>{' '}
                                Benutzer
                            </span>
                                    <div className="group header-input-group">
                                        <input
                                            className="header-input"
                                            type="text"
                                            name="test"
                                            value={this.props.userSearch || ''}
                                            placeholder={'Suche'}
                                            onChange={(e) =>
                                                this.props.updateUserSearch(e.target.value)
                                            }
                                        />
                                    </div>
                                    {this.can('einstellungen_benutzer_erstellen') &&
                                        <Button
                                            label="Neuer Benutzer"
                                            onClick={() => {
                                                this.props.updateUserModal(true);
                                                this.props.updateUserModalAction('new');
                                            }}
                                        />
                                    }
                                </div>
                                <div className="overflow-x">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'userSort',
                                                'value': 'lastName'
                                            })}><span>Name {this.showSorting({
                                                'name': 'userSort',
                                                'column': 'lastName'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'userSort',
                                                'value': 'firstName'
                                            })}><span>Vorname {this.showSorting({
                                                'name': 'userSort',
                                                'column': 'firstName'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'userSort',
                                                'value': 'location.name'
                                            })}><span>Standort {this.showSorting({
                                                'name': 'userSort',
                                                'column': 'location.name'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'userSort',
                                                'value': 'team'
                                            })}><span>Gruppe {this.showSorting({
                                                'name': 'userSort',
                                                'column': 'team'
                                            })}</span></th>
                                            <th>
                                                <span>Aktionen</span>
                                            </th>
                                        </tr>

                                        {this.props.userList.map((user, index) => (
                                            <tr key={index}
                                                className={
                                                    user.active
                                                        ? 'hoverable-user'
                                                        : 'hoverable-user table-overlay' +
                                                        (user.id === 'defaultId'
                                                            ? ' default-table-row'
                                                            : '')
                                                }
                                            >
                                                <td>
                                                    <div className="user-info-popup">
                                                        {user.resourceUri ? (
                                                            <img
                                                                src={user.resourceUri}
                                                                alt={`${user.firstName} ${user.lastName}`}
                                                                className="user-avatar"
                                                            />
                                                        ) : (
                                                            <i className="fa fa-user user-icon"></i> // Font Awesome icon
                                                        )}
                                                        <div className="user-details">
                                                            <h4>{user.firstName} {user.lastName}</h4>
                                                            <p>Benutzergruppe: {user.roleLabel}</p>
                                                            <p>Standort: {user.locationLabel}</p>
                                                        </div>
                                                    </div>
                                                    <span>{user.lastName}</span>
                                                </td>
                                                <td>
                                                    <span>{user.firstName}</span>
                                                </td>
                                                <td>
                                                <span>
                                                    {user.location
                                                        ? user.location.name
                                                        : ''}
                                                </span>
                                                </td>
                                                <td>
                                                    <span>{user.roleLabel}</span>
                                                </td>
                                                <td>
                                                    {user.id !== 'defaultId' && (
                                                        <span>
                                                        {this.can('einstellungen_benutzer_aktiv_inaktiv_setzen') &&
                                                            <i className={user.active && user.id ===
                                                            this.props.currentUser.id
                                                                ? 'fa fa-user' : user.active
                                                                    ? 'fa fa-eye-slash' : 'fa fa-eye'
                                                            }
                                                               aria-hidden="true"
                                                               onClick={e => {
                                                                   e.preventDefault();
                                                                   if (user.id !== this.props.currentUser.id) {
                                                                       this.props.toggleActivation(user.id);
                                                                   }
                                                               }}
                                                            />
                                                        }
                                                            {this.can('einstellungen_benutzer_l_schen') &&
                                                                <i className="fa fa-trash"
                                                                   aria-hidden="true"
                                                                   onClick={e => {
                                                                       e.preventDefault();
                                                                       this.deleteUser(user);
                                                                   }}
                                                                />
                                                            }
                                                            {this.can(
                                                                    'einstellungen_benutzer_bearbeiten',
                                                                    'einstellungen_benutzer_bearbeiten_alle_felder',
                                                                    'einstellungen_benutzer_bearbeiten_nur_freie_tage'
                                                                ) &&
                                                                <i className="fa fa-pencil"
                                                                   aria-hidden="true"
                                                                   onClick={e => {
                                                                       e.preventDefault();
                                                                       this.editUser(user);
                                                                   }}
                                                                />
                                                            }
                                                    </span>
                                                    )}
                                                    {user.id === 'defaultId' && (
                                                        <span/>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div>
                                            <br/>
                                            <form action={host + '/export-users?userSearch=' + this.props.userSearch}
                                                  method="post"
                                                  ref={(ref) => {
                                                      this.downloadFormUsers = ref;
                                                  }}>
                                                <input type="hidden" name="authTok"
                                                       value={'Bearer ' + this.props.token}
                                                />
                                            </form>
                                            <IconLink type="download"
                                                      onClick={() => this.downloadFormUsers.submit()}> Export
                                                XLSX</IconLink>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                        }
                        {this.can('einstellungen_sondertermine_sehen') &&
                            <Card pagination={Pagination(
                                this.props.locationSpecialAppointmentListCount,
                                this.props.locationSpecialAppointmentListPage,
                                this.props.updateLocationSpecialAppointmentListPage
                            )}
                            >
                                <div className="headline-counter">

                            <span>
                                <i className="fa fa-calendar" aria-hidden="true"/>

                                <label className="switch">
                                    <input checked={!this.props.locationSpecialAppointmentViewOld}
                                           onChange={() => this.props.updateLocationSpecialAppointmentViewOld(!this.props.locationSpecialAppointmentViewOld)}
                                           type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                                <span className="pl-60">Sondertermine</span>
                            </span>
                                    <div className="group header-input-group special-appointment">
                                        <input
                                            className="header-input"
                                            type="text"
                                            name="test"
                                            value={this.props.locationSpecialAppointmentSearchTerm || ''}
                                            placeholder={'Suche'}
                                            onChange={(e) =>
                                                this.props.updateLocationSpecialAppointmentSearchTerm(e.target.value)
                                            }
                                        />
                                    </div>

                                    {this.can('einstellungen_sondertermine_erstellen') &&
                                        <Button
                                            label="Neuer Sondertermin"
                                            onClick={() => {
                                                this.props.updateModal({
                                                        path: 'settings.locationSpecialAppointment',
                                                        prop: 'view',
                                                        val: true,
                                                    },
                                                );
                                                this.props.updateModal({
                                                        path: 'settings.locationSpecialAppointment',
                                                        prop: 'action',
                                                        val: 'new',
                                                    },
                                                );
                                                this.props.updateLocationSpecialAppointment({
                                                        prop: 'reset',
                                                        val: '',
                                                    },
                                                );
                                            }}
                                        />
                                    }
                                </div>
                                <div className="overflow-x">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'specialAppointmentSort',
                                                'value': 'info'
                                            })}><span>Termin {this.showSorting({
                                                'name': 'specialAppointmentSort',
                                                'column': 'info'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'specialAppointmentSort',
                                                'value': 'date'
                                            })}><span>Datum {this.showSorting({
                                                'name': 'specialAppointmentSort',
                                                'column': 'date'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'specialAppointmentSort',
                                                'value': 'endDate'
                                            })}><span>Datum Ende {this.showSorting({
                                                'name': 'specialAppointmentSort',
                                                'column': 'endDate'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'specialAppointmentSort',
                                                'value': 'locations'
                                            })}><span>Standorte {this.showSorting({
                                                'name': 'specialAppointmentSort',
                                                'column': 'locations'
                                            })}</span></th>
                                            <th>Aktionen</th>
                                        </tr>
                                        {this.props.locationSpecialAppointmentList.map(
                                            (lsa, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span>{lsa.info}</span>
                                                    </td>
                                                    <td>
                                                        <span>{moment(lsa.date).format('DD.MM.YY')}</span>
                                                    </td>
                                                    <td>
                                                        <span>{moment(lsa.endDate).format('DD.MM.YY')}</span>
                                                    </td>
                                                    <td>
                                                        <span>{lsa.locations.map((loc, ind) => (<span
                                                            key={ind}>{this.getLocationShortName(loc)}</span>))}</span>
                                                    </td>
                                                    {lsa.id !==
                                                        'defaultId' && (
                                                            <td>
                                                                {this.can('einstellungen_sondertermine_l_schen') &&
                                                                    <i className="fa fa-trash" aria-hidden="true"
                                                                       onClick={e => {
                                                                           e.preventDefault();
                                                                           this.deleteLocationSpecialAppointment(lsa);
                                                                       }}
                                                                    />
                                                                }
                                                                {this.can('einstellungen_sondertermine_bearbeiten') &&
                                                                    <i className="fa fa-pencil" aria-hidden="true"
                                                                       onClick={(e) => {
                                                                           e.preventDefault();

                                                                           this.props.updateLocationSpecialAppointment({
                                                                               prop: 'info',
                                                                               value: lsa.info,
                                                                           });
                                                                           this.props.updateLocationSpecialAppointment({
                                                                               prop: 'date',
                                                                               value: lsa.date ? moment(lsa.date)
                                                                                   .format('YYYY-MM-DD') : null,
                                                                           });
                                                                           this.props.updateLocationSpecialAppointment({
                                                                               prop: 'endDate',
                                                                               value: lsa.endDate ? moment(lsa.endDate)
                                                                                   .format('YYYY-MM-DD') : null,
                                                                           });
                                                                           this.props.updateLocationSpecialAppointment({
                                                                               prop: 'id',
                                                                               value: lsa.id,
                                                                           });
                                                                           this.props.updateDropdown({
                                                                               dropdown: 'dropdown.news.location',
                                                                               prop: 'selectedId',
                                                                               val: lsa.locations,
                                                                           });
                                                                           this.props.updateModal({
                                                                               path: 'settings.locationSpecialAppointment',
                                                                               prop: 'view',
                                                                               val: true,
                                                                           });
                                                                           this.props.updateModal({
                                                                               path: 'settings.locationSpecialAppointment',
                                                                               prop: 'action',
                                                                               val: 'edit',
                                                                           });
                                                                       }}
                                                                    />}
                                                            </td>
                                                        )}
                                                    {lsa.id ===
                                                        'defaultId' && (
                                                            <td>
                                                                <span/>
                                                            </td>
                                                        )}
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div>
                                            <br/>
                                            <form
                                                action={host + '/export-special-appointments?search=' + this.props.locationSpecialAppointmentSearchTerm + '&oldList=' + (this.props.locationSpecialAppointmentViewOld ? 1 : 0)}
                                                method="post"
                                                ref={(ref) => {
                                                    this.downloadFormSpecialAppoitments = ref;
                                                }}>
                                                <input type="hidden" name="authTok"
                                                       value={'Bearer ' + this.props.token}
                                                />
                                            </form>
                                            <IconLink type="download"
                                                      onClick={() => this.downloadFormSpecialAppoitments.submit()}> Export
                                                XLSX</IconLink>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        }

                        {this.can('einstellungen_benutzergruppen_sehen') &&
                            <Card>
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-users"
                                       aria-hidden="true"
                                    />
                                    Benutzergruppen
                                </span>
                                    {this.can('einstellungen_benutzergruppen_bearbeiten') &&
                                        <Button label="Neue Benutzergruppe"
                                                onClick={() => {
                                                    this.props.updateTeamToEdit({
                                                        view: 'new',
                                                    });
                                                    this.props.updatePermissionsView(true);
                                                }}
                                        />
                                    }
                                </div>
                                <div className="overflow-x">
                                    <table className="three-col">
                                        <tbody>
                                        <tr>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'groupSort',
                                                'value': 'label'
                                            })}><span>Gruppe {this.showSorting({
                                                'name': 'groupSort',
                                                'column': 'label'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'groupSort',
                                                'value': 'count'
                                            })}><span>Anzahl {this.showSorting({
                                                'name': 'groupSort',
                                                'column': 'count'
                                            })}</span></th>
                                            <th>Rechte</th>
                                            <th>Aktionen</th>
                                        </tr>
                                        {this.props.userGroupList
                                            .sort((a, b) => {
                                                let sortProp = this.props.groupSort[0] || ''
                                                const sortPropWithoutSign = sortProp.replace('-', '');
                                                let propa = a[sortPropWithoutSign];
                                                let propb = b[sortPropWithoutSign];
                                                if (['count'].includes(sortPropWithoutSign)) {
                                                    propa = Number(propa);
                                                    propb = Number(propb);
                                                }
                                                if (propa > propb) {
                                                    return sortProp[0] === '-' ? -1 : 1;
                                                } else if (propa < propb) {
                                                    return sortProp[0] === '-' ? 1 : -1;
                                                }
                                                return 0;
                                            })
                                            .map(
                                                (group, index) => (
                                                    <tr key={index}
                                                        className={group.id === 'defaultId' ? ' default-table-row' : 'usergroup-tr'}
                                                    >
                                                        <td><span>{group.label}</span></td>
                                                        <td><span>{group.count}</span></td>
                                                        <td>
                                                            <span>{group.rights ? group.rights.split('\n').map((str, index) =>
                                                                <p key={index}>{str}</p>) : ''}</span></td>

                                                        <td>
                                                            {this.can('einstellungen_benutzergruppen_bearbeiten') &&
                                                                <i className="fa fa-pencil" aria-hidden="true"
                                                                   onClick={() => {
                                                                       this.props.updatePermissionsView(true);
                                                                       this.props.updateTeamToEdit({
                                                                           ...group,
                                                                           view: 'edit',
                                                                           name: group.name.replace('Laminatdepot.Webapp:', ''),
                                                                       });
                                                                       this.props.updateDropdown({
                                                                           dropdown: this.props.userGroupDropdown.path,
                                                                           prop: 'selectedId',
                                                                           val: group.parent,
                                                                       });
                                                                       this.props.updateDropdown({
                                                                           dropdown: this.props.userGroupDropdown.path,
                                                                           prop: 'selectedLabel',
                                                                           val: group.parentLabel,
                                                                       });
                                                                       this.props.updateDropdown({
                                                                           dropdown: this.props.subgroupsDropdown.path,
                                                                           prop: 'selectedId',
                                                                           val: group.subordinateUserGroups || []
                                                                       })
                                                                   }}
                                                                />}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div>
                                            <br/>
                                            <form action={host + '/export-teams'}
                                                  method="post"
                                                  ref={(ref) => {
                                                      this.downloadFormTeams = ref;
                                                  }}>
                                                <input type="hidden" name="authTok"
                                                       value={'Bearer ' + this.props.token}
                                                />
                                            </form>
                                            <IconLink type="download"
                                                      onClick={() => this.downloadFormTeams.submit()}> Export
                                                XLSX</IconLink>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        }
                        {this.can('einstellungen_standorte_sehen') &&
                            <Card pagination={Pagination(
                                this.props.locationCount,
                                this.props.locationListPage,
                                this.props.updateLocationListPage,
                            )}
                            >
                                <div className="headline-counter">
                                <span>
                                    <i className="fa fa-map-marker"
                                       aria-hidden="true"
                                    />
                                    Standorte
                                </span>
                                    {this.can('einstellungen_standorte_erstellen') &&
                                        <Button label="Neuer Standort"
                                                onClick={() => {
                                                    this.props.updateLocationModal(true);
                                                    this.props.updateLocationModalAction(
                                                        'new',
                                                    );
                                                }}
                                        />
                                    }
                                </div>
                                <div className="overflow-x">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'name'
                                            })}><span>Name {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'name'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'shortName'
                                            })}><span>Kürzel {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'shortName'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'street'
                                            })}><span>Straße + Nr. {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'street'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'zip'
                                            })}><span>PLZ {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'zip'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'city'
                                            })}><span>Ort {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'city'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'region'
                                            })}><span>Bundesland {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'region'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'position'
                                            })}><span>Position {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'position'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'locationSort',
                                                'value': 'hideInSortiment'
                                            })}><span>Ausblenden {this.showSorting({
                                                'name': 'locationSort',
                                                'column': 'hideInSortiment'
                                            })}</span></th>
                                            <th>Aktionen</th>
                                        </tr>
                                        {this.props.locationList.map(
                                            (location, index) => (
                                                <tr key={index}
                                                    className={location.id === 'defaultId' ? ' default-table-row' : ''}
                                                >
                                                    <td><span>{location.name}</span></td>
                                                    <td><span>{location.shortName}</span></td>
                                                    <td><span>{location.street}</span></td>
                                                    <td><span>{location.zip}</span></td>
                                                    <td><span>{location.city}</span></td>
                                                    <td><span>{location.region || ''}</span></td>
                                                    <td><span>{location.position || ''}</span></td>
                                                    <td><span>{location.hideInSortiment ? 'ja' : 'nein'}</span></td>
                                                    {location.id !==
                                                        'defaultId' && (
                                                            <td>

                                                                {this.can('einstellungen_standorte_l_schen') &&
                                                                    <i className="fa fa-trash"
                                                                       aria-hidden="true"
                                                                       onClick={e => {
                                                                           e.preventDefault();
                                                                           this.deleteLocation(location);
                                                                       }}
                                                                    />
                                                                }
                                                                {this.can('einstellungen_standorte_bearbeiten') &&
                                                                    <i className="fa fa-pencil" aria-hidden="true"
                                                                       onClick={e => {
                                                                           e.preventDefault();
                                                                           this.props.updateLocationModal(true);
                                                                           this.props.updateLocationModalAction('edit');
                                                                           this.props.updateLocationToEdit(location.id);
                                                                           this.props.updateLocationModalInput(
                                                                               {value: location.name, prop: 'name'},
                                                                           );
                                                                           this.props.updateLocationModalInput(
                                                                               {value: location.street, prop: 'street'},
                                                                           );
                                                                           this.props.updateLocationModalInput(
                                                                               {value: location.zip, prop: 'zip'},
                                                                           );
                                                                           this.props.updateLocationModalInput(
                                                                               {value: location.city, prop: 'city'},
                                                                           );
                                                                           this.props.updateLocationModalInput(
                                                                               {
                                                                                   value: location.shortName,
                                                                                   prop: 'shortName'
                                                                               },
                                                                           );
                                                                           this.props.updateLocationModalInput(
                                                                               {value: location.ip, prop: 'ip'},
                                                                           );
                                                                           this.props.updateLocationModalInput(
                                                                               {
                                                                                   value: location.position,
                                                                                   prop: 'position'
                                                                               },
                                                                           );
                                                                           this.props.updateLocationModalInput(
                                                                               {
                                                                                   value: location.hideInSortiment,
                                                                                   prop: 'hideInSortiment',
                                                                               },
                                                                           );
                                                                           this.props.updateDropdown({
                                                                               dropdown: this.props.regionsDropdown.path,
                                                                               prop: 'selectedLabel',
                                                                               val: location.region,
                                                                           });
                                                                       }}
                                                                    />}
                                                            </td>
                                                        )}
                                                    {location.id ===
                                                        'defaultId' && (
                                                            <td>
                                                                <span/>
                                                            </td>
                                                        )}
                                                </tr>
                                            ),
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div>
                                            <br/>
                                            <form action={host + '/export-locations'}
                                                  method="post"
                                                  ref={(ref) => {
                                                      this.downloadFormLocations = ref;
                                                  }}>
                                                <input type="hidden" name="authTok"
                                                       value={'Bearer ' + this.props.token}
                                                />
                                            </form>
                                            <IconLink type="download"
                                                      onClick={() => this.downloadFormLocations.submit()}> Export
                                                XLSX</IconLink>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        }

                        {this.can('einstellungen_login_verlauf_sehen') &&
                            <Card pagination={
                                Pagination(
                                    this.props.loginLogCount,
                                    this.props.loginLogListPage,
                                    this.props.updateLoginLogListPage,
                                )}
                            >
                                <div className="headline-counter">
                            <span>
                                <i className="fa fa-sign-in" aria-hidden="true"/>{' '}
                                Login-Verlauf
                            </span>
                                    <div className="group header-input-group login">
                                        <input
                                            className="header-input"
                                            type="text"
                                            name="test"
                                            value={this.props.loginSearchTerm || ''}
                                            placeholder={'Suche'}
                                            onChange={(e) =>
                                                this.props.updateLoginSearchTerm(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="overflow-x">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'loginlogSort',
                                                'value': 'date'
                                            })}><span>Datum {this.showSorting({
                                                'name': 'loginlogSort',
                                                'column': 'date'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'loginlogSort',
                                                'value': 'time'
                                            })}><span>Uhrzeit {this.showSorting({
                                                'name': 'loginlogSort',
                                                'column': 'time'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'loginlogSort',
                                                'value': 'userName'
                                            })}><span>Benutzer {this.showSorting({
                                                'name': 'loginlogSort',
                                                'column': 'userName'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'loginlogSort',
                                                'value': 'ip'
                                            })}><span>IP {this.showSorting({
                                                'name': 'loginlogSort',
                                                'column': 'ip'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'loginlogSort',
                                                'value': 'city'
                                            })}><span>Ort {this.showSorting({
                                                'name': 'loginlogSort',
                                                'column': 'city'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'loginlogSort',
                                                'value': 'country'
                                            })}><span>Land {this.showSorting({
                                                'name': 'loginlogSort',
                                                'column': 'country'
                                            })}</span></th>
                                        </tr>
                                        {this.props.loginHistoryList.map(
                                            (login, index) => (
                                                <tr
                                                    key={index}
                                                    className={
                                                        login.id === 'defaultId'
                                                            ? ' default-table-row'
                                                            : ''
                                                    }
                                                >
                                                    <td>
                                                    <span>
                                                        {login.formatedDate}
                                                    </span>
                                                    </td>
                                                    <td>
                                                    <span>
                                                        {login.formatedTime}
                                                    </span>
                                                    </td>
                                                    <td>
                                                    <span>
                                                        {login.userName}
                                                    </span>
                                                    </td>
                                                    <td>
                                                        <span>{login.ip}</span>
                                                    </td>
                                                    <td>
                                                    <span>
                                                        {login.city}
                                                    </span>
                                                    </td>
                                                    <td>
                                                    <span>
                                                        {login.country}
                                                    </span>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div>
                                            <br/>
                                            <form
                                                action={host + '/export-login-logs?search=' + this.props.loginSearchTerm}
                                                method="post"
                                                ref={(ref) => {
                                                    this.downloadFormLoginlogs = ref;
                                                }}>
                                                <input type="hidden" name="authTok"
                                                       value={'Bearer ' + this.props.token}
                                                />
                                            </form>
                                            <IconLink type="download"
                                                      onClick={() => this.downloadFormLoginlogs.submit()}> Export
                                                XLSX</IconLink>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        }

                        {this.can('einstellungen_hersteller_sehen_1') &&
                            <Card>
                                <div className="headline-counter">
                            <span>
                                <i className="fa fa-building" aria-hidden="true"/> Hersteller
                            </span>
                                    {this.can('einstellungen_hersteller_erstellen') &&
                                        <Button label="Neuer Hersteller"
                                                onClick={() => {
                                                    this.props.updateManufacturerModal(true)
                                                    this.props.updateManufacturerModalAction('new')
                                                }}
                                        />
                                    }
                                </div>
                                <div className="overflow-x">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Aktionen</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.manufacturers.map(
                                            (manufacturer, index) => (
                                                <tr key={index}
                                                    className={manufacturer.id === 'defaultId' ? ' default-table-row' : ''}>
                                                    <td>
                                                    <span>
                                                        {manufacturer.name}
                                                    </span>
                                                    </td>
                                                    {manufacturer.id !== 'defaultId' && (
                                                        <td>
                                                            {this.can('einstellungen_hersteller_l_schen') &&
                                                                <i className="fa fa-trash" aria-hidden="true"
                                                                   onClick={e => {
                                                                       e.preventDefault()
                                                                       this.deleteManufacturer(manufacturer)
                                                                   }}
                                                                />}
                                                            {this.can('einstellungen_hersteller_bearbeiten') &&
                                                                <i className="fa fa-pencil" aria-hidden="true"
                                                                   onClick={e => {
                                                                       e.preventDefault();
                                                                       this.props.updateManufacturerModal(true)
                                                                       this.props.updateManufacturerToEdit(manufacturer.id)
                                                                       this.props.updateManufacturerModalInput(manufacturer.name)
                                                                       this.props.updateManufacturerModalAction('edit')
                                                                   }}
                                                                />}
                                                        </td>
                                                    )}
                                                    {manufacturer.id === 'defaultId' && (
                                                        <td>
                                                            <span/>
                                                        </td>
                                                    )}
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div>
                                            <br/>
                                            <form action={host + '/export-manufacturer'}
                                                  method="post"
                                                  ref={(ref) => {
                                                      this.downloadFormManufacturer = ref;
                                                  }}>
                                                <input type="hidden" name="authTok"
                                                       value={'Bearer ' + this.props.token}
                                                />
                                            </form>
                                            <IconLink type="download"
                                                      onClick={() => this.downloadFormManufacturer.submit()}> Export
                                                XLSX</IconLink>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        }
                        {this.can('einstellungen_labels_mit_farbe_sehen') &&
                            <Card>
                                <div className="headline-counter">
                            <span>
                                <i className="fa fa-dashboard" aria-hidden="true"/> Label mit Farbe
                            </span>
                                    {/*{this.can('einstellungen_labels_mit_farbe_erstellen') &&*/}
                                    {/*    <Button label="Neues Label"*/}
                                    {/*         onClick={() => {*/}
                                    {/*             this.props.updateColorModal(true);*/}
                                    {/*             this.props.updateColorModalAction('new');*/}
                                    {/*         }}*/}
                                    {/*    />*/}
                                    {/*}*/}
                                </div>
                                <div className="overflow-x">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'colorSort',
                                                'value': 'name'
                                            })}><span>Name {this.showSorting({
                                                'name': 'colorSort',
                                                'column': 'name'
                                            })}</span></th>
                                            <th onClick={() => this.updateSorting({
                                                'name': 'colorSort',
                                                'value': 'hex'
                                            })}><span>Name {this.showSorting({
                                                'name': 'colorSort',
                                                'column': 'hex'
                                            })}</span></th>
                                            <th>Aktionen</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.colors.map(
                                            (color, index) => (
                                                <tr key={index}
                                                    className={color.id === 'defaultId' ? ' default-table-row' : ''}>
                                                    <td>
                                                    <span>
                                                        {color.name}
                                                    </span>
                                                    </td>

                                                    <td style={{backgroundColor: color.hex}}></td>

                                                    {color.id !== 'defaultId' && (
                                                        <td>
                                                            {/*{ this.can('einstellungen_labels_mit_farbe_l_schen') &&*/}
                                                            {/*    <i className="fa fa-trash" aria-hidden="true"*/}
                                                            {/*        onClick={e => {*/}
                                                            {/*            e.preventDefault();*/}
                                                            {/*            this.deleteColor(color);*/}
                                                            {/*        }}*/}
                                                            {/*    />*/}
                                                            {/*}*/}
                                                            {this.can('einstellungen_labels_mit_farbe_bearbeiten') &&
                                                                color.name !== 'Auslauf' &&
                                                                color.name !== 'Werbung' &&
                                                                <i className="fa fa-pencil" aria-hidden="true"
                                                                   onClick={e => {
                                                                       e.preventDefault();
                                                                       this.props.updateColorModal(true);
                                                                       this.props.updateColorToEdit(color);
                                                                       this.props.updateColorModalAction('edit');
                                                                   }}
                                                                />
                                                            }
                                                        </td>
                                                    )}
                                                    {color.id === 'defaultId' && (
                                                        <td>
                                                            <span/>
                                                        </td>
                                                    )}
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <div>
                                            <br/>
                                            <form action={host + '/export-colors'}
                                                  method="post"
                                                  ref={(ref) => {
                                                      this.downloadFormColors = ref;
                                                  }}>
                                                <input type="hidden" name="authTok"
                                                       value={'Bearer ' + this.props.token}
                                                />
                                            </form>
                                            <IconLink type="download"
                                                      onClick={() => this.downloadFormColors.submit()}> Export
                                                XLSX</IconLink>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        }
                        {this.can('settings_claim_can_edit_status') &&
                        <Card>
                            <div className="headline-counter">
                            <span>
                                <i className="fa fa-info-circle" aria-hidden="true"/> Beanstandungen Status
                            </span>
                                    <Button label="Neuer Status"
                                         onClick={() => {
                                             this.props.updateStatusModal(true);
                                             this.props.updateStatusModalAction('new');
                                         }}
                                    />
                            </div>
                            <div className="overflow-x">
                                <table>
                                    <thead>
                                    <tr>
                                        <th onClick={() => this.updateSorting({
                                            'name': 'statusSort',
                                            'value': 'name'
                                        })}><span>Name {this.showSorting({
                                            'name': 'statusSort',
                                            'column': 'name'
                                        })}</span></th>

                                        <th>Aktionen</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.statuses.map(
                                        (status, index) => (
                                            <tr key={index}
                                                className={status.id === 'defaultId' ? ' default-table-row' : ''}>
                                                <td>
                                                    <span>
                                                        {status.name}
                                                    </span>
                                                </td>

                                                {status.id !== 'defaultId' && (
                                                    <td>
                                                            <i className="fa fa-pencil" aria-hidden="true"
                                                               onClick={e => {
                                                                   e.preventDefault();
                                                                   this.props.updateStatusModal(true);
                                                                   this.props.updateStatusToEdit(status);
                                                                   this.props.updateStatusModalAction('edit');
                                                               }}
                                                            />

                                                    </td>
                                                )}
                                                {status.id === 'defaultId' && (
                                                    <td>
                                                        <span/>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {/*
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div>
                                        <br/>
                                        <form action={host + '/export-colors'}
                                              method="post"
                                              ref={(ref) => {
                                                  this.downloadFormColors = ref;
                                              }}>
                                            <input type="hidden" name="authTok"
                                                   value={'Bearer ' + this.props.token}
                                            />
                                        </form>
                                        <IconLink type="download"
                                                  onClick={() => this.downloadFormColors.submit()}> Export
                                            XLSX</IconLink>
                                    </div>
                                </div>
                            </div>
                            */}
                        </Card>
                        }
                        <Card>
                            <div className="headline-counter">
                            <span>
                                <i className="fa fa-dashboard" aria-hidden="true"/> Beanstandungen Hersteller
                            </span>
                                <Button label="Neuer Hersteller"
                                        onClick={() => {
                                            this.props.updateClaimDropdownManufacturerModal(true)
                                            this.props.updateClaimDropdownManufacturerModalAction('new')
                                        }}
                                />
                            </div>
                            <div className="overflow-x">
                                <table>
                                    <thead>
                                    <tr>
                                        <th onClick={() => this.updateSorting({
                                            'name': 'claimDropdownManufacturerSort',
                                            'value': 'name'
                                        })}><span>Name {this.showSorting({
                                            'name': 'claimDropdownManufacturerSort',
                                            'column': 'name'
                                        })}</span></th>
                                        <th>Aktionen</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.claimDropdownManufacturers.map(
                                        (claimDropdownManufacturer, index) => (
                                            <tr key={index}
                                                className={claimDropdownManufacturer.id === 'defaultId' ? ' default-table-row' : ''}>
                                                <td>
                                                    <span>
                                                        {claimDropdownManufacturer.name}
                                                    </span>
                                                </td>
                                                {claimDropdownManufacturer.id !== 'defaultId' && (
                                                    <td>
                                                        <i className="fa fa-trash" aria-hidden="true"
                                                           onClick={e => {
                                                               e.preventDefault()
                                                               this.deleteClaimDropdownManufacturer(claimDropdownManufacturer)
                                                           }}
                                                        />
                                                        <i className="fa fa-pencil" aria-hidden="true" onClick={e => {
                                                            e.preventDefault();
                                                            this.props.updateClaimDropdownManufacturerModal(true)
                                                            this.props.updateClaimDropdownManufacturerToEdit(claimDropdownManufacturer)
                                                            this.props.updateClaimDropdownManufacturerModalAction('edit')
                                                        }}
                                                        />
                                                    </td>
                                                )}
                                                {claimDropdownManufacturer.id === 'defaultId' && (
                                                    <td>
                                                        <span/>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {}
                        </Card>
                        <Card>
                            <div className="headline-counter">
                            <span>
                                <i className="fa fa-dashboard" aria-hidden="true"/> Beanstandungen Qualität
                            </span>
                                <Button label="Neue Qualität"
                                        onClick={() => {
                                            this.props.updateClaimDropdownQualityModal(true)
                                            this.props.updateClaimDropdownQualityModalAction('new')
                                        }}
                                />
                            </div>
                            <div className="overflow-x">
                                <table>
                                    <thead>
                                    <tr>
                                        <th onClick={() => this.updateSorting({
                                            'name': 'claimDropdownQualitySort',
                                            'value': 'name'
                                        })}><span>Name {this.showSorting({
                                            'name': 'claimDropdownQualitySort',
                                            'column': 'name'
                                        })}</span></th>
                                        <th>Aktionen</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.claimDropdownQualities.map(
                                        (claimDropdownQuality, index) => (
                                            <tr key={index}
                                                className={claimDropdownQuality.id === 'defaultId' ? ' default-table-row' : ''}>
                                                <td>
                                                    <span>
                                                        {claimDropdownQuality.name}
                                                    </span>
                                                </td>
                                                {claimDropdownQuality.id !== 'defaultId' && (
                                                    <td>
                                                        <i className="fa fa-trash" aria-hidden="true"
                                                           onClick={e => {
                                                               e.preventDefault()
                                                               this.deleteClaimDropdownQuality(claimDropdownQuality)
                                                           }}
                                                        />
                                                        <i className="fa fa-pencil" aria-hidden="true" onClick={e => {
                                                            e.preventDefault();
                                                            this.props.updateClaimDropdownQualityModal(true)
                                                            this.props.updateClaimDropdownQualityToEdit(claimDropdownQuality)
                                                            this.props.updateClaimDropdownQualityModalAction('edit')
                                                        }}
                                                        />
                                                    </td>
                                                )}
                                                {claimDropdownQuality.id === 'defaultId' && (
                                                    <td>
                                                        <span/>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {}
                        </Card>
                        <Card>
                            <div className="headline-counter">
                            <span>
                                <i className="fa fa-dashboard" aria-hidden="true"/> Beanstandungen Grund
                            </span>

                                <Button label="Neuer Grund"
                                        onClick={() => {
                                            this.props.updateClaimDropdownReasonModal(true)
                                            this.props.updateClaimDropdownReasonModalAction('new')
                                        }}
                                />
                            </div>
                            <div className="overflow-x">
                                <table>
                                    <thead>
                                    <tr>
                                        <th onClick={() => this.updateSorting({
                                            'name': 'claimDropdownReasonSort',
                                            'value': 'name'
                                        })}><span>Name {this.showSorting({
                                            'name': 'claimDropdownReasonSort',
                                            'column': 'name'
                                        })}</span></th>
                                        <th>Aktionen</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.claimDropdownReasons.map(
                                        (claimDropdownReason, index) => (
                                            <tr key={index}
                                                className={claimDropdownReason.id === 'defaultId' ? ' default-table-row' : ''}>
                                                <td>
                                                    <span>
                                                        {claimDropdownReason.name}
                                                    </span>
                                                </td>
                                                {claimDropdownReason.id !== 'defaultId' && (
                                                    <td>
                                                        <i className="fa fa-trash" aria-hidden="true"
                                                           onClick={e => {
                                                               e.preventDefault()
                                                               this.deleteClaimDropdownReason(claimDropdownReason)
                                                           }}
                                                        />
                                                        <i className="fa fa-pencil" aria-hidden="true" onClick={e => {
                                                            e.preventDefault();
                                                            this.props.updateClaimDropdownReasonModal(true)
                                                            this.props.updateClaimDropdownReasonToEdit(claimDropdownReason)
                                                            this.props.updateClaimDropdownReasonModalAction('edit')
                                                        }}
                                                        />
                                                    </td>
                                                )}
                                                {claimDropdownReason.id === 'defaultId' && (
                                                    <td>
                                                        <span/>
                                                    </td>
                                                )}
                                            </tr>
                                        )
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {}
                        </Card>

                        {/*this.can('global_settings_can_see') &&
                    <GlobalSettings/>
                    */}
                    </main>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
