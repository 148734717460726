import ClaimForm from 'Components/Claims/ClaimForm';
import LocationFilterSelect from 'Components/LocationFilterSelect';
import Button from 'Components/UI/Button';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {actions} from "../../Redux";

class ClaimBreadcrumbMenu extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object,
        onLocationFilterChange: PropTypes.func,
        locationFilter: PropTypes.string,
    };

    state = {
        showForm: false,
    };

    toggleForm = () => {
        this.setState({
            showForm: !this.state.showForm,
        });
    };

    // filteredLocations() {
    //
    //     if(  hasPermission(
    //           this.props.currentUser,
    //           [roles.Personalleitung,
    //            roles.Buchhaltung,
    //            roles.Zentrallagerleitung,
    //            roles.Vertriebsleitung,
    //            roles.Vertrieb,
    //           ]
    //       )) {
    //           return this.props.locationFilter
    //       }
    //
    //       const locations = this.props.currentUser.allowedLocationsClaim ?
    //                         this.props.currentUser.allowedLocationsClaim :
    //                         [this.props.currentUser.currentLocation.id]
    //       return this.props.locationFilter ? this.props.locationFilter.filter(location => locations.includes(location.id)) : null
    //   }

    render() {
        return (
            <>
                <ClaimForm isOpen={this.state.showForm} onCancel={this.toggleForm} />
                <LocationFilterSelect
                        name="locationFilter"
                        value={this.props.locationFilter}
                        onChange={(ev) => {
                            this.props.onLocationFilterChange &&
                            this.props.onLocationFilterChange(ev.target.value)
                            this.props.updateYearFilter('')
                            console.log('ok we are here 2')
                            }
                        }
                    />
                    <div className="btn-3 absence-date year-filter">
                        <input type="text"
                               value={this.props.yearFilter || ''}
                               placeholder={'Jahr'}
                               onChange={e => {
                                   this.props.updateYearFilter(e.target.value);
                                   const yearRegex = /(?:(?:18|19|20|21)[0-9]{2})/g;
                                   if ((yearRegex.test(e.target.value)) || e.target.value === '') {
                                       this.props.reloadCompletedClaims({
                                           year: e.target.value
                                       })
                                       this.props.reloadOpenClaims({
                                           year: e.target.value
                                       })
                                   }
                               }}
                        />
                    </div>
                <Button label="Neue Beanstandung" onClick={this.toggleForm} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        yearFilter: state.data.yearFilter
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateYearFilter: (yearFilter) => dispatch(actions.Data.updateYearFilter(yearFilter)),
        reloadOpenClaims: (filter) => {
            return dispatch(
                actions.Claims.loadOpenList({
                    filter: {
                        completed: false,
                        location: ownProps.locationFilter,
                        ...filter,
                    },
                    forceReload: true,
                    fromNumber: 0,
                }),
            );
        },
        reloadCompletedClaims: (filter) =>
            dispatch(
                actions.Claims.loadClosedList({
                    filter: {
                        completed: true,
                        location: ownProps.locationFilter,
                        ...filter,
                    },
                    forceReload: true,
                    fromNumber: 0,
                }),
            ),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClaimBreadcrumbMenu);
