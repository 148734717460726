import { createAction } from 'redux-actions';
import { handleActions } from '../../Utility/HandleActions';
import { $get, $set } from 'plow-js';
import initialState from './initialState';

const UPDATE_USER = 'Data/UpdateUser';
const UPDATE_USER_DATA = 'Data/UpdateUserData';
const UPDATE_USER_TO_EDIT_DATA = 'Data/UpdateUserToEditData';
const UPDATE_NEW_USER_DATA = 'Data/UpdateNewUserData';
const LOAD_USER_DATA = 'Data/LoadUserData';
const LOAD_CURRENT_USER_DATA = 'Data/LoadCurrentUserData';
const LOAD_USER_LIST = 'Data/LoadUserList';
const UPDATE_USER_LIST = 'Data/UpdateUserList';
const UPDATE_TEMP_USER_DATA = 'Data/UpdateTempUserData';
const RESET_TEMP_USER_DATA = 'Data/ResetTempUserData';
const EDIT_USER = 'Data/EditUser';
const UPDATE_USER_TO_EDIT = 'Data/UpdateUserToEdit';
const LOAD_ARTICLES = 'Data/LoadArticles';
const UPDATE_ARTICLE_LIST = 'Data/UpdateArticleList';
const ADD_ARTICLE_TO_USER = 'Data/AddArticleToUser';
const REMOVE_ARTICLE_FROM_USER = 'Data/RemoveArticleFromUser';
const SAVE_USER = 'Data/SaveUser';
const LOAD_TASK_LIST = 'Data/LoadTaskList';
const UPDATE_TASK_LIST = 'Data/UpdateTaskList';
const EDIT_TASK_LIST = 'Data/EditTaskList';
const ADD_TO_COMPLETED_TASK_LIST = 'Data/AddToCompletedTaskList';
const REMOVE_FROM_TASK_LIST = 'Data/RemoveFromTaskList';
const LOAD_COMPLETED_TASK_LIST = 'Data/LoadCompletedTaskList';
const UPDATE_COMPLETED_TASK_LIST = 'Data/UpdateCompletedTaskList';
const LOAD_NEWS_LIST = 'Data/LoadNewsList';
const UPDATE_NEWS_LIST = 'Data/UpdateNewsList';
const LOAD_BESTAND_LIST = 'Data/LoadBestandList';
const UPDATE_BESTAND_LIST = 'Data/UpdateBestandList';
const LOAD_EMPLOYEE_LIST = 'Data/LoadEmployeeList';
const UPDATE_EMPLOYEE_LIST = 'Data/UpdateEmployeeList';
const LOAD_USER_GROUP_LIST = 'Data/LoadUserGroupList';
const UPDATE_USER_GROUP_LIST = 'Data/UpdateUserGroupList';
const LOAD_LOCATION_LIST = 'Data/LoadLocationList';
const UPDATE_LOCATION_LIST = 'Data/UpdateLocationList';
const LOAD_LOGIN_HISTORY_LIST = 'Data/LoadLoginHistoryList';
const UPDATE_LOGIN_HISTORY_LIST = 'Data/UpdateLoginHistoryList';
const LOAD_CALENDAR = 'Data/LoadCalendar';
const UPDATE_CALENDAR = 'Data/UpdateCalendar';
const LOAD_USER_INFO_LIST = 'Data/LoadUserInfoList';
const UPDATE_USER_INFO_LIST = 'Data/UpdateUserInfoList';
const LOAD_MANUFACTURER_LIST = 'Data/LoadManufacturerList';
const UPDATE_MANUFACTURER_LIST = 'Data/UpdateManufacturerList';
const UPDATE_LOCATION_MODAL_INPUT = 'Data/UpdateLocationModalInput';
const UPDATE_LOCATION_MODAL_ACTION = 'Data/UpdateLocationModalAction';
const UPDATE_LOCATION_TO_EDIT = 'Data/UpdateLocationToEdit';
const ADD_LOCATION = 'Data/AddLocation';
const UPDATE_LOCATION = 'Data/UpdateLocation';
const DELETE_LOCATION = 'Data/DeleteLocation';
const SAVE_MANUFACTURER = 'Data/SaveManufacturer';
const ADD_MANUFACTURER = 'Data/AddManufacturer';
const UPDATE_MANUFACTURER_MODAL_INPUT = 'Data/UpdateManufacturerModalInput';
const UPDATE_MANUFACTURER_MODAL_ACTION = 'Data/UpdateManufacturerModalAction';
const UPDATE_MANUFACTURER_TO_EDIT = 'Data/UpdateManufacturerToEdit';
const UPDATE_MANUFACTURERS = 'Data/UpdateManufacturers';
const UPDATE_MANUFACTURER = 'Data/UpdateManufacturer';
const DELETE_MANUFACTURER = 'Data/DeleteManufacturer';
const DELETE_USER = 'Data/DeleteUser';
const UPDATE_USER_MODAL_ACTION = 'Data/UpdateUserModalAction';
const SAVE_NEW_TASK = 'Data/SaveNewTask';
const UPDATE_SAVED_TASK = 'Data/UpdateSavedTask';
const UPDATE_CURRENT_TASK = 'Data/UpdateCurrentTask';
const REMOVE_TASK = 'Data/RemoveTask';
const SAVE_NEWS = 'Data/SaveNews';
const UPDATE_SAVED_NEWS = 'Data/UpdateSavedNews';
const ADD_NEW_PAGE_NEWS_LIST = 'Data/AddNewPageNewsList';
const ADD_NEW_PAGE_TASK_LIST = 'Data/AddNewPageTaskList';
const ADD_NEW_PAGE_COMPLETED_TASK_LIST = 'Data/AddNewPageCompletedTaskList';
const UPDATE_CURRENT_NEWS = 'Data/UpdateCurrentNews';
const UPDATE_LIST_PROPS = 'Data/UpdateListProps';
const FILTER_TASK_LIST_USING_GROUP = 'Data/FilterTaskListUsingGroup';
const UPDATE_DROPDOWN = 'Data/UpdateDropdown';
const ADD_USER = 'Data/AddUser';
const UPDATE_ALL_LOCATIONS = 'Data/UpdateAllLocations';
const UPDATE_MODAL = 'Data/UpdateModal';
const UPDATE_DOCUMENT_TO_EDIT = 'Data/UpdateDocumentToEdit';
const UPDATE_DOCUMENT_TO_EDIT_DATA = 'Data/UpdateDocumentToEditData';
const UPDATE_FOLDER_TO_EDIT = 'Data/UpdateFolderToEdit';
const UPDATE_FOLDER_TO_EDIT_DATA = 'Data/UpdateFolderToEditData';
const LOAD_FOLDER_LIST = 'Data/LoadFolderList';
const LOAD_DOCUMENT_LIST = 'Data/LoadDocumentList';
const UPDATE_FOLDER_LIST = 'Data/UpdateFolderList';
const LOAD_FOLDER = 'Data/LoadFolder';
const UPDATE_CURRENT_FOLDER = 'Data/UpdateCurrentFolder';
const LOAD_CURRENT_FOLDER = 'Data/LoadCurrentFolder';
const SAVE_FOLDER = 'Data/SaveFolder';
const SAVE_DOCUMENT = 'Data/SaveDocument';
const DELETE_DOCUMENT = 'Data/DeleteDocument';
const DELETE_FOLDER = 'Data/DeleteFolder';
const UPDATE_DOCUMENT_LIST = 'Data/UpdateDocumentList';
const UPDATE_ARTICLE_TO_EDIT = 'Data/UpdateArticleToEdit';
const UPDATE_ARTICLE_TO_EDIT_DATA = 'Data/UpdateArticleToEditData';
const SAVE_ARTICLE = 'Data/SaveArticle';
const UPDATE_ARTICLE = 'Data/UpdateArticle';
const DELETE_ARTICLE = 'Data/DeleteArticle';
const UPDATE_STOCK = 'Data/UpdateStock';
const LOAD_ARTICLE_DROPDOWN_LIST = 'Data/LoadArticleDropdownList';
const UPDATE_ARTICLE_DROPDOWN_LIST = 'Data/UpdateArticleDropdownList';
const UPDATE_LOAD_MORE = 'Data/UpdateLoadMore';
const UPDATE_PAGE = 'Data/UpdatePage';
const UPDATE_ARTICLE_STATE = 'Data/UpdateArticleState';
const UPDATE_CALENDAR_DATA = 'Data/UpdateCalendarData';
const LOAD_ALL_USERS = 'Data/LoadAllUsers';
const UPDATE_ALL_USERS = 'Data/UpdateAllUsers';
const UPDATE_FILTER = 'Data/UpdateFilter';
const TOGGLE_ARTICLE_VISIBILITY = 'Data/ToggleArticleVisibility';
const UPDATE_ABSENCE_DATE = 'Data/UpdateAbsenceDate';
const COUNT_HOLIDAYS = 'Data/CountHolidays';
const COUNT_SICKDAYS = 'Data/CountSickdays';
const UPDATE_HOLIDAYS = 'Data/UpdateHolidays';
const UPDATE_STOCK_FILTER = 'Data/UpdateStockFilter';
const UPDATE_ARTICLE_DROPDOWN_FILTER = 'Data/UpdateArticleDropdownFilter';
const UPDATE_ARTICLE_COUNT = 'Data/UpdateArticleCount';
const FIND_ARTICLES = 'Data/FindArticles';
const UPDATE_ARTICLES_FROM_ADVERTISING = 'Data/UpdateArticlesFromAdvertising';
const UPDATE_SHORT_LINK = 'Data/UpdateShortLink';
const SAVE_SHORT_LINK = 'Data/SaveShortLink';
const RESET_STATE = 'Data/ResetState';
const UPDATE_LOCATION_INFO = 'Data/UpdateLocationInfo';
const UPDATE_CALENDAR_USER = 'Data/UpdateCalendarUser';
const UPDATE_CALENDAR_WEEK = 'Data/UpdateCalendarWeek';
const LOAD_ARTICLES_WITH_EAN = 'Data/LoadArticlesWithEan';
const UPDATE_ALL_LOCATION_FILTERS = 'Data/UpdateAllLocationFilters';
const UPDATE_ALL_LOCATION_DROPDOWNS = 'Data/UpdateAllLocationDropdowns';
const UPDATE_CURRENT_STOCK_INPUT = 'Data/UpdateCurentStockInput';
const UPDATE_TEAM_TO_EDIT = 'Data/SettingsUpdateTeamToEdit';
const SAVE_TEAM = 'Data/SettingsSaveTeam';
const UPDATE_HOLIDAY_API = 'Data/UpdateHolidayApi';
const LOAD_HOLIDAY_API = 'Data/LoadHolidayApi';
const UPDATE_LOCATION_SPECIAL_APPOINTMENT = 'Data/UpdateLocationSpecialAppointment';
const UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST = 'Data/UpdateLocationSpecialAppointmentList';
const LOAD_LOCATION_SPECIAL_APPOINTMENT_LIST = 'Data/LoadLocationSpecialAppointmentList';
const SAVE_LOCATION_SPECIAL_APPOINTMENT = 'Data/SaveLocationSpecialAppointment';
const ADD_LOCATION_SPECIAL_APPOINTMENT = 'Data/AddLocationSpecialAppointment';
const DELETE_LOCATION_SPECIAL_APPOINTMENT = 'Data/DeleteLocationSpecialAppointment';
const UPDATE_LOGIN_SEARCH_TERM = 'Data/UpdateLoginSearchTerm';
const UPDATE_LOCATION_SPECIAL_APPOINTMENT_SEARCH_TERM = 'Data/UpdateLocationSpecialAppointmentSearchTerm';
const UPDATE_ARTICLE_BACKGROUND = 'Data/UpdateArticleBackground';
const ADD_LOCATION_SORTIMENT_HISTORY = 'Data/AddLocationSortimentHistory';
const SHOW_LOCATION_SORTIMENT_HISTORY = 'Data/ShowLocationSortimentHistory';
const LOAD_LOCATION_SORTIMENT_HISTORY_LIST = 'Data/LoadLocationSortimentHistoryList';
const UPDATE_LOCATION_SORTIMENT_HISTORY = 'Data/UpdateLocationSortimentHistory';
const UPDATE_LOCATION_SORTIMENT_HISTORY_LIST = 'Data/UpdateLocationSortimentHistoryList';
const UPDATE_COLORS = 'Data/UpdateColors';
const LOAD_COLORS = 'Data/LoadColors';
const UPDATE_COLOR_TO_EDIT = 'Data/UpdateColorToEdit';
const UPDATE_COLOR_MODAL_ACTION = 'Data/UpdateColorModalAction';
const SAVE_COLOR = 'Data/SaveColor';
const DELETE_COLOR = 'Data/DeleteColor';
const UPDATE_COLOR = 'Data/UpdateColor';
const UPDATE_STATUSES = 'Data/UpdateStatuses';
const LOAD_STATUSES = 'Data/LoadStatuses';
const UPDATE_STATUS_TO_EDIT = 'Data/UpdateStatusToEdit';
const UPDATE_STATUS_MODAL_ACTION = 'Data/UpdateStatusModalAction';
const SAVE_STATUS = 'Data/SaveStatus';
const DELETE_STATUS = 'Data/DeleteStatus';
const UPDATE_STATUS = 'Data/UpdateStatus';
const UPDATE_STOCK_SORTING = 'Data/UpdateStockSorting';
const SET_STOCK_SORTING = 'Data/SetStockSorting';
const UPDATE_YEAR_FILTER = 'Data/UpdateYearFilter';
const LOAD_CONTINGENTS = 'Data/LoadContingents';
const UPDATE_CONTINGENTS = 'Data/UpdateContingents';
const LOAD_GLOBAL_SETTINGS = 'Data/LoadGlobalSettings';
const UPDATE_GLOBAL_SETTINGS = 'Data/UpdateGlobalSettings';
const SAVE_GLOBAL_SETTINGS = 'Data/SaveGlobalSettings';

const LOAD_CLAIM_DROPDOWN_MANUFACTURERS = 'Data/LoadClaimDropdownManufacturers';
const UPDATE_CLAIM_DROPDOWN_MANUFACTURERS = 'Data/UpdateClaimDropdownManufacturers';
const SAVE_CLAIM_DROPDOWN_MANUFACTURER = 'Data/SaveClaimDropdownManufacturer';
const UPDATE_CLAIM_DROPDOWN_MANUFACTURER = 'Data/UpdateClaimDropdownManufacturer';
const DELETE_CLAIM_DROPDOWN_MANUFACTURER = 'Data/DeleteClaimDropdownManufacturer';
const UPDATE_CLAIM_DROPDOWN_MANUFACTURER_TO_EDIT = 'Data/UpdateClaimDropdownManufacturerToEdit';
const UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL_ACTION = 'Data/UpdateClaimDropdownManufacturerModalAction';

const LOAD_CLAIM_DROPDOWN_REASONS = 'Data/LoadClaimDropdownReasons';
const UPDATE_CLAIM_DROPDOWN_REASONS = 'Data/UpdateClaimDropdownReasons';
const SAVE_CLAIM_DROPDOWN_REASON = 'Data/SaveClaimDropdownReason';
const UPDATE_CLAIM_DROPDOWN_REASON = 'Data/UpdateClaimDropdownReason';
const DELETE_CLAIM_DROPDOWN_REASON = 'Data/DeleteClaimDropdownReason';
const UPDATE_CLAIM_DROPDOWN_REASON_TO_EDIT = 'Data/UpdateClaimDropdownReasonToEdit';
const UPDATE_CLAIM_DROPDOWN_REASON_MODAL_ACTION = 'Data/UpdateClaimDropdownReasonModalAction';

const LOAD_CLAIM_DROPDOWN_QUALITIES = 'Data/LoadClaimDropdownQualities';
const UPDATE_CLAIM_DROPDOWN_QUALITIES = 'Data/UpdateClaimDropdownQualities';
const SAVE_CLAIM_DROPDOWN_QUALITY = 'Data/SaveClaimDropdownQuality';
const UPDATE_CLAIM_DROPDOWN_QUALITY = 'Data/UpdateClaimDropdownQuality';
const DELETE_CLAIM_DROPDOWN_QUALITY = 'Data/DeleteClaimDropdownQuality';
const UPDATE_CLAIM_DROPDOWN_QUALITY_TO_EDIT = 'Data/UpdateClaimDropdownQualityToEdit';
const UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL_ACTION = 'Data/UpdateClaimDropdownQualityModalAction';

const TOGGLE_LIKE = 'Data/ToggleLike';
const COUNT_LIKES = 'Data/CountLikes';
const LIKES = 'Data/Likes';
const UPDATE_COUNT_LIKES = 'Data/UpdateCountLikes';
const UPDATE_LIKES = 'Data/UpdateLikes';

const LOAD_COMMENTS = 'Data/LoadComments';
const ADD_COMMENT = 'Data/AddComment';
const UPDATE_COMMENT = 'Data/UpdateComment';
const UPDATE_COMMENT_LIST = 'Data/UpdateCommentList';
const DELETE_COMMENT = 'Data/DeleteComment';
const UPDATE_COMMENT_TO_EDIT = 'Data/UpdateCommentToEdit';

const LOAD_USER_POPUP = 'Data/LoadUserPopup';
const UPDATE_USER_POPUP = 'Data/UpdateUserPopup';


const actionTypes = {
    UPDATE_USER,
    UPDATE_USER_DATA,
    UPDATE_USER_TO_EDIT_DATA,
    UPDATE_NEW_USER_DATA,
    LOAD_USER_DATA,
    LOAD_CURRENT_USER_DATA,
    LOAD_USER_LIST,
    UPDATE_USER_LIST,
    UPDATE_TEMP_USER_DATA,
    RESET_TEMP_USER_DATA,
    EDIT_USER,
    UPDATE_USER_TO_EDIT,
    LOAD_ARTICLES,
    UPDATE_ARTICLE_LIST,
    ADD_ARTICLE_TO_USER,
    REMOVE_ARTICLE_FROM_USER,
    UPDATE_TASK_LIST,
    EDIT_TASK_LIST,
    LOAD_TASK_LIST,
    LOAD_BESTAND_LIST,
    UPDATE_BESTAND_LIST,
    LOAD_NEWS_LIST,
    UPDATE_NEWS_LIST,
    LOAD_EMPLOYEE_LIST,
    UPDATE_EMPLOYEE_LIST,
    ADD_TO_COMPLETED_TASK_LIST,
    REMOVE_FROM_TASK_LIST,
    LOAD_COMPLETED_TASK_LIST,
    UPDATE_COMPLETED_TASK_LIST,
    LOAD_LOCATION_LIST,
    UPDATE_LOCATION_LIST,
    LOAD_LOGIN_HISTORY_LIST,
    UPDATE_LOGIN_HISTORY_LIST,
    LOAD_CALENDAR,
    UPDATE_CALENDAR,
    LOAD_USER_INFO_LIST,
    UPDATE_USER_INFO_LIST,
    LOAD_MANUFACTURER_LIST,
    UPDATE_MANUFACTURER_LIST,
    UPDATE_LOCATION_MODAL_INPUT,
    UPDATE_LOCATION_MODAL_ACTION,
    UPDATE_LOCATION_TO_EDIT,
    ADD_LOCATION,
    UPDATE_LOCATION,
    DELETE_LOCATION,
    DELETE_USER,
    SAVE_MANUFACTURER,
    ADD_MANUFACTURER,
    UPDATE_MANUFACTURER_MODAL_INPUT,
    UPDATE_MANUFACTURER_MODAL_ACTION,
    UPDATE_MANUFACTURER_TO_EDIT,
    UPDATE_MANUFACTURERS,
    UPDATE_MANUFACTURER,
    DELETE_MANUFACTURER,
    SAVE_USER,
    UPDATE_USER_MODAL_ACTION,
    UPDATE_SAVED_TASK,
    SAVE_NEW_TASK,
    UPDATE_CURRENT_TASK,
    REMOVE_TASK,
    SAVE_NEWS,
    UPDATE_SAVED_NEWS,
    ADD_NEW_PAGE_NEWS_LIST,
    ADD_NEW_PAGE_TASK_LIST,
    UPDATE_CURRENT_NEWS,
    ADD_NEW_PAGE_COMPLETED_TASK_LIST,
    UPDATE_LIST_PROPS,
    FILTER_TASK_LIST_USING_GROUP,
    UPDATE_DROPDOWN,
    ADD_USER,
    UPDATE_ALL_LOCATIONS,
    UPDATE_MODAL,
    UPDATE_DOCUMENT_TO_EDIT,
    UPDATE_DOCUMENT_TO_EDIT_DATA,
    UPDATE_FOLDER_TO_EDIT,
    UPDATE_FOLDER_TO_EDIT_DATA,
    LOAD_FOLDER,
    LOAD_FOLDER_LIST,
    UPDATE_FOLDER_LIST,
    UPDATE_CURRENT_FOLDER,
    SAVE_FOLDER,
    SAVE_DOCUMENT,
    LOAD_CURRENT_FOLDER,
    DELETE_DOCUMENT,
    DELETE_FOLDER,
    LOAD_DOCUMENT_LIST,
    UPDATE_DOCUMENT_LIST,
    UPDATE_ARTICLE_TO_EDIT,
    UPDATE_ARTICLE_TO_EDIT_DATA,
    SAVE_ARTICLE,
    UPDATE_ARTICLE,
    DELETE_ARTICLE,
    UPDATE_STOCK,
    LOAD_ARTICLE_DROPDOWN_LIST,
    UPDATE_ARTICLE_DROPDOWN_LIST,
    UPDATE_LOAD_MORE,
    UPDATE_PAGE,
    UPDATE_ARTICLE_STATE,
    UPDATE_CALENDAR_DATA,
    LOAD_ALL_USERS,
    UPDATE_ALL_USERS,
    UPDATE_FILTER,
    TOGGLE_ARTICLE_VISIBILITY,
    UPDATE_ABSENCE_DATE,
    COUNT_HOLIDAYS,
    COUNT_SICKDAYS,
    UPDATE_HOLIDAYS,
    UPDATE_STOCK_FILTER,
    UPDATE_ARTICLE_COUNT,
    FIND_ARTICLES,
    UPDATE_ARTICLES_FROM_ADVERTISING,
    UPDATE_ARTICLE_DROPDOWN_FILTER,
    UPDATE_SHORT_LINK,
    SAVE_SHORT_LINK,
    RESET_STATE,
    UPDATE_LOCATION_INFO,
    UPDATE_CALENDAR_USER,
    UPDATE_CALENDAR_WEEK,
    LOAD_ARTICLES_WITH_EAN,
    UPDATE_ALL_LOCATION_FILTERS,
    UPDATE_ALL_LOCATION_DROPDOWNS,
    UPDATE_CURRENT_STOCK_INPUT,
    UPDATE_TEAM_TO_EDIT,
    SAVE_TEAM,
    UPDATE_HOLIDAY_API,
    LOAD_HOLIDAY_API,
    UPDATE_LOCATION_SPECIAL_APPOINTMENT,
    UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST,
    LOAD_LOCATION_SPECIAL_APPOINTMENT_LIST,
    SAVE_LOCATION_SPECIAL_APPOINTMENT,
    ADD_LOCATION_SPECIAL_APPOINTMENT,
    DELETE_LOCATION_SPECIAL_APPOINTMENT,
    UPDATE_LOGIN_SEARCH_TERM,
    UPDATE_LOCATION_SPECIAL_APPOINTMENT_SEARCH_TERM,
    UPDATE_ARTICLE_BACKGROUND,
    ADD_LOCATION_SORTIMENT_HISTORY,
    SHOW_LOCATION_SORTIMENT_HISTORY,
    LOAD_LOCATION_SORTIMENT_HISTORY_LIST,
    UPDATE_LOCATION_SORTIMENT_HISTORY,
    UPDATE_LOCATION_SORTIMENT_HISTORY_LIST,
    UPDATE_COLORS,
    LOAD_COLORS,
    UPDATE_COLOR_TO_EDIT,
    UPDATE_COLOR_MODAL_ACTION,
    SAVE_COLOR,
    DELETE_COLOR,
    UPDATE_COLOR,
    UPDATE_STATUSES,
    LOAD_STATUSES,
    UPDATE_STATUS_TO_EDIT,
    UPDATE_STATUS_MODAL_ACTION,
    SAVE_STATUS,
    DELETE_STATUS,
    UPDATE_STATUS,
    UPDATE_STOCK_SORTING,
    SET_STOCK_SORTING,
    UPDATE_YEAR_FILTER,
    LOAD_CONTINGENTS,
    UPDATE_CONTINGENTS,
    LOAD_GLOBAL_SETTINGS,
    UPDATE_GLOBAL_SETTINGS,
    SAVE_GLOBAL_SETTINGS,

    LOAD_CLAIM_DROPDOWN_MANUFACTURERS,
    UPDATE_CLAIM_DROPDOWN_MANUFACTURERS,
    SAVE_CLAIM_DROPDOWN_MANUFACTURER,
    UPDATE_CLAIM_DROPDOWN_MANUFACTURER,
    DELETE_CLAIM_DROPDOWN_MANUFACTURER,
    UPDATE_CLAIM_DROPDOWN_MANUFACTURER_TO_EDIT,
    UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL_ACTION,

    LOAD_CLAIM_DROPDOWN_REASONS,
    UPDATE_CLAIM_DROPDOWN_REASONS,
    SAVE_CLAIM_DROPDOWN_REASON,
    UPDATE_CLAIM_DROPDOWN_REASON,
    DELETE_CLAIM_DROPDOWN_REASON,
    UPDATE_CLAIM_DROPDOWN_REASON_TO_EDIT,
    UPDATE_CLAIM_DROPDOWN_REASON_MODAL_ACTION,

    LOAD_CLAIM_DROPDOWN_QUALITIES,
    UPDATE_CLAIM_DROPDOWN_QUALITIES,
    SAVE_CLAIM_DROPDOWN_QUALITY,
    UPDATE_CLAIM_DROPDOWN_QUALITY,
    DELETE_CLAIM_DROPDOWN_QUALITY,
    UPDATE_CLAIM_DROPDOWN_QUALITY_TO_EDIT,
    UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL_ACTION,

    TOGGLE_LIKE,
    COUNT_LIKES,
    LIKES,
    UPDATE_LIKES,
    UPDATE_COUNT_LIKES,

    LOAD_COMMENTS,
    ADD_COMMENT,
    UPDATE_COMMENT,
    UPDATE_COMMENT_LIST,
    DELETE_COMMENT,
    UPDATE_COMMENT_TO_EDIT,
    LOAD_USER_POPUP,
    UPDATE_USER_POPUP,


};

const updateUser = createAction(UPDATE_USER, (user) => ({
    user,
}));
const updateUserData = createAction(UPDATE_USER_DATA, (userdata) => ({
    userdata,
}));
const updateUserToEditData = createAction(UPDATE_USER_TO_EDIT_DATA, (userdata) => ({
    userdata,
}));
const updateNewUserData = createAction(UPDATE_NEW_USER_DATA, (userdata) => ({
    userdata,
}));
const updateUserList = createAction(UPDATE_USER_LIST, (userlist) => ({
    userlist,
}));
const loadUserList = createAction(LOAD_USER_LIST, (userlist) => ({
    userlist,
}));
const loadUserData = createAction(LOAD_USER_DATA, (value) => ({
    value,
}));

const loadCurrentUserData = createAction(LOAD_CURRENT_USER_DATA, (value) => ({
    value,
}));
const updateTempUserData = createAction(UPDATE_TEMP_USER_DATA, (userdata) => ({
    userdata,
}));
const resetTempUserData = createAction(RESET_TEMP_USER_DATA, (userdata) => ({
    userdata,
}));
const editUser = createAction(EDIT_USER, (user) => ({
    user,
}));
const updateUserToEdit = createAction(UPDATE_USER_TO_EDIT, (user) => ({
    user,
}));
const loadArticles = createAction(LOAD_ARTICLES, (article) => ({
    article,
}));
const updateArticleList = createAction(UPDATE_ARTICLE_LIST, (articleList) => ({
    articleList,
}));
const addArticleToUser = createAction(ADD_ARTICLE_TO_USER, (article) => ({
    article,
}));
const removeArticleFromUser = createAction(REMOVE_ARTICLE_FROM_USER, (article) => ({
    article,
}));
const loadTaskList = createAction(LOAD_TASK_LIST, (list) => ({
    list,
}));
const updateTaskList = createAction(UPDATE_TASK_LIST, (list) => ({
    list,
}));
const loadNewsList = createAction(LOAD_NEWS_LIST, (list) => ({
    list,
}));
const updateNewsList = createAction(UPDATE_NEWS_LIST, (list) => ({
    list,
}));
const loadBestandList = createAction(LOAD_BESTAND_LIST, (list) => ({
    list,
}));
const updateBestandList = createAction(UPDATE_BESTAND_LIST, (list) => ({
    list,
}));
const loadEmployeeList = createAction(LOAD_EMPLOYEE_LIST, (array) => ({
    array,
}));
const updateEmployeeList = createAction(UPDATE_EMPLOYEE_LIST, (array) => ({
    array,
}));
const loadUserGroupList = createAction(LOAD_USER_GROUP_LIST, (array) => ({
    array,
}));
const loadLocationList = createAction(LOAD_LOCATION_LIST, (value) => ({
    value,
}));
const updateLocationList = createAction(UPDATE_LOCATION_LIST, (list) => ({
    list,
}));
const loadLoginHistoryList = createAction(LOAD_LOGIN_HISTORY_LIST, (array) => ({
    array,
}));
const updateLoginHistoryList = createAction(UPDATE_LOGIN_HISTORY_LIST, (array) => ({
    array,
}));
const updateUserGroupList = createAction(UPDATE_USER_GROUP_LIST, (array) => ({
    array,
}));
const addToCompletedTaskList = createAction(ADD_TO_COMPLETED_TASK_LIST, (completedTask) => ({
    completedTask,
}));
const removeFromTaskList = createAction(REMOVE_FROM_TASK_LIST, (completedTask) => ({
    completedTask,
}));
const loadCompletedTaskList = createAction(LOAD_COMPLETED_TASK_LIST, (completedTaskList) => ({
    completedTaskList,
}));
const updateCompletedTaskList = createAction(UPDATE_COMPLETED_TASK_LIST, (list) => ({
    list,
}));
const loadCalendar = createAction(LOAD_CALENDAR, (list) => ({
    list,
}));
const updateCalendar = createAction(UPDATE_CALENDAR, (list) => ({
    list,
}));
const loadUserInfoList = createAction(LOAD_USER_INFO_LIST, (infoList) => ({
    infoList,
}));
const updateUserInfoList = createAction(UPDATE_USER_INFO_LIST, (infoList) => ({
    infoList,
}));
const updateManufacturerList = createAction(UPDATE_MANUFACTURER_LIST, (list) => ({
    list,
}));
const updateLocationModalInput = createAction(UPDATE_LOCATION_MODAL_INPUT, (location) => ({
    location,
}));
const updateLocationModalAction = createAction(UPDATE_LOCATION_MODAL_ACTION, (value) => ({
    value,
}));
const updateLocationToEdit = createAction(UPDATE_LOCATION_TO_EDIT, (value) => ({
    value,
}));
const addLocation = createAction(ADD_LOCATION, (value) => ({
    value,
}));
const updateLocation = createAction(UPDATE_LOCATION, (location) => ({
    location,
}));
const deleteLocation = createAction(DELETE_LOCATION, (location) => ({
    location,
}));
const deleteUser = createAction(DELETE_USER, (user) => ({
    user,
}));
const saveManufacturer = createAction(SAVE_MANUFACTURER, (value) => ({
    value,
}));
const addManufacturer = createAction(ADD_MANUFACTURER, (manufacturer) => ({
    manufacturer,
}));
const updateManufacturerModalInput = createAction(UPDATE_MANUFACTURER_MODAL_INPUT, (value) => ({
    value,
}));
const updateManufacturerModalAction = createAction(UPDATE_MANUFACTURER_MODAL_ACTION, (value) => ({
    value,
}));
const updateManufacturerToEdit = createAction(UPDATE_MANUFACTURER_TO_EDIT, (value) => ({
    value,
}));
const updateManufacturers = createAction(UPDATE_MANUFACTURERS, (list) => ({
    list,
}));
const updateManufacturer = createAction(UPDATE_MANUFACTURER, (manufacturer) => ({
    manufacturer,
}));
const loadManufacturerList = createAction(LOAD_MANUFACTURER_LIST, (value) => ({
    value,
}));
const deleteManufacturer = createAction(DELETE_MANUFACTURER, (manufacturer) => ({
    manufacturer,
}));
const saveUser = createAction(SAVE_USER, (user) => ({
    user,
}));
const updateUserModalAction = createAction(UPDATE_USER_MODAL_ACTION, (value) => ({
    value,
}));
const saveNewTask = createAction(SAVE_NEW_TASK, (task) => ({
    task,
}));
const updateSavedTask = createAction(UPDATE_SAVED_TASK, (task) => ({
    task,
}));
const updateCurrentTask = createAction(UPDATE_CURRENT_TASK, (task) => ({
    task,
}));
const removeTask = createAction(REMOVE_TASK, (task) => ({
    task,
}));
const saveNews = createAction(SAVE_NEWS, (news) => ({
    news,
}));
const updateSavedNews = createAction(UPDATE_SAVED_NEWS, (news) => ({
    news,
}));
const addNewPageNewsList = createAction(ADD_NEW_PAGE_NEWS_LIST, (list) => ({
    list,
}));
const addNewPageTaskList = createAction(ADD_NEW_PAGE_TASK_LIST, (list) => ({
    list,
}));
const addNewPageCompletedTaskList = createAction(ADD_NEW_PAGE_COMPLETED_TASK_LIST, (list) => ({
    list,
}));
const updateListProps = createAction(UPDATE_LIST_PROPS, (listProps) => ({
    listProps,
}));
const updateDropdown = createAction(UPDATE_DROPDOWN, (dropdown) => ({
    dropdown,
}));
const addUser = createAction(ADD_USER, (user) => ({
    user,
}));
const updateAllLocations = createAction(UPDATE_ALL_LOCATIONS, (locations) => ({
    locations,
}));
const updateModal = createAction(UPDATE_MODAL, (modal) => ({
    modal
}));
const updateDocumentToEdit = createAction(UPDATE_DOCUMENT_TO_EDIT, (document) => ({
    document
}));
const updateDocumentToEditData = createAction(UPDATE_DOCUMENT_TO_EDIT_DATA, (document) => ({
    document
}));
const updateFolderToEdit = createAction(UPDATE_FOLDER_TO_EDIT, (folder) => ({
    folder
}));
const updateFolderToEditData = createAction(UPDATE_FOLDER_TO_EDIT_DATA, (folder) => ({
    folder
}));
const loadFolderList = createAction(LOAD_FOLDER_LIST, (list) => ({
    list
}));
const updateFolderList = createAction(UPDATE_FOLDER_LIST, (list) => ({
    list
}));
const loadFolder = createAction(LOAD_FOLDER, (folder) => ({
    folder
}));
const updateCurrentFolder = createAction(UPDATE_CURRENT_FOLDER, (folder) => ({
    folder
}));
const saveFolder = createAction(SAVE_FOLDER, (folder) => ({
    folder
}));
const saveDocument = createAction(SAVE_DOCUMENT, (document) => ({
    document
}));
const loadCurrentFolder = createAction(LOAD_CURRENT_FOLDER, (folder) => ({
    folder
}));
const deleteDocument = createAction(DELETE_DOCUMENT, (document) => ({
    document
}));
const deleteFolder = createAction(DELETE_FOLDER, (folder) => ({
    folder
}));
const loadDocumentList = createAction(LOAD_DOCUMENT_LIST, (list) => ({
    list
}));
const updateDocumentList = createAction(UPDATE_DOCUMENT_LIST, (list) => ({
    list
}));
const updateArticleToEdit = createAction(UPDATE_ARTICLE_TO_EDIT, (article) => ({
    article
}));
const updateArticleToEditData = createAction(UPDATE_ARTICLE_TO_EDIT_DATA, (article) => ({
    article
}));
const saveArticle = createAction(SAVE_ARTICLE, (article) => ({
    article
}));
const updateArticle = createAction(UPDATE_ARTICLE, (article) => ({
    article
}));
const deleteArticle = createAction(DELETE_ARTICLE, (article) => ({
    article
}));
const updateStock = createAction(UPDATE_STOCK, (stock) => ({
    stock
}));
const loadArticleDropdownList = createAction(LOAD_ARTICLE_DROPDOWN_LIST, (list) => ({
    list
}));
const updateArticleDropdownList = createAction(UPDATE_ARTICLE_DROPDOWN_LIST, (list) => ({
    list
}));
const updateLoadMore = createAction(UPDATE_LOAD_MORE, (data) => ({
    data
}));
const updatePage = createAction(UPDATE_PAGE, (data) => ({
    data
}));
const updateArticleState = createAction(UPDATE_ARTICLE_STATE, (article) => ({
    article
}));
const updateCurrentNews = createAction(UPDATE_CURRENT_NEWS, (singleNewsObject) => ({
    singleNewsObject,
}));
const filterTaskListUsingGroup = createAction(FILTER_TASK_LIST_USING_GROUP, (list) => ({
    list
}));
const updateCalendarData = createAction(UPDATE_CALENDAR_DATA, (data) => ({
    data
}));
const loadAllUsers = createAction(LOAD_ALL_USERS, (value) => ({
    value
}));
const updateAllUsers = createAction(UPDATE_ALL_USERS, (list) => ({
    list
}));
const updateFilter = createAction(UPDATE_FILTER, (filter) => ({
    filter
}));
const toggleArticleVisibility = createAction(TOGGLE_ARTICLE_VISIBILITY, (article) => ({
    article
}));

const updateAbsenceDate = createAction(UPDATE_ABSENCE_DATE, (date) => ({ date }));
const countHolidays = createAction(COUNT_HOLIDAYS, (value) => ({ value }));
const countSickDays = createAction(COUNT_SICKDAYS, (value) => ({ value }));
const updateHolidays = createAction(UPDATE_HOLIDAYS, (holidays) => ({ holidays }));
const updateStockFilter = createAction(UPDATE_STOCK_FILTER, (filter) => ({ filter }));
const updateArticleDropdownFilter = createAction(UPDATE_ARTICLE_DROPDOWN_FILTER, (filter) => ({ filter }));
const updateArticleCount = createAction(UPDATE_ARTICLE_COUNT, (value) => ({ value }));
const findArticles = createAction(FIND_ARTICLES, (articles) => ({ articles }));
const updateArticlesFromAdvertising = createAction(UPDATE_ARTICLES_FROM_ADVERTISING, (value) => ({ value }));
const updateShortLink = createAction(UPDATE_SHORT_LINK, (shortLink) => ({ shortLink }));
const saveShortLink = createAction(SAVE_SHORT_LINK, (shortLink) => ({ shortLink }));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const updateLocationInfo = createAction(UPDATE_LOCATION_INFO, (locationInfo) => ({ locationInfo }));
const updateCalendarUser = createAction(UPDATE_CALENDAR_USER, (calendarUser) => ({ calendarUser }));
const updateCalendarWeek = createAction(UPDATE_CALENDAR_WEEK, (date) => ({ date }));
const loadArticlesWithEan = createAction(LOAD_ARTICLES_WITH_EAN, (filter) => ({ filter }));
const updateAllLocationFilters = createAction(UPDATE_ALL_LOCATION_FILTERS, (filter) => ({ filter }));
const updateAllLocationDropdowns = createAction(UPDATE_ALL_LOCATION_DROPDOWNS, (dropdown) => ({ dropdown }));
const updateCurrentStockInput = createAction(UPDATE_CURRENT_STOCK_INPUT, (input) => ({ input }));
const updateTeamToEdit = createAction(UPDATE_TEAM_TO_EDIT, (team) => ({team}))
const saveTeam = createAction(SAVE_TEAM, (team) => ({team}))
const updateHolidayApi = createAction(UPDATE_HOLIDAY_API, (holidayApi) => ({holidayApi}))
const loadHolidayApi = createAction(LOAD_HOLIDAY_API, (holidayApi) => ({holidayApi}))
const updateLocationSpecialAppointment = createAction(UPDATE_LOCATION_SPECIAL_APPOINTMENT, (locationSpecialAppointment) => ({locationSpecialAppointment}))
const updateLocationSpecialAppointmentList = createAction(UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST, (list) => ({list}))
const loadLocationSpecialAppointmentList = createAction(LOAD_LOCATION_SPECIAL_APPOINTMENT_LIST, (page) => ({page}))
const saveLocationSpecialAppointment = createAction(SAVE_LOCATION_SPECIAL_APPOINTMENT, (locationSpecialAppointment) => ({locationSpecialAppointment}))
const addLocationSpecialAppointment = createAction(ADD_LOCATION_SPECIAL_APPOINTMENT, (locationSpecialAppointment) => ({locationSpecialAppointment}))
const deleteLocationSpecialAppointment = createAction(DELETE_LOCATION_SPECIAL_APPOINTMENT, (locationSpecialAppointment) => ({locationSpecialAppointment}))
const updateLoginSearchTerm = createAction(UPDATE_LOGIN_SEARCH_TERM, (term) => ({term}))
const updateLocationSpecialAppointmentSearchTerm = createAction(UPDATE_LOCATION_SPECIAL_APPOINTMENT_SEARCH_TERM, (term) => ({term}))
const updateArticleBackground = createAction(UPDATE_ARTICLE_BACKGROUND, (article) => ({article}))
const addLocationSortimentHistory  = createAction(ADD_LOCATION_SORTIMENT_HISTORY, (locationSortimentHistory) => ({locationSortimentHistory}))
const showLocationSortimentHistory  = createAction(SHOW_LOCATION_SORTIMENT_HISTORY, (locationSortimentHistory) => ({locationSortimentHistory}))
const updateLocationSortimentHistory  = createAction(UPDATE_LOCATION_SORTIMENT_HISTORY, (locationSortimentHistory) => ({locationSortimentHistory}))
const loadLocationSortimentHistoryList  = createAction(LOAD_LOCATION_SORTIMENT_HISTORY_LIST, (locationSortimentHistory) => ({locationSortimentHistory}))
const updateLocationSortimentHistoryList  = createAction(UPDATE_LOCATION_SORTIMENT_HISTORY_LIST, (list) => ({list}))
const updateColors  = createAction(UPDATE_COLORS, (list) => ({list}))
const updateColorToEdit  = createAction(UPDATE_COLOR_TO_EDIT, (color) => ({color}))
const updateColorModalAction  = createAction(UPDATE_COLOR_MODAL_ACTION, (value) => ({value}))
const saveColor  = createAction(SAVE_COLOR, (color) => ({color}))
const loadColors  = createAction(LOAD_COLORS, (value) => ({value}))
const deleteColor  = createAction(DELETE_COLOR, (color) => ({color}))
const updateColor  = createAction(UPDATE_COLOR, (color) => ({color}))
const updateStatuses  = createAction(UPDATE_STATUSES, (list) => ({list}))
const updateStatusToEdit  = createAction(UPDATE_STATUS_TO_EDIT, (status) => ({status}))
const updateStatusModalAction  = createAction(UPDATE_STATUS_MODAL_ACTION, (value) => ({value}))
const saveStatus  = createAction(SAVE_STATUS, (status) => ({status}))
const loadStatuses  = createAction(LOAD_STATUSES, (value) => ({value}))
const deleteStatus  = createAction(DELETE_STATUS, (status) => ({status}))
const updateStatus  = createAction(UPDATE_STATUS, (status) => ({status}))
const updateStockSorting  = createAction(UPDATE_STOCK_SORTING, (stockSorting) => ({stockSorting}))
const setStockSorting  = createAction(SET_STOCK_SORTING, (stockSorting) => ({stockSorting}))
const updateYearFilter  = createAction(UPDATE_YEAR_FILTER, (yearFilter) => ({yearFilter}))
const loadContingents  = createAction(LOAD_CONTINGENTS, (value) => ({value}))
const updateContingents  = createAction(UPDATE_CONTINGENTS, (contingents) => ({contingents}))
const loadGlobalSettings  = createAction(LOAD_GLOBAL_SETTINGS, (value) => ({value}))
const updateGlobalSettings  = createAction(UPDATE_GLOBAL_SETTINGS, (globalSettings) => ({globalSettings}))
const saveGlobalSettings  = createAction(SAVE_GLOBAL_SETTINGS, (globalSettings) => ({globalSettings}))

const loadClaimDropdownManufacturers  = createAction(LOAD_CLAIM_DROPDOWN_MANUFACTURERS, (value) => ({value}))
const updateClaimDropdownManufacturers  = createAction(UPDATE_CLAIM_DROPDOWN_MANUFACTURERS, (list) => ({list}))
const saveClaimDropdownManufacturer  = createAction(SAVE_CLAIM_DROPDOWN_MANUFACTURER, (claimDropdownManufacturer) => ({claimDropdownManufacturer}))
const updateClaimDropdownManufacturer  = createAction(UPDATE_CLAIM_DROPDOWN_MANUFACTURER, (claimDropdownManufacturer) => ({claimDropdownManufacturer}))
const deleteClaimDropdownManufacturer  = createAction(DELETE_CLAIM_DROPDOWN_MANUFACTURER, (claimDropdownManufacturer) => ({claimDropdownManufacturer}))
const updateClaimDropdownManufacturerToEdit  = createAction(UPDATE_CLAIM_DROPDOWN_MANUFACTURER_TO_EDIT, (claimDropdownManufacturer) => ({claimDropdownManufacturer}))
const updateClaimDropdownManufacturerModalAction  = createAction(UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL_ACTION, (value) => ({value}))

const loadClaimDropdownReasons  = createAction(LOAD_CLAIM_DROPDOWN_REASONS, (value) => ({value}))
const updateClaimDropdownReasons  = createAction(UPDATE_CLAIM_DROPDOWN_REASONS, (list) => ({list}))
const saveClaimDropdownReason  = createAction(SAVE_CLAIM_DROPDOWN_REASON, (claimDropdownReason) => ({claimDropdownReason}))
const updateClaimDropdownReason  = createAction(UPDATE_CLAIM_DROPDOWN_REASON, (claimDropdownReason) => ({claimDropdownReason}))
const deleteClaimDropdownReason  = createAction(DELETE_CLAIM_DROPDOWN_REASON, (claimDropdownReason) => ({claimDropdownReason}))
const updateClaimDropdownReasonToEdit  = createAction(UPDATE_CLAIM_DROPDOWN_REASON_TO_EDIT, (claimDropdownReason) => ({claimDropdownReason}))
const updateClaimDropdownReasonModalAction  = createAction(UPDATE_CLAIM_DROPDOWN_REASON_MODAL_ACTION, (value) => ({value}))

const loadClaimDropdownQualities  = createAction(LOAD_CLAIM_DROPDOWN_QUALITIES, (value) => ({value}))
const updateClaimDropdownQualities  = createAction(UPDATE_CLAIM_DROPDOWN_QUALITIES, (list) => ({list}))
const saveClaimDropdownQuality = createAction(SAVE_CLAIM_DROPDOWN_QUALITY, (claimDropdownQuality) => ({claimDropdownQuality}))
const updateClaimDropdownQuality  = createAction(UPDATE_CLAIM_DROPDOWN_QUALITY, (claimDropdownQuality) => ({claimDropdownQuality}))
const deleteClaimDropdownQuality  = createAction(DELETE_CLAIM_DROPDOWN_QUALITY, (claimDropdownQuality) => ({claimDropdownQuality}))
const updateClaimDropdownQualityToEdit  = createAction(UPDATE_CLAIM_DROPDOWN_QUALITY_TO_EDIT, (claimDropdownQuality) => ({claimDropdownQuality}))
const updateClaimDropdownQualityModalAction  = createAction(UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL_ACTION, (value) => ({value}))

const toggleLike  = createAction(TOGGLE_LIKE, (value) => ({value}))
const countLikes  = createAction(COUNT_LIKES, (value) => ({value}))
const updateCountLikes  = createAction(UPDATE_COUNT_LIKES, (list) => ({list}))
const likes  = createAction(LIKES, (value) => ({value}))
const updateLikes  = createAction(UPDATE_LIKES, (list) => ({list}))


const loadComments  = createAction(LOAD_COMMENTS, (value) => ({value}))
const addComment  = createAction(ADD_COMMENT, (comment) => ({comment}))
const updateComment  = createAction(UPDATE_COMMENT, (comment) => ({comment}))
const updateCommentList  = createAction(UPDATE_COMMENT_LIST, (list) => ({list}))
const deleteComment  = createAction(DELETE_COMMENT, (comment) => ({comment}))
const updateCommentToEdit  = createAction(UPDATE_COMMENT_TO_EDIT, (comment) => ({comment}))
const loadUserPopup  = createAction(LOAD_USER_POPUP, (value) => ({value}))
const updateUserPopup  = createAction(UPDATE_USER_POPUP, (list) => ({list}))

const actions = {
    updateUser,
    updateUserToEditData,
    updateNewUserData,
    updateUserList,
    loadUserData,
    loadCurrentUserData,
    updateTempUserData,
    resetTempUserData,
    loadUserList,
    editUser,
    updateUserToEdit,
    removeTask,
    updateListProps,
    loadArticles,
    updateArticleList,
    addArticleToUser,
    removeArticleFromUser,
    updateTaskList,
    loadTaskList,
    loadBestandList,
    updateBestandList,
    loadNewsList,
    updateNewsList,
    loadEmployeeList,
    updateEmployeeList,
    addToCompletedTaskList,
    removeFromTaskList,
    loadCompletedTaskList,
    updateCompletedTaskList,
    loadUserGroupList,
    updateUserGroupList,
    loadLocationList,
    updateLocationList,
    loadLoginHistoryList,
    updateLoginHistoryList,
    updateCurrentFolder,
    loadUserInfoList,
    updateUserInfoList,
    loadManufacturerList,
    updateManufacturerList,
    updateLocationModalInput,
    updateLocationModalAction,
    updateLocationToEdit,
    addLocation,
    updateLocation,
    deleteLocation,
    saveManufacturer,
    addManufacturer,
    updateManufacturerModalAction,
    updateManufacturerModalInput,
    updateManufacturerToEdit,
    updateManufacturers,
    deleteManufacturer,
    updateManufacturer,
    saveUser,
    updateUserData,
    deleteUser,
    updateUserModalAction,
    saveNewTask,
    updateSavedTask,
    updateCurrentTask,
    loadCalendar,
    updateCalendar,
    saveNews,
    updateSavedNews,
    addNewPageNewsList,
    updateCurrentNews,
    addNewPageTaskList,
    addNewPageCompletedTaskList,
    filterTaskListUsingGroup,
    addUser,
    updateDropdown,
    updateAllLocations,
    updateModal,
    updateDocumentToEdit,
    updateDocumentToEditData,
    updateFolderToEdit,
    updateFolderToEditData,
    loadFolderList,
    updateFolderList,
    loadFolder,
    saveFolder,
    saveDocument,
    loadCurrentFolder,
    deleteDocument,
    deleteFolder,
    loadDocumentList,
    updateDocumentList,
    updateArticleToEdit,
    updateArticleToEditData,
    saveArticle,
    updateArticle,
    deleteArticle,
    updateStock,
    loadArticleDropdownList,
    updateArticleDropdownList,
    updateLoadMore,
    updatePage,
    updateArticleState,
    updateCalendarData,
    loadAllUsers,
    updateAllUsers,
    updateFilter,
    toggleArticleVisibility,
    updateAbsenceDate,
    countHolidays,
    countSickDays,
    updateHolidays,
    updateStockFilter,
    updateArticleCount,
    findArticles,
    updateArticlesFromAdvertising,
    updateArticleDropdownFilter,
    updateShortLink,
    saveShortLink,
    resetState,
    updateLocationInfo,
    updateCalendarUser,
    updateCalendarWeek,
    loadArticlesWithEan,
    updateAllLocationFilters,
    updateAllLocationDropdowns,
    updateCurrentStockInput,
    updateTeamToEdit,
    saveTeam,
    updateHolidayApi,
    loadHolidayApi,
    updateLocationSpecialAppointment,
    updateLocationSpecialAppointmentList,
    loadLocationSpecialAppointmentList,
    saveLocationSpecialAppointment,
    addLocationSpecialAppointment,
    deleteLocationSpecialAppointment,
    updateLoginSearchTerm,
    updateLocationSpecialAppointmentSearchTerm,
    updateArticleBackground,
    addLocationSortimentHistory,
    showLocationSortimentHistory,
    updateLocationSortimentHistory,
    loadLocationSortimentHistoryList,
    updateLocationSortimentHistoryList,
    updateColors,
    updateColorToEdit,
    updateColorModalAction,
    saveColor,
    loadColors,
    deleteColor,
    updateColor,
    updateStatuses,
    updateStatusToEdit,
    updateStatusModalAction,
    saveStatus,
    loadStatuses,
    deleteStatus,
    updateStatus,
    updateStockSorting,
    setStockSorting,
    updateYearFilter,
    loadContingents,
    updateContingents,
    loadGlobalSettings,
    updateGlobalSettings,
    saveGlobalSettings,
    loadClaimDropdownManufacturers,
    updateClaimDropdownManufacturers,
    saveClaimDropdownManufacturer,
    updateClaimDropdownManufacturer,
    deleteClaimDropdownManufacturer,
    updateClaimDropdownManufacturerToEdit,
    updateClaimDropdownManufacturerModalAction,
    loadClaimDropdownReasons,
    updateClaimDropdownReasons,
    saveClaimDropdownReason,
    updateClaimDropdownReason,
    deleteClaimDropdownReason,
    updateClaimDropdownReasonToEdit,
    updateClaimDropdownReasonModalAction,
    loadClaimDropdownQualities,
    updateClaimDropdownQualities,
    saveClaimDropdownQuality,
    updateClaimDropdownQuality,
    deleteClaimDropdownQuality,
    updateClaimDropdownQualityToEdit,
    updateClaimDropdownQualityModalAction,
    toggleLike,
    countLikes,
    likes,
    updateCountLikes,
    updateLikes,
    loadComments,
    addComment,
    updateComment,
    deleteComment,
    updateCommentToEdit,
    updateCommentList,
    loadUserPopup,
    updateUserPopup,
};

const reducer = handleActions({
    [UPDATE_USER_DATA]: (payload) => oldState => {
        return $set('data.currentUser', payload.userdata, oldState);
    },
    [UPDATE_USER_TO_EDIT_DATA]: (payload) => oldState => {
        return $set('data.userToEdit.' + payload.userdata.prop, payload.userdata.val, oldState);
    },
    [UPDATE_NEW_USER_DATA]: (payload) => oldState => {
        return $set('data.newUser.' + payload.userdata.prop, payload.userdata.val, oldState);
    },
    [UPDATE_USER_LIST]: (payload) => oldState => {
        return $set('data.userList', payload.userlist, oldState);
    },
    [UPDATE_TEMP_USER_DATA]: (payload) => oldState => {
        return $set('data.userTempData.' + payload.userdata.prop, payload.userdata.val, oldState);
    },
    [RESET_TEMP_USER_DATA]: (payload) => oldState => {
        return $set('data.userTempData', payload.userdata, oldState);
    },
    [UPDATE_USER_TO_EDIT]: (payload) => oldState => {
        return $set('data.userToEdit', payload.user, oldState);
    },
    [UPDATE_ARTICLE_LIST]: (payload) => oldState => {
        let list = [...oldState.data.articleList, ...payload.articleList.articleList];
        if (payload.articleList.reset === true) {
           list = [];
        }
        return $set('data.articleList', list, oldState);
    },
    [ADD_ARTICLE_TO_USER]: (payload) => oldState => {
        let articles = oldState.data.userToEdit.articles;
        if (oldState.data.userToEdit.articles.indexOf(payload.article) === -1 && payload.article !== '') {
            articles.push(payload.article);
        }
        return $set('data.userToEdit.articles', articles, oldState);
    },
    [REMOVE_ARTICLE_FROM_USER]: (payload) => oldState => {
        let articles = oldState.data.userToEdit.articles;
        articles.splice(articles.indexOf(payload.article), 1);
        return $set('data.userToEdit.articles', articles, oldState);
    },
    [ADD_NEW_PAGE_NEWS_LIST]: (payload) => oldState => {
        let newList;
        newList = [...oldState.data.newsList, ...payload.list];
        return $set('data.newsList', newList, oldState);
    },
    [ADD_NEW_PAGE_COMPLETED_TASK_LIST]: (payload) => oldState => {
        let completedTaskList;
        completedTaskList = [...oldState.data.completedTaskList, ...payload.list];
        return $set('data.completedTaskList', completedTaskList, oldState);
    },
    [ADD_NEW_PAGE_TASK_LIST]: (payload) => oldState => {
        let taskList;
        taskList = [...oldState.data.taskList, ...payload.list];
        return $set('data.taskList', taskList, oldState);
    },
    [UPDATE_TASK_LIST]: (payload) => oldState => {
        return $set('data.taskList', payload.list, oldState);
    },
    [UPDATE_BESTAND_LIST]: (payload) => oldState => {
        return $set('data.bestandList', payload.list, oldState);
    },
    [UPDATE_BESTAND_LIST]: (payload) => oldState => {
        return $set('data.bestandList', payload.list, oldState);
    },
    [UPDATE_NEWS_LIST]: (payload) => oldState => {
        return $set('data.newsList', payload.list, oldState);
    },
    [UPDATE_CURRENT_NEWS]: (payload) => oldState => {
        return $set('data.currentNews', payload.singleNewsObject, oldState);
    },
    [UPDATE_USER_GROUP_LIST]: (payload) => oldState => {
        return $set('data.userGroupList', payload.array, oldState);
    },
    [UPDATE_LOCATION_LIST]: (payload) => oldState => {
        return $set('data.locationList', payload.list, oldState);
    },
    [UPDATE_LOGIN_HISTORY_LIST]: (payload) => oldState => {
        return $set('data.loginHistoryList', payload.array, oldState);
    },
    [UPDATE_COMPLETED_TASK_LIST]: (payload) => oldState => {
        let filteredList = payload.list.filter(task => {
            return task.completed
        });
        return $set('data.completedTaskList', filteredList, oldState);
    },
    [ADD_TO_COMPLETED_TASK_LIST]: (payload) => oldState => {
        let completedTaskList = oldState.data.taskList;

        completedTaskList.unshift(payload.task);
        return $set('data.completedTaskList', completedTaskList, oldState);
    },
    [REMOVE_FROM_TASK_LIST]: (payload) => oldState => {
        let completedTaskList = oldState.data.completedTaskList;
        completedTaskList.splice(completedTaskList.indexOf(payload.completedTask), 1);
        return $set('data.completedTaskList', completedTaskList, oldState);
    },
    [UPDATE_CALENDAR]: (payload) => oldState => {
        return $set('data.calendar', payload.list, oldState);
    },
    [UPDATE_USER_INFO_LIST]: (payload) => oldState => {
        return $set('data.userInfoList', payload.infoList, oldState);
    },
    [UPDATE_MANUFACTURER_LIST]: (payload) => oldState => {
        return $set('data.manufacturerList', payload.list, oldState);
    },
    [UPDATE_LOCATION_MODAL_INPUT]: (payload) => oldState => {
        return $set('data.locationModalInput.' + payload.location.prop, payload.location.value, oldState);
    },
    [UPDATE_LOCATION_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.locationModalAction', payload.value, oldState);
    },
    [UPDATE_LOCATION_TO_EDIT]: (payload) => oldState => {
        return $set('data.locationToEdit', payload.value, oldState);
    },
    [UPDATE_LOCATION]: (payload) => oldState => {
        let locations = oldState.data.locationList.map(location => {
            return (location.id === payload.location.id) ? payload.location : location;
        });
        return $set('data.locationList', locations, oldState);
    },
    [DELETE_LOCATION]: (payload) => oldState => {
        let locationList = oldState.data.locationList;
        locationList = oldState.data.locationList.filter(location => location.id !== payload.location.id)
        return $set('data.locationList', locationList, oldState);
    },
    [DELETE_MANUFACTURER]: (payload) => oldState => {
        let manufacturers = oldState.data.manufacturers;
        manufacturers = oldState.data.manufacturers.filter(manufacturer => manufacturer.id !== payload.manufacturer.id)
        return $set('data.manufacturers', manufacturers, oldState);
    },
    [DELETE_USER]: (payload) => oldState => {
        let users = oldState.data.userList;
        users = oldState.data.userList.filter(user => user.id !== payload.user.id)
        return $set('data.userList', users, oldState);
    },
    [ADD_MANUFACTURER]: (payload) => oldState => {
        let manufacturerList = oldState.data.manufacturers;
        manufacturerList.push(payload.manufacturer);
        return $set('data.manufacturers', manufacturerList, oldState);
    },
    [UPDATE_MANUFACTURERS]: (payload) => oldState => {
        return $set('data.manufacturers', payload.list, oldState);
    },
    [UPDATE_MANUFACTURER_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.manufacturerModalAction', payload.value, oldState);
    },
    [UPDATE_MANUFACTURER_MODAL_INPUT]: (payload) => oldState => {
        return $set('data.manufacturerModalInput', payload.value, oldState);
    },
    [UPDATE_MANUFACTURER_TO_EDIT]: (payload) => oldState => {
        return $set('data.manufacturerToEdit', payload.value, oldState);
    },
    [UPDATE_MANUFACTURER]: (payload) => oldState => {
        let manufacturers = oldState.data.manufacturers.map(manufacturer => {
            return (manufacturer.id === payload.manufacturer.id) ? payload.manufacturer : manufacturer;
        });
        return $set('data.manufacturers', manufacturers, oldState);
    },
    [UPDATE_USER_LIST]: (payload) => oldState => {
        return $set('data.userList', payload.userlist, oldState);
    },
    [UPDATE_USER]: (payload) => oldState => {
        let users = [];
        if (Array.isArray(oldState.data.userList)) {
             users = oldState.data.userList.map(user => {
                return (user.id === payload.user.id) ? payload.user : user;
            });
        }
        return $set('data.userList', users, oldState);
    },
    [UPDATE_USER_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.userModalAction', payload.value, oldState);
    },
    [UPDATE_SAVED_TASK]: (payload) => oldState => {
        return $set('data.uploadedTask.' + payload.task.prop, payload.task.val, oldState);
    },
    [REMOVE_TASK]: (payload) => oldState => {
        let tasks = [...oldState.data.taskList];
        tasks.splice(payload.task, 1);
        return $set('data.taskList', tasks, oldState);
    },
    [UPDATE_CURRENT_TASK]: (payload) => oldState => {
        let filteredList = oldState.data.taskList.filter(task => {
            if (task.id === payload.task.id) {
                task.completed = true;
                return task;
            }
            return task;
        });
        return $set('data.taskList', filteredList, oldState);
    },
    [FILTER_TASK_LIST_USING_GROUP]: (payload) => oldState => {
        let filteredList = oldState.data.taskList.filter(task => {
            if (task.team === oldState.data.taskListProps.groupName) {
                return task;
            }
            console.log("groupTasklist is here: ", task);
            return [];
        });
        return $set('data.groupFilterTaskList', filteredList, oldState);
    },
    [UPDATE_SAVED_NEWS]: (payload) => oldState => {
        return $set('data.uploadedNews.' +payload.news.prop, payload.news.val, oldState);
    },
    [UPDATE_LIST_PROPS]: (payload) => oldState => {
        return $set('data.taskListProps.'+payload.listProps.prop, payload.listProps.val, oldState);
    },
     [UPDATE_DROPDOWN]: (payload) => oldState => {

        if (payload.dropdown.prop === 'selectedId' && payload.dropdown.multiple) {
            console.log(payload)
            console.log(oldState.data.dropdown[payload.dropdown.parent])
           let ids = [...oldState.data.dropdown[payload.dropdown.parent][payload.dropdown.dropdownName].selectedId];
           if(ids.indexOf(payload.dropdown.val) === -1) {
              ids.push(payload.dropdown.val);
           } else {
               ids.splice(ids.indexOf(payload.dropdown.val), 1);
               if (!ids.length) {
                   ids = [];
               }
           }
           return $set('data.'+payload.dropdown.dropdown+'.'+payload.dropdown.prop, ids,  oldState);
        }
        return $set('data.'+payload.dropdown.dropdown+'.'+payload.dropdown.prop, payload.dropdown.val,  oldState);
    },
    [ADD_USER]: (payload) => oldState => {
        let userList = oldState.data.userList;
        userList.push(payload.user);
        return $set('data.userList', userList, oldState);
    },
    [UPDATE_ALL_LOCATIONS]: (payload) => oldState => {
        return $set('data.allLocations', payload.locations, oldState);
    },
    [UPDATE_MODAL]: (payload) => oldState => {
        return $set('data.modal.'+payload.modal.path+'.'+payload.modal.prop, payload.modal.val, oldState);
    },
    [UPDATE_DOCUMENT_TO_EDIT]: (payload) => oldState => {
        return $set('data.documentToEdit', payload.document, oldState);
    },
    [UPDATE_DOCUMENT_TO_EDIT_DATA]: (payload) => oldState => {
        return $set('data.documentToEdit.' + payload.document.prop, payload.document.val, oldState);
    },
    [UPDATE_FOLDER_TO_EDIT]: (payload) => oldState => {
        return $set('data.folderToEdit', payload.folder, oldState);
    },
    [UPDATE_FOLDER_TO_EDIT_DATA]: (payload) => oldState => {
        return $set('data.folderToEdit.' + payload.folder.prop, payload.folder.val, oldState);
    },
    [UPDATE_FOLDER_LIST]: (payload) => oldState => {
        return $set('data.folderList', payload.list, oldState);
    },
    [UPDATE_CURRENT_FOLDER]: (payload) => oldState => {
        return $set('data.currentFolder', payload.folder, oldState);
    },
    [UPDATE_DOCUMENT_LIST]: (payload) => oldState => {
        return $set('data.documentList', payload.list, oldState);
    },
    [UPDATE_ARTICLE_TO_EDIT]: (payload) => oldState => {
        return $set('data.articleToEdit', payload.article, oldState);
    },
    [UPDATE_ARTICLE_TO_EDIT_DATA]: (payload) => oldState => {
        if(payload.article.prop === 'scanList') {
            if (Object.prototype.hasOwnProperty.call(oldState.data.articleToEdit, 'scanList')) {
                let scanList = [...oldState.data.articleToEdit.scanList];
                scanList.push(payload.article.val);
                return $set('data.articleToEdit.' + payload.article.prop, scanList, oldState);
            } else {
                let scanList = [];
                scanList.push(payload.article.val);
                return $set('data.articleToEdit.' + payload.article.prop, scanList, oldState);
            }
        } else if(payload.article.prop === 'scanListEmpty') {
            return $set('data.articleToEdit.scanList', payload.article.val, oldState);
        } else {
            return $set('data.articleToEdit.' + payload.article.prop, payload.article.val, oldState);
        }
    },
    [DELETE_ARTICLE]: (payload) => oldState => {
        let articles = [...oldState.data.articleList];
        let newArticleList = articles.filter(article => article.id !== payload.article.id)
        return $set('data.articleList', newArticleList, oldState);
    },
    [UPDATE_ARTICLE_DROPDOWN_LIST]: (payload) => oldState => {
        return $set('data.articleDropdownList', payload.list, oldState);
    },
    [UPDATE_LOAD_MORE]: (payload) => oldState => {
        return $set('data.loadMore.'+payload.data.prop, payload.data.val, oldState);
    },
    [UPDATE_PAGE]: (payload) => oldState => {
        return $set('data.'+payload.data.prop, payload.data.val, oldState);
    },
    [UPDATE_ARTICLE_STATE]: (payload) => oldState => {
        let articles = oldState.data.articleList.map(article => {
            if(article.id === payload.article.id){
                return payload.article;
            }
            return article;
        });
        return $set('data.articleList', articles, oldState);
    },
    [UPDATE_ARTICLE_BACKGROUND]: (payload) => oldState => {
        let articles = oldState.data.articleList.map(article => {
            if(article.id === payload.article.id){
                article.backgroundColor = payload.article.color
                return article
            }
            return article;
        });

        return $set('data.articleList', articles, oldState);
    },
    [UPDATE_CALENDAR_DATA]: (payload) => oldState => {
        return $set('data.calendar.'+payload.data.dateIndex+'.users.'+payload.data.userIndex+'.'+payload.data.prop, payload.data.val, oldState);
    },
    [UPDATE_ALL_USERS]: (payload) => oldState => {
        return $set('data.allUsers', payload.list, oldState);
    },
    [UPDATE_FILTER]: (payload) => oldState => {
        return $set(payload.filter.path+'.'+payload.filter.prop, payload.filter.val, oldState);
    },
    [TOGGLE_ARTICLE_VISIBILITY]: (payload) => oldState => {
        let filteredList = oldState.data.articleList.filter(article => {
            if (article.id === payload.article.article.id) {
                article.active = !article.active;
                return article;
            }
            return article;
        });
        return $set('data.articleList', filteredList, oldState);
    },
    [UPDATE_ABSENCE_DATE]: (payload) => oldState => {
        return $set('data.absenceDate', payload.date, oldState)
    },
    [UPDATE_HOLIDAYS]: (payload) => oldState => {
        const holidays = {
            'past' : payload.holidays.past,
            'planned' : payload.holidays.planned
        };
        return $set('data.holidays', holidays, oldState)
    },
    [UPDATE_STOCK_FILTER]: (payload) => oldState => {
        return $set('data.stockFilter.'+payload.filter.prop, payload.filter.value, oldState)
    },
    [UPDATE_ARTICLE_DROPDOWN_FILTER]: (payload) => oldState => {
        return $set('data.articleDropdownFilter.'+payload.filter.prop, payload.filter.value, oldState)
    },
    [UPDATE_ARTICLE_COUNT]: (payload) => oldState => {
        return $set('data.articleCount', payload.value, oldState)
    },
    [UPDATE_ARTICLES_FROM_ADVERTISING]: (payload) => oldState => {
        return $set('data.articlesFromAdvertising', payload.value, oldState)
    },
    [UPDATE_SHORT_LINK]: (payload) => oldState => {
        return $set('data.shortLink.' + payload.shortLink.prop, payload.shortLink.val, oldState);
    },
    [RESET_STATE]: (payload) => oldState => {
        return $set('data', initialState, oldState);
    },
    [UPDATE_CALENDAR_USER]: (payload) => oldState => {
        return $set('data.calendarUser', payload.calendarUser, oldState);
    },
    [UPDATE_ALL_LOCATION_FILTERS]: (payload) => oldState => {
        let data = oldState.data;
        const paths = [
            'data.filter.task.location',
            'data.filter.absencerecord.location',
            'data.filter.stock.location',
            'data.filter.sickReport.location',
            'dropdown.settings.user.location',
            'dropdown.news.location',
        ];
        if (paths.includes(payload.filter.path)) {
            data.filter.task.location.selectedLabel = payload.filter.selectedLabel;
            data.filter.task.location.selectedId = payload.filter.selectedId;
            data.filter.absencerecord.location.selectedLabel = payload.filter.selectedLabel;
            data.filter.absencerecord.location.selectedId = payload.filter.selectedId;
            data.filter.stock.location.selectedLabel = payload.filter.selectedLabel;
            data.filter.stock.location.selectedId = payload.filter.selectedId;
            data.filter.sickReport.location.selectedLabel = payload.filter.selectedLabel;
            data.filter.sickReport.location.selectedId = payload.filter.selectedId;
            data.dropdown.settings.user.location.selectedLabel = payload.filter.selectedLabel;
            data.dropdown.settings.user.location.selectedId = payload.filter.selectedId;
            data.dropdown.news.location.selectedLabel = payload.filter.selectedLabel;
            data.dropdown.news.location.selectedId = [payload.filter.selectedId];
        }

        return $set('data', data, oldState);
    },
    [UPDATE_ALL_LOCATION_DROPDOWNS]: (payload) => oldState => {
        return $set('data.calendarUser', payload.calendarUser, oldState);
    },
    [UPDATE_CURRENT_STOCK_INPUT]: (payload) => oldState => {
        return $set('data.currentStockInput', payload.input, oldState);
    },

    [UPDATE_HOLIDAY_API]: (payload) => oldState => {
        return $set('data.holidayApi.'+payload.holidayApi.prop, payload.holidayApi.value, oldState);
    },
    [UPDATE_LOCATION_SPECIAL_APPOINTMENT]: (payload) => oldState => {
        if (payload.locationSpecialAppointment.prop === 'reset') {
            return $set('data.locationSpecialAppointment', {}, oldState);
        }
        return $set('data.locationSpecialAppointment.'+payload.locationSpecialAppointment.prop, payload.locationSpecialAppointment.value, oldState);
    },
    [UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST]: (payload) => oldState => {
        return $set('data.locationSpecialAppointmentList', payload.list, oldState);
    },
    [DELETE_LOCATION_SPECIAL_APPOINTMENT]: (payload) => oldState => {
        let list = oldState.data.locationSpecialAppointmentList;
        list = list.filter(lsa => lsa.id !== payload.locationSpecialAppointment.id)
        return $set('data.locationSpecialAppointmentList', list, oldState);
    },
    [UPDATE_LOGIN_SEARCH_TERM]: payload => oldState => $set('data.loginSearchTerm', payload.term, oldState),
    [UPDATE_LOCATION_SPECIAL_APPOINTMENT_SEARCH_TERM]: payload => oldState => $set('data.locationSpecialAppointmentSearchTerm', payload.term, oldState),
    [UPDATE_LOCATION_SORTIMENT_HISTORY]: (payload) => oldState => {
        return $set('data.locationSortimentHistory', payload.locationSortimentHistory, oldState);
    },
    [UPDATE_LOCATION_SORTIMENT_HISTORY_LIST]: (payload) => oldState => {
        return $set('data.locationSortimentHistoryList', payload.list, oldState);
    },
    [UPDATE_COLORS]: (payload) => oldState => {
        return $set('data.colors', payload.list, oldState);
    },
    [UPDATE_COLOR_TO_EDIT]: (payload) => oldState => {
        return $set('data.colorToEdit', payload.color, oldState);
    },
    [UPDATE_COLOR_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.colorModalAction', payload.value, oldState);
    },
    [DELETE_COLOR]: (payload) => oldState => {
        let colors = [...oldState.data.colors];
        const newColorList = colors.filter(color => color.id !== payload.color.id)
        return $set('data.colors', newColorList, oldState);
    },
    [SAVE_COLOR]: (payload) => oldState => {
        const colors = [...oldState.data.colors, payload.color];
        return $set('data.colors', colors, oldState);
    },
    [UPDATE_COLOR]: (payload) => oldState => {
        const colors = oldState.data.colors.map(color => {
            return (color.id === payload.color.id) ? payload.color : color;
        });
        return $set('data.colors', colors, oldState);
    },
    [UPDATE_STATUSES]: (payload) => oldState => {
        return $set('data.statuses', payload.list, oldState);
    },
    [UPDATE_STATUS_TO_EDIT]: (payload) => oldState => {
        return $set('data.statusToEdit', payload.status, oldState);
    },
    [UPDATE_STATUS_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.statusModalAction', payload.value, oldState);
    },
    [DELETE_STATUS]: (payload) => oldState => {
        let statuses = [...oldState.data.statuses];
        const newStatusList = statuses.filter(status => status.id !== payload.status.id)
        return $set('data.statuses', newStatusList, oldState);
    },
    [SAVE_STATUS]: (payload) => oldState => {
        const statuses = [...oldState.data.statuses, payload.status];
        return $set('data.statuses', statuses, oldState);
    },
    [UPDATE_STATUS]: (payload) => oldState => {
        const statuses = oldState.data.statuses.map(status => {
            return (status.id === payload.status.id) ? payload.status : status;
        });
        return $set('data.statuses', statuses, oldState);
    },
    [UPDATE_STOCK_SORTING]: ({ stockSorting }) => oldState => {

        // const s = stockSorting.replace('-', '');
        // const list = oldState.data.stockSorting.includes(`-${s}`)
        //     ? oldState.data.stockSorting.filter( so => so !== `-${s}`)
        //     : (oldState.data.stockSorting.includes(s) ?
        //     [...[...oldState.data.stockSorting].filter( so => so !== s), `-${s}`]  : [...oldState.data.stockSorting, s]);
        // return $set('data.stockSorting', list, oldState);

        const s = stockSorting.replace('-', '');
        const list = oldState.data.stockSorting.includes(`-${s}`) ? [] :  (oldState.data.stockSorting.includes(s) ? [`-${s}`] : [s]);
        return $set('data.stockSorting', list, oldState);

    },
    [SET_STOCK_SORTING]: ({ stockSorting }) => oldState => {
        return $set('data.stockSorting', stockSorting, oldState);
    },
    [UPDATE_YEAR_FILTER]: (payload) => oldState => {
        return $set('data.yearFilter', payload.yearFilter, oldState);
    },
    [UPDATE_CONTINGENTS]: (payload) => oldState => {
        return $set('data.contingents', payload.contingents, oldState);
    },
    [UPDATE_GLOBAL_SETTINGS]: ({globalSettings}) => oldState => {
        return $set('data.globalSettings', globalSettings, oldState);
    },

    [UPDATE_CLAIM_DROPDOWN_MANUFACTURERS]: (payload) => oldState => {
        return $set('data.claimDropdownManufacturers', payload.list, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_MANUFACTURER_TO_EDIT]: (payload) => oldState => {
        return $set('data.claimDropdownManufacturerToEdit', payload.claimDropdownManufacturer, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_MANUFACTURER_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.claimDropdownManufacturerModalAction', payload.value, oldState);
    },
    [DELETE_CLAIM_DROPDOWN_MANUFACTURER]: (payload) => oldState => {
        let claimDropdownManufacturers = [...oldState.data.claimDropdownManufacturers];
        const newClaimDropdownManufacturerList = claimDropdownManufacturers.filter(claimDropdownManufacturer => claimDropdownManufacturer.id !== payload.claimDropdownManufacturer.id)
        return $set('data.claimDropdownManufacturers', newClaimDropdownManufacturerList, oldState);
    },
    [SAVE_CLAIM_DROPDOWN_MANUFACTURER]: (payload) => oldState => {
        const claimDropdownManufacturers = [...oldState.data.claimDropdownManufacturers, payload.claimDropdownManufacturer];
        return $set('data.claimDropdownManufacturers', claimDropdownManufacturers, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_MANUFACTURER]: (payload) => oldState => {
        const claimDropdownManufacturers = oldState.data.claimDropdownManufacturers.map(claimDropdownManufacturer => {
            return (claimDropdownManufacturer.id === payload.claimDropdownManufacturer.id) ? payload.claimDropdownManufacturer : claimDropdownManufacturer;
        });
        return $set('data.claimDropdownManufacturers', claimDropdownManufacturers, oldState);
    },

    [UPDATE_CLAIM_DROPDOWN_REASONS]: (payload) => oldState => {
        return $set('data.claimDropdownReasons', payload.list, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_REASON_TO_EDIT]: (payload) => oldState => {
        return $set('data.claimDropdownReasonToEdit', payload.claimDropdownReason, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_REASON_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.claimDropdownReasonModalAction', payload.value, oldState);
    },
    [DELETE_CLAIM_DROPDOWN_REASON]: (payload) => oldState => {
        let claimDropdownReasons = [...oldState.data.claimDropdownReasons];
        const newClaimDropdownReasonList = claimDropdownReasons.filter(claimDropdownReason => claimDropdownReason.id !== payload.claimDropdownReason.id)
        return $set('data.claimDropdownReasons', newClaimDropdownReasonList, oldState);
    },
    [SAVE_CLAIM_DROPDOWN_REASON]: (payload) => oldState => {
        const claimDropdownReasons = [...oldState.data.claimDropdownReasons, payload.claimDropdownReason];
        return $set('data.claimDropdownReasons', claimDropdownReasons, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_REASON]: (payload) => oldState => {
        const claimDropdownReasons = oldState.data.claimDropdownReasons.map(claimDropdownReason => {
            return (claimDropdownReason.id === payload.claimDropdownReason.id) ? payload.claimDropdownReason : claimDropdownReason;
        });
        return $set('data.claimDropdownReasons', claimDropdownReasons, oldState);
    },


    [UPDATE_CLAIM_DROPDOWN_QUALITIES]: (payload) => oldState => {
        return $set('data.claimDropdownQualities', payload.list, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_QUALITY_TO_EDIT]: (payload) => oldState => {
        return $set('data.claimDropdownQualityToEdit', payload.claimDropdownQuality, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_QUALITY_MODAL_ACTION]: (payload) => oldState => {
        return $set('data.claimDropdownQualityModalAction', payload.value, oldState);
    },
    [DELETE_CLAIM_DROPDOWN_QUALITY]: (payload) => oldState => {
        let claimDropdownQualities = [...oldState.data.claimDropdownQualities];
        const newClaimDropdownQualityList = claimDropdownQualities.filter(claimDropdownQuality => claimDropdownQuality.id !== payload.claimDropdownQuality.id)
        return $set('data.claimDropdownQualities', newClaimDropdownQualityList, oldState);
    },
    [SAVE_CLAIM_DROPDOWN_QUALITY]: (payload) => oldState => {
        const claimDropdownQualities = [...oldState.data.claimDropdownQualities, payload.claimDropdownQuality];
        return $set('data.claimDropdownQualities', claimDropdownQualities, oldState);
    },
    [UPDATE_CLAIM_DROPDOWN_QUALITY]: (payload) => oldState => {
        const claimDropdownQualities = oldState.data.claimDropdownQualities.map(claimDropdownQuality => {
            return (claimDropdownQuality.id === payload.claimDropdownQuality.id) ? payload.claimDropdownQuality : claimDropdownQuality;
        });
        return $set('data.claimDropdownQualities', claimDropdownQualities, oldState);
    },
    [UPDATE_LIKES]: (payload) => oldState => {
        return $set('data.likes', payload.list, oldState)
    },
    [UPDATE_COUNT_LIKES]: (payload) => oldState => {
        return $set('data.countLikes', payload.list, oldState)
    },
    [UPDATE_COMMENT_TO_EDIT]: (payload) => oldState => {
        return $set('data.commentToEdit', payload.comment, oldState)
    },
    [UPDATE_COMMENT_LIST]: (payload) => oldState => {
        return $set('data.comments', payload.list, oldState)
    },
    [UPDATE_USER_POPUP]: (payload) => oldState => {
        return $set('data.userPopup', payload.list, oldState)
    },


});

const can = (state, permission) => state.data.currentUser.permissions.includes(permission);


const selectors = {
    can,
    data: $get('data'),
    filter: $get('data.filter'),
    currentUser: $get('data.currentUser'),
    calendar: $get('data.calendar'),
    dropdown: $get('data.dropdown'),
    holidayApi: $get('data.holidayApi'),
    loginSearchTerm: $get('data.loginSearchTerm'),
    locationSpecialAppointmentSearchTerm: $get('data.locationSpecialAppointmentSearchTerm'),
    locationSortimentHistory: $get('data.locationSortimentHistory')
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
