import { put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import { actions, actionTypes, selectors } from '../Redux/Claims';
import { actions as ui } from '../Redux/index';
import ApiHelper from '../Utility/ApiHelper';
import { host } from './host';
import { delay } from 'redux-saga';

const messageDelay = 2000;

function* loadClaimList() {
    yield takeLatest(actionTypes.LOAD_LIST, function*(action) {
        yield delay(300)
        const { payload } = action;
        yield put(actions.updateLoadAnimation({value:true}))
        try {
            let fromNumber = payload.fromNumber;
            if (payload.forceReload) {
                fromNumber = 0;
            } else if (typeof fromNumber !== 'number') {
                switch (payload.filter.completed) {
                    case true:
                        fromNumber = yield select((state) =>
                            selectors.completedClaimsCount(state, payload.filter.location)
                        );
                        break;
                    case false:
                        fromNumber = yield select((state) =>
                            selectors.openClaimsCount(state, payload.filter.location)
                        );
                        break;
                    default:
                        fromNumber = yield select((state) =>
                            selectors.allClaimsCount(state, payload.filter.location)
                        );
                }
                // Number of tasks we have loaded minus 1. Subtracting 2 to account for tasks completed somewhere else.
                fromNumber = Math.max(0, fromNumber - 3);
            }
            const queryURL = new URL(`/claims/list/${fromNumber}`, host);
            Object.getOwnPropertyNames(payload.filter).forEach((name) => {
                if (payload.filter[name] !== null) {
                    queryURL.searchParams.append(name, payload.filter[name]);
                }

            });
            const sorting = yield select(selectors.sorting);
            queryURL.searchParams.append('sort', sorting || '');
            const responseJSON = yield fetch(queryURL.toString(), yield ApiHelper.buildRequest())
                .then((response) => ApiHelper.handleFetchResponse(response))
                .then((response) => response.json());
            yield put(
                actions.loadListSuccess({
                    claims:
                        responseJSON.claims && responseJSON.claims.length
                            ? responseJSON.claims
                            : [],
                    filter: payload.filter,
                    forceReload: payload.forceReload,
                    fromNumber: fromNumber,
                })
            );
            yield put(actions.updateLoadAnimation({value:false}))
            //yield console.log(responseJSON)
            yield put(ui.Ui.updateCountSeen({page: 'Claims', value: responseJSON.new}))
        } catch (error) {
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Ein Fehler ist aufgetreten',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            console.error('ClaimsSaga: loadClaimList:', error);
            yield put(actions.loadListFailed(error));
        }
    });
}
function* loadClaimListOpenSaga() {
    yield takeLatest([actionTypes.LOAD_OPEN_LIST], function*(action) {
        try {
            yield delay(300)
            const { payload } = action;

            let fromNumber = 0;
            const claims = yield select(selectors.claims);
            fromNumber = claims.openList.length
            if (payload.forceReload) {
                fromNumber = 0;
                yield put(actions.updateLoadAnimation({value:true}))
            }

                const queryURL = new URL(`/claims/list/${fromNumber}`, host);
                Object.getOwnPropertyNames(payload.filter).forEach((name) => {
                    if (payload.filter[name] !== null) {
                        queryURL.searchParams.append(name, payload.filter[name]);
                    }

                });
                const sorting = yield select(selectors.sorting);
                queryURL.searchParams.append('sort', sorting || '');
                const result = yield fetch(queryURL.toString(), yield ApiHelper.buildRequest());
                yield put(ui.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    const list = yield result.json();
                    yield put(ui.Ui.updateCountSeen({page: 'Claims', value: list.new}))
                    const openClaimList = fromNumber !== 0 ? [...claims.openList, ...list.claims] : list.claims
                    yield put(actions.updateOpenList(openClaimList))
                    yield put(actions.updateCountOpenList(list.count))
                    yield put(actions.updateLoadAnimation({value:false}))
                }
        } catch (e) {
            console.log(e);
        }
    });
}
function* loadClaimListClosedSaga() {
    yield takeLatest([actionTypes.LOAD_CLOSED_LIST], function*(action) {
        try {
            yield delay(300)
            const { payload } = action;

            let fromNumber = 0;
            const claims = yield select(selectors.claims);
            fromNumber = claims.closedList.length
            if (payload.forceReload) {
                fromNumber = 0;
                yield put(actions.updateLoadAnimation({value:true}))
            }

                const queryURL = new URL(`/claims/list/${fromNumber}`, host);
                Object.getOwnPropertyNames(payload.filter).forEach((name) => {
                    if (payload.filter[name] !== null) {
                        queryURL.searchParams.append(name, payload.filter[name]);
                    }

                });
                const sorting = yield select(selectors.sorting);
                queryURL.searchParams.append('sort', sorting || '');
                const result = yield fetch(queryURL.toString(), yield ApiHelper.buildRequest());
                yield put(ui.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    const list = yield result.json();

                    yield put(ui.Ui.updateCountSeen({page: 'Claims', value: list.new}))
                    const closedClaimList = fromNumber !== 0 ? [...claims.closedList, ...list.claims] : list.claims
                    yield put(actions.updateClosedList(closedClaimList))
                    yield put(actions.updateCountClosedList(list.count))
                    yield put(actions.updateLoadAnimation({value:false}))
                }
        } catch (e) {
            console.log(e);
        }
    });
}


function createClaimPostData(claim) {
    const data = { claim: { ...claim } };
    delete data.claim.id;
    delete data.claim.number;
    delete data.claim.checklist;
    delete data.claim.createdAt;
    return JSON.stringify(data);
}

function createClaimChecklistPostData(checklist) {
    const data = { ...checklist };
    delete data.id;
    return JSON.stringify(data);
}

function* loadClaim() {
    yield takeEvery(actionTypes.LOAD_CLAIM, function*(action) {
        try {
            const responseJSON = yield fetch(
                host + '/claims/' + action.payload.claimId,
                yield ApiHelper.buildRequest()
            )
                .then((response) => ApiHelper.handleFetchResponse(response))
                .then((response) => response.json());
            yield put(actions.loadClaimSuccess(responseJSON));
        } catch (error) {
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Ein Fehler ist aufgetreten',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            console.error('ClaimsSaga: loadClaim:', error);
            yield put(actions.loadClaimFailed(error));
        }
    });
}

function* createClaim() {
    yield takeEvery(actionTypes.CREATE, function*(action) {
        try {
            const postJSON = createClaimPostData(action.payload.claim);
            const responseJSON = yield fetch(
                host + '/claims',
                yield ApiHelper.buildRequest('POST', postJSON)
            )
                .then((response) => ApiHelper.handleFetchResponse(response))
                .then((response) => response.json());
            yield put(actions.createClaimSuccess(responseJSON));
        } catch (error) {
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Ein Fehler ist aufgetreten',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            console.error('ClaimsSaga: createClaim:', error);
            yield put(actions.createClaimFailed(error));
        }
    });
}

function* updateClaim() {
    yield takeEvery(actionTypes.UPDATE, function*(action) {
        try {
            const id = action.payload.claim.id;
            delete action.payload.claim.createdAtFormated;
            const postJSON = createClaimPostData(action.payload.claim);
            const responseJSON = yield fetch(
                host + '/claims/' + id,
                yield ApiHelper.buildRequest('PUT', postJSON)
            )
                .then((response) => ApiHelper.handleFetchResponse(response))
                .then((response) => response.json());
            yield put(actions.updateClaimSuccess(responseJSON));
            yield put(
                ui.Ui.updateAlert({
                    view: 'visible',
                    type: 'Info',
                    message: 'Änderungen gespeichert',
                })
            );
            yield delay(2000);
            yield put(ui.Ui.updateAlert({ view: 'hidden', type: 'Info', message: '' }));
        } catch (error) {
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Ein Fehler ist aufgetreten',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            yield put(actions.updateClaimFailed(error));
        }
    });
}

function* updateClaimChecklist() {
    yield takeEvery(actionTypes.UPDATE_CHECKLIST, function*(action) {
        try {
            const id = action.payload.checklist.id;
            const postJSON = createClaimChecklistPostData(action.payload.checklist);
            const responseJSON = yield fetch(
                host + '/checklist/' + id,
                yield ApiHelper.buildRequest('PUT', postJSON)
            )
                .then((response) => ApiHelper.handleFetchResponse(response))
                .then((response) => response.json());
            yield put(actions.updateClaimChecklistSuccess(responseJSON));
            yield put(
                ui.Ui.updateAlert({
                    view: 'visible',
                    type: 'Info',
                    message: 'Änderungen gespeichert',
                })
            );
            yield delay(2000);
            yield put(ui.Ui.updateAlert({ view: 'hidden', type: 'Info', message: '' }));
        } catch (error) {
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Ein Fehler ist aufgetreten',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            console.error('ClaimsSaga: updateClaimChecklist:', error);
            yield put(actions.updateClaimChecklistFailed(error));
        }
    });
}

export default [loadClaimList, loadClaim, createClaim, updateClaim, updateClaimChecklist, loadClaimListOpenSaga, loadClaimListClosedSaga];
