import { put, select, takeLatest } from 'redux-saga/effects';
import { actions, actionTypes, selectors } from 'Redux/index';
import getHeaders from 'Sagas/headers';
import { host } from 'Sagas/host';

function* loadPermissionsSaga() {
    yield takeLatest(actionTypes.Settings.LOAD_PERMISSIONS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const result = yield fetch(`${host}/permission`, { method: "GET", headers: getHeaders(token)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const { permissions, permissionCategories } = yield result.json();
                yield put(actions.Settings.updatePermissions(permissions))
                yield put(actions.Settings.updatePermissionCategories(permissionCategories))
            }
        } catch (e) {
            console.log(e);
        }
    });
}
export default [loadPermissionsSaga];
