import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';
import * as moment from 'moment';
import {delay} from 'redux-saga'

function* createLocationSpecialAppointmentSaga() {
    yield takeLatest(actionTypes.Data.ADD_LOCATION_SPECIAL_APPOINTMENT, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);

            const lsa = stateData.locationSpecialAppointment;
            const locationSpecialAppointment = {
                "locationSpecialAppointment": {
                    info: lsa.info,
                    date: moment(lsa.date).format('DD.MM.YYYY'),
                    endDate: lsa.endDate ? moment(lsa.endDate).format('DD.MM.YYYY') : moment(lsa.date).format('DD.MM.YYYY'),
                    locations: stateData.dropdown.news.location.selectedId || []
                 },
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/locationspecialappointment', {
                method: "POST",
                headers,
                body: JSON.stringify(locationSpecialAppointment),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateModal({
                        path: 'settings.locationSpecialAppointment',
                        prop: 'view',
                        val: false,
                    }));
                yield put(actions.Ui.updateLocationSpecialAppointmentListPage(0));
                yield put(actions.Data.updateLocationSpecialAppointment({prop: 'reset', value: ''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedLabel', val:''}));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* locationSpecialAppointmentsSaga() {
    yield takeLatest([
        actionTypes.Data.LOAD_LOCATION_SPECIAL_APPOINTMENT_LIST,
        actionTypes.Ui.UPDATE_LOCATION_SPECIAL_APPOINTMENT_LIST_PAGE,
        actionTypes.Ui.UPDATE_LOCATION_SPECIAL_APPOINTMENT_VIEW_OLD,
        actionTypes.Data.UPDATE_LOCATION_SPECIAL_APPOINTMENT_SEARCH_TERM,
    ], function* (action) {
        try {
            yield delay(200)
            const token = yield select(selectors.Ui.token)
            const page = yield select(selectors.Ui.locationSpecialAppointmentListPage)
            const old = yield select(selectors.Ui.locationSpecialAppointmentViewOld)
            const term = yield select(selectors.Data.locationSpecialAppointmentSearchTerm)
            const sorting = yield select(selectors.Settings.specialAppointmentSort)
            const headers = getHeaders(token);

            const result = yield fetch(`${host}/locationspecialappointment/list/${page}?oldList=${old}&term=${term}&sort=${sorting}`, {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const locationSpecialAppointments = yield result.json();
                yield put(actions.Data.updateLocationSpecialAppointmentList(locationSpecialAppointments['list']));
                yield put(actions.Ui.updateLocationSpecialAppointmentListCount(locationSpecialAppointments['count']));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateLocationSpecialAppointmenSaga() {
    yield takeLatest(actionTypes.Data.SAVE_LOCATION_SPECIAL_APPOINTMENT, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const lsa = stateData.locationSpecialAppointment;
            const locationSpecialAppointment = {
                "locationSpecialAppointment": {
                    id: lsa.id,
                    info: lsa.info,
                    date: moment(lsa.date).format('DD.MM.YYYY'),
                    endDate: lsa.endDate ? moment(lsa.endDate).format('DD.MM.YYYY') : moment(lsa.date).format('DD.MM.YYYY'),
                    locations: stateData.dropdown.news.location.selectedId || []
                 },
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/locationspecialappointment', {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(locationSpecialAppointment),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateModal({
                    path: 'settings.locationSpecialAppointment',
                    prop: 'view',
                    val: false,
                }));
            yield put(actions.Ui.updateLocationSpecialAppointmentListPage(0));
            yield put(actions.Data.updateLocationSpecialAppointment({prop: 'reset', value: ''}));
            yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedId', val:''}));
            yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedLabel', val:''}));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteLocationSpecialAppointmentSaga() {
    yield takeLatest(actionTypes.Data.DELETE_LOCATION_SPECIAL_APPOINTMENT, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const locationSpecialAppointment = {
                "locationSpecialAppointment": {
                    id: action.payload.locationSpecialAppointment.id,
                 },
            };

            const result = yield fetch(host + '/locationspecialappointment', {
                method: "DELETE",
                headers,
                body: JSON.stringify(locationSpecialAppointment),
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* checkExistingLocationSpecialAppointmentSaga() {
    yield takeLatest(actionTypes.Ui.CHECK_EXISTING_LOCATION_SPECIAL_APPOINTMENT, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const stateData = yield select(selectors.Data.data);
            const headers = getHeaders(token);

            const lsa = stateData.locationSpecialAppointment;
            const locationSpecialAppointment = {
                "locationSpecialAppointment": {
                    id: lsa.id,
                    info: lsa.info,
                    date: moment(lsa.date).format('DD.MM.YYYY'),
                    endDate: lsa.endDate ? moment(lsa.endDate).format('DD.MM.YYYY') : moment(lsa.date).format('DD.MM.YYYY'),
                    locations: stateData.dropdown.news.location.selectedId || []
                 },
            };

            const result = yield fetch(host + '/locationspecialappointment/check', {
                method: "POST",
                headers,
                body: JSON.stringify(locationSpecialAppointment),
            });

            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const existingAppointment = yield result.json();
                if (!existingAppointment) {
                    if (action.payload.value === 'new') {
                        yield put(actions.Data.addLocationSpecialAppointment({}))
                    } else {
                        yield put(actions.Data.saveLocationSpecialAppointment({}))
                    }
                    return
                }
                const acType = action.payload.value === 'new' ? 'addLocationSpecialAppointment' : 'saveLocationSpecialAppointment'
                const locations = existingAppointment.locations.length ? existingAppointment.locations : 'Alle'
                const label = lsa.id ? 'Trotzdem speichern' : 'Trotzdem erstellen';
                yield put(actions.Ui.updateConfirmModal({prop: 'visible', value: true}))
                yield put(actions.Ui.updateConfirmModal({prop: 'title', value: 'Warnung Terminüberschneidung'}))
                yield put(actions.Ui.updateConfirmModal({prop: 'label', value: label}))
                yield put(actions.Ui.updateConfirmModal({prop: 'icon', value: 'warning'}))
                yield put(actions.Ui.updateConfirmModal({prop: 'text', value: `Es besteht Überschneidung mit dem Termin "${existingAppointment.info}" für Standort "${locations}"`}))
                yield put(actions.Ui.updateConfirmModal({prop: 'payload', value: {actionType: acType, param: {}}}))
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [
    createLocationSpecialAppointmentSaga,
    locationSpecialAppointmentsSaga,
    updateLocationSpecialAppointmenSaga,
    deleteLocationSpecialAppointmentSaga,
    checkExistingLocationSpecialAppointmentSaga
];
