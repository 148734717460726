export function getFileIconClass(type) {
    // noinspection OverlyComplexBooleanExpressionJS
    if (type === 'jpeg' || type === 'jpg' || type === 'gif' || type === 'png') {
        return "fa fa-file-image-o";
    }
    if (type === 'pdf') {
        return "fa fa-file-pdf-o";
    }
    if (type === 'doc' || type === 'docx' || type === 'dot') {
        return "fa fa-file-word-o";
    }
    if (type === 'xls' || type === 'xla' || type === 'xlsx') {
        return "fa fa-file-excel-o";
    }
    // noinspection OverlyComplexBooleanExpressionJS
    if (type === 'ppt' || type === 'pptx' || type === 'ppz' || type === 'pps' || type === 'pot') {
        return "fa fa-file-powerpoint-o";
    }
    if (type === 'zip') {
        return "fa fa-file-archive-o";
    }
    if (type === 'txt') {
        return "fa fa-file-text-o";
    }
    return "fa fa-file-o";
}

export function bytesToString(bytes, decimals = 2) {
    const base = 1024;
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return '0 Byte';
    }
    let i = Math.floor(Math.log(bytes) / Math.log(base));
    return (bytes / Math.pow(base, i)).toFixed(decimals) + ' ' + sizes[i];
}
