import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon/Icon';

const iconType = {
    archive: 'file-archive',
    audio: 'file-audio',
    code: 'file-code',
    excel: 'file-excel',
    file: 'file',
    image: 'file-image',
    pdf: 'file-pdf',
    powerpoint: 'file-powerpoint',
    text: 'file-text',
    video: 'file-video',
    word: 'file-word',
};

const extensions = {
    aac: iconType.audio,
    flac: iconType.audio,
    m4a: iconType.audio,
    mp3: iconType.audio,
    ogg: iconType.audio,
    wav: iconType.audio,

    '7z': iconType.archive,
    gz: iconType.archive,
    rar: iconType.archive,
    zip: iconType.archive,

    css: iconType.code,
    html: iconType.code,
    js: iconType.code,
    json: iconType.code,
    yml: iconType.code,
    yaml: iconType.code,

    xla: iconType.excel,
    xls: iconType.excel,
    xlsx: iconType.excel,

    gif: iconType.image,
    jpeg: iconType.image,
    jpg: iconType.image,
    png: iconType.image,
    svg: iconType.image,
    webp: iconType.image,

    pdf: iconType.pdf,

    key: iconType.powerpoint,
    pot: iconType.powerpoint,
    pps: iconType.powerpoint,
    ppt: iconType.powerpoint,
    pptx: iconType.powerpoint,

    csv: iconType.text,
    md: iconType.text,
    txt: iconType.text,

    avi: iconType.video,
    flv: iconType.video,
    mkv: iconType.video,
    mov: iconType.video,
    mp4: iconType.video,
    mpg: iconType.video,
    mpeg: iconType.video,
    mv4: iconType.video,
    webm: iconType.video,
    wmv: iconType.video,

    doc: iconType.word,
    docx: iconType.word,
    dot: iconType.word,
    pages: iconType.word,
};

const mediaTypes = {
    audio: iconType.audio,
    image: iconType.image,
    text: iconType.text,
    video: iconType.video,
};

const fullMediaTypes = {
    'application/gzip': iconType.archive,
    'application/x-7z-compressed': iconType.archive,
    'application/x-bzip': iconType.archive,
    'application/x-bzip2': iconType.archive,
    'application/x-rar-compressed': iconType.archive,
    'application/zip': iconType.archive,

    'application/graphql': iconType.code,
    'application/javascript': iconType.code,
    'application/json': iconType.code,
    'application/php': iconType.code,
    'application/sql': iconType.code,
    'application/xhtml+xml': iconType.code,
    'application/xml': iconType.code,
    'text/css': iconType.code,
    'text/html': iconType.code,
    'text/javascript': iconType.code,
    'text/xml': iconType.code,

    'application/msexcel': iconType.excel,
    'application/vnd.ms-excel': iconType.excel,
    'application/vnd.oasis.opendocument.presentation': iconType.excel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': iconType.excel,

    'application/pdf': iconType.pdf,

    'application/mspowerpoint': iconType.powerpoint,
    'application/vnd.ms-powerpoint': iconType.powerpoint,
    'application/vnd.oasis.opendocument.spreadsheet': iconType.powerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': iconType.powerpoint,

    'application/msword': iconType.word,
    'application/rtf': iconType.word,
    'application/vnd.oasis.opendocument.text': iconType.word,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': iconType.word,
};

export function getFileIcon(type) {
    const input = type.trim().toLowerCase();
    if (extensions[input] !== undefined) {
        return extensions[input];
    }
    if (fullMediaTypes[input] !== undefined) {
        return fullMediaTypes[input];
    }
    const mediaType = input.split('/');
    if (mediaType.length === 2) {
        if (mediaTypes[mediaType[0]] !== undefined) {
            return mediaTypes[mediaType[0]];
        }
        if (extensions[mediaType[1]] !== undefined) {
            return extensions[mediaType[1]];
        }
    }
    return iconType.file;
}

class FileIcon extends React.PureComponent {
    static propTypes = {
        fileType: PropTypes.string,
        solid: PropTypes.bool,
        onClick: PropTypes.func,
    };

    render() {
        const { fileType, ...childProps } = this.props;
        return <Icon {...childProps} type={getFileIcon(fileType)} />;
    }
}

export default FileIcon;
