import Absence from './Absence';
import Category from './Category';
import Claims from './Claims';
import Data from './Data';
import News from './News';
import Task from './Task';
import Ui from './Ui';
import Training from './Training';
import SickReport from './SickReport';
import Advertising from './Advertising';
import Settings from './Settings';

export const actions = {
    Absence: Absence.actions,
    Category: Category.actions,
    Claims: Claims.actions,
    Data: Data.actions,
    News: News.actions,
    Task: Task.actions,
    Ui: Ui.actions,
    Training: Training.actions,
    SickReport: SickReport.actions,
    Advertising: Advertising.actions,
    Settings: Settings.actions,
};

export const actionTypes = {
    Absence: Absence.actionTypes,
    Category: Category.actionTypes,
    Claims: Claims.actionTypes,
    Data: Data.actionTypes,
    News: News.actionTypes,
    Task: Task.actionTypes,
    Ui: Ui.actionTypes,
    Training: Training.actionTypes,
    SickReport: SickReport.actionTypes,
    Advertising: Advertising.actionTypes,
    Settings: Settings.actionTypes
};

export const initialState = {
    absence: Absence.initialState,
    category: Category.initialState,
    claims: Claims.initialState,
    data: Data.initialState,
    news: News.initialState,
    task: Task.initialState,
    ui: Ui.initialState,
    training: Training.initialState,
    sickReport: SickReport.initialState,
    advertising: Advertising.initialState,
    settings: Settings.initialState
};

export const reducerArray = [
    Absence.reducer,
    Category.reducer,
    Claims.reducer,
    Data.reducer,
    News.reducer,
    Task.reducer,
    Ui.reducer,
    Training.reducer,
    SickReport.reducer,
    Advertising.reducer,
    Settings.reducer
];

export const selectors = {
    Absence: Absence.selectors,
    Category: Category.selectors,
    Claims: Claims.selectors,
    Data: Data.selectors,
    News: News.selectors,
    Task: Task.selectors,
    Ui: Ui.selectors,
    Training: Training.selectors,
    SickReport: SickReport.selectors,
    Advertising: Advertising.selectors,
    Settings: Settings.selectors
};
