
export const regions = [
    {
        "id": 1,
        "label": "Baden Württemberg",
    },
    {
        "id": 2,
        "label": "Bayern",
    },
    {
        "id": 3,
        "label": "Berlin",
    },
    {
        "id": 4,
        "label": "Brandenburg",
    },
    {
        "id": 5,
        "label": "Bremen",
    },
    {
        "id": 6,
        "label": "Hamburg",
    },
    {
        "id": 7,
        "label": "Hessen",
    },
    {
        "id": 8,
        "label": "Mecklenburg-Vorpommern",
    },
    {
        "id": 9,
        "label": "Niedersachsen",
    },
    {
        "id": 10,
        "label": "Nordrhein Westfalen",
    },
    {
        "id": 11,
        "label": "Rheinland-Pfalz",
    },
    {
        "id": 12,
        "label": "Saarland",
    },
    {
        "id": 13,
        "label": "Sachsen",
    },
    {
        "id": 14,
        "label": "Sachsen-Anhalt",
    },
    {
        "id": 15,
        "label": "Schleswig-Holstein",
    },
    {
        "id": 16,
        "label": "Thüringen",
    },
]
    


