import * as PropTypes from 'prop-types';
import React from 'react';

export default class FilterSelect extends React.Component {
    static propTypes = {
        multiple: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.any),
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        value: PropTypes.any,
        labelFunc: PropTypes.func,
        valueFunc: PropTypes.func,
    };

    state = {
        isOpen: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.readOnly &&
            this.props.readOnly !== prevProps.readOnly &&
            this.state.isOpen
        ) {
            this.setState({ isOpen: false });
        }
    }

    getOptionLabel = (option) => {
        if (typeof option === 'object' && typeof this.props.labelFunc !== 'function') {
            console.error('Select: prop `labelFunc` is required for options of type `object`.');
        }
        return String(this.props.labelFunc ? this.props.labelFunc(option) : option);
    };

    getOptionValue = (option) => {
        return this.props.valueFunc ? this.props.valueFunc(option) : option;
    };

    getSelectedLabel = () => {
        const label = this.props.options
            .filter(this.isOptionSelected)
            .map(this.getOptionLabel)
            .join(', ');
        return label ? label : this.props.placeholder ? this.props.placeholder : 'Bitte wählen';
    };

    handleOnChange = (value) => {
        const event = { target: { name: this.props.name, value: value } };
        this.props.onChange && this.props.onChange(event);
        this.props.onBlur && this.props.onBlur(event);
    };

    handleOptionClick = (option) => {
        if (this.props.readOnly) {
            return;
        }
        const optionValue = this.getOptionValue(option);
        if (this.props.multiple) {
            const newValue = Array.isArray(this.props.value)
                ? [...this.props.value]
                : [this.props.value];
            const idx = newValue.indexOf(optionValue);
            if (idx === -1) {
                newValue.push(optionValue);
            } else {
                newValue.splice(idx, 1);
            }
            this.handleOnChange(newValue);
        } else {
            this.handleOnChange(this.isOptionSelected(option) ? '' : optionValue);
        }
    };

    isOptionSelected = (option) => {
        const optionValue = this.getOptionValue(option);
        if (this.props.multiple && Array.isArray(this.props.value)) {
            return this.props.value.some((value) => value === optionValue);
        }
        return this.props.value === optionValue;
    };

    toggleSelect = () => this.setState({ isOpen: !(this.props.readOnly || this.state.isOpen) });

    render() {
        return (

            <div className="dropdownFilter">
                <ul>
                    <li
                        className={this.state.isOpen ? 'active' : 'inactive'}
                        onClick={this.toggleSelect}
                    >
                        <a href="/#-" onClick={(ev) => ev.preventDefault()}>
                            {this.getSelectedLabel()}
                        </a>
                        {this.props.options && !!this.props.options.length && (
                            <ul>
                                {this.props.options.map((option, idx) => (
                                    <li key={idx}>
                                        <a
                                            href="/#-"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                this.handleOptionClick(option);
                                            }}
                                        >
                                            {this.getOptionLabel(option)}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                </ul>
            </div>

        );
    }
}
