import de from 'date-fns/locale/de';
import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import './dateInput.scss'

registerLocale('de', de);
setDefaultLocale('de');

class DateInput extends React.Component {
    render() {
        return (
            <DatePicker
                locale="de"
                dateFormat="dd.MM.yyyy"
                placeholderText="tt.mm.jjjj"
                {...this.props}
            />
        );
    }
}

export default DateInput;
