import {$get, $set} from 'plow-js';
import {createAction} from 'redux-actions';
import {handleActions} from '../Utility/HandleActions';

const CREATE = 'Category/Create';
const UPDATE = 'Category/Update';
const DELETE = 'Category/Delete';
const LOAD_LIST = 'Category/LoadList';
const UPDATE_LIST = 'Category/UpdateList';
const RESET_STATE = 'Category/ResetState';
const UPDATE_CURRENT_CATEGORY = 'Category/UpdateCurrentCategory';
const UPDATE_CURRENT_CATEGORY_FOLDER = 'Category/UpdateCurrentCategoryFolder';
const ADD_CATEGORY_FOLDER = 'Category/AddCategoryFolder';
const ADD_CATEGORY_FILE = 'Category/AddCategoryFile';
const UPDATE_CATEGORY_SUBFOLDER_NAME = 'Category/UpdateCategorySubfolderName';
const UPDATE_CATEGORY_FILE = 'Category/UpdateCategoryFile';
const UPDATE_CATEGORY_FILE_NAME = 'Category/UpdateCategoryFileName';
const UPDATE_SUBFOLDER_DIALOG = 'Category/UpdateSubfolderDialog';
const DELETE_CATEGORY_FILE_OR_FOLDER = 'Category/DeleteCategoryFileOrFolder';
const UPDATE_OPENED_FOLDER = 'Category/UpdateOpenedFolder';
const UPDATE_FOLDER_TO_RENAME = 'Category/UpdateFolderToRename';
const UPDATE_FILE_TO_MOVE = 'Category/UpdateFileToMove';
const MOVE_FILE = 'Category/MoveFile';



const actionTypes = {
    CREATE,
    UPDATE,
    DELETE,
    LOAD_LIST,
    UPDATE_LIST,
    RESET_STATE,
    UPDATE_CURRENT_CATEGORY,
    UPDATE_CURRENT_CATEGORY_FOLDER,
    ADD_CATEGORY_FOLDER,
    ADD_CATEGORY_FILE,
    UPDATE_CATEGORY_SUBFOLDER_NAME,
    UPDATE_CATEGORY_FILE,
    UPDATE_CATEGORY_FILE_NAME,
    UPDATE_SUBFOLDER_DIALOG,
    DELETE_CATEGORY_FILE_OR_FOLDER,
    UPDATE_OPENED_FOLDER,
    UPDATE_FOLDER_TO_RENAME,
    UPDATE_FILE_TO_MOVE,
    MOVE_FILE

};

const loadList = createAction(LOAD_LIST, (category) => ({category}));
const updateList = createAction(UPDATE_LIST, (categoryList) => ({categoryList}));
const createCategory = createAction(CREATE, (category) => ({category}));
const updateCategory = createAction(UPDATE, (category) => ({category}));
const deleteCategory = createAction(DELETE, (category) => ({category}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const updateCurrentCategory = createAction(UPDATE_CURRENT_CATEGORY, (category) => ({ category }));
const updateCurrentCategoryFolder = createAction(UPDATE_CURRENT_CATEGORY_FOLDER, (folder) => ({ folder }));
const addCategoryFolder = createAction(ADD_CATEGORY_FOLDER, (folder) => ({ folder }));
const addCategoryFile = createAction(ADD_CATEGORY_FILE, (file) => ({ file }));
const updateCategorySubfolderName = createAction(UPDATE_CATEGORY_SUBFOLDER_NAME, (value) => ({ value }));
const updateCategoryFile = createAction(UPDATE_CATEGORY_FILE, (file) => ({ file }));
const updateCategoryFileName = createAction(UPDATE_CATEGORY_FILE_NAME, (value) => ({ value }));
const updateSubFolderDialog = createAction(UPDATE_SUBFOLDER_DIALOG, (value) => ({ value }));
const deleteCategoryFileOrFolder = createAction(DELETE_CATEGORY_FILE_OR_FOLDER, (item) => ({ item }));
const updateOpenedFolder = createAction(UPDATE_OPENED_FOLDER, (folder) => ({ folder }));
const updateFolderToRename = createAction(UPDATE_FOLDER_TO_RENAME, (folder) => ({ folder }));
const updateFileToMove = createAction(UPDATE_FILE_TO_MOVE, (file) => ({ file }));
const moveFile = createAction(MOVE_FILE, (file) => ({ file }));

const actions = {
    loadList,
    updateList,
    createCategory,
    updateCategory,
    deleteCategory,
    resetState,
    updateCurrentCategory,
    updateCurrentCategoryFolder,
    addCategoryFolder,
    addCategoryFile,
    updateCategorySubfolderName,
    updateCategoryFile,
    updateCategoryFileName,
    updateSubFolderDialog,
    deleteCategoryFileOrFolder,
    updateOpenedFolder,
    updateFolderToRename,
    updateFileToMove,
    moveFile

};

const initialState = {
    list: [],
    currentCategory: null,
    currentCategoryFolder: null,
    categorySubfolderName: '',
    categoryFile: '',
    categoryFileName: '',
    subFolderDialog: false,
    openedFolder: {
        parent: '',
        folder: ''
    },
    folderToRename: '',
    fileToMove: '',
};

const reducer = handleActions({
    [UPDATE_LIST]: (payload) => oldState => {
        let newList = payload.categoryList || [];
        return $set('category.list', newList, oldState);
    },
    [CREATE]: (payload) => oldState => {
        let list = [...oldState.category.list];
        list.unshift(payload.category);
        return $set('category.list', list, oldState);
    },
    [UPDATE]: (payload) => oldState => {
        let list = [...oldState.category.list].map((category) => {
            if (payload.category.id === category.id) {
                return {...payload.category};
            }
            return category;
        });
        return $set('category.list', list, oldState);
    },
    [DELETE]: (payload) => oldState => {
        let list = [...oldState.category.list];
        list = list.filter(category => category.id !== payload.category.id)
        return $set('category.list', list, oldState);  
    },
    [RESET_STATE]: (payload) => oldState => {
        return $set('category', initialState, oldState);
    },
    [UPDATE_CURRENT_CATEGORY]: (payload) => oldState => {
        return $set('category.currentCategory', payload.category, oldState);
    },
    [UPDATE_CURRENT_CATEGORY_FOLDER]: (payload) => oldState => {
        return $set('category.currentCategoryFolder', payload.folder, oldState);
    },
    [UPDATE_CATEGORY_SUBFOLDER_NAME]: (payload) => oldState => {
        return $set('category.categorySubfolderName', payload.value, oldState);
    },
    [UPDATE_CATEGORY_FILE]: (payload) => oldState => {
        return $set('category.categoryFile', payload.file, oldState);
    },
    [UPDATE_CATEGORY_FILE_NAME]: (payload) => oldState => {
        return $set('category.categoryFileName', payload.value, oldState);
    },
    [UPDATE_SUBFOLDER_DIALOG]: (payload) => oldState => {
        return $set('category.subFolderDialog', payload.value, oldState);
    },
    [UPDATE_OPENED_FOLDER]: (payload) => oldState => {
        return $set('category.openedFolder.'+payload.folder.prop, payload.folder.value, oldState);
    },
    [UPDATE_FOLDER_TO_RENAME]: (payload) => oldState => {
        return $set('category.folderToRename', payload.folder, oldState);
    },
    [UPDATE_FILE_TO_MOVE]: (payload) => oldState => {
        return $set('category.fileToMove', payload.file, oldState);
    },
});

const selectors = {
    category: $get('category'),
    folderToRename: $get('category.folderToRename'),
    fileToMove: $get('category.fileToMove'),
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
