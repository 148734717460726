import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';




function* createLocationSortimentHistorySaga() {
    yield takeLatest(actionTypes.Data.ADD_LOCATION_SORTIMENT_HISTORY, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const locationSortimentHistory = yield select(selectors.Data.locationSortimentHistory);
            const headers = getHeaders(token);
            const result = yield fetch(`${host}/locationsortimenthistory`, { method: "POST", headers, body: JSON.stringify({locationSortimentHistory: action.payload.locationSortimentHistory})});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.loadLocationSortimentHistoryList(action.payload.locationSortimentHistory))
                yield put(actions.Data.showLocationSortimentHistory({
                    ...locationSortimentHistory
                }))
            }
        } catch (e) {
            console.log(e);
        }
    });
}

function* showLocationSortimentHistorySaga() {
    yield takeLatest(actionTypes.Data.SHOW_LOCATION_SORTIMENT_HISTORY, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const locationSortimentHistory = yield select(selectors.Data.locationSortimentHistory);
            const headers = getHeaders(token);
            const sortiment = action.payload.locationSortimentHistory.sortiment;
            const location = action.payload.locationSortimentHistory.location;
            const from = action.payload.locationSortimentHistory.from ? action.payload.locationSortimentHistory.from : 0;
            const result = yield fetch(`${host}/locationsortimenthistory/${sortiment}/${location}?from=${from}`, { method: "GET", headers});

            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(actions.Data.updateLocationSortimentHistory(
                    {
                        ...locationSortimentHistory,
                        list: from > 0 ? [...locationSortimentHistory.list, ...list.list] : list.list,
                        countList: list.count
                    }
                ));
            }
        } catch (e) {
            console.log(e);
        }
    });
}
function* loadLocationSortimentHistoryListSaga() {
    yield takeLatest(actionTypes.Data.LOAD_LOCATION_SORTIMENT_HISTORY_LIST, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const sortiment = action.payload.locationSortimentHistory.sortiment;
            const result = yield fetch(`${host}/locationsortimenthistory/${sortiment}`, { method: "GET", headers});

            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                 const list = yield result.json();
                 yield put(actions.Data.updateLocationSortimentHistoryList(list));
            }
        } catch (e) {
            console.log(e);
        }
    });
}


export default [
    createLocationSortimentHistorySaga,
    showLocationSortimentHistorySaga,
    loadLocationSortimentHistoryListSaga
];
