export default {
    locationModalInput: {
        'name': '',
        'street': '',
        'zip': '',
        'city': '',
        'position': '',
        'hideInSortiment': false,
        'ip': ''
    },
    manufacturerModalInput: '',
    manufacturerModalAction: 'new',
    userModalAction: 'new',
    manufacturerToEdit: '',
    currentUser: {
        "active": true,
        "createdAt": "",
        "email": "",
        "firstName": "",
        "id": "",
        "lastName": "",
        "location": {
            "city": null,
            "id": "",
            "name": "",
            "street": null,
            "zip": "",
        },
        "phone": null,
        "resource": null,
        "resourceUri": null,
        "role": null,
        "roleIdentifier": "",
        "roleLabel": "",
        "shortName": "",
        "updatedAt": "",
    },
    userList: [
        {
            id: 'defaultId',
            lastName: '',
            firstName: '',
            roleLabel: '',
            active: false,
            location: {
                name: '',
                id: '1',
            },
        },
        {
            id: 'defaultId',
            lastName: '          ',
            firstName: '',
            roleLabel: '',
            active: false,
            location: {
                name: '',
                id: '1',
            },
        },
        {
            id: 'defaultId',
            lastName: '',
            firstName: '',
            roleLabel: '',
            active: false,
            location: {
                name: '',
                id: '1',
            },
        },
        {
            id: 'defaultId',
            lastName: '',
            firstName: '',
            roleLabel: '',
            active: false,
            location: {
                name: '',
                id: '1',
            },
        },
        {
            id: 'defaultId',
            lastName: '',
            firstName: '',
            roleLabel: '',
            active: false,
            location: {
                name: '',
                id: '1',
            },
        },
        {
            id: 'defaultId',
            lastName: '',
            firstName: '',
            roleLabel: '',
            active: false,
            location: {
                name: '',
                id: '1',
            },
        },
    ],
    taskCheckbox: {},
    currentTask: {
        'completed': null,
        'taskId': '',
        'locationId': '',
        'all': null,
    },
    taskList: [],
    groupFilterTaskList: [],
    taskListProps: {
        "showDropdownLocation": false,
        "showDropdownGroup": false,
        "page": 0,
        "completed": null,
        "count": null,
        "taskLength": 0,
        "locationId": "",
        "locationName": "",
        "groupName": "",
        "groupLabel": "",
        "all": null,
        "sortProperty": 'deadline',
    },
    completedTaskList: [],
    userGroupList: [
        {
            name: 'defaultId',
            label: '',
            count: '',
        },
        {
            name: 'defaultId',
            label: '',
            count: '',
        },
        {
            name: 'defaultId',
            label: '',
            count: '',
        },
        {
            name: 'defaultId',
            label: '',
            count: '',
        },
        {
            name: 'defaultId',
            label: '',
            count: '',
        },
        {
            name: 'defaultId',
            label: '',
            count: '',
        },
    ],
    locationList: [
        {
            id: 'defaultId',
            name: '',
            street: '',
            zip: '',
            city: '',
        },
        {
            id: 'defaultId',
            name: '',
            street: '',
            zip: '',
            city: '',
        },
        {
            id: 'defaultId',
            name: '',
            street: '',
            zip: '',
            city: '',
        },
        {
            id: 'defaultId',
            name: '',
            street: '',
            zip: '',
            city: '',
        },
        {
            id: 'defaultId',
            name: '',
            street: '',
            zip: '',
            city: '',
        },
        {
            id: 'defaultId',
            name: '',
            street: '',
            zip: '',
            city: '',
        },
    ],
    allLocations: [],
    manufacturers: [
        {
            id: 'defaultId',
            name: '',
        },
        {
            id: 'defaultId',
            name: '',
        },
        {
            id: 'defaultId',
            name: '',
        },
        {
            id: 'defaultId',
            name: '',
        },
        {
            id: 'defaultId',
            name: '',
        },
        {
            id: 'defaultId',
            name: '',
        },
    ],
    loginHistoryList: [
        {
            id: 'defaultId',
            formatedDate: '',
            formatedTime: '',
            userName: '',
            ip: '',
            city: '',
            country: '',
        },
        {
            id: 'defaultId',
            formatedDate: '',
            formatedTime: '',
            userName: '',
            ip: '',
            city: '',
            country: '',
        },
        {
            id: 'defaultId',
            formatedDate: '',
            formatedTime: '',
            userName: '',
            ip: '',
            city: '',
            country: '',
        },
        {
            id: 'defaultId',
            formatedDate: '',
            formatedTime: '',
            userName: '',
            ip: '',
            city: '',
            country: '',
        },
        {
            id: 'defaultId',
            formatedDate: '',
            formatedTime: '',
            userName: '',
            ip: '',
            city: '',
            country: '',
        },
        {
            id: 'defaultId',
            formatedDate: '',
            formatedTime: '',
            userName: '',
            ip: '',
            city: '',
            country: '',
        },
    ],
    newsList: [
    ],
    currentNews: {},
    uploadedTask: {
        "title": "",
        "deadline": "",
        "locationId": "",
        "users": [],
        "team": "",
        "locationName": "",
        "teamLabel": "",
    },
    calendar: [
        {
            'date': '02.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'abwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'urlaub' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'anwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'anwesend' },
            ],
        },
        {
            'date': '03.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'krank' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'urlaub' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'anwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'anwesend' },
            ],
        },
        {
            'date': '04.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'urlaub' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'anwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'anwesend' },
            ],
        },
        {
            'date': '05.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'abwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'krank' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'anwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'urlaub' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'abwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'abwesend' },
            ],
        },
        {
            'date': '06.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'abwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'abwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'urlaub' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'abwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'abwesend' },
            ],
        },
        {
            'date': '07.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'urlaub' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'urlaub' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'abwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'urlaub' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'abwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'abwesend' },
            ],
        },
        {
            'date': '08.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'abwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'urlaub' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'abwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'urlaub' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'abwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'abwesend' },
            ],
        },
        {
            'date': '09.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'abwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'abwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'anwesend' },
            ],
        },
        {
            'date': '10.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'abwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'abwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'anwesend' },
            ],
        },
        {
            'date': '11.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'abwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'urlaub' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'abwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'krank' },
            ],
        },
        {
            'date': '12.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'krank' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'abwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'krank' },
            ],
        },
        {
            'date': '13.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'krank' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'krank' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'krank' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'urlaub' },
            ],
        },
        {
            'date': '14.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'krank' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'krank' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'krank' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'urlaub' },
            ],
        },
        {
            'date': '15.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'abwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'krank' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'krank' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'urlaub' },
            ],
        },
        {
            'date': '16.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'abwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'krank' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'anwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'krank' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'krank' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'urlaub' },
            ],
        },
        {
            'date': '17.05.2019',
            'track': [
                { 'id': 1, 'name': 'Alex Danvers', 'status': 'anwesend' },
                { 'id': 2, 'name': 'Kara Danvers', 'status': 'anwesend' },
                { 'id': 3, 'name': 'Felicity Smoak', 'status': 'anwesend' },
                { 'id': 4, 'name': 'Thea Queen', 'status': 'abwesend' },
                { 'id': 5, 'name': 'Sara Lance', 'status': 'anwesend' },
                { 'id': 6, 'name': 'Roy Harper', 'status': 'abwesend' },
                { 'id': 7, 'name': 'Nora Alan', 'status': 'anwesend' },
                { 'id': 8, 'name': 'Iris West', 'status': 'anwesend' },
                { 'id': 9, 'name': 'Teresa Lisbon', 'status': 'anwesend' },
                { 'id': 10, 'name': 'Patrick Jane', 'status': 'anwesend' },
            ],
        },
    ],
    subTaskList: ['Fällig: 02.05.2019'],
    bestandList: ['bestand 1'],
    userToEdit: {},
    newUser: {},
    articleList: [],
    articleDropdownList: [],
    teams: [],
    allUsers: [],
    folderList: [],
    documentToEdit: {},
    folderToEdit: {},
    currentFolder: {
        'documents': [],
        'folders': [
            {
                'id': 'defaultId',
                'name': '',
                'parent': null,
                'teams': [],
            },
            {
                'id': 'defaultId',
                'name': '',
                'parent': null,
                'teams': [],
            },
            {
                'id': 'defaultId',
                'name': '',
                'parent': null,
                'teams': [],
            },
            {
                'id': 'defaultId',
                'name': '',
                'parent': null,
                'teams': [],
            },
            {
                'id': 'defaultId',
                'name': '',
                'parent': null,
                'teams': [],
            },
            {
                'id': 'defaultId',
                'name': '',
                'parent': null,
                'teams': [],
            },
        ],
    },
    dropdown: {
        paths: [
            'dropdown.settings.user.location',
            'dropdown.settings.userAdditionalLocations',
            'dropdown.settings.userAllowedLocationsClaim',
            'dropdown.settings.user.usergroup',
            'dropdown.settings.permissions.usergroup',
            'dropdown.settings.permissions.copyFrom',
            'dropdown.settings.subgroups',
            'dropdown.settings.location.regions',
            'dropdown.documents.folder',
            'dropdown.documents.team',
            'dropdown.documents.teamUpload',
            'dropdown.stock.manufacturer',
            'dropdown.stock.category',
            'dropdown.stock.article',
            'dropdown.task.user',
            'dropdown.news.location',
            'dropdown.news.team',
            'dropdown.news.category',
            'dropdown.absencerecord.location',
            'dropdown.training.user',
            'dropdown.training.category',
            'dropdown.sickReport.user',
            'dropdown.userData.availableLocations'
        ],
        settings: {
            user: {
                location: {
                    view: false,
                    label: 'Standort',
                    selectedId: '',
                    selectedLabel: '',
                    path: 'dropdown.settings.user.location',
                },
                usergroup: {
                    view: false,
                    label: 'Benutzergruppe',
                    selectedId: '',
                    selectedLabel: '',
                    path: 'dropdown.settings.user.usergroup',
                },
            },
            permissions: {
                usergroup: {
                    view: false,
                    label: 'Benutzergruppe',
                    selectedId: '',
                    selectedLabel: '',
                    path: 'dropdown.settings.permissions.usergroup',
                },
                copyFrom: {
                    view: false,
                    label: 'Rechte vorlage',
                    selectedId: '',
                    selectedLabel: '',
                    path: 'dropdown.settings.permissions.copyFrom',
                },
            },
            subgroups: {
                view: false,
                label: 'Untergeordnete Benutzergruppen',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.settings.subgroups',
            },

            userAdditionalLocations: {
                view: false,
                label: 'Weitere Standorte',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.settings.userAdditionalLocations',
            },
            userAllowedLocationsClaim: {
                view: false,
                label: 'Beanstandungen in',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.settings.userAllowedLocationsClaim',
            },
            location: {
                regions: {
                    view: false,
                    label: 'Bundesland',
                    selectedId: '',
                    selectedLabel: '',
                    path: 'dropdown.settings.location.regions',
                },
            },
        },
        documents: {
            folder: {
                view: false,
                label: 'Ordner',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.documents.folder',
            },
            team: {
                view: false,
                label: 'Team',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.documents.team',
            },
            teamUpload: {
                view: false,
                label: 'Team upload',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.documents.teamUpload',
            },
        },
        stock: {
            manufacturer: {
                view: false,
                label: 'Hersteller',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.stock.manufacturer',
            },
            category: {
                view: false,
                label: 'Sortiment',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.stock.category',
            },
            article: {
                view: false,
                label: 'Artikel',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.stock.article',
            },
        },
        task: {
            user: {
                view: false,
                label: 'Benutzer',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.task.user',
            },
        },
        news: {
            location: {
                view: false,
                label: 'Standort',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.news.location',
            },
            team: {
                view: false,
                label: 'Benutzergruppen',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.news.team',
            },
            category: {
                view: false,
                label: 'Kategorie',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.news.category',
            },
        },
        training: {
            user: {
                view: false,
                label: 'Mitarbeiter',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.training.user',
            },
            category: {
                view: false,
                label: 'Kategorie',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.training.category',
            },
        },
        sickReport: {
            user: {
                view: false,
                label: 'Benutzer',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.sickReport.user',
            }
        },
        userData: {
            availableLocations: {
                view: false,
                label: 'Aktueller Standort',
                selectedId: '',
                selectedLabel: '',
                path: 'dropdown.userData.availableLocations',
            }
        },
    },
    modal: {
        documents: {
            document: {
                action: 'newDocument',
                view: false,
            },
            folder: {
                action: 'newFolder',
                view: false,
            },
            shortLink: {
                action: 'shortLink',
                view: false,
            },
        },
        stock: {
            article: {
                action: 'newArticle',
                view: false,
                tab: 1,
            },
            scan: {
                action: 'scan',
                view: false,
            },
        },
        task: {
            task: {
                action: 'newTask',
                view: false,
            },
        },
        news: {
            news: {
                action: 'newNews',
                view: false,
            },
        },
        category: {
            category: {
                action: 'newCategory',
                view: false,
            },
        },
        training: {
            training: {
                action: 'newTraining',
                view: false,
            },
            reschedule: {
                action: 'reschedule',
                view: false,
            },
            addRegistration: {
                action: 'addRegistration',
                view: false,
            },
            valuation: {
                action: 'newValuation',
                view: false,
            }
        },
        sickReport: {
            sickReport: {
                action: 'newSickReport',
                view: false,
            }
        },
        advertising: {
            advertising: {
                action: 'newAdvertising',
                view: false,
            }
        },
        settings: {
            locationSpecialAppointment: {
                action: 'new',
                view: false,
            }
        },
        absence: {
            calendarUser: {
                action: 'editCalendarUser',
                view: false,
            }
        }
    },
    filter: {
        paths: [
            'data.filter.task.location',
            'data.filter.task.team',
            'data.filter.absencerecord.location',
            'data.filter.stock.category',
            'data.filter.stock.manufacturer',
            'data.filter.stock.manufacturerForArticleDropdown',
            'data.filter.stock.location',
            'data.filter.stock.label',
            'data.filter.sickReport.location',
            'data.filter.sickReport.year',
            'data.filter.training.location',

        ],
        task: {
            location: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.task.location',
            },
            team: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.task.team',
            },
        },
        absencerecord: {
            location: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.absencerecord.location',
            }
        },
        stock: {
            category: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.stock.category',
            },
            label: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.stock.label',
            },
            manufacturer: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.stock.manufacturer',
            },
            manufacturerForArticleDropdown: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.stock.manufacturerForArticleDropdown',
            },
            location: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.stock.location',
            },
        },
        sickReport: {
            location: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.sickReport.location',
            },
            year: {
                view: false,
                selectedLabel: '',
                path: 'data.filter.sickReport.year',
            }
        },
        training: {
            location: {
                view: false,
                selectedLabel: '',
                selectedId: '',
                path: 'data.filter.training.location',
            },
        },
    },
    documentList: [
        {
            id: 'defaultId',
            name: '',
            type: '',
            size: '',
            resourceUri: '',
        },
        {
            id: 'defaultId',
            name: '',
            type: '',
            size: '',
            resourceUri: '',
        },
        {
            id: 'defaultId',
            name: '',
            type: '',
            size: '',
            resourceUri: '',
        },
        {
            id: 'defaultId',
            name: '',
            type: '',
            size: '',
            resourceUri: '',
        },
        {
            id: 'defaultId',
            name: '',
            type: '',
            size: '',
            resourceUri: '',
        },
        {
            id: 'defaultId',
            name: '',
            type: '',
            size: '',
            resourceUri: '',
        },
    ],
    articleToEdit: {},
    loadMore: {
        'article': true,
    },
    articlePage: 0,
    absenceDate: '',
    holidays: {
        'past': 0,
        'planned': 0
    },
    stockFilter: {
        'category': null,
        'manufacturer': null,
        'number': null,
        'decor': null,
        'label': null
    },
    articleDropdownFilter: {
        'manufacturer': null,
        'number': null,
        'decor': null
    },
    articleCount: 0,
    articlesFromAdvertising: false,
    shortLink: {
        id: '',
        name: '',
        error: false,
        type: 'sl'
    },
    calendarUser: {},
    currentStockInput: {
        index: null,
        value: null
    },
    holidayApi: {
        year: 2021,
        region: 'NW',
        holidays: {},
        vacation: [],
        holidaysLastYear: {},
        vacationLastYear: [],
        holidaysNextYear: {},
        vacationNextYear: []
    },
    locationSpecialAppointment: {},
    locationSpecialAppointmentList: [],
    loginSearchTerm: '',
    locationSpecialAppointmentSearchTerm: '',
    locationSortimentHistoryList:[],
    locationSortimentHistory: {
        location: null,
        sortiment: null,
        list: [],
        view: false,
        date: null,
        countList: 0,
        from: 0,
        locationName: ''
    },
    colors: [
        {
            id: 'defaultId',
            name: '',
            hex: ''
        },
        {
            id: 'defaultId',
            name: '',
            hex: ''
        },
        {
            id: 'defaultId',
            name: '',
            hex: ''
        },
        {
            id: 'defaultId',
            name: '',
            hex: ''
        },
        {
            id: 'defaultId',
            name: '',
            hex: ''
        }
    ],
    statuses: [
        {
            id: 'defaultId',
            name: ''
        },
        {
            id: 'defaultId',
            name: ''
        },
        {
            id: 'defaultId',
            name: ''
        },
        {
            id: 'defaultId',
            name: ''
        },
        {
            id: 'defaultId',
            name: ''
        }
    ],
    colorToEdit: {
        id: '',
        name: '',
        hex: ''
    },
    statusToEdit: {
        id: '',
        name: ''
    },
    globalSettings: {
        googleSheetsLink: ''
    },
    colorModalAction: 'new',
    statusModalAction: 'new',
    stockSorting: [
        'number',
        'manufacturer.name',
        'quality'
    ],
    yearFilter: null,
    contingents: [],
    claimDropdownManufacturers: [
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
    ],
    claimDropdownManufacturerToEdit: {id: '',name: ''},
    claimDropdownManufacturerModalAction: 'new',
    claimDropdownReasons: [
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
    ],
    claimDropdownReasonToEdit: {id: '',name: ''},
    claimDropdownReasonModalAction: 'new',
    claimDropdownQualities: [
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
        {id: 'defaultId',name: ''},
    ],
    claimDropdownQualityToEdit: {id: '',name: ''},
    claimDropdownQualityModalAction: 'new',
    likes: [],
    countLikes: [],
    comments: [],
    commentToEdit: {},
    userPopup: [],
};
