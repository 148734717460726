import { delay } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';
import * as moment from 'moment';


function* calendarSaga() {
    yield takeLatest(
        [
            actionTypes.Data.LOAD_CALENDAR,
            actionTypes.Data.UPDATE_ABSENCE_DATE,
            actionTypes.Data.UPDATE_CALENDAR_WEEK,
            actionTypes.Ui.UPDATE_ABSENCE_WEEK_FILTER,
        ],
        function*(action) {
            try {
                yield delay(300);
                yield put(actions.Absence.updateCalendarLoading(true));

                const stateData = yield select(selectors.Data.data);
                const absenceWeekFilter = yield select(selectors.Ui.absenceWeekFilter);

                const token = yield select(selectors.Ui.token);
                const headers = getHeaders(token);
                let location = '';
                let locationName = 'Velbert';
                if (stateData.filter.absencerecord.location.selectedId !== '') {
                    location = stateData.filter.absencerecord.location.selectedId;
                    locationName = stateData.filter.absencerecord.location.selectedLabel;
                } else if (stateData.currentUser.currentLocation) {
                    location = stateData.currentUser.currentLocation.id;
                    locationName = stateData.currentUser.currentLocation.label
                }

                let url = host + '/calendar/' + location;

                if (action.type === actionTypes.Ui.UPDATE_ABSENCE_WEEK_FILTER && absenceWeekFilter !== '') {
                    const dt = moment().day('Monday').week(absenceWeekFilter).format('YYYY-MM-DD');
                    url = url + '/' + dt;
                } else if (action.payload.date || stateData.absenceDate) {
                    const dt = action.payload.date || stateData.absenceDate;
                    url = url + '/' + dt;
                }

                const result = yield fetch(url, {
                    method: 'GET',
                    headers: headers,
                });
                yield put(actions.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    const calendarLog = yield result.json();
                    yield put(actions.Data.updateCalendar(calendarLog));
                    yield put(actions.Absence.updateCalendarLoading(false));
                    console.log(locationName)
                    yield put(actions.Data.loadHolidayApi({
                        year: moment(calendarLog[0].dt).format('YYYY'),
                        region: location
                    }));
                }
            } catch (e) {
                console.log('ERROR', e);
                yield put(actions.Ui.showLoginError(true));
                yield put(actions.Absence.updateCalendarLoading(false));
            }
        }
    );
}

function* absenceRequestSaga() {
    yield takeLatest(actionTypes.Absence.SEND_ABSENCE_REQUEST, function*() {
        try {
            yield put(actions.Advertising.updateLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const absence = yield select(selectors.Absence.absence);
            const headers = getHeaders(token);

            const newAbsenceRequest = {
                ...absence.absenceRequest,
                startDate : moment(
                    absence.absenceRequest.startDate + ' 0:00',
                    'YYYY-MM-DD HH:mm'
                ).format('DD.MM.YYYY HH:mm'),
                endDate: moment(
                    absence.absenceRequest.endDate + ' 0:00',
                    'YYYY-MM-DD HH:mm'
                ).format('DD.MM.YYYY HH:mm')
            }

            const requestData = {
                appointment: newAbsenceRequest,
            };

            const result = yield fetch(host + '/appointment', {
                method: 'POST',
                headers,
                body: JSON.stringify(requestData),
            });
            yield put(actions.Advertising.updateLoadingAnimation(false));
            if (result.status === 200) {
                yield put(actions.Ui.updateView(absence.prevView));
                yield put(actions.Absence.updateAbsenceRequest({prop: 'overtime', val: ""}));
                yield put(actions.Absence.updateAbsenceRequest({prop: 'overtimeDeduction', val: ""}));
                yield put(actions.Absence.updateAbsenceRequest({prop: 'specialHoliday', val: ""}));
                yield put(actions.Absence.updateAbsenceRequest({prop: 'reason', val: ""}));
                yield put(actions.Absence.updateAbsenceRequest({prop: 'startDate', val: ""}));
                yield put(actions.Absence.updateAbsenceRequest({prop: 'endDate', val: ""}));
            }

            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const appointmentData = yield result.json();
                if (appointmentData && result.status !== 224) {
                    yield put(actions.Data.loadCalendar(true));
                    yield put(actions.Absence.updateAbsenceList({ list: [], reset: true }));
                    yield put(actions.Absence.loadAbsenceList({ from: 0, view: 1, lazy: false }));
                }
                if (result.status === 422) {
                    // attempting to create holiday appointment twice
                    window.alert('Es gibt für dieses Datum bereits ein Urlaubsantrag');
                }
                if (result.status === 224) {
                    console.log(appointmentData)
                    // attempting to create holiday appointment which exceeds contingent
                    //const conf = window.confirm('Nur noch '+appointmentData.appointments+' Tage verfügbar. Antrag trotzdem stellen?');
                    if (window.confirm('Nur noch '+appointmentData[0]+' Tage verfügbar. Antrag trotzdem stellen?')) {
                        yield put(actions.Ui.updateView(absence.prevView));
                        yield put(actions.Absence.updateAbsenceRequest({prop: 'overtime', val: ""}));
                        yield put(actions.Absence.updateAbsenceRequest({prop: 'overtimeDeduction', val: ""}));
                        yield put(actions.Absence.updateAbsenceRequest({prop: 'specialHoliday', val: ""}));
                        yield put(actions.Absence.updateAbsenceRequest({prop: 'reason', val: ""}));
                        yield put(actions.Absence.updateAbsenceRequest({prop: 'startDate', val: ""}));
                        yield put(actions.Absence.updateAbsenceRequest({prop: 'endDate', val: ""}));
                    } else {
                        yield put(actions.Absence.deleteAbsence({id: appointmentData[1]}));
                        yield put(actions.Task.deleteTask())
                    }
                    //window.alert('Dieser Urlaubsantrag übersteigt Ihr Urlaubskontingent');
                }
            }
        } catch (e) {
            yield put(actions.Advertising.updateLoadingAnimation(false));
            console.log('ERROR', e);
        }
    });
}

function* absenceSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_CALENDAR_DATA, function*(action) {
        try {
            if (action.payload.data.prop === 'status') {
                const token = yield select(selectors.Ui.token);
                const calendar = yield select(selectors.Data.calendar);
                const headers = getHeaders(token);

                const requestData = {
                    appointment: {
                        startDate: action.payload.data.user.date,
                        endDate: action.payload.data.user.date,
                        userId: action.payload.data.user.id,
                        status:
                            calendar[action.payload.data.dateIndex].users[
                                action.payload.data.userIndex
                            ].status,
                        statusLabel:
                            calendar[action.payload.data.dateIndex].users[
                                action.payload.data.userIndex
                            ].statusLabel,
                        approved: true,
                    },
                };
                const result = yield fetch(host + '/appointment', {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(requestData),
                });
                yield put(actions.Ui.checkLogin(result.status));
                if (result.status === 422) {
                    // attempting to create holiday appointment twice
                    window.alert('Es gibt für dieses Datum bereits ein Urlaubsantrag');
                }

            } else if (action.payload.data.prop === 'info') {
                yield put(
                    actions.Data.updateLocationInfo({
                        date: action.payload.data.user.date,
                        location: action.payload.data.user.loc,
                        info: action.payload.data.user.info,
                        isAppointment: action.payload.data.user.isAppointment,
                    })
                );
            }
        } catch (e) {
            console.log('ERROR', e);
        }
    });
}

function* absenceListSaga() {
    yield takeLatest(
        [actionTypes.Absence.LOAD_ABSENCE_LIST], function*(action) {
        try {
            if (action.payload.list.lazy !== true) {
                yield put(actions.Absence.updateLoadingList(true));
            }
            const token = yield select(selectors.Ui.token);
            const stateData = yield select(selectors.Data.data);
            const userSearch = yield select(selectors.Ui.userSearch)
            const sorting = yield select(selectors.Absence.sorting)
            const yearFilter = stateData.yearFilter ? `?year=${stateData.yearFilter}`: '';
            const headers = getHeaders(token);
            const sign = yearFilter !== '' ? '&' : '?';
            const searchTerm = userSearch !== '' ? `${sign}searchTerm=${userSearch}` : ''
            const sort = searchTerm !== '' ? '&sort=' + sorting : `${sign}sort=${sorting}`

            let location = '';
            if (stateData.filter.absencerecord.location.selectedId !== '') {
                location = stateData.filter.absencerecord.location.selectedId;
            } else if (stateData.currentUser.currentLocation) {
                location = stateData.currentUser.currentLocation.id;
            }

            const result = yield fetch(
                host +
                    '/absence/' +
                    action.payload.list.from +
                    '/' +
                    location +
                    '/' +
                    action.payload.list.view +
                    yearFilter +
                    searchTerm +
                    sort
                ,
                { method: 'GET', headers }
            );
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(
                    actions.Absence.updateAbsenceList({ list: list.appointments, reset: false })
                );
                yield put(actions.Absence.updateLoadingList(false));
                yield put(actions.Absence.updateListCount(list.count));
                if (action.payload.list.view === 1) {
                    yield put(actions.Absence.updateOpenListCount(list.count));
                }
            }
        } catch (e) {
            console.log('Error, loadAbsenceList', e);
        }
    });
}

function* updateApprovedSaga() {
    yield takeLatest(actionTypes.Absence.UPDATE_ABSENCE_APPROVED, function*(action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(
                host +
                    '/updateabsence/' +
                    action.payload.absence.id +
                    '/' +
                    action.payload.absence.approved,
                { method: 'PUT', headers }
            );
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Absence.updateAbsenceList({ list: [], reset: true }));
                yield put(actions.Absence.loadAbsenceList({ from: 0, view: 1, lazy: false }));
                if (result.status === 422) {
                    // attempting to create holiday appointment twice
                    window.alert('Es gibt für dieses Datum bereits ein Urlaubsantrag');
                }
            }
        } catch (e) {
            console.log('Error, updateAbsenceApproved', e);
        }
    });
}
function* deleteAbsenceSaga() {
    yield takeLatest(actionTypes.Absence.DELETE_ABSENCE, function*(action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/appointment/' + action.payload.absence.id, {
                method: 'DELETE',
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log('Error, deleteAbsence', e);
        }
    });
}
function* locationInfoSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_LOCATION_INFO, function*(action) {
        try {
            yield delay(300);
            const uri = action.payload.locationInfo.isAppointment
                ? 'locationappointment'
                : 'locationinfo';
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(`${host}/${uri}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(action.payload.locationInfo),
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log('Error, locationInfo', e);
        }
    });
}
function* loadHolidayApiSaga() {
    yield takeLatest(actionTypes.Data.LOAD_HOLIDAY_API, function*(action) {
        try {
            const year = action.payload.holidayApi.year
            const region = action.payload.holidayApi.region
            const holidayApi = yield select(selectors.Data.holidayApi)
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const method = 'GET'

            if (holidayApi.year !== year || holidayApi.region !== region || !holidayApi.holidays['Neujahrstag']) {

                let vacationList = [];
                let holidayList = {};
                let vacationListLastYear = [];
                let holidayListLastYear = {};
                let vacationListNextYear = [];
                let holidayListNextYear = {};
                if (region !== 'Alle') {
                    const result = yield fetch(`${host}/holidayapi/holidays/${region}`, {method, headers});
                    const holidays = yield result.json()

                    holidayList = JSON.parse(holidays['holidayList']);
                    holidayListLastYear = JSON.parse(holidays['holidayListLastYear']);
                    holidayListNextYear = JSON.parse(holidays['holidayListNextYear']);
                    vacationList = JSON.parse(holidays['vacationList']);
                    vacationListLastYear = JSON.parse(holidays['vacationListLastYear']);
                    vacationListNextYear = JSON.parse(holidays['vacationListNextYear']);
                }

                yield put(actions.Data.updateHolidayApi({
                    prop: 'holidays',
                    value: holidayList
                }));
                yield put(actions.Data.updateHolidayApi({
                    prop: 'vacation',
                    value: vacationList
                }));
                yield put(actions.Data.updateHolidayApi({
                    prop: 'holidaysLastYear',
                    value: holidayListLastYear
                }));
                yield put(actions.Data.updateHolidayApi({
                    prop: 'vacationLastYear',
                    value: vacationListLastYear
                }));
                yield put(actions.Data.updateHolidayApi({
                    prop: 'holidaysNextYear',
                    value: holidayListNextYear
                }));
                yield put(actions.Data.updateHolidayApi({
                    prop: 'vacationNextYear',
                    value: vacationListNextYear
                }));
                yield put(actions.Data.updateHolidayApi({
                    prop: 'year',
                    value: year
                }));
                yield put(actions.Data.updateHolidayApi({
                    prop: 'region',
                    value: region
                }));
            } else {
                console.log('holidayApi data is up to date')
            }
        } catch (e) {
            console.log('Error, locationInfo', e);
        }
    });
}

export default [
    calendarSaga,
    absenceRequestSaga,
    absenceSaga,
    absenceListSaga,
    updateApprovedSaga,
    deleteAbsenceSaga,
    locationInfoSaga,
    loadHolidayApiSaga
];
