import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';



function* saveAdvertisingSaga() {
    yield takeLatest(actionTypes.Advertising.SAVE_ADVERTISING, function* (action) {
        try {
            yield put(actions.Advertising.updateLoadingAnimation(true));

            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token)
            const method = '__identity' in action.payload.advertising.advertising ? 'PUT' : 'POST';
            const result = yield fetch(host + '/advertising', { method: method, headers: headers, body: JSON.stringify(action.payload.advertising) });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Advertising.updateAdvertising({prop: 'resetObj', val: ''}));
                yield put(actions.Data.updateModal({path: 'advertising.advertising', prop: 'view', val: false}));
                yield put(actions.Advertising.loadAdvertisingList({'from': 0}));
                yield put(actions.Advertising.updateLoadingAnimation(false))
            }
        } catch (e) {
            console.log(e);
            yield put(actions.Advertising.updateLoadingAnimation(false))
        }
    });
}



function* advertisingListSaga() {
    yield takeLatest(actionTypes.Advertising.LOAD_ADVERTISING_LIST, function* (action) {
        try {
            yield put(actions.Advertising.updateLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const advertising = yield select(selectors.Advertising.advertising);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/advertising/list/'+action.payload.list.from, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json()
                if (action.payload.list.from !== 0) {
                    yield put(actions.Advertising.updateAdvertisingList([...advertising.list, ...list.advertisings]))
                } else {
                    yield put(actions.Advertising.updateAdvertisingList(list.advertisings))
                }
                yield put(actions.Advertising.updateAdvertisingListCount(list.count))
                yield put(actions.Ui.updateCountSeen({page: 'Advertisings', value: list.new}))
                yield put(actions.Advertising.updateLoadingAnimation(false))
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Advertising.updateLoadingAnimation(false))
        }
    });
}

function* deleteAdvertisingSaga() {
    yield takeLatest(actionTypes.Advertising.DELETE_ADVERTISING, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token)
            const result = yield fetch(host + '/advertising/'+action.payload.advertising.id, {method: "DELETE",headers: headers});
            yield put(actions.Ui.checkLogin(result.status));

        } catch (e) {
            console.log("ERROR", e);
        }
    });
}


export default [deleteAdvertisingSaga, saveAdvertisingSaga, advertisingListSaga];
