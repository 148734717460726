import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* groupSaga() {
    yield takeLatest(actionTypes.Ui.LOAD_GROUPS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const result = yield fetch(host + '/team', { method: "GET", headers: getHeaders(token) });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const groups = yield result.json();
                yield put(actions.Data.updateUserGroupList(groups));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [groupSaga];
