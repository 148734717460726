import DateInput from 'Components/UI/Form/DateInput';
import * as moment from 'moment';
import * as PropTypes from 'prop-types';
import Quagga from 'quagga';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import IconLink from '../Components/UI/IconLink';
import { actions } from '../Redux/index';
import { host } from '../Sagas/host';
import Dropdown from './Dropdown';
import FilterDropdown from './FilterDropdown';
import Scanner from './Scanner';

const mapStateToProps = (state) => {
    return {
        articleModal: state.data.modal.stock.article,
        scanModal: state.data.modal.stock.scan,
        articleToEdit: state.data.articleToEdit,
        articleList: state.data.articleList,
        articleDropdownList: state.data.articleDropdownList,
        manufacturerDropdown: state.data.dropdown.stock.manufacturer,
        articleDropdown: state.data.dropdown.stock.article,
        manufacturers: state.data.manufacturers,
        allLocations: state.data.allLocations,
        locationDropdown: state.data.dropdown.settings.user.location,
        loadMore: state.data.loadMore.article,
        articlePage: state.data.articlePage,
        currentUser: state.data.currentUser,
        categoryList: state.category.list,
        categoryDropdown: state.data.dropdown.stock.category,
        token: state.ui.token,
        categoryFilter: state.data.filter.stock.category,
        manufacturerFilter: state.data.filter.stock.manufacturer,
        manufacturerFilterForArticleDropdown: state.data.filter.stock.manufacturerForArticleDropdown,
        stockFilter: state.data.stockFilter,
        articleDropdownFilter: state.data.articleDropdownFilter,
        articleCount: state.data.articleCount,
        loadingAnimation: state.ui.stockLoadingAnimation,
        deliveryCentralWarehouse: state.ui.deliveryCentralWarehouse,
        deliveryCentralWarehouse2: state.ui.deliveryCentralWarehouse2,
        articlesFromAdvertising: state.data.articlesFromAdvertising,
        locationFilter: state.data.filter.stock.location,
        currentStockInput: state.data.currentStockInput,
        headerOpen: state.ui.headerOpen,
        stickyClass: state.ui.stickyClass,
        currentArticleBackgroundColor: state.ui.currentArticleBackgroundColor,
        colorPalette: state.ui.colorPalette,
        locationSortimentHistory: state.data.locationSortimentHistory,
        locationSortimentHistoryList: state.data.locationSortimentHistoryList,
        colors: state.data.colors,
        labelFilter: state.data.filter.stock.label,
        stockSorting: state.data.stockSorting
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateArticleToEdit: (article) => dispatch(actions.Data.updateArticleToEdit(article)),
        updateArticleToEditData: (article) => dispatch(actions.Data.updateArticleToEditData(article)),
        saveArticle: (article) => dispatch(actions.Data.saveArticle(article)),
        updateArticle: (article) => dispatch(actions.Data.updateArticle(article)),
        deleteArticle: (article) => dispatch(actions.Data.deleteArticle(article)),
        updateStock: (stock) => dispatch(actions.Data.updateStock(stock)),
        updateLoadMore: (data) => dispatch(actions.Data.updateLoadMore(data)),
        updatePage: (data) => dispatch(actions.Data.updatePage(data)),
        loadArticles: (article) => dispatch(actions.Data.loadArticles(article)),
        toggleArticleVisibility: (article) => dispatch(actions.Data.toggleArticleVisibility(article)),
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        updateStockFilter: (filter) => dispatch(actions.Data.updateStockFilter(filter)),
        updateArticleDropdownFilter: (filter) => dispatch(actions.Data.updateArticleDropdownFilter(filter)),
        loadArticlesWithEan: (filter) => dispatch(actions.Data.loadArticlesWithEan(filter)),
        updateDeliveryCentralWarehouse: (value) => dispatch(actions.Ui.updateDeliveryCentralWarehouse(value)),
        updateDeliveryCentralWarehouse2: (value) => dispatch(actions.Ui.updateDeliveryCentralWarehouse2(value)),
        loadArticleDropdownList: (list) => dispatch(actions.Data.loadArticleDropdownList(list)),
        updateCurrentStockInput: (input) => dispatch(actions.Data.updateCurrentStockInput(input)),
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateStickyClass: (value) => dispatch(actions.Ui.updateStickyClass(value)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        updateCurrentArticleBackgroundColor: (article) => dispatch(actions.Ui.updateCurrentArticleBackgroundColor(article)),
        updateColorPalette: (value) => dispatch(actions.Ui.updateColorPalette(value)),
        saveArticleBackground: (article) => dispatch(actions.Ui.saveArticleBackground(article)),
        updateArticleBackgroundPreview: (article) => dispatch(actions.Data.updateArticleBackground(article)),
        addLocationSortimentHistory: (locationSortimentHistory) => dispatch(actions.Data.addLocationSortimentHistory(locationSortimentHistory)),
        showLocationSortimentHistory: (locationSortimentHistory) => dispatch(actions.Data.showLocationSortimentHistory(locationSortimentHistory)),
        updateLocationSortimentHistory: (locationSortimentHistory) => dispatch(actions.Data.updateLocationSortimentHistory(locationSortimentHistory)),
        updateLocationSortimentHistoryList: (list) => dispatch(actions.Data.updateLocationSortimentHistoryList(list)),
        loadLocationSortimentHistoryList: (locationSortimentHistory) => dispatch(actions.Data.loadLocationSortimentHistoryList(locationSortimentHistory)),
        updateStockSorting: (stockSorting) => dispatch(actions.Data.updateStockSorting(stockSorting)),
        setStockSorting: (stockSorting) => dispatch(actions.Data.setStockSorting(stockSorting)),
    };
};

class Stock extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { offset: 0 }
    }

    static propTypes = {
        articleList: PropTypes.array,
        articleDropdownList: PropTypes.array,
        updateDropdown: PropTypes.func,
        updateArticleToEdit: PropTypes.func,
        updateArticleToEditData: PropTypes.func,
        saveArticle: PropTypes.func,
        updateArticle: PropTypes.func,
        deleteArticle: PropTypes.func,
        updateStock: PropTypes.func,
        updateLoadMore: PropTypes.func,
        updatePage: PropTypes.func,
        loadArticles: PropTypes.func,
        categoryDropdown: PropTypes.object,
        categoryList: PropTypes.array,
        stockFilter: PropTypes.object,
        articleDropdownFilter: PropTypes.object,
        updateStockFilter: PropTypes.func.isRequired,
        sortimentView: PropTypes.string,
        stickyClass: PropTypes.string,
        stockSorting: PropTypes.array,
        updateStockSorting: PropTypes.func,
        setStockSorting: PropTypes.func

    };


    componentDidMount() {
        this.props.updateStockFilter({ prop: 'category', value: this.props.categoryFilter.selectedId });
        this.props.updateColorPalette(false)
       // window.addEventListener('scroll', this.handleScroll, true);
       window.scrollInterval = window.setInterval(() => {
            const offset = 303
            let top = 205;
            if (this.props.headerOpen && window.innerWidth > 1024 && window.innerWidth < 1350) {
                top = 238
            }
            const off = window.scrollY-offset > 0 ? window.scrollY-top : 0;
            if(this.state.offset !== off) {
                this.setState({offset: off})
            }
        }, 800);

    }

    componentWillUnmount() {
       // window.removeEventListener('scroll', this.handleScroll);
       clearInterval(window.scrollInterval)
        this.props.setStockSorting([
            'number',
            'manufacturer.name',
            'quality',

        ])
    }


    editArticle(article) {
            this.props.updateModal({path: 'stock.article', prop: 'view', val: !this.props.articleModal.view});
            this.props.updateModal({path: 'stock.article', prop: 'action', val: 'edit'});

        this.props.updateArticleToEdit({
            'id': article.id,
            "name": article.name,
            "number": article.number,
            "ean": article.ean,
            "active": article.active,
            "position": article.position,
            "decor": article.decor || '',
            "sales1": article.sales1 || '',
            "sales2": article.sales2 || '',
            "sales3": article.sales3 || '',
            "strength": article.strength || '',
            "outlet": article.outlet || '',
            "quality": article.quality || '',
            "baseboard": article.baseboard || '',
            "corner": article.corner || '',
            "srp": article.srp || '',
            "price": article.price || '',
            "actionPrice": article.actionPrice || '',
            "package": article.package || '',
            "package2": article.package2 || '',
            "deliveryCentralWarehouse": article.deliveryCentralWarehouse || '',
            "deliveryCentralWarehouse2": article.deliveryCentralWarehouse2 || '',
            "historyItems": article.historyItems,
            "actionPriceFrom": article.actionPriceFrom,
            "actionPriceTill": article.actionPriceTill,
            "oldPosition": article.position
        });
            this.props.updateDropdown({dropdown: this.props.manufacturerDropdown.path, prop:'selectedLabel', val:article.manufacturer.name});
            this.props.updateDropdown({dropdown: this.props.manufacturerDropdown.path, prop:'selectedId', val:article.manufacturer.id});

            if (article.category) {
                this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedLabel', val:article.category.name});
                this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedId', val:article.category.id});
            }


    }

    deleteArticle(article) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Artikel löschen?'})
        this.props.updateConfirmModal({prop: 'text', value: "Artikel '" + article.number + "' Löschen?"})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteArticle', param: article}})
    }

    getLink() {
        if (this.props.articlesFromAdvertising === false) {
            const limit = this.props.articleList.length;
            const category = this.props.stockFilter.category || 'null';
            const manufacturer = this.props.stockFilter.manufacturer || 'null';
            const quality = this.props.stockFilter.quality || 'null';
            const decor = this.props.stockFilter.decor || 'null';
            return host + "/export/"+category+"/"+manufacturer+"/"+decor+"/"+quality+"/0/"+limit;
        } else {
            return host + "/exportadvertising/"+this.props.articlesFromAdvertising;
        }

    }

    saveArticle()
    {
        if (!this.checkInput(this.props.articleToEdit.number, 'Nummer darf nicht leer sein.')) {
            return;
        }
        if (!this.checkInput(this.props.articleToEdit.ean, 'Ean darf nicht leer sein.')) {
            return;
        }
        if (!this.checkInput(this.props.manufacturerDropdown.selectedId, 'Hersteller darf nicht leer sein.')) {
            return;
        }
        if (!this.checkInput(this.props.categoryDropdown.selectedId, 'Sortiment darf nicht leer sein.')) {
            return;
        }
        if ((this.props.articleToEdit.actionPriceFrom && !this.props.articleToEdit.actionPriceTill) ||
            (!this.props.articleToEdit.actionPriceFrom && this.props.articleToEdit.actionPriceTill)
        ) {
            alert('Beide Datumsfelder für Aktionspreis ausfüllen.')
            return;
        }

        const actionPriceFrom = moment(this.props.articleToEdit.actionPriceFrom+ ' 0:00', 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm');
        const actionPriceTill = moment(this.props.articleToEdit.actionPriceTill+ ' 23:59', 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm');

        if (this.props.articleModal.action === 'newArticle') {



            this.props.saveArticle({
                "article": {
                    "name": this.props.articleToEdit.name || '',
                    "number": this.props.articleToEdit.number,
                    "ean": this.props.articleToEdit.ean,
                    "position": this.props.articleToEdit.position || '0',
                    "manufacturer": this.props.manufacturerDropdown.selectedId,
                    "category": this.props.categoryDropdown.selectedId,
                    "decor": this.props.articleToEdit.decor || '',
                    "sales1": this.props.articleToEdit.sales1 || '',
                    "sales2": this.props.articleToEdit.sales2 || '',
                    "sales3": this.props.articleToEdit.sales3 || '',
                    "strength": this.props.articleToEdit.strength || '',
                    "outlet": this.props.articleToEdit.outlet || '',
                    "quality": this.props.articleToEdit.quality || '',
                    "baseboard": this.props.articleToEdit.baseboard || '',
                    "corner": this.props.articleToEdit.corner || '',
                    "srp": this.props.articleToEdit.srp || '',
                    "price": this.props.articleToEdit.price || '',
                    "actionPrice": this.props.articleToEdit.actionPrice || '',
                    "package": this.props.articleToEdit.package || '',
                    "package2": this.props.articleToEdit.package2 || '',
                    "deliveryCentralWarehouse": this.props.articleToEdit.deliveryCentralWarehouse || '',
                    "deliveryCentralWarehouse2": this.props.articleToEdit.deliveryCentralWarehouse2 || '',
                    "actionPriceFrom": actionPriceFrom !== 'Invalid date' ? actionPriceFrom : null,
                    "actionPriceTill": actionPriceTill !== 'Invalid date' ? actionPriceTill : null

                }
            })

        } else {
            this.props.updateArticle({
                "article": {
                    'id': this.props.articleToEdit.id,
                    "name": this.props.articleToEdit.name || '',
                    "number": this.props.articleToEdit.number,
                    "ean": this.props.articleToEdit.ean,
                    "position": this.props.articleToEdit.position,
                    "active": this.props.articleToEdit.active,
                    "manufacturer": this.props.manufacturerDropdown.selectedId,
                    "category": this.props.categoryDropdown.selectedId,
                    "decor": this.props.articleToEdit.decor || '',
                    "sales1": this.props.articleToEdit.sales1 || '',
                    "sales2": this.props.articleToEdit.sales2 || '',
                    "sales3": this.props.articleToEdit.sales3 || '',
                    "strength": this.props.articleToEdit.strength || '',
                    "outlet": this.props.articleToEdit.outlet || '',
                    "quality": this.props.articleToEdit.quality || '',
                    "baseboard": this.props.articleToEdit.baseboard || '',
                    "corner": this.props.articleToEdit.corner || '',
                    "srp": this.props.articleToEdit.srp || '',
                    "price": this.props.articleToEdit.price || '',
                    "actionPrice": this.props.articleToEdit.actionPrice || '',
                    "package": this.props.articleToEdit.package || '',
                    "package2": this.props.articleToEdit.package2 || '',
                    "deliveryCentralWarehouse": this.props.articleToEdit.deliveryCentralWarehouse || '',
                    "deliveryCentralWarehouse2": this.props.articleToEdit.deliveryCentralWarehouse2 || '',
                    "actionPriceFrom": actionPriceFrom !== 'Invalid date' ? actionPriceFrom : null,
                    "actionPriceTill": actionPriceTill !== 'Invalid date' ? actionPriceTill : null
                },
                "oldPosition": this.props.articleToEdit.oldPosition
            })
        }
    }
    saveScan() {
        if (!this.checkInput(this.props.articleToEdit.count, 'Anzahl darf nicht leer sein.')) {
            return;
        }

        if (!this.props.articleToEdit.ean && !this.props.articleDropdown.selectedId) {
            alert('Ean darf nicht leer sein.');
            return;
        }
        if (!this.checkInput(this.props.locationDropdown.selectedId, 'Wählen Sie ein Standort aus.')) {
            return;
        }

        this.props.updateStock({
            "stock": {
                "ean": this.props.articleToEdit.ean || this.props.articleDropdown.selectedId,
                "location": this.props.locationDropdown.selectedId,
                "count": this.props.articleToEdit.count
            }
        });

    }
    renderActionPriceDates(article) {
        if(article.actionPriceFrom) {
                const priceFrom = moment(article.actionPriceFrom).format('DD.MM.YY');
                const priceTill = moment(article.actionPriceTill).format('DD.MM.YY');
                return <small className="action-price-small">{priceFrom} - {priceTill}</small>
        }else if (article.advertisingActionPriceFrom) {
            return <small className="action-price-small">{article.advertisingActionPriceFrom} - {article.advertisingActionPriceTill}</small>
        } else {
            return ''
        }
    }
    renderActionPrice(article) {
        return article.actionPrice ? article.actionPrice : article.advertisingActionPrice
    }

    getCode() {
        const arr1 = this.props.articleToEdit.scanList;
        let mf = 1;
        let m = 0;
        let item;
        for (let i=0; i<arr1.length; i++)
        {
            for (let j=i; j<arr1.length; j++)
            {
                if (arr1[i] === arr1[j])
                    m++;
                if (mf<m)
                {
                    mf=m;
                    item = arr1[i];
                }
            }
            m=0;
        }
        return item;
    }

    checkInput(input, alertMessage) {
        if (input) {
            if(input.length >= 1) {
                return true;
            } else {
                alert(alertMessage);
                return false;
            }
        } else {
            alert(alertMessage);
            return false;
        }
    }
    /**
     * @return string
     */
    currentTime() {
        // return new Date().toJSON().slice(0,16);

        let now = new Date();

        const year = now.getFullYear();
        const months =  ['01','02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

        const month = months[now.getMonth()];

        let hour = now.getHours();

        let minutes = '00';
        if (now.getMinutes() >= 1 && now.getMinutes() < 30) {
            minutes = '30';
        } else if (now.getMinutes() >= 31 && now.getMinutes() <= 59) {
            hour = hour + 1;
        }
        let day = '01';
        if (now.getDate() < 10) {
            day = '0'+now.getDate();
        } else {
            day = now.getDate();
        }

        return year + '-' + month + '-' +day+ 'T'+ hour + ':' + minutes;
    }

    getCountStock(article, location) {
            return article.stocks.map((stock, index) => {
                if(stock.location.id === location.id) {
                    return (
                        <article key={index}>{/*<b>{stock.updatedAtFormated}</b>*/}<span>{stock.count}</span></article>
                    )
                }
                return null;
            })
    }


    getLocationStock(article, location) {
        let stockTofind = null
        article.stocks.map( stock => {
                if(stock.location.id === location.id) {
                    stockTofind = stock.count;
                    return stock.count;
                }
                return null;
            })
        return stockTofind;
    }



    getSelectedCategory(col){
        let cat = {
            'sales1Name': 'Abverkauf Spalte 1',
            'sales2Name': 'Abverkauf Spalte 2',
            'sales3Name': 'Abverkauf Spalte 3'
        }
       if (this.props.categoryFilter.selectedId !== "") {
           this.props.categoryList.map(category => {
               if (category.id === this.props.categoryFilter.selectedId) {
                   if (category['sales'+col+'Name'].length)
                   cat['sales'+col+'Name'] = category['sales'+col+'Name']

               }
               return category;
           })
       }
       return cat['sales'+col+'Name'];
    }

    getPresetColor(article, index)
    {
        if (article.advertising) {
            const color = this.props.colors.find(c => c.name.toLowerCase() === 'werbung')
            return color ? color.hex : 'rgb(255, 249, 196)'
        }
        else if(article.outlet === 'A'){
            const color = this.props.colors.find(c => c.name.toLowerCase() === 'auslauf')
            return color ? color.hex : 'rgb(197, 202, 233)'
        }
        else {
            return article.backgroundColor ? article.backgroundColor : index % 2 === 0 ? '#ffffff' : '#ededed'
        }
    }

    getBackgroundStyle(article, index) {
        return {
            backgroundColor: this.getPresetColor(article, index),
            borderBottom: '1px solid rgb(125,125,125)',
            borderRight:'1px solid rgb(125,125,125)',
            boxSizing: 'border-box',
        }
    }

    getLocationSortimentHistoryDate(location) {
        const locationSortimentHistory = this.props.locationSortimentHistoryList.find(item => item.location === location.id)
        if (!locationSortimentHistory) {
            return <div style={{color: '#cdcdcd', letterSpacing: '1.1px'}}>tt.mm.jj</div>
        }
        return locationSortimentHistory.dateFormated
    }

    showLocationSortimentHistory(location){

        this.props.updateLocationSortimentHistory({
            ...this.props.locationSortimentHistory,
            location: location.id,
            sortiment: this.props.stockFilter.category,
            view: true,
            locationName: location.shortName.toUpperCase()
        })
        this.props.showLocationSortimentHistory({
            location: location.id,
            sortiment: this.props.stockFilter.category,
            from: 0
        })
    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    showSorting(column) {
        if(this.props.stockSorting.includes(column)){
            return <i className="fa fa-angle-up" />
        }
        if(this.props.stockSorting.includes(`-${column}`)){
            return <i className="fa fa-angle-down" />
        }

    }

    isValid(color) {
        if(color.id === '' || color.id === 'defaultId'){
            return null;
        } else {
            return(
                <li key={color.id} className="color-line">
                    <div className="color-cell color-block"  style={{ backgroundColor: color.hex, maxWidth: '20px', }} />
                    <div className="color-cell color-name" style={{marginLeft: 5, fontSize: 16, whiteSpace: 'nowrap'}}>{color.name}</div>
                </li>
            )
        }
    }


    render() {
        //sort by 2 properties
        // const sortedList = this.props.articleList.sort((artA, artB) => {
        //     if (artA.position === artB.position) {
        //         return artA.number > artB.number ? 1 : -1;
        //      }
        //      return artA.position > artB.position ? 1 : -1;
        // })
        const sortedList = [...this.props.articleList]
        return (
            <div id="main-side">

                <div id="Modal1" className={this.props.articleModal.view ? 'modal visible': 'modal hidden'}>
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                                <span>
                                    <i className="fa fa-database" aria-hidden="true"></i> {this.props.articleModal.action === 'newArticle' ? 'Neuer Artikel' : 'Artikel bearbeiten'}
                                </span> <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.updateModal({path: 'stock.article', prop: 'view', val: !this.props.articleModal.view});
                                           this.props.updateArticleToEdit({});
                                           this.props.updateDropdown({dropdown: this.props.manufacturerDropdown.path, prop:'selectedLabel', val:''});
                                           this.props.updateDropdown({dropdown: this.props.manufacturerDropdown.path, prop:'selectedId', val:''});

                                           this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedLabel', val:''});
                                           this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedId', val:''});
                                       }}
                                    >
                                        <i className="fa fa-times-circle-o"></i>
                                    </a>
                                </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <ul className="tabs">
                                        <li className={this.props.articleModal.tab === 1 ? 'tab-link current' : 'tab-link'} onClick={(e) => {e.preventDefault(); this.props.updateModal({path: 'stock.article', prop: 'tab', val: 1});}}>Artikel</li>
                                        <li className={this.props.articleModal.tab === 4 ? 'tab-link current' : 'tab-link'} onClick={(e) => {e.preventDefault(); this.props.updateModal({path: 'stock.article', prop: 'tab', val: 4});}}>Preise</li>
                                        <li className={this.props.articleModal.tab === 5 ? 'tab-link current' : 'tab-link'} onClick={(e) => {e.preventDefault(); this.props.updateModal({path: 'stock.article', prop: 'tab', val: 5});}}>Verpackung</li>
                                        <li className={this.props.articleModal.tab === 2 ? 'tab-link current' : 'tab-link'} onClick={(e) => {e.preventDefault(); this.props.updateModal({path: 'stock.article', prop: 'tab', val: 2});}}>Lieferdaten</li>
                                        <li className={this.props.articleModal.tab === 3 ? 'tab-link current' : 'tab-link'} onClick={(e) => {e.preventDefault(); this.props.updateModal({path: 'stock.article', prop: 'tab', val: 3});}}>Historie</li>
                                    </ul>
                                    <div className={this.props.articleModal.tab === 1 ? 'tab-content current' : 'tab-content'}>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.number || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'number',
                                                            'val': e.target.value,
                                                        })}/> <label>Nummer</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.ean || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'ean',
                                                            'val': e.target.value,
                                                        })}/> <label>Ean</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <Dropdown dropdown={this.props.categoryDropdown} dropdownList={this.props.categoryList} itemIdProp={'id'} itemLabelProp={'name'}/>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <Dropdown dropdown={this.props.manufacturerDropdown} dropdownList={this.props.manufacturers} itemIdProp={'id'} itemLabelProp={'name'}/>
                                            </div>

                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.decor || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'decor',
                                                            'val': e.target.value,
                                                        })}/> <label>Artikelbezeichnung</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.quality || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'quality',
                                                            'val': e.target.value,
                                                        })}/> <label>Qualität</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.baseboard || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'baseboard',
                                                            'val': e.target.value,
                                                        })}/> <label>Fußleiste</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.corner || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'corner',
                                                            'val': e.target.value,
                                                        })}/> <label>Ecke</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.sales1 || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'sales1',
                                                            'val': e.target.value,
                                                        })}/> <label>Abverkauf Spalte 1</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.sales2 || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'sales2',
                                                            'val': e.target.value,
                                                        })}/> <label>Abverkauf Spalte 2</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.sales3 || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'sales3',
                                                            'val': e.target.value,
                                                        })}/> <label>Abverkauf Spalte 3</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.outlet || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'outlet',
                                                            'val': e.target.value,
                                                        })}/> <label>Kategorie</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.strength || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'strength',
                                                            'val': e.target.value,
                                                        })}/> <label>Stärke</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.props.articleModal.tab === 4 ? 'tab-content current' : 'tab-content'}>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.srp || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'srp',
                                                            'val': e.target.value,
                                                        })}/> <label>UVP</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.price || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'price',
                                                            'val': e.target.value,
                                                        })}/> <label>VK-Preis</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <DateInput
                                                            selected={this.props.articleToEdit.actionPriceFrom
                                                                ? new Date(this.props.articleToEdit.actionPriceFrom)
                                                                : null}

                                                                onChange={(date) => {
                                                                    this.props.updateArticleToEditData({
                                                                        prop: 'actionPriceFrom',
                                                                        val: date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : ''
                                                                    })
                                                                    if (!date) {
                                                                        //empty dateTill
                                                                        this.props.updateArticleToEditData({
                                                                            prop: 'actionPriceTill',
                                                                            val: '',
                                                                        });
                                                                    }
                                                                    }
                                                                }
                                                        />

                                                        <label>Aktionspreis gültig von</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                {this.props.articleToEdit.actionPriceFrom &&
                                                <div className="group">
                                                    <DateInput
                                                        minDate={new Date(this.props.articleToEdit.actionPriceFrom)}
                                                        selected={this.props.articleToEdit.actionPriceTill
                                                            ? new Date(this.props.articleToEdit.actionPriceTill)
                                                            : null}
                                                        onChange={(date) => {
                                                            this.props.updateArticleToEditData({
                                                                prop: 'actionPriceTill',
                                                                val: date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : '',
                                                            });
                                                        }
                                                        }
                                                    />
                                                    <label>Aktionspreis gültig bis</label>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.actionPrice || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'actionPrice',
                                                            'val': e.target.value,
                                                        })}/> <label>Aktionspreis</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.props.articleModal.tab === 5 ? 'tab-content current' : 'tab-content'}>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.package || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'package',
                                                            'val': e.target.value,
                                                        })}/> <label>Pakete pro Palette</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <input type="text" value={this.props.articleToEdit.package2 || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                            'prop': 'package2',
                                                            'val': e.target.value,
                                                        })}/> <label>Bestand Zentrallager</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.props.articleModal.tab === 2 ? 'tab-content current' : 'tab-content'}>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <span style={{
                                                            position: 'absolute',
                                                            top: '10px',
                                                            right: '10px',
                                                            cursor: 'pointer'
                                                        }}
                                                              onClick={(e) => {
                                                                  this.props.updateDeliveryCentralWarehouse(!this.props.deliveryCentralWarehouse);
                                                              }}

                                                            className="fa-stack">
                                                          <i className="fa fa-calendar fa-stack-1x"/>
                                                            { !this.props.deliveryCentralWarehouse &&
                                                                <i className="fa fa-ban fa-stack-2x"/>
                                                            }
                                                        </span>

                                                        <input
                                                            value={this.props.articleToEdit.deliveryCentralWarehouse || '' }
                                                            id="deliveryCentralWarehouse"
                                                            onChange={(e) => {this.props.updateArticleToEditData({prop:'deliveryCentralWarehouse', val:e.target.value});}}
                                                            type={this.props.deliveryCentralWarehouse ? 'date' : 'text'} />
                                                        <label>Lieferung (ZL)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <div className="group">
                                                        <span style={{
                                                            position: 'absolute',
                                                            top: '10px',
                                                            right: '10px',
                                                            cursor: 'pointer'
                                                        }}
                                                              onClick={(e) => {
                                                                  this.props.updateDeliveryCentralWarehouse2(!this.props.deliveryCentralWarehouse2);
                                                              }}

                                                              className="fa-stack">
                                                          <i className="fa fa-calendar fa-stack-1x"/>
                                                            { !this.props.deliveryCentralWarehouse2 &&
                                                                <i className="fa fa-ban fa-stack-2x"/>
                                                            }
                                                        </span>
                                                        <input
                                                            value={this.props.articleToEdit.deliveryCentralWarehouse2 || '' }
                                                            id="deliveryCentralWarehouse2"
                                                            onChange={(e) => {this.props.updateArticleToEditData({prop:'deliveryCentralWarehouse2', val:e.target.value});}}
                                                            type={this.props.deliveryCentralWarehouse2 ? 'date' : 'text'} />
                                                        <label>Voraussichtl. nächste Lieferung (ZL)</label>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className={this.props.articleModal.tab === 3 ? 'tab-content current' : 'tab-content'}>
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <div className="grid-wrapper">
                                                    <div className="small-4 medium-4 large-4">
                                                        <h3>Datum</h3>
                                                    </div>
                                                    <div className="small-4 medium-4 large-4">
                                                        <h3>Benutzer</h3>
                                                    </div>
                                                    <div className="small-4 medium-4 large-4">
                                                        <h3>Standort</h3>
                                                    </div>
                                                    {this.props.articleToEdit.historyItems && this.props.articleToEdit.historyItems.map((historyItem, index) => (
                                                        <React.Fragment key={index}>
                                                            <div  className="small-4 medium-4 large-4">
                                                                <span>{historyItem.updatedAtFormated}</span>
                                                            </div>
                                                            <div className="small-4 medium-4 large-4">
                                                                <span>{historyItem.userName}</span>
                                                            </div>
                                                            <div className="small-4 medium-4 large-4">
                                                                <span>{historyItem.locationName} </span>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="content-wrapper">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <Button
                                                label={this.props.articleModal.action === 'newArticle' ? 'Hinzufügen' : 'Speichern'}
                                                inverted
                                                onClick={() => this.saveArticle()}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="Modal2" className={this.props.scanModal.view ? 'modal visible': 'modal hidden'}>
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                                <v-quagga v-if="hasDetectedABarcode" />
                                <span>
                                    <i className="fa fa-database" aria-hidden="true"></i> {this.props.scanModal.action === 'scan' ? 'Bestand ändern' : ''}
                                </span> <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.updateModal({path: 'stock.scan', prop: 'view', val: !this.props.scanModal.view});
                                           this.props.updateArticleToEdit({});
                                           this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'selectedLabel', val:''});
                                           this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'selectedId', val:''});


                                       }}
                                    >
                                        <i className="fa fa-times-circle-o"></i>
                                    </a>
                                </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <a href="/#-" className="buttonlink"
                                               onClick={(e) => {
                                                   e.preventDefault();
                                                   this.props.updateArticleToEditData({
                                                       'prop': 'scan',
                                                       'val': !this.props.articleToEdit.scan,
                                                   })
                                                   this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'view', val:false});
                                                   this.props.updateArticleToEditData({ 'prop': 'ean', 'val': ''});
                                                   this.props.updateArticleToEditData({ 'prop': 'scanListEmpty', 'val': []});
                                                   this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'selectedLabel', val:''});
                                                   this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'selectedId', val:''});
                                               }}
                                            >
                                                <div className="formbutton">
                                                    <p>
                                                        Bestand scannen
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <div className="group">
                                                    {this.props.articleToEdit.scan ? <Scanner onDetected={(scanResult) => {
                                                        this.props.updateArticleToEditData({ 'prop': 'scanList', 'val': scanResult.codeResult.code});
                                                        if(this.props.articleToEdit.scanList) {
                                                            if (this.props.articleToEdit.scanList.length > 30) {
                                                                this.props.loadArticlesWithEan({
                                                                    'ean': this.getCode(),
                                                                });
                                                                this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'view', val:true});
                                                                this.props.updateArticleToEditData({ 'prop': 'ean', 'val': this.getCode()});
                                                                this.props.updateArticleToEditData({'prop': 'scan','val': false});
                                                                this.props.updateArticleToEditData({ 'prop': 'scanListEmpty', 'val': []});
                                                                Quagga.offDetected()
                                                                Quagga.stop()
                                                            }
                                                        }

                                                    }}/> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-wrapper">


                                        <div className="small-12 medium-small-12 medium-4 large-4 custom-dropdown">
                                            <div className="group">
                                                <FilterDropdown filter={this.props.manufacturerFilterForArticleDropdown} list={this.props.manufacturers} itemIdProp={'id'} itemLabelProp={'name'} defaultSelectedLabel={'Hersteller'} actionToExecute={this.props.updateArticleDropdownFilter} paramsToExecute={{prop: 'manufacturer', value: this.props.manufacturerFilterForArticleDropdown.selectedId}} />
                                            </div>
                                        </div>

                                        <div className="small-12 medium-small-12 medium-4 large-4">
                                            <div className="group">
                                                <input className={'custom-input'} type="text" name='quality' value={this.props.articleDropdownFilter.number || ''} placeholder={'Artikelnummer'}
                                                       onChange={(e) =>  {
                                                                this.props.updateArticleDropdownFilter({
                                                               'prop': 'number',
                                                               'value': e.target.value,
                                                           });
                                                           //this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'view', val:false});
                                                       }}
                                                />
                                            </div>
                                        </div>

                                        <div className="small-12 medium-small-12 medium-4 large-4">
                                            <div className="group">
                                                <input className={'custom-input'} type="text" name='decor' value={this.props.articleDropdownFilter.decor || ''} placeholder={'Artikelbezeichnung'}
                                                       onChange={(e) =>  {
                                                           this.props.updateArticleDropdownFilter({
                                                               'prop': 'decor',
                                                               'value': e.target.value,

                                                           });
                                                           //this.props.updateDropdown({dropdown: this.props.articleDropdown.path, prop:'view', val:false});
                                                       }}
                                                />
                                            </div>
                                        </div>

                                        <div className="small-12 medium-12 large-12">
                                            <div className="group">
                                                <Dropdown dropdown={this.props.articleDropdown} dropdownList={this.props.articleDropdownList} itemIdProp={'id'} itemLabelProp={'manufacturerQualityAndDecor'}/>
                                            </div>
                                        </div>
                                        <div className="small-12 medium-12 large-12">
                                            <div className="group">
                                                <Dropdown dropdown={this.props.locationDropdown}
                                                          disabled={!this.can('sortiment_bei_allen_artikel_bestand_bearbeiten')
                                                          }
                                                          dropdownList={this.props.allLocations} itemIdProp={'id'} itemLabelProp={'label'}
                                                />
                                            </div>
                                        </div>
                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <div className="group">
                                                    <input type="text" disabled value={this.props.articleToEdit.ean || ''} onChange={(e) => this.props.updateArticleToEditData({
                                                        'prop': 'ean',
                                                        'val': e.target.value,
                                                    })}/> <label>Ean</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <div className="group">
                                                    <input type="text"  value={this.props.articleToEdit.count || '' } onChange={(e) => this.props.updateArticleToEditData({
                                                        'prop': 'count',
                                                        'val': e.target.value,
                                                    })}/> <label>Anzahl</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <div className="group">
                                                    {this.props.articleToEdit.scan ? <Scanner onDetected={(scanResult) => {
                                                        this.props.updateArticleToEditData({ 'prop': 'scanList', 'val': scanResult.codeResult.code});
                                                        if(this.props.articleToEdit.scanList) {
                                                            if (this.props.articleToEdit.scanList.length > 30) {
                                                                this.props.updateArticleToEditData({ 'prop': 'count', 'val': this.getCode()});
                                                                this.props.updateArticleToEditData({'prop': 'scan','val': false});
                                                                this.props.updateArticleToEditData({ 'prop': 'scanListEmpty', 'val': []});
                                                            }
                                                        }

                                                    }}/> : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className="content-wrapper">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <Button
                                                label={this.props.scanModal.action === 'scan' ? 'Hinzufügen' : 'Speichern'}
                                                inverted
                                                onClick={() => this.saveScan()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="ColorLabelModal" className={this.props.colorPalette ? 'modal visible': 'modal hidden'}>
                    <div className="modal-content width-1000">
                        <div className="headline-counter">

                            <span>
                                    <i className="fa fa-dashboard" aria-hidden="true"></i> Label anpassen
                                </span> <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.saveArticleBackground(this.props.currentArticleBackgroundColor)
                                           this.props.updateColorPalette(false)
                                       }}
                                    >
                                        <i className="fa fa-times-circle-o"></i>
                                    </a>
                                </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            {this.props.colors.filter(color => color.id !== 'defaultId').map((color, index) => (
                                                <div key={color.id} className="color-line"
                                                     onClick={(e) => {
                                                         e.stopPropagation();
                                                         //this.props.updateColorPalette(false)
                                                         this.props.updateCurrentArticleBackgroundColor({id: this.props.colorPalette, color: color.hex})
                                                         this.props.updateArticleBackgroundPreview({id: this.props.colorPalette, color: color.hex})
                                                     }} style={{
                                                    backgroundColor: color.hex,
                                                    outline: this.props.currentArticleBackgroundColor.color === color.hex ? '4px solid red': '4px solid transparent',
                                                }}
                                                >
                                                    <div className="color-cell"
                                                    >{color.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="content-wrapper">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-around'
                                            }}>


                                            <Button
                                                label="Zurücksetzen"
                                                inverted
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    //this.props.updateColorPalette(false)
                                                    this.props.updateCurrentArticleBackgroundColor({id: this.props.colorPalette, color: null})
                                                    //this.props.saveArticleBackground({id: article.id, color: null})
                                                    this.props.updateArticleBackgroundPreview({id: this.props.colorPalette, color: null})
                                                }}
                                            />
                                            <Button
                                                label="Speichern"
                                                inverted
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    this.props.updateColorPalette(false)
                                                    this.props.saveArticleBackground(this.props.currentArticleBackgroundColor)
                                                }}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="ModalLocationSortimentHistory" className={this.props.locationSortimentHistory.view ? 'modal visible': 'modal hidden'}>
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                                <span>
                                    <i className="fa fa-database" aria-hidden="true"></i> Datum der letzten Bestandsänderung
                                </span> <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.updateLocationSortimentHistory({
                                                location: null,
                                                sortiment: null,
                                                list: [],
                                                view: false,
                                                date: null,
                                                countList: 0,
                                                from: 0,
                                                locationName: ''
                                            })
                                       }}
                                    >
                                        <i className="fa fa-times-circle-o"></i>
                                    </a>
                                </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12">
                                            <div className="group">
                                                <div className="group">
                                                    <DateInput selected={this.props.locationSortimentHistory.date
                                                                ? new Date(this.props.locationSortimentHistory.date)
                                                                : null}
                                                        onChange={(date) => {
                                                            this.props.updateLocationSortimentHistory({
                                                                ...this.props.locationSortimentHistory,
                                                                date: date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : ''
                                                            })}}/>
                                                        <label>Datum der letzten änderung</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="small-12 medium-12 large-12">
                                            <Button
                                                label='Speichern'
                                                inverted
                                                onClick={() => this.props.addLocationSortimentHistory(
                                                    this.props.locationSortimentHistory
                                                )}
                                            />
                                        </div>
                                        <hr/>
                                        <div className="small-12 medium-12 large-12">
                                                <div className="grid-wrapper">
                                                    <div className="small-4 medium-4 large-4">
                                                        <h3>Datum</h3>
                                                    </div>
                                                    <div className="small-4 medium-4 large-4">
                                                        <h3>Benutzer</h3>
                                                    </div>
                                                    <div className="small-4 medium-4 large-4">
                                                        <h3>Standort</h3>
                                                    </div>
                                                    {this.props.locationSortimentHistory.list.length > 0 && this.props.locationSortimentHistory.list.map((historyItem, index) => (
                                                        <React.Fragment key={index}>
                                                            <div  className="small-4 medium-4 large-4">
                                                                <span>{historyItem.dateFormated || ''}</span>
                                                            </div>
                                                            <div className="small-4 medium-4 large-4">
                                                                <span>{historyItem.userName}</span>
                                                            </div>
                                                            <div className="small-4 medium-4 large-4">
                                                                <span>{this.props.locationSortimentHistory.locationName} </span>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                </div>
                            </div>

                            {this.props.locationSortimentHistory.countList > this.props.locationSortimentHistory.list.length &&
                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label='mehr'
                                                    inverted
                                                    onClick={() => this.props.showLocationSortimentHistory({
                                                        ...this.props.locationSortimentHistory,
                                                        from: this.props.locationSortimentHistory.list.length
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>

                <Breadcrumb path={[{label: 'Sortiment', view: 'CategoryPage', onClickHaendler: this.props.updateView},this.props.categoryFilter.selectedLabel]}>
                    <FilterDropdown
                        filter={this.props.categoryFilter}
                        list={this.props.categoryList}
                        itemIdProp={'id'}
                        itemLabelProp={'name'}
                        defaultSelectedLabel={'Sortiment'}
                        actionToExecute={this.props.updateStockFilter}
                        paramsToExecute={{
                            prop: 'category',
                            value: this.props.categoryFilter.selectedId,
                        }}
                    />
                    <FilterDropdown
                        filter={this.props.locationFilter}
                        list={this.props.allLocations}
                        itemIdProp={'id'}
                        itemLabelProp={'label'}
                        defaultSelectedLabel={'Standort'}
                    />
                    <Button
                        label="Bestand ändern"
                        onClick={() => {
                            this.props.updateModal({
                                path: 'stock.scan',
                                prop: 'view',
                                val: !this.props.scanModal.view,
                            });
                            this.props.updateArticleToEdit({});
                            this.props.loadArticleDropdownList(true);
                            this.props.updateDropdown({dropdown: this.props.locationDropdown.path, prop:'selectedLabel', val:this.props.currentUser.currentLocation.label});
                            this.props.updateDropdown({dropdown: this.props.locationDropdown.path, prop:'selectedId', val:this.props.currentUser.currentLocation.id});



                        }}
                    />
                    {this.can('sortiment_artikel_erstellen') &&
                    <Button label="Neuer Artikel"
                            onClick={() => {
                                this.props.updateModal({
                                    path: 'stock.article',
                                    prop: 'view',
                                    val: !this.props.articleModal.view,
                                });
                                this.props.updateModal({ path: 'stock.article', prop: 'action', val: 'newArticle' });
                                this.props.updateArticleToEdit({});
                            }}
                    />}
                </Breadcrumb>

                <main>
                    <div className="content-wrapper inner-ver ">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                                            <span><i className="fa fa-bars" aria-hidden="true"/> Bestand {this.props.loadingAnimation && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/>} </span>
                                            <div style={{ display: "flex" }}>
                                                <FilterDropdown
                                                    filter={this.props.labelFilter}
                                                    list={this.props.colors}
                                                    itemIdProp={'id'}
                                                    itemLabelProp={'name'}
                                                    defaultSelectedLabel={'Label'}
                                                    actionToExecute={this.props.updateStockFilter}
                                                    paramsToExecute={{prop: 'label', value: this.props.labelFilter.selectedId}}
                                                    color
                                                />
                                                <FilterDropdown filter={this.props.manufacturerFilter} list={this.props.manufacturers} itemIdProp={'id'} itemLabelProp={'name'} defaultSelectedLabel={'Hersteller'} actionToExecute={this.props.updateStockFilter} paramsToExecute={{prop: 'manufacturer', value: this.props.manufacturerFilter.selectedId}} />
                                                &nbsp;
                                                <input style={{fontSize: "16px",
                                                    padding: "10px 5px",
                                                    border: "2px solid #303030",
                                                    lineHeight: "22px"}} type="text" name='test' value={this.props.stockFilter.number || ''} placeholder={'Artikelnummer'}
                                                       onChange={(e) => this.props.updateStockFilter({
                                                           'prop': 'number',
                                                           'value': e.target.value,
                                                       })}
                                                />
                                                &nbsp;
                                                <input style={{fontSize: "16px",
                                                    padding: "10px 5px",
                                                    border: "2px solid #303030",
                                                    lineHeight: "22px"}} type="text" name='test' value={this.props.stockFilter.decor || ''}  placeholder={'Artikelbezeichnung'}
                                                       onChange={(e) => this.props.updateStockFilter({
                                                           'prop': 'decor',
                                                           'value': e.target.value,
                                                       })}
                                                />
                                            </div>
                                        </div>
                                        <ul className="colorKey">
                                            {this.props.colors.map((color) => (this.isValid(color)))}
                                        </ul>

                                        {
                                        <div className="table safari_table sortiment-table">
                                            <div className="table-col">
                                                <div className="table-head table-green">
                                                    <span>#</span>
                                                </div>
                                                <div className={"sticky-header sticky table-head table-green"} style={{top: this.state.offset + 'px'}}>
                                                    <span># {this.props.loadingAnimation && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/>}</span>
                                                </div>
                                                <div className="table-content-1">
                                                    {sortedList.map((article, index) => {
                                                        return(
                                                            <span key={index}
                                                                onClick={() => {
                                                                    if(this.can('sortiment_artikel_label_bearbeiten')) {
                                                                        this.props.updateColorPalette(article.id)
                                                                        this.props.updateCurrentArticleBackgroundColor({id: article.id, color: article.backgroundColor})
                                                                    }
                                                                }}
                                                                className="article-index"
                                                                style={{cursor: 'pointer', ...this.getBackgroundStyle(article, index)}}
                                                            >
                                                                {index + 1}

                                                                </span>
                                                        )

                                                    })}
                                                </div>
                                            </div>
                                            <div className="table-col">
                                                <div className="table-head table-green" onClick={() => this.props.updateStockSorting('number')}>
                                                    <span>Artikelnr. {this.showSorting('number')}</span>
                                                </div>
                                                <div
                                                    className={"sticky-header sticky  table-head table-green"}
                                                    style={{top: this.state.offset + 'px'}}
                                                    onClick={() => this.props.updateStockSorting('number')}
                                                >
                                                    <span>Artikelnr. {this.showSorting('number')}</span>
                                                </div>
                                                <div className="table-content-1">
                                                    <div className="table-left">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.number}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-col table-col-wide">
                                                <div className="table-head table-green"
                                                     onClick={() => this.props.updateStockSorting('decor')}
                                                >
                                                    <span>Artikelbezeichnung {this.showSorting('decor')}</span>
                                                </div>
                                                <div className={"sticky-header sticky  table-head table-green"}
                                                     style={{top: this.state.offset + 'px', width: '250px'}}
                                                     onClick={() => this.props.updateStockSorting('decor')}
                                                >
                                                    <span>Artikelbezeichnung {this.showSorting('decor')}</span>
                                                </div>
                                                <div className="table-content-1">
                                                    <div className="table-right">
                                                        {sortedList.map((article, index) => {

                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.decor}</span>
                                                            )

                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="scroll-x-able">
                                                <div className="table-col">
                                                    <div className="table-head table-green"
                                                         onClick={() => this.props.updateStockSorting('manufacturer.name')}
                                                    >
                                                        <span>Hersteller {this.showSorting('manufacturer.name')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-green"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('manufacturer.name')}
                                                    >
                                                        <span>Hersteller {this.showSorting('manufacturer.name')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.manufacturer.name}</span>
                                                            )

                                                        })}
                                                    </div>
                                                </div>

                                                <div className="table-col">
                                                    <div className="table-head table-green"
                                                         onClick={() => this.props.updateStockSorting('quality')}
                                                    >
                                                        <span>Qualität {this.showSorting('quality')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-green"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('quality')}
                                                    >
                                                        <span>Qualität {this.showSorting('quality')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        <div className="table-left">
                                                            {sortedList.map((article, index) => {
                                                                return(
                                                                    <span key={index}
                                                                    style={this.getBackgroundStyle(article, index)}
                                                                    >{article.quality}</span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-col">
                                                    <div className="table-head table-blue"
                                                         onClick={() => this.props.updateStockSorting('sales1')}
                                                    >
                                                        <span>{this.getSelectedCategory(1)} {this.showSorting('sales1')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-blue"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('sales1')}
                                                    >
                                                        <span>{this.getSelectedCategory(1)} {this.showSorting('sales1')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.sales1}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-blue"
                                                         onClick={() => this.props.updateStockSorting('sales2')}
                                                    >
                                                        <span>{this.getSelectedCategory(2)} {this.showSorting('sales2')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-blue"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('sales2')}
                                                    >
                                                        <span>{this.getSelectedCategory(2)} {this.showSorting('sales2')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.sales2}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-blue"
                                                         onClick={() => this.props.updateStockSorting('sales3')}
                                                    >
                                                        <span>{this.getSelectedCategory(3)} {this.showSorting('sales3')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-blue"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('sales3')}
                                                    >
                                                        <span>{this.getSelectedCategory(3)} {this.showSorting('sales3')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.sales3}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-blue"
                                                         onClick={() => this.props.updateStockSorting('baseboard')}
                                                    >
                                                        <span>Fußleiste {this.showSorting('baseboard')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-blue"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('baseboard')}
                                                    >
                                                        <span>Fußleiste {this.showSorting('baseboard')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.baseboard}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-blue"
                                                         onClick={() => this.props.updateStockSorting('corner')}
                                                    >
                                                        <span>Ecke {this.showSorting('corner')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-blue"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('corner')}
                                                    >
                                                        <span>Ecke {this.showSorting('corner')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.corner}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>


                                                {/**
                                                <div className="table-col">
                                                    <div className="table-head table-blue">
                                                        <span>Stärke</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}>{article.strength}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>


                                                <div className="table-col">
                                                    <div className="table-head table-yellow">
                                                        <span>Z. L Pak.</span>
                                                    </div>
                                                    <div className="table-content-1 centered">
                                                        {sortedList.map((article, index) => (
                                                                    <span key={index}>{article.package2}</span>
                                                                )

                                                        )}
                                                    </div>
                                                </div>
                                                */}
                                                <div className="table-col">
                                                    <div className="table-head table-yellow"
                                                         onClick={() => this.props.updateStockSorting('srp')}
                                                    >
                                                        <span>UVP {this.showSorting('srp')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-yellow"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('srp')}
                                                    >
                                                        <span>UVP {this.showSorting('srp')}</span>
                                                    </div>
                                                    <div className="table-content-1 centered">
                                                        {sortedList.map((article, index) => (
                                                                    <span key={index}
                                                                    style={this.getBackgroundStyle(article, index)}
                                                                    >{article.srp}</span>
                                                                )

                                                        )}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-yellow"
                                                         onClick={() => this.props.updateStockSorting('price')}
                                                    >
                                                        <span>Preis {this.showSorting('price')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-yellow"}
                                                         onClick={() => this.props.updateStockSorting('price')}
                                                         style={{top: this.state.offset + 'px'}}
                                                    >
                                                        <span>Preis {this.showSorting('price')}</span>
                                                    </div>
                                                    <div className="table-content-1 centered">
                                                        {sortedList.map((article, index) => (
                                                                    <span key={index}
                                                                    style={this.getBackgroundStyle(article, index)}
                                                                    >{article.price}</span>
                                                                )
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-yellow"
                                                         onClick={() => this.props.updateStockSorting('actionPrice')}
                                                    >
                                                        <span>Aktion {this.showSorting('actionPrice')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-yellow"}
                                                         onClick={() => this.props.updateStockSorting('actionPrice')}
                                                         style={{top: this.state.offset + 'px'}}
                                                    >
                                                        <span>Aktion {this.showSorting('actionPrice')}</span>
                                                    </div>
                                                    <div className="table-content-1 centered">
                                                        {sortedList.map((article, index) => (
                                                                    <span
                                                                    style={{position:'relative', ...this.getBackgroundStyle(article,index)}}
                                                                    key={index}>
                                                                        {this.renderActionPrice(article)} {this.renderActionPriceDates(article)}
                                                                    </span>
                                                                )

                                                        )}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-yellow"
                                                         onClick={() => this.props.updateStockSorting('outlet')}
                                                    >
                                                        <span>Kategorie {this.showSorting('outlet')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-yellow"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('outlet')}
                                                    >
                                                        <span>Kategorie {this.showSorting('outlet')}</span>
                                                    </div>
                                                    <div className="table-content-1 centered">
                                                        {sortedList.map((article, index) => (
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.outlet}</span>
                                                            )

                                                        )}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-blue"
                                                         onClick={() => this.props.updateStockSorting('package')}
                                                    >
                                                        <span>Pak. {this.showSorting('package')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-blue"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('package')}
                                                    >
                                                        <span>Pak. {this.showSorting('package')}</span>
                                                    </div>
                                                    <div className="table-content-1">
                                                        {sortedList.map((article, index) => {
                                                            return(
                                                                <span key={index}
                                                                style={this.getBackgroundStyle(article, index)}
                                                                >{article.package}</span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-yellow"
                                                         onClick={() => this.props.updateStockSorting('deliveryCentralWarehouse')}
                                                    >
                                                        <span>Lieferung Z. L {this.showSorting('deliveryCentralWarehouse')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-blue"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('deliveryCentralWarehouse')}
                                                    >
                                                        <span>Lieferung Z. L {this.showSorting('deliveryCentralWarehouse')}</span>
                                                    </div>
                                                    <div className="table-content-1 centered">
                                                        {sortedList.map((article, index) => (
                                                                    <span key={index}
                                                                    style={this.getBackgroundStyle(article, index)}
                                                                    >{article.deliveryCentralWarehouseFormated}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="table-col">
                                                    <div className="table-head table-yellow"
                                                         onClick={() => this.props.updateStockSorting('deliveryCentralWarehouse2')}
                                                    >
                                                        <span>Lieferung Z. L {this.showSorting('deliveryCentralWarehouse2')}</span>
                                                    </div>
                                                    <div className={"sticky-header sticky  table-head table-yellow"}
                                                         style={{top: this.state.offset + 'px'}}
                                                         onClick={() => this.props.updateStockSorting('deliveryCentralWarehouse2')}
                                                    >
                                                        <span>Lieferung Z. L {this.showSorting('deliveryCentralWarehouse2')}</span>
                                                    </div>
                                                    <div className="table-content-1 centered">
                                                        {sortedList.map((article, index) => (
                                                                    <span key={index}
                                                                    style={this.getBackgroundStyle(article, index)}
                                                                    >{article.deliveryCentralWarehouse2Formated}</span>
                                                                )

                                                        )}
                                                    </div>
                                                </div>

                                                {this.props.allLocations.filter(location => !location.hideInSortiment).map((location, index) => {

                                                    if(this.props.locationFilter.selectedId === '' || this.props.locationFilter.selectedId === location.id) {
                                                        return(
                                                            <div key={index} className="table-col">
                                                                <div className="table-head table-orange"
                                                                    style={{cursor: 'pointer'}}
                                                                     onClick={() => this.props.updateStockSorting(`location:${location.shortName}`)}
                                                                >
                                                                    <span>{location.shortName ?  location.shortName.toUpperCase() : location.label.substr(0,2).toUpperCase()} {this.showSorting(`location:${location.shortName}`)}</span>
                                                                    <span style={{fontSize: '70%', position: 'absolute', top: '40px'}}
                                                                          onClick={() => this.showLocationSortimentHistory(location)}
                                                                    >
                                                                         {this.getLocationSortimentHistoryDate(location)}
                                                                    </span>
                                                                </div>
                                                                <div className={"sticky-header sticky  table-head table-orange"}
                                                                     onClick={() => this.props.updateStockSorting(`location:${location.shortName}`)}

                                                                style={{top: this.state.offset + 'px', width: '120px'}}>
                                                                    <span>{location.shortName ?  location.shortName.toUpperCase() : location.label.substr(0,2).toUpperCase()} {this.showSorting(`location:${location.shortName}`)}</span>
                                                                    <span style={{fontSize: '70%', position: 'absolute', top: '40px'}}
                                                                          onClick={() => this.showLocationSortimentHistory(location)}
                                                                    >
                                                                        {this.getLocationSortimentHistoryDate(location)}
                                                                    </span>
                                                                </div>
                                                                <div className="table-content-1 centered">
                                                                    {sortedList.map((article, index2) => {
                                                                        if ((this.props.currentUser.currentLocation.id === location.id
                                                                            && this.can('sortiment_artikel_bestand_in_eigener_filiale_bearbeiten'))
                                                                            || this.can('sortiment_bei_allen_artikel_bestand_bearbeiten')) {
                                                                            return (<span key={index2}
                                                                                style={this.getBackgroundStyle(article, index2)}
                                                                            ><input type="text" className={"editStockInput"}
                                                                            onChange={(e) => {
                                                                                this.refs['stockInput'+location.id ].value = e.target.value
                                                                            }}
                                                                            ref={'stockInput'+location.id}
                                                                            defaultValue={this.getLocationStock(article, location)}
                                                                            /><button
                                                                            onClick={() => {
                                                                                this.props.updateCurrentStockInput({value: location.id, index: index2})
                                                                                this.props.updateStock({
                                                                                "stock": {
                                                                                    "ean": article.id,
                                                                                    "location": location.id,
                                                                                    "count": this.refs['stockInput'+location.id ].value
                                                                                }
                                                                            })}}
                                                                            ><small className={this.props.currentStockInput.index === index2 && this.props.currentStockInput.value === location.id  ? 'fa fa-spinner fa-pulse fa-fw': 'fa fa-check'}></small></button></span>)
                                                                        } else {
                                                                            return (<span key={index2}
                                                                                style={this.getBackgroundStyle(article, index2)}
                                                                            >{this.getCountStock(article, location)}</span>)
                                                                        }
                                                                    } )}
                                                                </div>
                                                            </div>
                                                        )
                                                    } else return (<React.Fragment key={index}></React.Fragment>)

                                                })}
                                                {this.can('sortiment_artikel_bearbeiten') &&
                                                    <div className="table-col">
                                                        <div className="table-head table-yellow">
                                                            <span><i className={'fa fa-edit'}></i></span>
                                                        </div>
                                                        <div className={"sticky-header sticky  table-head table-yellow"} style={{top: this.state.offset + 'px'}}>
                                                            <span><i className={'fa fa-edit'}></i></span>
                                                        </div>
                                                        <div className="table-content-1 centered">
                                                            {sortedList.map((article, index) => (
                                                                    <span key={index}
                                                                          style={this.getBackgroundStyle(article, index)}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              this.editArticle(article);
                                                                          }}
                                                                    ><i className={'fa fa-edit'}></i></span>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                                {this.can('sortiment_artikel_aktiv_inaktiv_setzen') &&
                                                    <div className="table-col">
                                                        <div className="table-head table-yellow">
                                                            <span><i className={'fa fa-eye'}></i></span>
                                                        </div>
                                                        <div className={"sticky-header sticky  table-head table-yellow"} style={{top: this.state.offset + 'px'}}>
                                                            <span><i className={'fa fa-eye'}></i></span>
                                                        </div>
                                                        <div className="table-content-1 centered">
                                                            {sortedList.map((article, index) => (
                                                                    <span key={index}
                                                                          style={this.getBackgroundStyle(article, index)}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              this.props.toggleArticleVisibility({"article": article});
                                                                          }}
                                                                    ><i className={article.active ? 'fa fa-eye-slash' : 'fa fa-eye'}></i></span>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                                {this.can('sortiment_artikel_l_schen') &&
                                                    <div className="table-col">
                                                        <div className="table-head table-yellow">
                                                            <span><i className={'fa fa-trash'}></i></span>
                                                        </div>
                                                        <div className={"sticky-header sticky  table-head table-yellow"} style={{top: this.state.offset + 'px'}}>
                                                            <span><i className={'fa fa-trash'}></i></span>
                                                        </div>
                                                        <div className="table-content-1 centered">
                                                            {sortedList.map((article, index) => (
                                                                    <span key={index}
                                                                          style={this.getBackgroundStyle(article, index)}
                                                                          onClick={(e) => {
                                                                              e.preventDefault();
                                                                              this.deleteArticle(article);
                                                                          }}
                                                                    ><i className={'fa fa-trash'}></i></span>
                                                                )

                                                            )}
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                        }


                                    </div>
                                </div>
                                    <div className="small-12 medium-12 large-12">
                                        {this.props.articleCount > this.props.articleList.length &&
                                        <Button
                                            icon={this.props.loadingAnimation ? 'load-more-animated': 'load-more'}
                                            label="alle Artikel laden"
                                            inverted
                                            onClick={() => this.props.loadArticles({ lazy: true })}
                                        />}
                                        &nbsp;
                                        <form action={this.getLink()}
                                              method="post"
                                              ref={(ref) => {
                                                  this.downloadForm = ref;
                                              }}>
                                            <input type="hidden" name="authTok"
                                                   value={'Bearer ' + this.props.token}
                                            />
                                        </form>
                                        {this.can('sortiment_artikel_exportieren') &&
                                        <IconLink type="download"
                                                  onClick={() => this.downloadForm.submit()}>Download XLSX</IconLink>}
                                    </div>
                            </div>
                        </div>
                    </div>
                </main>

                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stock);
