import { createAction } from 'redux-actions';
import { handleActions } from '../Utility/HandleActions';
import { $get, $set } from 'plow-js';

const UPDATE_ADVERTISING = 'Advertising/UpdateAdvertising';
const LOAD_ADVERTISING_LIST = 'Advertising/LoadAdvertisingList';
const UPDATE_ADVERTISING_LIST = 'Advertising/UpdateAdvertisingList';
const UPDATE_ADVERTISING_LIST_COUNT = 'Advertising/UpdateAdvertisingListCount';
const UPDATE_LOADING_ANIMATION = 'Advertising/UpdateLoadingAnimation';
const SAVE_ADVERTISING = 'Advertising/SaveAdvertising';
const DELETE_ADVERTISING = 'Advertising/DeleteAdvertising';
const RESET_STATE = 'Advertising/ResetState';



const actionTypes = {
    UPDATE_ADVERTISING,
    LOAD_ADVERTISING_LIST,
    UPDATE_ADVERTISING_LIST,
    UPDATE_ADVERTISING_LIST_COUNT,
    UPDATE_LOADING_ANIMATION,
    SAVE_ADVERTISING,
    DELETE_ADVERTISING,
    RESET_STATE

};

const updateAdvertising = createAction(UPDATE_ADVERTISING, (advertising) => ({advertising}));
const loadAdvertisingList = createAction(LOAD_ADVERTISING_LIST, (list) => ({list}));
const updateAdvertisingList = createAction(UPDATE_ADVERTISING_LIST, (list) => ({list}));
const updateAdvertisingListCount = createAction(UPDATE_ADVERTISING_LIST_COUNT, (count) => ({count}));
const updateLoadingAnimation = createAction(UPDATE_LOADING_ANIMATION, (value) => ({value}));
const saveAdvertising = createAction(SAVE_ADVERTISING, (advertising) => ({advertising}));
const deleteAdvertising = createAction(DELETE_ADVERTISING, (advertising) => ({advertising}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));



const actions = {
    updateAdvertising,
    loadAdvertisingList,
    updateAdvertisingList,
    updateAdvertisingListCount,
    updateLoadingAnimation,
    saveAdvertising,
    deleteAdvertising,
    resetState

};

const initialState = {
    advertising: {},
    list: [],
    listCount: 0,
    loadingAnimation: false,
};

const reducer = handleActions({
    [UPDATE_ADVERTISING]: (payload) => oldState => {
        if(payload.advertising.prop === 'resetObj') {
            return $set('advertising.advertising', {}, oldState);
        } else {
            return $set('advertising.advertising.' + payload.advertising.prop, payload.advertising.val, oldState);
        }
    },
    [UPDATE_ADVERTISING_LIST]: (payload) => oldState => {
        return $set('advertising.list', payload.list, oldState);
    },
    [DELETE_ADVERTISING]: (payload) => oldState => {
        let list = [...oldState.advertising.list];
        list = list.filter(advertising => advertising.id !== payload.advertising.id)
        return $set('advertising.list', list, oldState);
    },

    [UPDATE_ADVERTISING_LIST_COUNT]: (payload) => oldState => {
        return $set('advertising.listCount', payload.count, oldState);
    },

    [UPDATE_LOADING_ANIMATION]: (payload) => oldState => {
        return $set('advertising.loadingAnimation', payload.value, oldState);
    },

    [RESET_STATE]: (payload) => oldState => {
        return $set('advertising', initialState, oldState);
    },

});

const selectors = {
    advertising: $get('advertising'),
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
