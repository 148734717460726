import moment from 'moment';
import 'moment/locale/de';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import { actions, initialState, reducerArray } from './Redux/index';
import sagas from './Sagas/index';
import {host} from './Sagas/host';
import { combineReducers } from './Utility/HandleActions';

moment.locale('de');
//console.log(initialState)
// Update initial state with some external data
if (window.localStorage.getItem('Laminat_Depot_Store')) {
    const lS = JSON.parse(window.localStorage.getItem('Laminat_Depot_Store'))
    initialState.ui = lS.ui
    initialState.data.filter.stock.category.selectedId = lS.stockFilterId
    initialState.data.filter.stock.category.selectedLabel = lS.stockFilterLabel
   /* initialState.data = lS.data
    initialState.absence = lS.absence
    initialState.advertising = lS.advertising
    initialState.category = lS.category
    initialState.claims = lS.claims
    initialState.news = lS.news
    initialState.sickReport = lS.sickReport
    initialState.task = lS.task
    initialState.training = lS.training */
}

initialState.ui.token = window.localStorage.getItem('Laminat_Depot_JWT') || '';
initialState.ui.username = process.env.REACT_APP_DEFAULT_USER || '';
initialState.ui.password = process.env.REACT_APP_DEFAULT_PASSWORD || '';



const reducer = combineReducers(reducerArray);
const sagaMiddleware = createSagaMiddleware();
// noinspection JSUnresolvedVariable
const composeEnhancers = composeWithDevTools({ actions, trace: true, traceLimit: 500 });
const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagas.forEach((saga) => sagaMiddleware.run(saga));

if (initialState.ui.token) {
    store.dispatch(actions.Data.loadUserData({ token: initialState.ui.token }));
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

/**
 * for shortlinks
 */
const pathArray = window.location.pathname.split('/');
if (pathArray[1] === 'sl') {
    // deprecated
    // const form = document.createElement('form');
    // form.method = 'post';
    // form.target = '_blank';
    // form.action = host+'/download/'+pathArray[2];
    // const hiddenField = document.createElement('input');
    // hiddenField.type = 'hidden';
    // hiddenField.name = 'authTok';
    // hiddenField.value = 'Bearer ' + initialState.ui.token;
    // form.appendChild(hiddenField);
    // document.body.appendChild(form);
    // form.submit();

    //new version
    const downloadUrl = host + '/download/' + pathArray[2];
    window.location.href = downloadUrl + '?authTok=' + encodeURIComponent('Bearer ' + initialState.ui.token);
    window.history.replaceState({}, document.title, "/");
}
