import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import './assets/styles/app.scss';
import Login from './Containers/Login';
import Main from './Containers/Main';
import logoBlack from './assets/img/daten_depot_black.svg';
import Alert from './Containers/Alert';

const mapStateToProps = (state) => ({
    token: state.ui.token,
    currentUser: state.data.currentUser
});

class App extends React.PureComponent {
    static propTypes = {
        authenticated: PropTypes.bool,
    };

    renderPage() {
        if (this.props.token.length && this.props.currentUser.id) {
            return <Main />;
        } else if (this.props.token.length && !this.props.currentUser.id.length) {
            return (
                <div className="login-site">
                    <Alert/>
                    
                                        
                    <div className="page-wrapper" >
                        <div id="main-side">
                            <main>
                                <div className="login">
                                    <div className="login-header">
                                        <img src={logoBlack} alt="DatenDEPOT"/>
                                    </div>

                                    <div className="login-body">
                                        <p>connecting <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/></p>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <Login />;
        }

    }

    render() {
        return (<div>{this.renderPage()}</div>);
    }
}

export default connect(mapStateToProps)(App);
