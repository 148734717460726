import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';
import { delay } from 'redux-saga';

function* articleSaga() {
    yield takeLatest([
        actionTypes.Data.LOAD_ARTICLES,
        actionTypes.Data.UPDATE_STOCK_FILTER,
        actionTypes.Data.UPDATE_STOCK_SORTING
        ], function* (action) {
        try {
            yield put(actions.Ui.updateStockLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const stateData = yield select(selectors.Data.data);
            const category = stateData.stockFilter.category || 'null';
            const manufacturer = stateData.stockFilter.manufacturer || 'null';
            const number = stateData.stockFilter.number || 'null';
            const decor = stateData.stockFilter.decor || 'null';
            const from = action.payload.article ? (action.payload.article.lazy ? stateData.articleList.length : 0) : 0;
            const label = stateData.stockFilter.label ? `?label=${stateData.stockFilter.label}` : ''
            const sign = label ? '&' : '?'
            const sorting = stateData.stockSorting.length ? `${sign}sorting=${stateData.stockSorting.join(',')}` : ''

            const result = yield fetch(host + '/article/filter/'+category+"/"+manufacturer+"/"+decor+"/"+number+"/"+from+label+sorting, {
                method: 'GET',
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const articleList = yield result.json();
                yield put(actions.Data.loadLocationSortimentHistoryList({sortiment: category}))
                if (action.payload.article) {
                    if (action.payload.article.lazy) {
                        yield put(actions.Data.updateArticleList({articleList: articleList.articles, reset: false}));
                    }
                    else {
                        yield put(actions.Data.updateArticleList({articleList: articleList.articles, reset: true}));
                        yield put(actions.Data.updateArticleList({articleList: articleList.articles, reset: false}));
                        yield put(actions.Data.updateArticleCount(articleList.count));
                    }
                } else {
                    yield put(actions.Data.updateArticleList({articleList: articleList.articles, reset: true}));
                    yield put(actions.Data.updateArticleList({articleList: articleList.articles, reset: false}));
                    yield put(actions.Data.updateArticleCount(articleList.count));
                }
                yield put(actions.Ui.updateStockLoadingAnimation(false));
                yield put(actions.Data.updateArticlesFromAdvertising(false));
            }
        } catch (e) {
            console.log("Error", e);
        }
    });
}

function* articleDropdownSaga() {
    yield takeLatest([
        actionTypes.Data.LOAD_ARTICLE_DROPDOWN_LIST,
        actionTypes.Data.UPDATE_ARTICLE_DROPDOWN_FILTER,
        actionTypes.Data.LOAD_ARTICLES_WITH_EAN], function* (action) {
        try {
            yield put(actions.Ui.updateStockLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const stateData = yield select(selectors.Data.data);
            const category = stateData.stockFilter.category || 'null';
            const manufacturer = stateData.articleDropdownFilter.manufacturer || 'null';
            let number = stateData.articleDropdownFilter.number || 'null';
            const decor = stateData.articleDropdownFilter.decor || 'null';

            if (number === 'null' && action.type === 'Data/LoadArticlesWithEan') {
                number = action.payload.filter.ean;
            }

            const result = yield fetch(host + '/article/listall/'+category+"/"+manufacturer+"/"+decor+"/"+number, {
                method: 'GET',
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const articleList = yield result.json();
                yield put(actions.Data.updateArticleDropdownList(articleList))
            }
        } catch (e) {
            console.log("Error", e);
        }
    });
}

function* saveArticleSaga() {
    yield takeLatest(actionTypes.Data.SAVE_ARTICLE, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/article', {
                method: 'POST',
                headers,
                body: JSON.stringify(action.payload.article),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateArticleToEdit({}));
                yield put(actions.Data.updateModal({path: 'stock.article', prop: 'view', val: false}));
                yield put(actions.Data.updateModal({path: 'stock.article', prop: 'tab', val: 1}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.manufacturer', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.manufacturer', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.category', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.category', prop:'selectedId', val:''}));
            }
        } catch (e) {
            console.log("Error", e);
        }
    });
}

function* updateArticleSaga() {
    yield takeLatest([actionTypes.Data.UPDATE_ARTICLE, actionTypes.Data.TOGGLE_ARTICLE_VISIBILITY], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const id = action.payload.article.article.id;
            let art = action.payload.article;

            if(action.type !== 'Data/ToggleArticleVisibility') {
                delete art.article.id;
            }

            if(action.type === 'Data/ToggleArticleVisibility') {
                art = {
                    article: {
                        active: action.payload.article.article.active
                    }
                }
            }

            const result = yield fetch(host + '/article/'+id, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(art),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const article = yield result.json();

                if(action.type !== 'Data/ToggleArticleVisibility') {
                    yield put(actions.Data.updateArticleToEdit({}));
                    yield put(actions.Data.updateModal({path: 'stock.article', prop: 'view', val: false}));
                    yield put(actions.Data.updateModal({path: 'stock.article', prop: 'action', val: 'newArticle'}));
                    yield put(actions.Data.updateModal({path: 'stock.article', prop: 'tab', val: 1}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.manufacturer', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.manufacturer', prop:'selectedId', val:''}));

                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.category', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.category', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateArticleState(article));
                }
            }

        } catch (e) {
            console.log("Error", e);
        }
    });
}

function* deleteArticleSaga() {
    yield takeLatest(actionTypes.Data.DELETE_ARTICLE, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/article/'+action.payload.article.id, {
                method: 'DELETE',
                headers
            });
            yield put(actions.Ui.checkLogin(result.status));

        } catch (e) {
            console.log("Error", e);
        }
    });
}

function* updateStockSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_STOCK, function* (action) {
        try {
            yield delay(10);

            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/article/updatestock', {
                method: 'POST',
                headers,
                body: JSON.stringify(action.payload.stock),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const article = yield result.json();
                if(article === null) {
                    yield alert('Artikel mit der ean nummer '+action.payload.stock.stock.ean+' wurde nicht gefunden.');
                } else {
                    yield put(actions.Data.updateArticleToEdit({}));
                    yield put(actions.Data.updateModal({path: 'stock.scan', prop: 'view', val: false}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.article', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.stock.article', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateArticleState(article));
                }
                yield put(actions.Ui.updateStockLoadingAnimation(false));
                yield put(actions.Data.updateCurrentStockInput({value: null, index: null}))
            }
        } catch (e) {
            console.log("Error", e);
        }
    });
}


function* findArticlesSaga() {
    yield takeLatest([actionTypes.Data.FIND_ARTICLES], function* (action) {
        try {
            yield put(actions.Ui.updateStockLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/article/filterlist', {
                method: 'POST',
                headers,
                body: JSON.stringify({"articles": action.payload.articles})});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const articleList = yield result.json();
                yield put(actions.Data.updateArticleList({articleList: articleList.articles, reset: true}));
                yield put(actions.Data.updateArticleList({articleList: articleList.articles, reset: false}));
                yield put(actions.Data.updateArticleCount(articleList.count));
                yield put(actions.Ui.updateStockLoadingAnimation(false));
            }

        } catch (e) {
            console.log("Error", e);
            yield put(actions.Ui.updateStockLoadingAnimation(false));
        }
    });
}


function* saveArticleBackgroundSaga() {
    yield takeLatest(actionTypes.Ui.SAVE_ARTICLE_BACKGROUND, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const color = action.payload.article.color ? action.payload.article.color.replace('#', '') : 0
            const result = yield fetch(`${host}/articlebackground/${action.payload.article.id}/${color}`, {
                method: 'GET',
                headers
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("Error", e);
        }
    });
}



export default [
    articleSaga,
    articleDropdownSaga,
    saveArticleSaga,
    updateArticleSaga,
    deleteArticleSaga,
    updateStockSaga,
    findArticlesSaga,
    saveArticleBackgroundSaga
];
