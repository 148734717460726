import React from 'react';
import Breadcrumb from '../Components/UI/Breadcrumb';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        globalSettings: state.data.globalSettings,
    };
};


class GoogleSheetPage extends React.Component {


    render() {
        return (
            <div id="main-side">
                <Breadcrumb path={['Sortiment']} />
                <main>
                    <div className="iframe">
                        <iframe title="googlesheets" src={this.props.globalSettings.googleSheetsLink} width="100%" height="1200"></iframe>
                    </div>
                </main>
                <footer id="footer-main"></footer>
            </div>
        );
    }
}

export default connect(mapStateToProps)(GoogleSheetPage);
