import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../Redux';
import CardGrid from '../UI/Card/CardGrid';
import CategoryForm from './CategoryForm';
import CategoryTile from './CategoryTile';
import logoBlack from '../../assets/img/daten_depot_black.svg'


const mapStateToProps = (state) => ({
    articleList: state.data.articleList,
    categoryList: state.category.list,
    currentUser: state.data.currentUser,
    token: state.ui.token,
});

const mapDispatchToProps = (dispatch) => ({
    loadList: (value) => dispatch(actions.Category.loadList(value)),
    deleteCategory: (value) => dispatch(actions.Category.deleteCategory(value)),
    updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
});

class CategoryList extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object,
        categoryList: PropTypes.array,
        updateConfirmModal: PropTypes.func
    };

    state = {
        editCategory: null,
    };

    componentDidMount() {
        this.props.loadList(0);
    }

    createCategory = () => {
        this.can('sortiment_kategorie_erstellen')
        && this.setState({
            editCategory: null,
        });
    };

    editCategory = (category) => {
        this.can('sortiment_kategorie_bearbeiten')
        && this.setState({
            editCategory: category,
        });
    };

    deleteCategory = (category) => {
        if (!this.can('sortiment_kategorie_l_schen')) {
            return;
        }
        if (this.props.articleList.some((article) => article.category && article.category.id === category.id)) {
            window.alert('Nur leere Sortimente können gelöscht werden.');
            return;
        }
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Sortiment löschen'})
        this.props.updateConfirmModal({prop: 'text', value: 'Sortiment "' + category.name + '" löschen?'})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteCategory', param: category}})
        //window.confirm('Sortiment "' + category.name + '" löschen?')
        //&& this.props.deleteCategory(category);
    };

    resetEditCategory = () => {
        this.setState({editCategory: null});
    };

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    render() {
        if(!this.props.categoryList.length) {
            return (
                <div className="page-wrapper" >
                    <div id="main-side">
                        <main>
                            <div className="login">
                                <div className="login-header">
                                    <img src={logoBlack} alt="DatenDEPOT"/>
                                </div>

                                <div className="login-body">
                                    <p>connecting <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/></p>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            )
        }
        return (
            <CardGrid>
                {this.state.editCategory &&
                <CategoryForm
                    category={this.state.editCategory}
                    onSubmit={this.resetEditCategory}
                    onCancel={this.resetEditCategory}
                />}
                    {this.props.categoryList.sort((a, b) => a.position - b.position).map((category, index) =>
                            <CategoryTile
                                key={category.id || index}
                                category={category}
                                onEdit={this.can('sortiment_kategorie_bearbeiten') ? this.editCategory : undefined}
                                onDelete={this.can('sortiment_kategorie_l_schen') ? this.deleteCategory : undefined}
                            />,
                        )}

            </CardGrid>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CategoryList);
