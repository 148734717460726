import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import './card.scss';

export default class CardGrid extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        footer: PropTypes.element,
        halfWidth: PropTypes.bool,
        header_extra: PropTypes.element,
        icon: PropTypes.string,
        title: PropTypes.string,
    };

    render() {
        return (
            <div
                className={classNames(this.props.className, 'card__grid content-wrapper inner-ver')}
            >
                <div className="content-wrapper-box">
                    <div className="grid-wrapper">{this.props.children}</div>
                </div>
            </div>
        );
    }
}
