import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import FileListItem from './FileListItem';
import FileListItemPlaceholder from './FileListItemPlaceholder';

class FileList extends React.PureComponent {
    static propTypes = {
        files: PropTypes.arrayOf(PropTypes.object),
        placeholderCount: PropTypes.number,
        onDelete: PropTypes.func,
        striped: PropTypes.bool,
        hideDownloadIcons: PropTypes.bool,
        token: PropTypes.string,
    };

    getFileItems = () => {
        return this.props.files.map((file, index) => {
            const deleteFunc = this.props.onDelete
                ? (f) => this.props.onDelete(f, index)
                : undefined;
            return (
                <FileListItem
                    key={file.id || index}
                    file={file}
                    hideDownloadIcon={this.props.hideDownloadIcons}
                    onDelete={deleteFunc}
                    token={this.props.token}
                />
            );
        });
    };

    getPlaceholder = () =>
        [...Array(this.props.placeholderCount || 0)].map((_, idx) => (
            <FileListItemPlaceholder key={idx} />
        ));

    render() {
        return (
            <div
                className={classNames(
                    this.props.className,
                    'dokumente-list',
                    this.props.striped ? 'dokumente-list-striped' : ''
                )}
            >
                <div className="dokumente-wrapper">
                    {this.props.files && this.props.files.length
                        ? this.getFileItems()
                        : this.getPlaceholder()}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default FileList;
