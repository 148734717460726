import React from 'react';

const Pagination = (countSource, activePage, updateFunc) => {
    let pages = countSource;
    let mod = pages % 6;
    if (pages >= 6) {
        pages = (pages - mod) / 6;
        if (mod !== 0) {
            pages++;
        }
    } else {
        pages = 1;
    }
    let items = [];
    let active = activePage + 1;

    let next = activePage + 1;
    let prev = activePage - 1;

    if (prev < 0) {
        prev = 0;
    }
    if (next > pages - 1) {
        next = pages - 1;
    }

    for (let number = 1; number <= pages; number++) {
        let cls = 'inactive';
        let style = { display: 'none' };
        let label = number;
        if (number === active) {
            cls = 'active';
            style = { display: 'block' };
        } else if (
            active + 1 === number ||
            active + 2 === number ||
            active - 1 === number ||
            active - 2 === number
        ) {
            style = { display: 'block' };
        }
        if (number === 1 || number === pages) {
            style = { display: 'block' };
        }
        if (active - 3 === number && number > 1) {
            label = '...';
            style = { display: 'block' };
        }
        if (active + 3 === number && number < pages) {
            label = '...';
            style = { display: 'block' };
        }
        items.push(
            <li key={number} className={cls} style={style}>
                <a
                    href="/#-"
                    onClick={(e) => {
                        e.preventDefault();
                        updateFunc(number - 1);
                    }}
                >
                    {label}
                </a>
            </li>
        );
    }
    return (
        <div className="small-12 medium-12 large-12">
            <div className="table-navigation">
                <div className="site-list">
                    <ul>{items}</ul>
                </div>
                <div className="site-next-prev">
                    <span
                        className="site-next"
                        onClick={ e => {
                            e.preventDefault();
                            updateFunc(prev);
                        }}
                    />
                    <span
                        className="site-prev"
                        onClick={ e => {
                            e.preventDefault();
                            updateFunc(next);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Pagination;
