import React from 'react';

const PermissionCheckbox = ({title, checked, action, disabled}) =>(
    <div className="small-12 medium-12 large-12">
        <div className="check-list" style={{ boxShadow: 'none' }} >
            <div className="check-list-main">
                <div className="checkbox-wrapper" style={
                    { backgroundColor: '#fff'}}>
                    <label style={
                        {
                            opacity: disabled ? 0.3 : 1
                        }}>
                        <span className={checked ? 'boxChecked' : 'boxUnchecked'}
                            onClick={action}
                        />
                    </label>
                    <div className="checkbox-text">
                        <span className={'prim-text'}>
                            {title}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
)


export default PermissionCheckbox;
