import PermissionCheckbox from 'Components/Settings/PermissionCheckbox';
import Button from 'Components/UI/Button';
import Dropdown from 'Containers/Dropdown';
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'Redux/index';
import { Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import HeadlineWithBacklink from 'Components/Settings/HeadlineWithBacklink';
import Card from 'Components/Settings/Card';
import './settings.scss'

const mapStateToProps = (state) => {
    return {
        alert: state.ui.alert,
        teamToEdit: state.settings.teamToEdit,
        documentsParentUserGroupDropdown: state.data.dropdown.settings.user.usergroup,
        copyFromDropdown: state.data.dropdown.settings.permissions.copyFrom,
      //  vacationTaskReceiverUserGroupDropdown: state.data.dropdown.settings.permissions.usergroup,
        subgroupsDropdown: state.data.dropdown.settings.subgroups,
        userGroupList: state.data.userGroupList,
        permissions: state.settings.permissions,
        permissionCategories: state.settings.permissionCategories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAlert: (alert) => dispatch(actions.Ui.updateAlert(alert)),
        updateTeamToEdit: team => dispatch(actions.Settings.updateTeamToEdit(team)),
        updatePermissionsView: value => dispatch(actions.Settings.updatePermissionsView(value)),
        loadPermissions: value => dispatch(actions.Settings.loadPermissions()),
        saveTeam: team => dispatch(actions.Data.saveTeam(team)),
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
    };
};

class Permissions extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            teamPermissions: []
        };
    }

    static propTypes = {
        alert: PropTypes.object,
        updateAlert: PropTypes.func,
        teamToEdit: PropTypes.object,
        permissionsView: PropTypes.bool,
        updatePermissionsView: PropTypes.func,
        loadPermissions: PropTypes.func,
        documentsParentUserGroupDropdown: PropTypes.object,
       // vacationTaskReceiverUserGroupDropdown: PropTypes.object,
        permissions: PropTypes.array,
        permissionCategories: PropTypes.array,
        saveTeam: PropTypes.func,
        subgroupsDropdown: PropTypes.object
    };

    componentDidMount() {
        //const vacationTaskReceiverLabel = this.props.userGroupList.find(group => group.name === this.props.teamToEdit.vacationTaskReceiver);
        this.props.loadPermissions(true)
        // this.props.updateDropdown({
        //     dropdown: this.props.vacationTaskReceiverUserGroupDropdown.path,
        //     prop: 'selectedId',
        //     val: this.props.teamToEdit.vacationTaskReceiver
        // })
        // this.props.updateDropdown({
        //     dropdown: this.props.vacationTaskReceiverUserGroupDropdown.path,
        //     prop:'selectedLabel',
        //     val: vacationTaskReceiverLabel ? vacationTaskReceiverLabel.label : ''
        // })
        this.setState((state) => {
            return {
                ...state,
                teamPermissions : this.props.teamToEdit.permissions ? [...this.props.teamToEdit.permissions] : []
            };
        })
        const waitForPermissionsToLoad = setInterval(() => {
            if (this.props.permissions.length) {
                let subpermissionNames = this.state.teamPermissions
                    .filter(tp => tp.dependencies.length)
                    .map(tp => [...tp.dependencies])
                subpermissionNames = [].concat.apply([], subpermissionNames);

                const additionalPermissions = this.props.permissions.filter(p => subpermissionNames.includes(p.name)).filter(p => !this.state.teamPermissions.find(tp => p.id === tp.id ))
                this.setState((state) => {
                    return {
                        ...state,
                        teamPermissions : this.props.teamToEdit.permissions ? [...this.props.teamToEdit.permissions, ...additionalPermissions] : []
                    };
                })
                clearInterval(waitForPermissionsToLoad)
            }
        }, 20)
    }
    teamHasPermission(permission) {
       return  !!this.state.teamPermissions.find(tp => tp.id === permission.id)
    }

    togglePermission(permission) {
        const conflictingWith = permission.conflictingWith.length ? this.state.teamPermissions.find(tp => permission.conflictingWith.includes(tp.name)) : null;
        if (conflictingWith && !this.teamHasPermission(permission)) {
            return alert(`"${permission.label}" kann nicht mit "${conflictingWith.label}" zusammen ausgewält werden`)
        }
        const additionalPermissions = this.props.permissions.filter(p => permission.dependencies.includes(p.name)).filter(p => !this.state.teamPermissions.find(tp => p.id === tp.id ))
        this.setState((state) => {
            return {
                ...state,
                teamPermissions :
                    this.state.teamPermissions.find(tp => tp.id === permission.id) ?
                        [...state.teamPermissions.filter(tp => tp.id !== permission.id)] :
                        [...state.teamPermissions, permission, ...additionalPermissions]
            };
        })
    }

    saveTeam() {
        if (!this.props.teamToEdit.name || this.props.teamToEdit.name.length < 3) {
            return alert('Benutzergruppenname ist ein Pflichtfeld')
        }
        // if (!this.props.vacationTaskReceiverUserGroupDropdown.selectedId) {
        //     return alert('"Urlaubsanträge gehen an" ist ein Pflichtfeld')
        // }
        const team = {
            ...this.props.teamToEdit,
            parent: this.props.documentsParentUserGroupDropdown.selectedId,
        //    vacationTaskReceiver: this.props.vacationTaskReceiverUserGroupDropdown.selectedId,
            subordinateUserGroups: this.props.subgroupsDropdown.selectedId || [],
            permissions: [...this.state.teamPermissions]
        }
        this.props.saveTeam(team)
    }

    disabled(permission) {
        if (!permission.parentPermission) {
            return false;
        }
        if (this.state.teamPermissions.find(tp => permission.parentPermission.includes(tp.name))) {
            return true;
        }
    }

    copyFrom(userGroupName) {
        const userGroup = this.props.userGroupList.find(ug => ug.name === userGroupName)
        this.setState((state) => {
            return {
                ...state,
                teamPermissions : [...userGroup.permissions]
            };
        })
    }

    render() {
        return (
            <Card marginTop>
                <HeadlineWithBacklink
                    title={this.props.teamToEdit.view !== 'edit' ? 'Benutzerguppe erstellen' : `Benutzergruppe ${this.props.teamToEdit.label} bearbeiten`}
                    linkTitle="zurück"
                    action={() => this.props.updatePermissionsView(false)}
                />
                <div className="text-input-wrapper">
                    {this.props.teamToEdit.view !== 'edit' &&
                        <div className="small-12 medium-12 large-12">
                            <div className="group" >
                                <input type="text"
                                    value={this.props.teamToEdit.name || ''}
                                    disabled={this.props.teamToEdit.view === 'edit'}
                                    onChange={ e => {
                                        this.props.updateTeamToEdit({
                                            ...this.props.teamToEdit,
                                            name: e.target.value
                                        })
                                    }}
                                />
                                <label>Name</label>
                            </div>
                        </div>
                    }
                    {this.props.teamToEdit.view === 'edit' &&
                        <div className="small-12 medium-12 large-12">
                            <div className="group">
                                <input type="text"
                                       value={this.props.teamToEdit.label || ''}
                                       onChange={e => {
                                           this.props.updateTeamToEdit({
                                               ...this.props.teamToEdit,
                                               label: e.target.value
                                           })
                                       }}
                                />
                                <label>Name</label>
                            </div>
                        </div>
                    }
                    <div className="dropdown-wrapper">
                        <div className="small-12 medium-6 large-12">
                            <div className="group">
                                <input type="number"
                                       value={this.props.teamToEdit.sortingIndex || ''}
                                       onChange={ e => {
                                           this.props.updateTeamToEdit({
                                               ...this.props.teamToEdit,
                                               sortingIndex: e.target.value
                                           })
                                       }}
                                />
                                <label>Position in der Liste</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion-body-wrapper">

                    <div className="dropdown-wrapper copy-from">
                        <Dropdown
                            dropdown={this.props.copyFromDropdown}
                            dropdownList={this.props.userGroupList}
                            dropdownLabelOverride={'Vorlage für Rechte'}
                            itemIdProp={'name'}
                            itemLabelProp={'label'}
                            onSelect={(userGroupName) => this.copyFrom(userGroupName)}
                        />
                    </div>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemTitle>Rechte bearbeiten</AccordionItemTitle>
                            <AccordionItemBody>
                                <div className="accordion-body-wrapper">
                                    {this.props.permissionCategories.map(permissionCategorie => (
                                        <Accordion key={permissionCategorie}>
                                            <AccordionItem>
                                                <AccordionItemTitle><i
                                                    className={this.props.permissions.find(p => p.category === permissionCategorie).categoryIcon}
                                                    aria-hidden="true"
                                                    role="presentation"/>
                                                    {permissionCategorie}
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {this.props.permissions
                                                        .filter(p => p.category === permissionCategorie)
                                                        .map(permission => (
                                                            <PermissionCheckbox
                                                                key={permission.name}
                                                                checked={this.teamHasPermission(permission)}
                                                                action={() => {
                                                                    if (!this.disabled(permission)) {
                                                                        this.togglePermission(permission)
                                                                    }
                                                                }}
                                                                title={permission.label}
                                                                disabled={this.disabled(permission)}
                                                            />
                                                        ))
                                                    }
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>
                                    ))}

                                </div>
                            </AccordionItemBody>
                        </AccordionItem>
                    </Accordion>
                    <div className="dropdown-wrapper">
                        <div className="group">
                            <textarea
                                value={this.props.teamToEdit.rights || ''}
                                onChange={e =>
                                    this.props.updateTeamToEdit({
                                        ...this.props.teamToEdit,
                                        rights: e.target.value
                                    })
                                }
                            />
                            <label>Beschreibung</label>
                        </div>
                    </div>
                    <div className="dropdown-wrapper">
                        <Dropdown
                            dropdown={this.props.documentsParentUserGroupDropdown}
                            dropdownList={this.props.userGroupList}
                            dropdownLabelOverride={'Übergeordnete Gruppe für Dokumente'}
                            itemIdProp={'name'}
                            itemLabelProp={'label'}
                        />
                    </div>
                    {/*
                    <div className="dropdown-wrapper">
                        <Dropdown
                            dropdown={this.props.vacationTaskReceiverUserGroupDropdown}
                            dropdownList={this.props.userGroupList}
                            dropdownLabelOverride={'Urlaubsanträge gehen an'}
                            itemIdProp={'name'}
                            itemLabelProp={'label'}
                        />
                    </div>
                    */}
                    <div className="dropdown-wrapper margin-bottom-half">
                        <Dropdown
                            dropdown={this.props.subgroupsDropdown}
                            dropdownList={this.props.userGroupList}
                            dropdownLabelOverride={'Untergeordnete Gruppen für die Urlaubsansicht'}
                            itemIdProp={'name'}
                            itemLabelProp={'label'}
                            multiple={true}
                        />
                    </div>
                    <div className="dropdown-wrapper">
                        <div className="small-12 medium-6 large-12">
                            <div className="group">
                                <input type="number"
                                       value={this.props.teamToEdit.loginTimeForDesktop || ''}
                                       onChange={e => {
                                           this.props.updateTeamToEdit({
                                               ...this.props.teamToEdit,
                                               loginTimeForDesktop: e.target.value
                                           })
                                       }}
                                />
                                <label>Dauer bis zum Logout (min.)</label>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown-wrapper">
                        <div className="small-12 medium-6 large-12">
                            <div className="group">
                                <input type="number"
                                       value={this.props.teamToEdit.loginTimeForTablet || ''}
                                       onChange={e => {
                                           this.props.updateTeamToEdit({
                                               ...this.props.teamToEdit,
                                               loginTimeForTablet: e.target.value
                                           })
                                       }}
                                />
                                <label>Dauer bis zum Logout iPad (min.)</label>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-body-wrapper margin-top">
                        <Button label="Speichern"
                                onClick={() => this.saveTeam()}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
