import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* createStatusSaga() {
    yield takeLatest(actionTypes.Data.SAVE_STATUS, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const status = {
                "status": {
                    "name": stateData.statusToEdit.name,
                },
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/status', {
                method: "POST",
                headers,
                body: JSON.stringify(status),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateStatusToEdit({ id: '',name: ''}));
                yield put(actions.Ui.updateStatusModal(false));
                yield put(actions.Data.loadStatuses(true));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* statusesSaga() {
    yield takeLatest([actionTypes.Data.LOAD_STATUSES, actionTypes.Ui.UPDATE_STATUS_LIST_PAGE], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const sorting = yield select(selectors.Settings.statusSort);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/status/list?sort='+sorting, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const statuses = yield result.json();
                yield put(actions.Data.updateStatuses(statuses));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateStatusSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_STATUS, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const status = {
                "status": {
                    name: stateData.statusToEdit.name,
                },
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/status/' + action.payload.status.id, {
                method: "PUT",
                headers,
                body: JSON.stringify(status),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateStatusToEdit({id: '', name: ''}));
                yield put(actions.Ui.updateStatusModal(false));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteStatusSaga() {
    yield takeLatest(actionTypes.Data.DELETE_STATUS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/status/' + action.payload.status.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [createStatusSaga, statusesSaga, updateStatusSaga, deleteStatusSaga];
