import { select } from 'redux-saga/effects';
import { selectors } from '../Redux';

function* buildRequest(method = 'GET', body = undefined) {
    yield select(selectors.Ui.token);
    const token = yield select(selectors.Ui.token);
    if (!token) {
        throw Error('Not logged in.');
    }
    let headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);
    return {
        method: method.toUpperCase(),
        headers: headers,
        body: body,
    };
}

function handleFetchResponse(response) {
    if (!response.ok) {
        throw Error('Anfrage fehlgeschlagen: ' + response.statusText);
    }
    if (!response.json) {
        throw Error('Keine gültigen Daten erhalten: ' + response.data);
    }
    return response;
}

export default {
    buildRequest,
    handleFetchResponse,
}
