import * as PropTypes from 'prop-types';
import React from 'react';

export default class FormInput extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,

        component: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.elementType,
            PropTypes.node,
            PropTypes.string,
        ]),
        errorMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
        size: PropTypes.string,
        value: PropTypes.any,
    };

    className = () => {
        if (this.props.size === 'large') {
            return 'small-12 medium-12 large-12';
        }
        if (this.props.size === 'medium') {
            return 'small-12 medium-6 large-6';
        }
        if (this.props.size === 'third') {
            return 'small-12 medium-6 large-4';
        }
        return this.props.size ? this.props.size : '';
    };

    id = () => {
        if (this.props.id) {
            return this.props.id;
        }
        if (this.props.name) {
            return 'FormField__' + this.props.name;
        }
        return 'FormField__' + Math.random().toString(36);
    };

    render() {
        let { label, errorMessage, component, size, ...childProps } = this.props;
        childProps.id = this.id();
        if (!component) {
            component = 'input';
            childProps.type = 'text';
        }
        const input = React.isValidElement(component)
            ? React.cloneElement(component, childProps)
            : React.createElement(component, childProps);

        return (
            <div className={this.className()}>
                <div className="group">
                    {input}
                    <label htmlFor={this.id()}>{this.props.label}</label>
                </div>
                {typeof this.props.errorMessage === 'string' ? (
                    <div>{this.props.errorMessage}</div>
                ) : (
                    this.props.errorMessage
                )}
            </div>
        );
    }
}
