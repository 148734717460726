import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* createManufacturerSaga() {
    yield takeLatest(actionTypes.Data.SAVE_MANUFACTURER, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);

            const manufacturer = {
                "manufacturer": {
                    "name": stateData.manufacturerModalInput,
                },
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/manufacturer', {
                method: "POST",
                headers: headers,
                body: JSON.stringify(manufacturer),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const newManufacturer = yield result.json();
                yield put(actions.Data.updateManufacturerModalInput(''));
                yield put(actions.Data.addManufacturer(newManufacturer));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* manufacturersSaga() {
    yield takeLatest([actionTypes.Data.LOAD_MANUFACTURER_LIST, actionTypes.Ui.UPDATE_MANUFACTURER_LIST_PAGE], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/manufacturer/list', {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const manufacturers = yield result.json();
                yield put(actions.Data.updateManufacturers(manufacturers));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateManufacturerSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_MANUFACTURER, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const manufacturer = {
                "manufacturer": { name: stateData.manufacturerModalInput },
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/manufacturer/' + action.payload.manufacturer.id, {
                method: "PUT",
                headers,
                body: JSON.stringify(manufacturer),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateManufacturerModalInput(''));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteManufacturerSaga() {
    yield takeLatest(actionTypes.Data.DELETE_MANUFACTURER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/manufacturer/' + action.payload.manufacturer.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [createManufacturerSaga, manufacturersSaga, updateManufacturerSaga, deleteManufacturerSaga];
