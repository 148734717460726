import { delay } from 'redux-saga';
import { all, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

const messageDelay = 2000;

function* authenticateSaga() {
    yield takeLatest(actionTypes.Ui.LOGIN, function*(action) {
        try {
            let data = new FormData();
            yield put(actions.Ui.updateLoginLoadingAnimation(true));
            data.append('username', action.payload.credentials.username);
            data.append('password', action.payload.credentials.password);
            const result = yield fetch(host + '/token', { method: 'POST', body: data });
            const auth = yield result.json();

            if (auth.token) {
                yield put(actions.Ui.updateToken(auth.token));
                window.localStorage.setItem('Laminat_Depot_JWT', auth.token);
                yield put(actions.Data.loadUserData({ token: auth.token, initial: true }));
            } else {
                yield put(
                    actions.Ui.updateAlert({
                        view: 'visible',
                        type: 'Error',
                        message: 'Falscher Benutzername oder Passwort.',
                    })
                );
                yield delay(messageDelay);
                yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
                yield put(actions.Ui.updateLoginLoadingAnimation(false));
            }
        } catch (e) {
            window.localStorage.setItem('Laminat_Depot_JWT', '');
            window.localStorage.setItem('Laminat_Depot_Store', '');
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Falscher Benutzername oder Passwort.',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            yield put(actions.Ui.updateLoginLoadingAnimation(false));
        }
    });
}

function* getUserDataSaga() {
    yield takeLatest(actionTypes.Data.LOAD_USER_DATA, function*(action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/user', { method: 'GET', headers });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const userData = yield result.json();
                const usr = {...userData.user}
                usr.parentTeam = userData.parentTeam
                usr.lastSeenNewsDate = userData.lastSeenNewsDate
                yield all([
                    put(actions.Data.loadLocationList(0)),
                    put(actions.Ui.countLocation(true)),
                    put(actions.Ui.loadGroups(true)),
                    put(actions.Data.updateUserData(usr)),
                    put(actions.Ui.countUser(true)),
                    //put(actions.Data.countSickDays(true)),
                    put(actions.Data.loadColors(true)),
                    put(actions.Data.loadClaimDropdownManufacturers(true)),
                    put(actions.Data.loadClaimDropdownReasons(true)),
                    put(actions.Data.loadClaimDropdownQualities(true)),
                    put(
                        actions.Absence.loadAbsenceList({
                            from: 0,
                            view: 1,
                            lazy: false,
                        })
                    ),

                ]);

                if (userData.user.permissions.includes('aufgaben_alle_sehen')) {
                    yield put(actions.Data.updateFilter({path: 'data.filter.task.location', prop:'selectedId', val:userData.user.location.id}));
                    yield put(actions.Data.updateFilter({path: 'data.filter.task.location', prop:'selectedLabel', val:userData.user.location.label}));
                    yield put(actions.Data.updateFilter({path: 'data.filter.task.team', prop:'selectedId', val:userData.user.roleIdentifier}));
                    yield put(actions.Data.updateFilter({path: 'data.filter.task.team', prop:'selectedLable', val:userData.user.roleLabel}));
                }
                yield put(actions.Task.reloadTaskList(true));
                yield put(actions.Data.loadDocumentList(true));
                yield put(actions.Data.loadFolderList(true));
                //yield put(actions.Data.loadCurrentFolder(true));
                // yield put(actions.Claims.loadList({ filter: { completed: false }, forceReload: true }));
                // yield put(actions.Claims.loadList({ filter: { completed: true }, forceReload: true }));
                yield put(actions.Category.loadList({ page: 0 }));
                //yield put(actions.Data.loadArticleDropdownList(true));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.userData.availableLocations', prop:'selectedLabel', val:userData.user.currentLocation.label}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.userData.availableLocations', prop:'selectedId', val:userData.user.currentLocation.id}));
                yield put(actions.Advertising.loadAdvertisingList({'from': 0}))
                yield put(actions.Training.loadTrainingList({'old': false, 'from': 0}))
                yield put(actions.Training.loadTrainingList({'old': true, 'from': 0}))
                yield put(actions.Ui.updateManufacturerListPage(0));
                yield put(actions.Ui.updateLoginLogListPage(0));
                yield put(actions.Ui.updateUserListPage(0));
                yield put(actions.Ui.updateLoginLoadingAnimation(false));
                yield put(actions.Data.loadUserPopup(true));
            }


        } catch (e) {
            yield put(actions.Ui.updateToken(''));
            window.localStorage.setItem('Laminat_Depot_JWT', '');
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Bitte melden Sie sich erneut an',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            yield put(actions.Ui.updateLoginLoadingAnimation(false));
        }
    });
}

function* editUserSaga() {
    yield takeLatest(actionTypes.Data.EDIT_USER, function*(action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/user/' + action.payload.user.id, {
                method: 'GET',
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const userData = yield result.json();
                yield put(actions.Data.updateUserToEdit(userData.user));
                yield put(actions.Ui.updateView(12));
            }
        } catch (e) {
            console.log('ERROR', e);
        }
    });
}

function* getUserListSaga() {
    yield takeLatest(actionTypes.Data.LOAD_USER_INFO_LIST, function*(action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/user/list/' + action.payload.infoList.page, {
                method: 'GET',
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const userList = yield result.json();
                yield put(actions.Data.updateUserInfoList(userList.users));
            }
        } catch (e) {
            console.log('ERROR', e);
        }
    });
}

function* getUserPopupSaga() {
    yield takeLatest(actionTypes.Data.LOAD_USER_POPUP, function*(action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/user-popup',{
                method: 'GET',
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const users = yield result.json();
                yield put(actions.Data.updateUserPopup(users));
            }
        } catch (e) {
            console.log('ERROR', e);
        }
    });
}

function* updateCurrentUserDataSaga() {
    yield takeLatest(actionTypes.Data.LOAD_CURRENT_USER_DATA, function*(action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/user', { method: 'GET', headers });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const userData = yield result.json();
                const usr = {...userData.user}
                usr.parentTeam = userData.parentTeam
                yield put(actions.Data.updateUserData(usr))
            }

        } catch (e) {
            yield put(actions.Ui.updateToken(''));
            window.localStorage.setItem('Laminat_Depot_JWT', '');
            yield put(
                actions.Ui.updateAlert({
                    view: 'visible',
                    type: 'Error',
                    message: 'Bitte melden Sie sich erneut an',
                })
            );
            yield delay(messageDelay);
            yield put(actions.Ui.updateAlert({ view: 'hidden', type: 'Error', message: '' }));
            console.log('ERROR', e);
        }
    });
}

function* checkLoginSaga() {
    yield takeLatest(actionTypes.Ui.CHECK_LOGIN, function*(action) {
        try {
            if(action.payload.status === 406 || action.payload.status === 408 || action.payload.status === 401) {
                yield put(actions.Ui.updateLoginLoadingAnimation(false));
                yield put(actions.Ui.updateToken(''));
                window.localStorage.setItem('Laminat_Depot_JWT', '');
                const message = action.payload.status === 406 ? 'Diese Ip Adresse ist nicht zugelassen' : 'Ihre Sitzung ist abgelaufen, melden Sie sich erneut an';
                yield put( actions.Ui.updateAlert({view: 'visible',type: 'Error',message: message}));
                yield delay(5000);
                yield put(actions.Ui.resetState(true));
                yield put(actions.Data.resetState(true));
                yield put(actions.Training.resetState(true));
                yield put(actions.Task.resetState(true));
                yield put(actions.News.resetState(true));
                yield put(actions.SickReport.resetState(true));
                yield put(actions.Claims.resetState(true));
                yield put(actions.Category.resetState(true));
                yield put(actions.Advertising.resetState(true));
                yield put(actions.Absence.resetState(true));
            } else {
                yield yield put(actions.Ui.refreshToken());
            }
        } catch (e) {
            console.log('ERROR', e);
        }
    });
}

function* saveToStoreSaga() {
    yield takeEvery('*', function*(action) {
        try {
            const ui = yield select(selectors.Ui.ui)
           // const filter = yield select(selectors.Data.filter)
           /* const data = yield select(selectors.Data.data)
            const absence = yield select(selectors.Absence.absence)
            const advertising = yield select(selectors.Advertising.advertising)
            const category = yield select(selectors.Category.category)
            const claims = yield select(selectors.Claims.claims)
            const news = yield select(selectors.News.news)
            const sickReport = yield select(selectors.SickReport.sickReport)
            const task = yield select(selectors.Task.task)
            const training = yield select(selectors.Training.training)
            const currentState = {ui,data,absence,advertising,category,claims,news, sickReport, task, training}*/
            //  const currentState = {ui,
            //      stockFilterLabel: filter.stock.category.selectedLabel,
            //      stockFilterId: filter.stock.category.selectedId
            // }
             const currentState = {ui,
                 stockFilterLabel: '',
                 stockFilterId: ''
            }
            window.localStorage.setItem('Laminat_Depot_Store', JSON.stringify(currentState));
        } catch (e) {
            console.log('ERROR', e)
        }
    });
}

function* refreshTokenSaga() {
    yield takeLatest(actionTypes.Ui.REFRESH_TOKEN, function*(action) {
        try {
            yield delay(10000)
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(`${host}/api-token-refresh`, { method: "GET", headers});
            if (result.status === 200) {
                const auth = yield result.json();
                if (auth.token) {
                    yield put(actions.Ui.updateToken(auth.token));
                    window.localStorage.setItem('Laminat_Depot_JWT', auth.token);
                }
            }
        } catch (e) {
            console.log('ERROR', e);
        }
    });
}




export default [authenticateSaga, getUserDataSaga, editUserSaga, getUserListSaga, updateCurrentUserDataSaga, checkLoginSaga, saveToStoreSaga, refreshTokenSaga, getUserPopupSaga];
