import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import './loadingAnimation.scss';

const LoadingAnimation = (props) => {
    return (
        <div
            className={classNames('LoadingAnimation', {
                'LoadingAnimation--hidden': !props.show,
            })}
        ></div>
    );
};

LoadingAnimation.propTypes = {
    show: PropTypes.bool,
};

export default LoadingAnimation;
