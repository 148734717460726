import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from '../Redux/index';
import Alert from './Alert';
import logoBlack from '../assets/img/daten_depot_black.svg';

const mapStateToProps = (state) => {
    return {
        showLoginError: state.ui.showLoginError,
        username: state.ui.username,
        password: state.ui.password,
        showPasswordForgottenView: state.ui.showPasswordForgottenView,
        showPasswordSentMessage: state.ui.showPasswordSentMessage,
        passwordForgottenEmail: state.data.passwordForgottenEmail,
        loadingAnimation: state.ui.loginLoadingAnimation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (credentials) => dispatch(actions.Ui.login(credentials)),
        updateUsername: (username) => dispatch(actions.Ui.updateUsername(username)),
        updatePassword: (password) => dispatch(actions.Ui.updatePassword(password)),
        updatePasswordForgottenView: (value) => dispatch(actions.Ui.updatePasswordForgottenView(value)),
        updatePasswordSentMessage: (value) => dispatch(actions.Ui.updatePasswordSentMessage(value)),
        updatePasswordForgottenEmail: (value) => dispatch(actions.Data.updatePasswordForgottenEmail(value)),
        sendEmailWithNewPassword: (value) => dispatch(actions.Data.sendEmailWithNewPassword(value)),
    };
};

class Login extends PureComponent {
    static propTypes = {
        showLoginError: PropTypes.bool,
        login: PropTypes.func.isRequired,
        updateUsername: PropTypes.func.isRequired,
        updatePassword: PropTypes.func.isRequired,
        username: PropTypes.string,
        password: PropTypes.string,
        showPasswordForgottenView: PropTypes.bool,
        showPasswordSentMessage: PropTypes.bool,
        passwordForgottenEmail: PropTypes.string,
        updatePasswordForgottenView: PropTypes.func.isRequired,
        updatePasswordSentMessage: PropTypes.func.isRequired,
        updatePasswordForgottenEmail: PropTypes.func.isRequired,
        sendEmailWithNewPassword: PropTypes.func.isRequired,
    };

    componentDidMount() {
        if (!this.usernameInput.value) {
            this.usernameInput.focus();
        } else if (!this.passwordInput.value) {
            this.passwordInput.focus();
        } else {
            this.usernameInput.select();
            this.usernameInput.focus();
        }
    }

    render() {
        return (
            <div className="login-site">
                <Alert/>
                <div className="page-wrapper">
                    <div id="main-side">
                        <main>
                            <div className="login">
                                <div className="login-header">
                                    <img src={logoBlack} alt="DatenDEPOT"/>
                                </div>

                                <div className="login-body">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        this.props.login({
                                            username: this.props.username,
                                            password: this.props.password,
                                        });
                                    }}>
                                        <div className="login-input">
                                            <label>Benutzername</label>
                                            <input type="text" placeholder="Benutzername" name="Benutzername"
                                                   ref={(input) => {
                                                       this.usernameInput = input;
                                                   }}
                                                   value={this.props.username}
                                                   onChange={(e) => this.props.updateUsername(e.target.value)}/>
                                        </div>
                                        <div className="login-input">
                                            <label>Password</label>
                                            <input type="password" placeholder="Passwort" name="Password"
                                                   ref={(input) => {
                                                       this.passwordInput = input;
                                                   }}
                                                   value={this.props.password}
                                                   onChange={(e) => this.props.updatePassword(e.target.value)}/>
                                        </div>
                                        <div className="login-submit">
                                            <button disabled={this.props.loadingAnimation} type="submit">Anmelden {this.props.loadingAnimation && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/>}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
