import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* createClaimDropdownQualitySaga() {
    yield takeLatest(actionTypes.Data.SAVE_CLAIM_DROPDOWN_QUALITY, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const claimDropdownQuality = {
                "claimDropdownQuality": {
                    "name": stateData.claimDropdownQualityToEdit.name,
                },
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownquality', {
                method: "POST",
                headers,
                body: JSON.stringify(claimDropdownQuality),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateClaimDropdownQualityToEdit({ id: '',name: ''}));
                yield put(actions.Ui.updateClaimDropdownQualityModal(false));
                yield put(actions.Data.loadClaimDropdownQualities(true));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* claimDropdownQualitiesSaga() {
    yield takeLatest([actionTypes.Data.LOAD_CLAIM_DROPDOWN_QUALITIES, actionTypes.Ui.UPDATE_CLAIM_DROPDOWN_QUALITY_LIST_PAGE], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const sorting = yield select(selectors.Settings.colorSort);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/claimdropdownquality/list?sort='+sorting, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const claimDropdownQualities = yield result.json();
                yield put(actions.Data.updateClaimDropdownQualities(claimDropdownQualities));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateClaimDropdownQualitySaga() {
    yield takeLatest(actionTypes.Data.UPDATE_CLAIM_DROPDOWN_QUALITY, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const claimDropdownQuality = {
                "claimDropdownQuality": {
                    name: stateData.claimDropdownQualityToEdit.name,
                },
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownquality/' + action.payload.claimDropdownQuality.id, {
                method: "PUT",
                headers,
                body: JSON.stringify(claimDropdownQuality),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateClaimDropdownQualityToEdit({id: '', name: ''}));
                yield put(actions.Ui.updateClaimDropdownQualityModal(false));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteClaimDropdownQualitySaga() {
    yield takeLatest(actionTypes.Data.DELETE_CLAIM_DROPDOWN_QUALITY, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownquality/' + action.payload.claimDropdownQuality.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [createClaimDropdownQualitySaga, claimDropdownQualitiesSaga, updateClaimDropdownQualitySaga, deleteClaimDropdownQualitySaga];
