import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors} from '../Redux/index';
import { host } from './host';

function* saveTeamSaga() {
    yield takeLatest(actionTypes.Data.SAVE_TEAM, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const method = action.payload.team.view === 'new' ? 'POST' : 'PUT'
            const result = yield fetch(host + '/team', {
                method,
                headers: getHeaders(token),
                body: JSON.stringify({team: action.payload.team}),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Ui.loadGroups(true));
                yield put(actions.Settings.updatePermissionsView(false));
                yield put(actions.Settings.updateTeamToEdit({}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.usergroup', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.usergroup', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.permissions.usergroup', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.permissions.usergroup', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.permissions.subordinateUserGroups', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.permissions.subordinateUserGroups', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.permissions.copyFrom', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.permissions.copyFrom', prop:'selectedLabel', val:''}));

            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [saveTeamSaga];
