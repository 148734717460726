import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import './card.scss';
import CardHeader from './CardHeader';

export default class Card extends React.Component {
    static propTypes = {
        footer: PropTypes.node,
        halfWidth: PropTypes.bool,
        extraClass: PropTypes.string,
        header_extra: PropTypes.node,
        icon: PropTypes.string,
        title: PropTypes.string,
    };

    render() {
        return (
            <div
                className={classNames(
                    'medium-small-12',
                    this.props.halfWidth ? 'medium-6 large-6' : (this.props.extraClass ? this.props.extraClass : 'medium-12 large-12')
                )}
            >
                <div className={classNames(this.props.className, 'card')}>
                    <CardHeader
                        icon={this.props.icon}
                        title={this.props.title}
                        extra={this.props.header_extra}
                    />
                    <div className="card__content">{this.props.children}</div>
                    {this.props.footer && <div className="card__footer">{this.props.footer}</div>}
                </div>
            </div>
        );
    }
}
