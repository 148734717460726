import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* taskSaga() {
    yield takeEvery([actionTypes.Task.LOAD_TASK_LIST], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const filter = yield select(selectors.Data.filter);
            const stateData = yield select(selectors.Data.data);
            const taskView = yield select(selectors.Ui.taskView);
            const headers = getHeaders(token);
            let location = filter.task.location.selectedId || null;
            let team = filter.task.team.selectedId || null;
            const completed = action.payload.list.completed || false;
            const from = action.payload.list.from || 0;

            if ((stateData.currentUser.roleLabel !== 'Admin' && stateData.currentUser.roleLabel !== 'Verwaltung' && stateData.currentUser.roleLabel !== 'Personalleitung') || action.payload.list.initialLoad) {
                location = stateData.currentUser.currentLocation.id;
                team = stateData.currentUser.roleIdentifier;
            }


            const result = yield fetch(host + '/task/list/'+from+'/'+location+'/'+team+'/'+completed+'?taskview='+taskView, {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                if (completed) {
                    yield put(actions.Task.updateTaskListFinishedCount(list.count));
                    yield put(actions.Task.updateTaskListFinished({list:list.taskList, start:action.payload.list.from}));
                } else {
                    yield put(actions.Task.updateTaskListNotFinishedCount(list.count));
                    yield put(actions.Task.updateTaskList({list:list.taskList, start:action.payload.list.from}));
                    if (taskView === '3') {
                        yield list.taskList.map(function* (task) {
                            const result = yield fetch(host + '/subtasks/'+task.id, {
                                method: "GET",
                                headers,
                            });
                            const sub = yield result.json();
                            yield put(actions.Task.updateTask({id: task.id, prop: 'subtasks', val: sub}))
                            return sub;
                        })

                    }
                }
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* reloadTaskListSaga() {
    yield takeEvery([actionTypes.Task.RELOAD_TASK_LIST], function* () {
        try {
            yield put(actions.Task.loadTaskList({from: 0, completed: false}));
            yield put(actions.Task.loadTaskList({from: 0, completed: true}));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* uploadTaskSaga() {
    yield takeLatest(actionTypes.Task.ADD_TASK, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const newTask = {
                "task": {
                    "title": action.payload.task.title,
                    "locations": action.payload.task.locations,
                    "teams": action.payload.task.teams,
                    "users": action.payload.task.users,
                    "deadline": action.payload.task.deadline,
                },
                "multiTask": action.payload.task.multiTask ? true : false
            };

            const result = yield fetch(host+'/task', { method: "POST", headers, body: JSON.stringify(newTask)});

            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const addedTask = yield result.json();
                yield put(actions.Task.reloadTask({'id': action.payload.task.id, 'task': addedTask}));
                yield put(actions.Task.updateTaskToEdit({prop: 'resetObj'}));
                yield put(actions.Data.updateModal({path: 'task.task', prop: 'view', val: false}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.team', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.team', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.task.user', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.task.user', prop:'selectedId', val:''}));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* editTaskSaga() {
    yield takeLatest(actionTypes.Task.UPDATE_TASK, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            if(action.payload.task.prop === "completed") {
                const result = yield fetch(host + '/task/' + action.payload.task.id, {
                    method: "PUT",
                    headers,
                    body: JSON.stringify({task: {completed: true}})
                });
                yield put(actions.Ui.checkLogin(result.status));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* removeTaskSaga() {
    yield takeLatest(actionTypes.Data.REMOVE_TASK, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            yield select(selectors.Data.data);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/task/' + action.payload.task.id, {method: 'DELETE',headers});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const removedTask = yield result.json();
                if (removedTask) {
                    yield put(actions.Data.updateCompletedTaskList(true));
                }
            }
        } catch (e) {
            console.log("Error", e);
        }
    });
}

export default [taskSaga, reloadTaskListSaga, uploadTaskSaga, editTaskSaga, removeTaskSaga];
