import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* toggleLikeSaga() {
    yield takeLatest(actionTypes.Data.TOGGLE_LIKE, function* (action) {
        try {
            yield put(actions.Advertising.updateLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const like = {
                "entityId": action.payload.value.entityId,
                "entity": action.payload.value.entity,
            };
            let method = 'POST';
            const result = yield fetch(`${host}/like`, { method, headers, body: JSON.stringify(like)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.countLikes({"entity": action.payload.value.entity}));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* countLikesSaga() {
    yield takeLatest(actionTypes.Data.COUNT_LIKES, function* (action) {
        try {
            yield put(actions.Advertising.updateLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const entity = {
                    "entity": action.payload.value,
            };
            let method = 'POST';
            const result = yield fetch(host+'/likes', { method, headers, body: JSON.stringify(entity)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const likes = yield result.json();
                yield put(actions.Data.updateCountLikes(likes));
            }
            yield put(actions.Advertising.updateLoadingAnimation(false));

        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* likesSaga() {
    yield takeLatest(actionTypes.Data.LIKES, function* (action) {
        try {
            yield put(actions.Advertising.updateLoadingAnimation(true));
            yield put(actions.Data.updateLikes([]));
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const like = {
                "entityId": action.payload.value.entityId,
                "entity": action.payload.value.entity,
            };
            let method = 'GET';
            const result = yield fetch(`${host}/likes?entity=${like.entity}&entityId=${like.entityId}`, { method, headers});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const likes = yield result.json();
                yield put(actions.Data.updateLikes(likes));
            }
            yield put(actions.Advertising.updateLoadingAnimation(false));

        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* loadCommentsSaga() {
    yield takeLatest(actionTypes.Data.LOAD_COMMENTS, function* (action) {
        try {
            yield put(actions.Advertising.updateLoadingAnimation(true));
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const entity = {
                "entityId": action.payload.value.entityId,
                "entity": action.payload.value.entity,
            };
            let method = 'POST';
            const result = yield fetch(`${host}/comments`, { method, headers, body: JSON.stringify(entity)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const comments = yield result.json();
                yield put(actions.Data.updateCommentList(comments));
            }
            yield put(actions.Advertising.updateLoadingAnimation(false));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}
function* addCommentSaga() {
    yield takeLatest(actionTypes.Data.ADD_COMMENT, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const comment = {
                "entityId": action.payload.comment.entityId,
                "entity": action.payload.comment.entity,
                "comment": action.payload.comment.comment,
            };
            let method = 'POST';
            const result = yield fetch(`${host}/comment`, { method, headers, body: JSON.stringify(comment)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.loadComments({'entityId': comment.entityId, 'entity': comment.entity}));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}
function* updateCommentSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_COMMENT, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const comment = {
                "commentId": action.payload.comment.id,
                "comment": action.payload.comment.comment,
            };
            let method = 'PUT';
            const result = yield fetch(`${host}/comment`, { method, headers, body: JSON.stringify(comment)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.loadComments({'entityId': action.payload.comment.entityId, 'entity': action.payload.comment.entity}));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}
function* deleteCommentSaga() {
    yield takeLatest(actionTypes.Data.DELETE_COMMENT, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const comment = {
                "commentId": action.payload.comment.id,
            };
            let method = 'DELETE';
            const result = yield fetch(`${host}/comment`, { method, headers, body: JSON.stringify(comment)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.loadComments({'entityId': action.payload.comment.entityId, 'entity': action.payload.comment.entity}));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [toggleLikeSaga, countLikesSaga, loadCommentsSaga, addCommentSaga, updateCommentSaga, deleteCommentSaga, likesSaga];
