import PropTypes from 'prop-types';
import React from 'react';
import IconLink from './IconLink';

export default class Breadcrumb extends React.PureComponent {
    static propTypes = {
        path: PropTypes.oneOfType([
            PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
        ]),
    };

    static defaultProps = {
        path: null,
    };

    get path() {
        if (!this.props.path) {
            return null;
        }
        if (typeof this.props.path === 'string') {
            return <li><a href="/" onClick={(e) => e.preventDefault()}>{this.props.path}</a></li>;
        }
        const pathLen = this.props.path.length;
        return this.props.path.map((pathItem, index) => (
            <li key={index} className={pathLen === index + 1 ? null : 'arrow-right'}>
                {typeof pathItem === 'string'
                    ? <a href="/#-" onClick={(e) => e.preventDefault()}>{pathItem}</a>
                    : <a href="/#-" onClick={ e =>  { e.preventDefault(); pathItem.onClickHaendler(pathItem.view)}}>{pathItem.label}</a>}
            </li>
        ));
    }

    render() {
        return (
            <div className="breadcrumb-menu">
                <ul>
                    <li className="arrow-right">
                        <IconLink type="home" href="/" />
                    </li>
                    {this.path}
                </ul>
                <div style={{ display: 'flex' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
