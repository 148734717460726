import ClaimBreadcumbMenu from 'Components/Claims/ClaimBreadcumbMenu';
import ClaimsList from 'Components/Claims/ClaimList';
import React from 'react';
import Breadcrumb from '../Components/UI/Breadcrumb';

class ClaimsPage extends React.Component {
    state = {
        locationFilter: null,
    };

    render() {
        return (
            <div id="main-side">
                <Breadcrumb path={['Reklamationen']}>
                    <ClaimBreadcumbMenu
                        locationFilter={this.state.locationFilter}
                        onLocationFilterChange={(value) => {
                            this.setState({ locationFilter: value});
                            console.log('ok we are here')
                        }}
                    />
                </Breadcrumb>
                <main>
                    <ClaimsList locationFilter={this.state.locationFilter} />
                </main>
                <footer id="footer-main"></footer>
            </div>
        );
    }
}

export default ClaimsPage;
