import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors} from '../Redux/index';
import { host } from './host';
import { delay } from 'redux-saga';

function* createUserSaga() {
    yield takeLatest(actionTypes.Data.SAVE_USER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/user', {
                method: "POST",
                headers,
                body: JSON.stringify(action.payload.user),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateUserToEdit({}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.usergroup', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.usergroup', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.userAllowedLocationsClaim', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.userAllowedLocationsClaim', prop:'selectedLabel', val:''}));
                yield put(actions.Ui.updateUserModal(false));
                yield put(actions.Ui.updateUserListPage(0));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* userlistSaga() {
    yield takeLatest([actionTypes.Data.LOAD_USER_LIST, actionTypes.Ui.UPDATE_USER_LIST_PAGE, actionTypes.Ui.UPDATE_USER_SEARCH], function* (action) {
        try {
            yield delay(300)
            const token = yield select(selectors.Ui.token);
            const ui = yield select(selectors.Ui.ui);
            const userSearch = yield select(selectors.Ui.userSearch);
            const sorting = yield select(selectors.Settings.userSort);
            const headers = getHeaders(token);

                let uri = host + '/user/list/' + action.payload.value;
                if (action.type === 'Ui/UpdateUserSearch') {
                    yield put(actions.Ui.updateUserListPage(0));
                }
                   uri = host + '/user/list/' + ui.userListPage
                   uri += '?userSearch='+userSearch;
                   uri += '&sort='+sorting;
                const result = yield fetch(uri, {
                    method: "GET",
                    headers,
                });
                yield put(actions.Ui.checkLogin(result.status));
                if (result.status !== 406 && result.status !== 408) {
                    const users = yield result.json();
                    yield put(actions.Data.updateUserList(users.users));
                    yield put(actions.Ui.updateCountUser(users.count));
                    yield put(actions.Settings.updatePns(users.pn));

                }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateUserSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_USER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const stateUi = yield select(selectors.Ui.ui);
            const stateData = yield select(selectors.Data.data);

            const headers = getHeaders(token);
            const result = yield fetch(host + '/user/' + stateData.userToEdit.id, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(action.payload.user),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                if (stateData.currentUser.id === stateData.userToEdit.id) {
                    yield put(window.location.reload(false));
                }
                if(result.status === 409) {
                    yield put(window.alert('Benutzername wird bereits verwendet.'))
                } else {
                    yield put(actions.Ui.updateUserListPage(stateUi.userListPage));
                    yield put(actions.Data.updateUserToEdit({}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.location', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.location', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.usergroup', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.user.usergroup', prop:'selectedLabel', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.userAllowedLocationsClaim', prop:'selectedId', val:''}));
                    yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.userAllowedLocationsClaim', prop:'selectedLabel', val:''}));
                    yield put(actions.Ui.updateUserModal(false));
                }



            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* deleteUserSaga() {
    yield takeLatest(actionTypes.Data.DELETE_USER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/user/' + action.payload.user.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}
function* loadAbsenceUserOverviewListSaga() {
    yield takeLatest(actionTypes.Absence.LOAD_ABSENCE_USER_OVERVIEW_LIST, function* (action){
        try {
            if(action.payload.from === 0) {
                yield put(actions.Absence.updateLoadingList(true))
            }
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token)
            const headers = getHeaders(token);
            const yearFilter = stateData.yearFilter ? `&year=${stateData.yearFilter}`: '';

            let location = '';
            if (stateData.filter.absencerecord.location.selectedId !== '') {
                location = stateData.filter.absencerecord.location.selectedId;
            }

            const locationString = location ? '&location='+location : ''
            const result = yield fetch(`${host}/user/listallabsenceuseroverview?from=${action.payload.from}${locationString}${yearFilter}`, {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(actions.Absence.updateAbsenceUserOverviewList(list))
                yield put(actions.Absence.updateLoadingList(false))
            }
        } catch (e) {
            console.log("Error", e)
        }
    })
}

function* countUserSaga() {
    yield takeLatest(actionTypes.Ui.COUNT_USER, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/count/user/', { method: "GET", headers });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const count = yield result.json();
                yield put(actions.Ui.updateCountUser(count));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* activateUserSaga() {
    yield takeLatest(actionTypes.Ui.TOGGLE_ACTIVATION, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/activate/user/' + action.payload.value, {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const user = yield result.json();
                yield put(actions.Data.updateUser(user));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* loadAllUsersSaga() {
    yield takeLatest(actionTypes.Data.LOAD_ALL_USERS, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/user/listall', {method: "GET", headers});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(actions.Data.updateAllUsers(list));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* countSickdaysSaga() {
    yield takeLatest(actionTypes.Data.COUNT_SICKDAYS, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            yield fetch(host + '/countsickdays', { method: "GET",headers: headers});
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}


function* contingentsSaga() {
    yield takeLatest(actionTypes.Data.LOAD_CONTINGENTS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/user-contingents', {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const contingents = yield result.json();
                yield put(actions.Data.updateContingents(contingents));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}


export default [createUserSaga, userlistSaga, updateUserSaga, deleteUserSaga, countUserSaga, activateUserSaga, loadAllUsersSaga, loadAbsenceUserOverviewListSaga, countSickdaysSaga, contingentsSaga];
