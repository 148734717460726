import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import Dropdown from './Dropdown';
import {getPopupUser, getUserData} from './Util';
import { host } from 'Sagas/host';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import DatePicker from "react-multi-date-picker"
import Rating from "../Components/Rating";
import FilterDropdown from "./FilterDropdown";
import IconLink from "../Components/UI/IconLink";

const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        trainingModal: state.data.modal.training.training,
        rescheduleModal: state.data.modal.training.reschedule,
        addRegistrationModal: state.data.modal.training.addRegistration,
        valuationModal: state.data.modal.training.valuation,
        trainingToEdit: state.training.trainingToEdit,
        list: state.training.list,
        oldList: state.training.oldList,
        listCount: state.training.listCount,
        oldListCount: state.training.oldListCount,
        oldListView: state.training.oldListView,
        loadingAnimation: state.training.loadingAnimation,
        allUsers: state.training.allUsersForTraining,
        userDropdown: state.data.dropdown.training.user,
        categoryDropdown: state.data.dropdown.training.category,
        currentTraining: state.training.currentTraining,
        valuation: state.training.valuation,
        token: state.ui.token,
        userSearch: state.ui.userSearch,
        reference: state.training.reference,
        yearFilter: state.data.yearFilter,
        categories: state.training.categories,
        valuationReference: state.training.valuationReference,
        activeTab: state.training.activeTab,
        view: state.training.view,
        allLocations: state.data.allLocations,
        locationFilter: state.data.filter.training.location,
        overviewList: state.training.overviewList,
        registerStatus: state.training.registerStatus,
        registerMessage: state.training.registerMessage,
        forceRegister: state.training.forceRegister,
        userPopup: state.data.userPopup,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateTrainingToEdit: (training) => dispatch(actions.Training.updateTrainingToEdit(training)),
        saveTraining: (value) => dispatch(actions.Training.saveTraining(value)),
        updateParticipantListView: (training) => dispatch(actions.Training.updateParticipatListView(training)),
        deleteTraining: (training) => dispatch(actions.Training.deleteTraining(training)),
        registerForTraining: (training) => dispatch(actions.Training.registerForTraining(training)),
        approveRegistrationForTraining: (appointment) => dispatch(actions.Training.approveRegistrationForTraining(appointment)),
        declineRegistrationForTraining: (appointment) => dispatch(actions.Training.declineRegistrationForTraining(appointment)),
        loadTrainingList: (list) => dispatch(actions.Training.loadTrainingList(list)),
        updateOldListView: (value) => dispatch(actions.Training.updateOldListView(value)),
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        updateCurrentTraining: (training) => dispatch(actions.Training.updateCurrentTraining(training)),
        updateValuation: (valuation) => dispatch(actions.Training.updateValuation(valuation)),
        addValuation: (valuation) => dispatch(actions.Training.addValuation(valuation)),
        updateUserSearch: (search) => dispatch(actions.Ui.updateUserSearch(search)),
        loadAllUsersForTraining: list => dispatch(actions.Training.loadAllUsersForTraining(list)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        updateYearFilter: (yearFilter) => dispatch(actions.Data.updateYearFilter(yearFilter)),
        updateValuationReference: (value) => dispatch(actions.Training.updateValuationReference(value)),
        updateActiveTab: (value) => dispatch(actions.Training.updateActiveTab(value)),
        loadOverviewList: (value) => dispatch(actions.Training.loadOverviewList(value)),
        updateOverviewList: (list) => dispatch(actions.Training.updateOverviewList(list)),
        updateRegisterStatus: (value) => dispatch(actions.Training.updateRegisterStatus(value)),
        updateRegisterMessage: (value) => dispatch(actions.Training.updateRegisterMessage(value)),
        updateForceRegister: (value) => dispatch(actions.Training.updateForceRegister(value)),
    };
};

const traingReferenceIds = {};

class Training extends PureComponent {

    constructor() {
        super();
        this.downloadForm = [];
        this.downloadCurrentForm = {};
        this.downloadOverviewForm = [];
    }
    state = {
        trainingInfo: '',
        referrer: 'registerForTraining',
        sorting: '',
        sortingDirection: 'up'
    };

    static propTypes = {
        currentUser: PropTypes.object,
        trainingModal: PropTypes.object,
        updateModal: PropTypes.func.isRequired,
        updateParticipantListView: PropTypes.func.isRequired,
        deleteTraining: PropTypes.func.isRequired,
        registerForTraining: PropTypes.func.isRequired,
        approveRegistrationForTraining: PropTypes.func.isRequired,
        declineRegistrationForTraining: PropTypes.func.isRequired,
        loadTrainingList: PropTypes.func.isRequired,
        loadingAnimation: PropTypes.bool,
        updateCurrentTraining: PropTypes.func.isRequired,
        updateValuation: PropTypes.func.isRequired,
        userSearch: PropTypes.string,
        updateUserSearch: PropTypes.func,
        updateYearFilter: PropTypes.func,
        valuationReference: PropTypes.bool,
        view: PropTypes.number
    };

    componentDidMount() {
        this.props.updateUserSearch('');
        this.props.updateYearFilter('');
        if(this.props.view === 2) {
            window.setTimeout(() => {
                console.log('linked')
                if (this.props.reference) {
                    let training = this.props.list.find(tr => tr.id === this.props.reference)
                    if(!training) {
                        training = this.props.oldList.find(tr => tr.id === this.props.reference)
                    }
                    if(training) {
                        let tabIndex = 0;
                        switch (training.category) {
                            case 'Termine' : tabIndex = 1; break;
                            case 'Sonstiges' : tabIndex = 2; break;
                            default: tabIndex = 0;
                        }
                        this.props.updateActiveTab(tabIndex)
                        traingReferenceIds[this.props.reference].scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });

                        if(this.props.valuationReference) {
                            this.props.updateModal({
                                path: 'training.valuation',
                                prop: 'view',
                                val: true,
                            });
                            this.props.updateValuation({prop: 'training', val: training})
                            this.props.updateValuation({prop: 'userId', val: this.props.currentUser.id})
                            this.props.updateValuation({prop: 'ratings', val: training.ratings})
                            this.props.updateValuationReference(false)
                        }
                    }

                }


            }, 200)
            this.props.loadAllUsersForTraining(true);
            this.props.updateDropdown({dropdown: 'dropdown.training.category', prop:'selectedLabel', val:this.props.categories[0].label});
            this.props.updateDropdown({dropdown: 'dropdown.training.category', prop:'selectedId', val:this.props.categories[0].label});
        } else {
            console.log('not linked')
            this.props.loadOverviewList(true);
        }


    }
    showSorting(column) {
        if(this.state.sorting === column){
            return <i className={`fa fa-angle-${this.state.sortingDirection}`} />
        }
    }
    setSorting(column) {
        this.setState({
            ...this.state,
            sorting: column,
            sortingDirection: this.state.sortingDirection === 'up' ? 'down' : 'up'
        })
    }

    deletedUser() {
        return <span style={{opacity: 0.3}}>Benutzer gelöscht</span>
    }

    addTraining() {
        if(this.props.trainingToEdit.title === '' || this.props.trainingToEdit.title === undefined) {
            alert('Der Titel darf nicht leer sein.')
            return;
        }
        if(this.props.trainingToEdit.description === '' || this.props.trainingToEdit.description === undefined) {
            alert('Die Beschreibung darf nicht leer sein.')
            return;
        }
        if(this.props.trainingToEdit.address === '' || this.props.trainingToEdit.address === undefined) {
            alert('Die Adresse darf nicht leer sein.')
            return;
        }
        if(this.props.trainingToEdit.zip === '' || this.props.trainingToEdit.zip === undefined) {
            alert('Die Plz darf nicht leer sein.')
            return;
        }
        if(this.props.trainingToEdit.city === '' || this.props.trainingToEdit.city === undefined) {
            alert('Das Ort darf nicht leer sein.')
            return;
        }
        if(this.props.userDropdown.selectedId === '' || this.props.userDropdown.selectedId === undefined) {
            alert('Ansprechpartner darf nicht leer sein.')
            return;
        }
        if((this.props.trainingToEdit.dates === '' || this.props.trainingToEdit.dates === undefined) && this.props.trainingModal.action === 'newTraining') {
            alert('Das Datum darf nicht leer sein.')
            return;
        }
        if((this.props.trainingToEdit.time === '' || this.props.trainingToEdit.time === undefined) && this.props.trainingModal.action === 'newTraining') {
            alert('Die Uhrzeit darf nicht leer sein.')
            return;
        }
        if(this.props.trainingToEdit.contingent === '' || this.props.trainingToEdit.contingent === undefined) {
            alert('Die Anzahl verfügbarer Plätze darf nicht leer sein.')
            return;
        }

        if (!this.props.trainingToEdit.ratings.some(rating => rating.label !== "")) {
            if (!window.confirm('Achtung! Es wurden für diese Veranstaltung keine Bewertungsfragen definiert. Trotzdem speichern?')) {
                return;
            }
        }
        if (this.props.trainingToEdit.id) {
            this.props.saveTraining('update');
        } else {
            this.props.saveTraining('add');
        }

    }

    rescheduleTraining() {
        if(this.props.trainingToEdit.dates === '' || this.props.trainingToEdit.dates === undefined) {
            alert('Das Datum darf nicht leer sein.')
            return;
        }
        if((this.props.trainingToEdit.time === '' || this.props.trainingToEdit.time === undefined) && this.props.trainingModal.action === 'newTraining') {
            alert('Die Uhrzeit darf nicht leer sein.')
            return;
        }
        this.props.saveTraining('reschedule');

    }

    removeTraining(training, path) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Veranstaltung löschen?'})
        this.props.updateConfirmModal({prop: 'text', value: 'Möchten Sie wirklich die Veranstaltung absagen?'})
        this.props.updateConfirmModal({prop: 'label', value: "Löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteTraining', param: {path: path, training: training}}})

    }

    registerForTraining(id) {
        const training = this.props.list.find(t => t.id === id);
        const alreadyRegistered = training.appointments.find(
            (appointment) => appointment.userId === this.props.currentUser.id
        );
        if (alreadyRegistered) {
            alert('Sie haben Sich bereits angemeldet');
        } else {
            if(this.props.registerStatus === 400) {
                this.props.updateForceRegister(training.id);
            }
            this.props.registerForTraining(training);
        }
    }

    registerForTrainingAdmin() {
        if (this.props.userDropdown.selectedId === '') {
            alert('Bitte wählen Sie einen Mitarbeiter aus');
            return;
        }

        const alreadyRegistered = this.props.currentTraining.appointments.find(
            (appointment) => this.props.userDropdown.selectedId.includes(appointment.userId)
        );
        if (alreadyRegistered) {
            alert('Mitarbeiter ist bereits angemeldet');
        } else {
            if(this.props.registerStatus === 400) {
                this.props.updateForceRegister(this.props.currentTraining.id);
            }

            this.props.registerForTraining({
                id: this.props.currentTraining.id,
                directRegistration: true,
                user: [this.props.userDropdown.selectedId],
                info: this.state.trainingInfo
            });
            this.setState({...this.state, trainingInfo: ''})
        }
    }

    isOnWaitingList(training) {
        const onWaitingList = training.appointments.find(

            (appointment) => {
                if (appointment.userId === this.props.currentUser.id && !appointment.approved) {
                    return true;
                }
                return false;
            }

        );
        return onWaitingList !== undefined;
    }

    declineRegistrationForTraining(training) {
        training.appointments.forEach((appointment, appointmentIndex) => {
            if (appointment.userId === this.props.currentUser.id) {
                this.props.declineRegistrationForTraining({
                    trainingId: training.id,
                    index: appointmentIndex,
                    appointment: appointment,
                });
            }
        });
    }

    canValuate(user, training) {
        const registred = training.appointments.find(

            (appointment) => {
                if (appointment.userId === this.props.currentUser.id && appointment.approved) {
                    return true;
                }
                return false;
            }
        );
        const allreadyValuated = training.valuations.find(
            (valuation) => {
                if (valuation.userId === this.props.currentUser.id) {
                    return true;
                }
                return false;
            }
        );
        if (!allreadyValuated && registred) {
            return true;
        } else {
            return false;
        }
    }

    addValuation() {
        let valid = true;
        this.props.valuation.ratings.map(rating => {
            if(rating.type === 'Frage' && rating.value.trim() === '') {
                valid = false;
            }
            return rating;
        })
        if (valid) {
            this.props.addValuation(this.props.valuation);
        } else {
            alert('Bitte beantworten Sie alle Fragen.')
        }
    }

    getLink(training) {
        return host + "/exporttraining/"+training.id;
    }
    getCurrentLink(training) {
        return host + "/exportcurrenttraining/"+training.id;
    }
    getOverviewLink() {

        let yearFilter = this.props.yearFilter ? `?year=${this.props.yearFilter}&search=${this.props.userSearch}`: `?year=0&search=${this.props.userSearch}`;

        let location = '';
        if (this.props.locationFilter.selectedId !== '') {
            location = this.props.locationFilter.selectedId;
        }else if (this.props.currentUser.currentLocation) {
            location = this.props.currentUser.currentLocation.id;
        }

        return host + "/exporttrainingoverview/"+location+yearFilter;
    }

    copyTraining(training) {
        const copy = {...training}
        delete(copy.id);

        this.props.updateUserSearch('')
        this.props.updateModal({
            path: 'training.training',
            prop: 'view',
            val: !this.props.trainingModal.view,
        });
        this.props.updateModal({
            path: 'training.training',
            prop: 'action',
            val: 'newTraining',
        });
        this.props.updateTrainingToEdit({prop: 'resetObj', val: ''})
        this.props.updateTrainingToEdit({prop: 'title', val: training.title + ' - Kopie'});
        this.props.updateTrainingToEdit({prop: 'description', val: training.description});
        this.props.updateTrainingToEdit({prop: 'address', val: training.address});
        this.props.updateTrainingToEdit({prop: 'zip', val: training.zip});
        this.props.updateTrainingToEdit({prop: 'city', val: training.city});
        this.props.updateTrainingToEdit({prop: 'contingent', val: training.contingent});
        this.props.updateTrainingToEdit({prop: 'q1', val: training.q1});
        this.props.updateTrainingToEdit({prop: 'q2', val: training.q2});
        this.props.updateTrainingToEdit({prop: 'lecturer', val: training.lecturer});
        this.props.updateTrainingToEdit({prop: 'ratings', val: training.ratings});
        this.props.updateTrainingToEdit({prop: 'time', val: training.time});
        this.props.updateTrainingToEdit({prop: 'dates', val: training.dates});
        this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'selectedId', val:training.contactPerson});
        this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'selectedLabel', val:training.contactPersonName});
        this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedId', val:training.category});
        this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedLabel', val:training.category});

    }


    filterParicipantList(appointment, training) {
        if (appointment.waitingList) {
            if (this.can('warteliste_anzeigen_alle') || this.props.currentUser.id === training.contactPerson) {
                return {
                    'display': 'table-row'
                }
            } else {
                return {
                    'display' : 'none'
                }
            }
        }
        if (this.can('teilnehmerliste_anzeigen_alle')) {
            return {
                'display': 'table-row'
            }
        }

        if (this.can('teilnehmerliste_anzeigen_eigene_filiale')) {
            if (this.props.currentUser.currentLocation.name === getUserData(appointment.userData)[2]) {
                return {
                    'display': 'table-row'
                }
            } else {
                return {
                    'display': 'none'
                }
            }
        } else {
            return {
                'display': 'none'
            }
        }
    }

    sortByLocations(a, b) {
        if (getUserData(a.userData)[2] > getUserData(b.userData)[2]) {
            return 1;
        } else if(getUserData(a.userData)[2] < getUserData(b.userData)[2]) {
            return -1;
        }
        return 0;
    }
    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    canApproveOrDecline(appointment, decline) {
        let permissionOwn = 'anmeldung_best_tigen_eigene_filiale';
        let permissionAll = 'anmeldung_best_tigen_alle';
        if(decline) {
            permissionOwn = 'anmeldung_ablehnen_eigene_filiale';
            permissionAll = 'anmeldung_ablehnen_alle';
        }
        let all = false;
        let own = false;
        if(this.can(permissionOwn)) {
            own = true;
        }
        if(this.can(permissionAll)) {
            all = true;
        }
        if(own && !all) {
            if(this.props.currentUser.currentLocation.name !== getUserData(appointment.userData)[2]) {
                return false;
            }
        }
        return true;
    }

    countAppointmentsByWaitingListStatus(training, waitingList = false) {
        return training.appointments.filter(appointment => appointment.waitingList === waitingList ? true : false).length
    }


    render() {
        let newTrainingSchulungen=0, newTrainingTermin=0, newTrainingSonstiges = 0;

        const allLocations = [{label: 'Alle', id: 'receivealllocations'}, ...this.props.allLocations]

        return (
            <div id="main-side">
                <Breadcrumb path="Veranstaltungen">
                    {this.can('training_overview_list_can_see') && this.props.view === 3 &&
                    <>
                        <FilterDropdown
                            filter={this.props.locationFilter}
                            list={allLocations}
                            itemIdProp={'id'}
                            itemLabelProp={'label'}
                            resetFilterLabel={true}
                            defaultSelectedLabel={'Nach Ort Filtern'}
                            actionToExecute={this.props.loadOverviewList}
                        />
                    </>
                    }
                    {this.can('training_overview_list_can_see') && this.props.view === 3 &&
                    <div className="btn-3 absence-date">
                        <input type="text" name='test'
                               value={this.props.userSearch || ''}
                               placeholder={'Suche Mitarbeiter'}
                               onChange={e => {
                                   this.props.updateUserSearch(e.target.value)
                               }}
                        />
                    </div>}

                    <div className="btn-3 absence-date year-filter">
                        <input type="text"
                               value={this.props.yearFilter || ''}
                               placeholder={'Jahr'}
                               onChange={e => {
                                   this.props.updateYearFilter(e.target.value);
                                   if(this.props.view === 2) {
                                       this.props.loadTrainingList({'old': false, 'from': 0})
                                   }
                                   if(this.props.view === 3) {
                                       this.props.loadOverviewList(true)
                                   }

                               }}
                        />
                    </div>
                    { this.can('schulung_erstellen') && this.props.view === 2 &&
                    <Button onClick={() => {
                        this.props.updateUserSearch('')
                        this.props.updateModal({
                            path: 'training.training',
                            prop: 'view',
                            val: !this.props.trainingModal.view,
                        });
                        this.props.updateModal({
                            path: 'training.training',
                            prop: 'action',
                            val: 'newTraining',
                        });
                    }}
                            label="Neue Veranstaltung"/>
                    }

                </Breadcrumb>

                {this.props.view === 2 &&
                <main>
                    <div id="trainingModal" className={this.props.trainingModal.view ? 'modal visible': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-graduation-cap" aria-hidden="true"/> Veranstaltung {this.props.trainingModal.action === 'newTraining' ? 'Erstellen' : 'Speichern'}
                                </span> <span className="close">
                                    <a href="/" onClick={(e) => { e.preventDefault(); this.props.updateTrainingToEdit({prop: 'resetObj', val: ''}); this.props.updateModal({path: 'training.training', prop: 'view', val: !this.props.trainingModal.view}); }}>
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <div className="group">
                                                    <input type="text" value={this.props.trainingToEdit.title || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'title', val: e.target.value})} />
                                                    <label>Titel</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-12 large-12">
                                                <Dropdown dropdown={this.props.categoryDropdown} dropdownList={this.props.categories} itemIdProp={'label'} itemLabelProp={'label'} defaultSelectedLabel={this.props.categories[0].label} multiple={false}/>
                                            </div>
                                            <div className="small-12 medium-12 large-12">
                                                <div className="group">
                                                    <textarea value={this.props.trainingToEdit.description || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'description', val: e.target.value})}/>
                                                    <label>Nachricht</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-12 large-12">
                                                <div className="group">
                                                    <input type="text" value={this.props.trainingToEdit.address || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'address', val: e.target.value})} />
                                                    <label>Anschrift</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.trainingToEdit.zip || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'zip', val: e.target.value})} />
                                                    <label>PLZ</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.trainingToEdit.city || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'city', val: e.target.value})} />
                                                    <label>Ort</label>
                                                </div>
                                            </div>
                                            {/*
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.trainingToEdit.q1 || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'q1', val: e.target.value})} />
                                                    <label>Bewertung Frage 1</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.trainingToEdit.q2 || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'q2', val: e.target.value})} />
                                                    <label>Bewertung Frage 2</label>
                                                </div>
                                            </div>
                                            */}
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.userSearch || ''}
                                                           onChange={(e) => {
                                                               this.props.updateUserSearch(e.target.value);
                                                               if (e.target.value) {
                                                                   this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'view', val:true});
                                                               } else {
                                                                   this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'view', val:false});
                                                               }
                                                           }
                                                           }
                                                    />
                                                    <label>Suchen </label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <Dropdown
                                                    dropdown={this.props.userDropdown}
                                                    dropdownLabelOverride={'Ansprechpartner'}
                                                    dropdownList={this.props.allUsers}
                                                    itemIdProp={'id'}
                                                    itemLabelProp={'dropdownLabel'}
                                                    defaultSelectedLabel={'Bitte wählen'}
                                                    filterProp={'searchName'}
                                                    filterBy={this.props.userSearch}
                                                    multiple={false}/>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.trainingToEdit.lecturer || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'lecturer', val: e.target.value})} />
                                                    <label>Dozent </label>
                                                </div>
                                            </div>
                                            {this.props.trainingModal.action === 'newTraining' &&

                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">

                                                    <DatePicker
                                                        multiple
                                                        weekStartDayIndex={1}
                                                        weekDays={['So','Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']}
                                                        months={["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "October", "November", "Dezember"]}
                                                        sort
                                                        mapDays={({ date }) => {
                                                            let isWeekend = [0].includes(date.weekDay.index)

                                                            if (isWeekend) return {
                                                                disabled: true,
                                                                style: { color: "#ccc" },
                                                            }
                                                        }}
                                                        format={'DD.MM.YYYY'}
                                                        onChange={(date) => {
                                                            this.props.updateTrainingToEdit({
                                                                prop: 'dates',
                                                                val: date.toString(),
                                                            })
                                                        }}
                                                        value={this.props.trainingToEdit.dates ? this.props.trainingToEdit.dates.split(",") : (this.props.trainingToEdit.dateFormated ? this.props.trainingToEdit.dateFormated : '')}
                                                    />

                                                    <label>Datum</label>
                                                </div>
                                            </div>

                                            }

                                            {this.props.trainingModal.action === 'newTraining' &&

                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input
                                                        type="text"
                                                        value={this.props.trainingToEdit.time || ""}
                                                        onChange={(e) => this.props.updateTrainingToEdit({prop: 'time', val: e.target.value}) }
                                                    />
                                                    <label>Uhrzeit</label>
                                                </div>
                                            </div>

                                            }

                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="number" value={this.props.trainingToEdit.contingent || ''} onChange={(e) => this.props.updateTrainingToEdit({prop: 'contingent', val: e.target.value})} />
                                                    <label>Verfügbare Plätze</label>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.trainingToEdit.ratings.map((rating,index) => <Rating key={`ratingIndex${index}`} ratingIndex={index}/>)}
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label={this.props.trainingModal.action === 'newTraining' ? 'Erstellen' : 'Speichern'}
                                                    inverted
                                                    onClick={() => this.addTraining()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="rescheduleModal" className={this.props.rescheduleModal.view ? 'modal visible': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-graduation-cap" aria-hidden="true"/> Schulung verschieben
                                </span> <span className="close">
                                    <a href="/" onClick={(e) => { e.preventDefault(); this.props.updateTrainingToEdit({prop: 'resetObj', val: ''}); this.props.updateModal({path: 'training.reschedule', prop: 'view', val: !this.props.rescheduleModal.view}); }}>
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <DatePicker
                                                        multiple
                                                        weekStartDayIndex={1}
                                                        weekDays={['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']}
                                                        months={["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "October", "November", "Dezember"]}
                                                        sort
                                                        mapDays={({ date }) => {
                                                            let isWeekend = [0].includes(date.weekDay.index)

                                                            if (isWeekend) return {
                                                                disabled: true,
                                                                style: { color: "#ccc" },
                                                            }
                                                        }}
                                                        format={'DD.MM.YYYY'}
                                                        onChange={(date) => {
                                                            this.props.updateTrainingToEdit({
                                                                prop: 'dates',
                                                                val: date.toString(),
                                                            })
                                                        }}
                                                        value={this.props.trainingToEdit.dates ? this.props.trainingToEdit.dates.split(",") : (this.props.trainingToEdit.dateFormated ? this.props.trainingToEdit.dateFormated : '')}
                                                    />

                                                    <label>Datum</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input
                                                        type="text"
                                                        value={this.props.trainingToEdit.time || ""}
                                                        onChange={(e) => this.props.updateTrainingToEdit({prop: 'time', val: e.target.value}) }
                                                    />
                                                    <label>Uhrzeit</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label="verschieben"
                                                    inverted
                                                    onClick={() => this.rescheduleTraining()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="registerMessageModal" className={this.props.registerMessage !== '' ? 'modal visible top-modal': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"/> {this.props.registerStatus === 500 ? 'Fehler' : 'Warnung'}
                                </span> <span className="close">
                                    <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.updateRegisterStatus(200);
                                        this.props.updateRegisterMessage('');
                                        this.props.updateForceRegister('');
                                    }}>
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <h3>{this.props.registerMessage}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.props.registerStatus !== 400 &&
                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label="Ok"
                                                    inverted
                                                    onClick={() => {
                                                        this.props.updateRegisterStatus(200);
                                                        this.props.updateRegisterMessage('');
                                                        this.props.updateForceRegister('');
                                                    }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.props.registerStatus === 400 &&
                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-4 large-4">
                                                <Button
                                                    label="Nein"
                                                    inverted
                                                    onClick={() => {
                                                        this.props.updateRegisterStatus(200);
                                                        this.props.updateRegisterMessage('');
                                                        this.props.updateForceRegister('');
                                                    }}/>
                                            </div>
                                            <div className="small-12 medium-4 large-4">
                                                <Button
                                                    label="Ja"
                                                    inverted
                                                    onClick={() => {
                                                        if(this.state.referrer === 'registerForTraining') {
                                                            this.registerForTraining(this.props.forceRegister)
                                                        } else {
                                                            this.registerForTrainingAdmin(this.props.forceRegister)
                                                        }
                                                    }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </form>
                        </div>
                    </div>
                    <div id="addRegistrationModal" className={this.props.addRegistrationModal.view ? 'modal visible': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-graduation-cap" aria-hidden="true"/>{
                                    this.props.currentTraining ?
                                        (this.props.currentTraining.contingent > 0 ? "Mitarbeiter Anmelden" : "Mitarbeiter auf Warteliste setzen") :
                                        "Mitarbeiter Anmelden"
                                }
                                </span> <span className="close">
                                    <a href="/" onClick={(e) => { e.preventDefault(); this.props.updateCurrentTraining(null); this.props.updateModal({path: 'training.addRegistration', prop: 'view', val: !this.props.addRegistrationModal.view}); }}>
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <div className="group">
                                                    <input className="header-input" type="text" name='test' value={this.props.userSearch || ''} style={{'maxWidth': '250px'}}  placeholder={'Suche'}
                                                           onChange={
                                                               (e) => {
                                                                   this.props.updateUserSearch(e.target.value);
                                                                   if (e.target.value) {
                                                                       this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'view', val:true});
                                                                   } else {
                                                                       this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'view', val:false});
                                                                   }

                                                               }
                                                           }
                                                    />
                                                </div>
                                            </div>
                                            <div className="small-12 medium-12 large-12">
                                                <Dropdown
                                                    dropdown={this.props.userDropdown}
                                                    dropdownList={this.props.allUsers}
                                                    itemIdProp={'id'}
                                                    itemLabelProp={'dropdownLabel'}
                                                    defaultSelectedLabel={'Bitte wählen'}
                                                    filterProp="searchName"
                                                    filterBy={this.props.userSearch}
                                                    multiple={false}/>
                                            </div>
                                            <div className="small-12 medium-12 large-12">
                                                <div className="group">
                                                    <input type="text"
                                                           value={this.state.trainingInfo}
                                                           placeholder={''}
                                                           onChange={(e) =>
                                                               this.setState({
                                                                   ...this.state,
                                                                   trainingInfo: e.target.value
                                                               })
                                                           }
                                                    />
                                                    <label>Info</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label="Anmelden"
                                                    inverted
                                                    onClick={() => this.registerForTrainingAdmin()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="addValuationModal" className={this.props.valuationModal.view ? 'modal visible': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-graduation-cap" aria-hidden="true"/> Bewertung abgeben
                                </span> <span className="close">
                                    <a href="/" onClick={(e) => { e.preventDefault(); this.props.updateValuation({prop: 'resetObj', val: ''}); this.props.updateModal({path: 'training.valuation', prop: 'view', val: !this.props.valuationModal.view}); }}>
                                        <i className="fa fa-times-circle-o"/>
                                    </a>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            {this.props.valuation.ratings && this.props.valuation.ratings.map((rating, ratingIndex) => {
                                                if(!rating.label) {
                                                    return '';
                                                }
                                                if(rating.type !== 'Sternbewertung') {
                                                    return '';
                                                }
                                                return (
                                                    <div className="small-12 medium-12 large-12">
                                                        <div className="group">
                                                            <div>{rating.label}</div>
                                                            <div style={{color: '#e4c529'}}>
                                                                <i className={rating.value > 0 ? "fa fa-star fa-5x" : "fa fa-star-o fa-5x"} onClick={(e) => this.props.updateValuation({prop: 'ratings.'+ratingIndex+'.value', val: 1}) } /> &nbsp;
                                                                <i className={rating.value > 1 ? "fa fa-star fa-5x" : "fa fa-star-o fa-5x"} onClick={(e) => this.props.updateValuation({prop: 'ratings.'+ratingIndex+'.value', val: 2}) } /> &nbsp;
                                                                <i className={rating.value > 2 ? "fa fa-star fa-5x" : "fa fa-star-o fa-5x"} onClick={(e) => this.props.updateValuation({prop: 'ratings.'+ratingIndex+'.value', val: 3}) } /> &nbsp;
                                                                <i className={rating.value > 3 ? "fa fa-star fa-5x" : "fa fa-star-o fa-5x"} onClick={(e) => this.props.updateValuation({prop: 'ratings.'+ratingIndex+'.value', val: 4}) } /> &nbsp;
                                                                <i className={rating.value> 4 ? "fa fa-star fa-5x" : "fa fa-star-o fa-5x"} onClick={(e) => this.props.updateValuation({prop: 'ratings.'+ratingIndex+'.value', val: 5}) } /> &nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {this.props.valuation.ratings && this.props.valuation.ratings.map((rating, ratingIndex) => {
                                                if(!rating.label) {
                                                    return '';
                                                }
                                                if(rating.type === 'Sternbewertung') {
                                                    return '';
                                                }

                                                return(
                                                    <div className="small-12 medium-12 large-12">
                                                        <div className="group">
                                                            <textarea value={rating.value || ''} onChange={(e) => this.props.updateValuation({prop: 'ratings.'+ratingIndex+'.value', val: e.target.value})}/>
                                                            <label>{rating.label}</label>
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label="Bewertung abgeben"
                                                    inverted
                                                    onClick={() => this.addValuation()}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="content-wrapper inner-ver ">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                                            <span><i className="fa fa-graduation-cap" aria-hidden="true"></i> Veranstaltungen {this.props.loadingAnimation && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>}</span>
                                        </div>
                                        <Tabs
                                            selectedIndex={this.props.activeTab}
                                            onSelect={(index) => this.props.updateActiveTab(index)}
                                        >
                                            <TabList className="tabs-training react-tabs__tab-list">
                                                {this.props.categories.map(tab => <Tab key={tab.label}>{tab.label}</Tab>)}
                                            </TabList>
                                            {this.props.categories.map(tab => (
                                                <TabPanel key={tab.label}>
                                                    {this.props.list.filter(n => n.category === tab.label).map((training, index) => {
                                                        if(training.category==='Schulungen'){
                                                            newTrainingSchulungen++;
                                                        }
                                                        else if(training.category==='Termine'){
                                                            newTrainingTermin++;
                                                        }
                                                        else if(training.category==='Sonstiges'){
                                                            newTrainingSonstiges++;
                                                        }
                                                        if(training.id === 'defaultId') {
                                                            return (
                                                                <a href="/#-" className="dokument-item" key={index}>
                                                                    <div className="default-list-item">
                                                                        <span></span>
                                                                    </div>
                                                                </a>
                                                            )
                                                        } else {
                                                            return (
                                                                <div key={index} ref={(ref) => {
                                                                    traingReferenceIds[training.id] = ref;
                                                                }}>
                                                                    <div className="training">
                                                                        <div className="headline-small">{training.title} { this.can('schulung_bearbeiten') &&
                                                                        <>
                                                                            <i className="fa fa-pencil"
                                                                               aria-hidden="true"
                                                                               style={{marginRight: '5px'}}
                                                                               onClick={() => {
                                                                                   this.props.updateModal({
                                                                                       path: 'training.training',
                                                                                       prop: 'view',
                                                                                       val: !this.props.trainingModal.view,
                                                                                   });
                                                                                   this.props.updateModal({
                                                                                       path: 'training.training',
                                                                                       prop: 'action',
                                                                                       val: 'editTraining',
                                                                                   });
                                                                                   this.props.updateTrainingToEdit({prop: 'id', val: training.id});
                                                                                   this.props.updateTrainingToEdit({prop: 'title', val: training.title});
                                                                                   this.props.updateTrainingToEdit({prop: 'description', val: training.description});
                                                                                   this.props.updateTrainingToEdit({prop: 'address', val: training.address});
                                                                                   this.props.updateTrainingToEdit({prop: 'zip', val: training.zip});
                                                                                   this.props.updateTrainingToEdit({prop: 'city', val: training.city});
                                                                                   this.props.updateTrainingToEdit({prop: 'contingent', val: training.contingent});
                                                                                   this.props.updateTrainingToEdit({prop: 'q1', val: training.q1});
                                                                                   this.props.updateTrainingToEdit({prop: 'q2', val: training.q2});
                                                                                   this.props.updateTrainingToEdit({prop: 'lecturer', val: training.lecturer});
                                                                                   this.props.updateTrainingToEdit({prop: 'ratings', val: training.ratings});
                                                                                   this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'selectedId', val:training.contactPerson});
                                                                                   this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'selectedLabel', val:training.contactPersonName});
                                                                                   this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedId', val:training.category});
                                                                                   this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedLabel', val:training.category});
                                                                               }}
                                                                            />
                                                                            <i className="fa fa-copy" aria-hidden="true"
                                                                                onClick={() => this.copyTraining(training)}
                                                                            />
                                                                        </>
                                                                        }



                                                                        </div>
                                                                        <div className="training-content">
                                                                            <div>
                                                                                <p>{training.description}</p>
                                                                            </div>
                                                                            <div>
                                                                                <div className="inline">
                                                                                    <ul>
                                                                                        <li>Anschrift</li>
                                                                                        <li>Wann</li>
                                                                                        <li>Verfügbarkeit</li>
                                                                                        <li>Ansprechpartner</li>
                                                                                        <li>Dozent</li>
                                                                                    </ul>
                                                                                    <ul>
                                                                                        <li>{training.address}, {training.zip} {training.city}
                                                                                        </li>
                                                                                        <li>{training.dateFormated} / {training.time} Uhr
                                                                                        </li>
                                                                                        <li>{training.contingent} von {this.countAppointmentsByWaitingListStatus(training, false) + training.contingent}</li>
                                                                                        <li>{training.contactPersonName}</li>
                                                                                        <li>{training.lecturer}</li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="inline">
                                                                                    { this.isOnWaitingList(training) &&
                                                                                    <Button
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.declineRegistrationForTraining(training);
                                                                                        }}
                                                                                        label={'Von Warteliste entfernen'} inverted/>
                                                                                    }
                                                                                    { !this.isOnWaitingList(training) &&
                                                                                    <Button
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.registerForTraining(training.id);
                                                                                            this.setState({...this.state, referrer: 'registerForTraining'})
                                                                                        }}
                                                                                        label={training.contingent > 0 ? 'Interessiert' : 'Warteliste'} inverted/>

                                                                                    }

                                                                                    { this.can('mitarbeiter_anmelden_alle', 'mitarbeiter_anmelden_eigene_filiale') &&
                                                                                    <Button label={training.contingent > 0 ? "Mitarbeiter anmelden" : "Mitarbeiter auf Warteliste setzen"} inverted
                                                                                            onClick={() => {
                                                                                                this.props.updateModal({
                                                                                                    path: 'training.addRegistration',
                                                                                                    prop: 'view',
                                                                                                    val: !this.props.addRegistrationModal.view,
                                                                                                });
                                                                                                this.props.updateCurrentTraining(training);
                                                                                                this.setState({...this.state, referrer: 'registerForTrainingAdmin'})
                                                                                            }}
                                                                                    />
                                                                                    }

                                                                                </div>
                                                                                <div className="inline">
                                                                                    { this.can('teilnehmerliste_anzeigen_alle', 'teilnehmerliste_anzeigen_eigene_filiale') &&
                                                                                    <a href="/"
                                                                                       onClick={(e) => {
                                                                                           e.preventDefault();
                                                                                           this.props.updateParticipantListView({
                                                                                               path: 'list',
                                                                                               id: training.id,
                                                                                               showList: !training.showList
                                                                                           })
                                                                                       }}
                                                                                    >Teilnehmerliste {training.showList === true ? 'verbergen' : 'anzeigen'}</a>
                                                                                    }
                                                                                    { this.can('schulung_verschieben') &&

                                                                                    <a href="/"
                                                                                       onClick={(e) => {
                                                                                           e.preventDefault();
                                                                                           this.props.updateModal({
                                                                                               path: 'training.reschedule',
                                                                                               prop: 'view',
                                                                                               val: !this.props.rescheduleModal.view,
                                                                                           });
                                                                                           this.props.updateTrainingToEdit({
                                                                                               prop: 'id',
                                                                                               val: training.id
                                                                                           });
                                                                                           this.props.updateTrainingToEdit({
                                                                                               prop: 'dates',
                                                                                               val: training.dates ? training.dates : training.dateFormated
                                                                                           });
                                                                                           this.props.updateTrainingToEdit({
                                                                                               prop: 'time',
                                                                                               val: training.time
                                                                                           });
                                                                                           this.props.updateTrainingToEdit({
                                                                                               prop: 'category',
                                                                                               val: training.category
                                                                                           });
                                                                                           this.props.updateTrainingToEdit({
                                                                                               prop: 'ratings',
                                                                                               val: training.ratings
                                                                                           });
                                                                                           this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'selectedId', val:training.contactPerson});
                                                                                           this.props.updateDropdown({dropdown: this.props.userDropdown.path, prop:'selectedLabel', val:training.contactPersonName});
                                                                                           this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedId', val:training.category});
                                                                                           this.props.updateDropdown({dropdown: this.props.categoryDropdown.path, prop:'selectedLabel', val:training.category});
                                                                                       }}
                                                                                    >Veranstaltung verschieben</a>
                                                                                    }
                                                                                    { this.can('teilnehmerliste_herunterladen_alle', 'teilnehmerliste_herunterladen_eigene_filiale') &&
                                                                                    <div>
                                                                                        <form action={this.getCurrentLink(training)}
                                                                                              method="post"
                                                                                              ref={(ref) => {
                                                                                                  this.downloadCurrentForm[training.id] = ref;
                                                                                              }}>
                                                                                            <input type="hidden" name="authTok"
                                                                                                   value={'Bearer ' + this.props.token}
                                                                                            />
                                                                                        </form>
                                                                                        <a href="/"
                                                                                           onClick={(e) => {e.preventDefault(); this.downloadCurrentForm[training.id].submit()}}
                                                                                        >Teilnehmerliste herunterladen</a>
                                                                                    </div>
                                                                                    }
                                                                                    {this.can('schulung_absagen') &&
                                                                                    <a href="/"
                                                                                       onClick={(e) => {
                                                                                           e.preventDefault();
                                                                                           this.removeTraining(training, 'list');
                                                                                       }}
                                                                                    >Veranstaltung absagen</a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        training.showList && !this.props.loadingAnimation &&
                                                                        <div className="overflow-x" style={{'boxShadow': '0 0 37px -7px rgba(0,0,0,.5)'}}>
                                                                            <table>
                                                                                <tbody>
                                                                                {training.appointments.length > 0 &&
                                                                                <tr>
                                                                                    <th>Name</th>
                                                                                    <th>Vorname</th>
                                                                                    <th>Standort</th>
                                                                                    <th>Status</th>
                                                                                    <th>Benutzergruppe</th>
                                                                                    <th>Bearbeitet von</th>
                                                                                    <th>Bearbeitet am</th>
                                                                                    <th>Aktionen</th>
                                                                                </tr>
                                                                                }
                                                                                {training.appointments.length === 0 &&
                                                                                <tr>
                                                                                    <th>Noch keine Anmeldungen</th>
                                                                                </tr>
                                                                                }

                                                                                {  training.appointments.filter((appointment) => getUserData(appointment.userData)[5] !== 'expired').sort(this.sortByLocations).map((appointment, appointmentIndex) => {
                                                                                       const confirmator = getPopupUser(this.props.userPopup, appointment.confirmedBy)
                                                                                       return (

                                                                                        <tr key={appointmentIndex}
                                                                                        style={this.filterParicipantList(appointment, training)}>
                                                                                        <td className="hoverable-user">
                                                                                            <u>{getUserData(appointment.userData)[1] || this.deletedUser()}</u>
                                                                                            <div
                                                                                                className="user-info-popup">
                                                                                                {getUserData(appointment.userData)[6] ? (
                                                                                                    <img
                                                                                                        src={getUserData(appointment.userData)[6]}
                                                                                                        alt={`${getUserData(appointment.userData)[0]} ${getUserData(appointment.userData)[1]}`}
                                                                                                        className="user-avatar"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <i className="fa fa-user user-icon"></i> // Font Awesome icon
                                                                                                )}
                                                                                                <div
                                                                                                    className="user-details">
                                                                                                    <h4>{getUserData(appointment.userData)[0]} {getUserData(appointment.userData)[1]}</h4>
                                                                                                    <p>Benutzergruppe: {getUserData(appointment.userData)[4]}</p>
                                                                                                    <p>Standort: {getUserData(appointment.userData)[2]}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{getUserData(appointment.userData)[0] || this.deletedUser()}</td>
                                                                                        <td>{getUserData(appointment.userData)[2] || this.deletedUser()}</td>
                                                                                        <td>{appointment.waitingList ? 'Warteliste' : (appointment.approved ? 'Genehmigt' : 'Angefragt')}</td>
                                                                                        <td>{getUserData(appointment.userData)[4] ? getUserData(appointment.userData)[4].split(':').pop().trim() : this.deletedUser()}</td>
                                                                                        <td className='hoverable-user'>
                                                                                            <span><u>{getUserData(appointment.confirmedByData)[1]} {getUserData(appointment.confirmedByData)[0]}</u></span>
                                                                                            {confirmator.fullName &&
                                                                                                <div
                                                                                                    className="user-info-popup">
                                                                                                    {confirmator.resourceUri ? (
                                                                                                        <img
                                                                                                            src={confirmator.resourceUri}
                                                                                                            alt={confirmator.fullName}
                                                                                                            className="user-avatar"
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <i className="fa fa-user user-icon"></i> // Font Awesome icon
                                                                                                    )}
                                                                                                    <div
                                                                                                        className="user-details">
                                                                                                        <h4>{confirmator.fullName}</h4>
                                                                                                        <p>Benutzergruppe: {confirmator.roleLabel}</p>
                                                                                                        <p>Standort: {confirmator.locationLabel}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                        <span>{appointment.confirmedAtFormated}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            {appointment.approved &&
                                                                                                <i className="fa fa-check"
                                                                                                   style={{'opacity': 0.2}}
                                                                                                   aria-hidden="true"/>
                                                                                            }
                                                                                            {!appointment.approved && this.canApproveOrDecline(appointment) && !appointment.waitingList &&
                                                                                                <i className="fa fa-check"
                                                                                                   style={{'cursor': 'pointer'}}
                                                                                                   aria-hidden="true"
                                                                                                   onClick={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       this.props.approveRegistrationForTraining({
                                                                                                           'trainingId': training.id,
                                                                                                           'index': appointmentIndex,
                                                                                                           appointment: appointment
                                                                                                       });
                                                                                                   }}

                                                                                                />
                                                                                            }
                                                                                            {!appointment.approved &&
                                                                                                this.canApproveOrDecline(appointment) &&
                                                                                                appointment.waitingList && (this.can("waitinglist_can_edit") || training.contactPerson === this.props.currentUser.id) &&
                                                                                                <i className="fa fa-check"
                                                                                                   style={{'cursor': 'pointer'}}
                                                                                                   aria-hidden="true"
                                                                                                   onClick={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       this.props.approveRegistrationForTraining({
                                                                                                           'trainingId': training.id,
                                                                                                           'index': appointmentIndex,
                                                                                                           appointment: appointment
                                                                                                       });
                                                                                                   }}

                                                                                                />
                                                                                            }
                                                                                            {this.canApproveOrDecline(appointment, true) && !appointment.waitingList &&
                                                                                                <i className="fa fa-ban"
                                                                                                   aria-hidden="true"
                                                                                                   onClick={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       const confirmResult = window.confirm('Möchten Sie wirklich die Anmeldung ablehnen?');
                                                                                                       if (confirmResult === true) {
                                                                                                           this.props.declineRegistrationForTraining({
                                                                                                               'trainingId': training.id,
                                                                                                               'index': appointmentIndex,
                                                                                                               appointment: appointment
                                                                                                           });
                                                                                                       }

                                                                                                   }}
                                                                                                />
                                                                                            }
                                                                                            {this.canApproveOrDecline(appointment, true) && appointment.waitingList
                                                                                                && (this.can("waitinglist_can_edit") || training.contactPerson === this.props.currentUser.id) &&
                                                                                                <i className="fa fa-ban"
                                                                                                   aria-hidden="true"
                                                                                                   onClick={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       const confirmResult = window.confirm('Möchten Sie wirklich die Anmeldung ablehnen?');
                                                                                                       if (confirmResult === true) {
                                                                                                           this.props.declineRegistrationForTraining({
                                                                                                               'trainingId': training.id,
                                                                                                               'index': appointmentIndex,
                                                                                                               appointment: appointment
                                                                                                           });
                                                                                                       }

                                                                                                   }}
                                                                                                />
                                                                                            }

                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                                )
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        training.showList && this.props.loadingAnimation &&
                                                                        <div className="overflow-x" style={{'boxShadow': '0 0 37px -7px rgba(0,0,0,.5)'}}>
                                                                            <table>
                                                                                <tbody>
                                                                                {
                                                                                    <tr>
                                                                                        <th>Daten werden gespeichert ...</th>
                                                                                    </tr>
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                    {(newTrainingSchulungen===0 && tab.label==='Schulungen') && !this.props.loadingAnimation  && (<div className="noNewsFound"><p>Keine Veranstaltungen vorhanden</p></div>)}
                                                    {(newTrainingTermin===0 && tab.label==='Termin') && !this.props.loadingAnimation && (<div className="noNewsFound"><p>Keine Veranstaltungen vorhanden</p></div>)}
                                                    {(newTrainingSonstiges===0 && tab.label==='Sonstiges') &&!this.props.loadingAnimation && (<div className="noNewsFound"><p>Keine Veranstaltungen vorhanden</p></div>)}
                                                </TabPanel>
                                            ))}
                                        </Tabs>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { this.props.listCount > this.props.list.length &&
                    <div
                        className={this.props.listCount ? "content-wrapper visible" : "content-wrapper hidden"}>
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <Button
                                        label="Weitere Veranstaltungen anzeigen"
                                        inverted
                                        onClick={() => this.props.loadTrainingList({'old': false, 'from': this.props.list.length})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    { this.props.oldList.length > 0 && !this.props.yearFilter &&
                    <div
                        className={this.props.oldListCount ? "content-wrapper visible" : "content-wrapper hidden"}>
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <Button
                                        label={this.props.oldListView ? 'Vergangene Veranstaltungen verbergen': 'Vergangene Veranstaltungen anzeigen'}
                                        inverted
                                        onClick={() => this.props.updateOldListView(!this.props.oldListView)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    { this.props.oldListView && !this.props.yearFilter &&

                    <div className="content-wrapper inner-ver ">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                                            <span><i className="fa fa-graduation-cap" aria-hidden="true"></i>Vergangene Veranstaltungen</span>
                                        </div>
                                        <Tabs
                                            selectedIndex={this.props.activeTab}
                                            onSelect={(index) => this.props.updateActiveTab(index)}
                                        >
                                            <TabList className="tabs-training react-tabs__tab-list">
                                                {this.props.categories.map(tab => <Tab key={tab.label}>{tab.label}</Tab>)}
                                            </TabList>
                                            {this.props.categories.map(tab => (
                                                <TabPanel key={tab.label}>
                                                    {this.props.oldList.filter(n => n.category === tab.label).map((training, index) => {
                                                        if(training.id === 'defaultId') {
                                                            return (
                                                                <a href="/#-" className="dokument-item" key={index}>
                                                                    <div className="default-list-item">
                                                                        <span></span>
                                                                    </div>
                                                                </a>
                                                            )
                                                        } else {
                                                            return (
                                                                <div key={index} ref={(ref) => {
                                                                    traingReferenceIds[training.id] = ref;
                                                                }}>
                                                                    <div className="training">
                                                                        <div className="headline-small">{training.title}</div>
                                                                        <div className="training-content">
                                                                            <div>
                                                                                <p>{training.description}</p>
                                                                            </div>
                                                                            <div>
                                                                                <div className="inline">
                                                                                    <ul>
                                                                                        <li>Anschrift</li>
                                                                                        <li>Wann</li>
                                                                                        <li>Verfügbarkeit</li>
                                                                                    </ul>
                                                                                    <ul>
                                                                                        <li>{training.address}, {training.zip} {training.city}</li>
                                                                                        <li>{training.dateFormated} / {training.time} Uhr
                                                                                        </li>
                                                                                        <li>{training.contingent} von {this.countAppointmentsByWaitingListStatus(training, false) + training.contingent}</li>
                                                                                    </ul>
                                                                                </div>
                                                                                {this.can('teilnehmerliste_anzeigen_alle', 'teilnehmerliste_anzeigen_eigene_filiale') &&
                                                                                <a href="/"
                                                                                   onClick={(e) => {
                                                                                       e.preventDefault();
                                                                                       this.props.updateParticipantListView({
                                                                                           path: 'oldList',
                                                                                           id: training.id,
                                                                                           showList: !training.showList
                                                                                       })
                                                                                   }}
                                                                                >Teilnehmerliste {training.showList === true ? 'verbergen' : 'anzeigen'}</a>
                                                                                }
                                                                                { this.can('teilnehmerliste_herunterladen_alle', 'teilnehmerliste_herunterladen_eigene_filiale') &&
                                                                                <div>
                                                                                    <form action={this.getCurrentLink(training)}
                                                                                          method="post"
                                                                                          ref={(ref) => {
                                                                                              this.downloadCurrentForm[training.id] = ref;
                                                                                          }}>
                                                                                        <input type="hidden" name="authTok"
                                                                                               value={'Bearer ' + this.props.token}
                                                                                        />
                                                                                    </form>
                                                                                    <a href="/"
                                                                                       onClick={(e) => {e.preventDefault(); this.downloadCurrentForm[training.id].submit()}}
                                                                                    >Teilnehmerliste herunterladen</a>
                                                                                </div>
                                                                                }
                                                                                {this.canValuate(this.props.currentUser, training) &&
                                                                                <a href="/"
                                                                                   onClick={(e) => {
                                                                                       e.preventDefault();
                                                                                       this.props.updateModal({
                                                                                           path: 'training.valuation',
                                                                                           prop: 'view',
                                                                                           val: !this.props.valuationModal.view,
                                                                                       });
                                                                                       this.props.updateValuation({prop: 'training', val: training})
                                                                                       this.props.updateValuation({prop: 'userId', val: this.props.currentUser.id})
                                                                                       this.props.updateValuation({prop: 'ratings', val: training.ratings})
                                                                                   }}
                                                                                >Bewertung abgeben</a>
                                                                                }
                                                                                {this.can('bewertungen_herunterladen_alle', 'bewertungen_herunterladen_eigene_filiale') &&
                                                                                <div>
                                                                                    <form action={this.getLink(training)}
                                                                                          method="post"
                                                                                          ref={(ref) => {
                                                                                              this.downloadForm[index] = ref;
                                                                                          }}>
                                                                                        <input type="hidden" name="authTok"
                                                                                               value={'Bearer ' + this.props.token}
                                                                                        />
                                                                                    </form>
                                                                                    <a href="/"
                                                                                       onClick={(e) => {e.preventDefault(); this.downloadForm[index].submit()}}
                                                                                    >{training.valuations.length || 0}/{training.countApprovedAppointments} Bewertungen herunterladen</a>
                                                                                </div>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        training.showList &&
                                                                        <div className="overflow-x" style={{'boxShadow': '0 0 37px -7px rgba(0,0,0,.5)'}}>
                                                                            <table>
                                                                                <tbody>
                                                                                {training.appointments.length > 0 &&
                                                                                    <tr>
                                                                                        <th>Name</th>
                                                                                        <th>Vorname</th>
                                                                                        <th>Standort</th>
                                                                                        <th>Status</th>
                                                                                        <th>Benutzergruppe</th>
                                                                                        <th>Bearbeitet von</th>
                                                                                        <th>Bearbeitet am</th>
                                                                                        <th>Aktionen</th>
                                                                                    </tr>
                                                                                }
                                                                                {training.appointments.length === 0 &&
                                                                                <tr>
                                                                                    <th>keine Anmeldungen</th>
                                                                                </tr>
                                                                                }

                                                                                {  training.appointments.filter((appointment) => getUserData(appointment.userData)[5] !== 'expired').sort(this.sortByLocations).map((appointment, appointmentIndex) => {
                                                                                    const confirmator = getPopupUser(this.props.userPopup, appointment.confirmedBy)
                                                                                return (
                                                                                    <tr key={appointmentIndex}
                                                                                        style={this.filterParicipantList(appointment)} >
                                                                                        <td className={'hoverable-user'}>
                                                                                            <u>{getUserData(appointment.userData)[1] || this.deletedUser()}</u>
                                                                                            <div
                                                                                                className="user-info-popup">
                                                                                                {getUserData(appointment.userData)[6] ? (
                                                                                                    <img
                                                                                                        src={getUserData(appointment.userData)[6]}
                                                                                                        alt={`${getUserData(appointment.userData)[0]} ${getUserData(appointment.userData)[1]}`}
                                                                                                        className="user-avatar"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <i className="fa fa-user user-icon"></i> // Font Awesome icon
                                                                                                )}
                                                                                                <div
                                                                                                    className="user-details">
                                                                                                    <h4>{getUserData(appointment.userData)[0]} {getUserData(appointment.userData)[1]}</h4>
                                                                                                    <p>Benutzergruppe: {getUserData(appointment.userData)[4]}</p>
                                                                                                    <p>Standort: {getUserData(appointment.userData)[2]}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{getUserData(appointment.userData)[0] || this.deletedUser()}</td>
                                                                                        <td>{getUserData(appointment.userData)[2] || this.deletedUser()}</td>
                                                                                        <td>{appointment.waitingList ? 'Warteliste' : (appointment.approved ? 'Genehmigt' : 'Angefragt')}</td>
                                                                                        <td>{getUserData(appointment.userData)[4] ? getUserData(appointment.userData)[4].split(':').pop().trim() : this.deletedUser()}</td>
                                                                                        <td className='hoverable-user'>
                                                                                            <span><u>{getUserData(appointment.confirmedByData)[1]} {getUserData(appointment.confirmedByData)[0]}</u></span>
                                                                                            {confirmator.fullName &&
                                                                                                <div
                                                                                                    className="user-info-popup">
                                                                                                    {confirmator.resourceUri ? (
                                                                                                        <img
                                                                                                            src={confirmator.resourceUri}
                                                                                                            alt={confirmator.fullName}
                                                                                                            className="user-avatar"
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <i className="fa fa-user user-icon"></i> // Font Awesome icon
                                                                                                    )}
                                                                                                    <div
                                                                                                        className="user-details">
                                                                                                        <h4>{confirmator.fullName}</h4>
                                                                                                        <p>Benutzergruppe: {confirmator.roleLabel}</p>
                                                                                                        <p>Standort: {confirmator.locationLabel}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{appointment.confirmedAtFormated}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <i className="fa fa-check"
                                                                                               style={{'opacity': 0.2}}
                                                                                               aria-hidden="true"></i>
                                                                                            {this.can('old_training_participant_list_can_edit') &&
                                                                                                <i className={appointment.approved ? 'fa fa-ban' : 'fa fa-minus'}
                                                                                                   style={{'opacity': appointment.approved ? 1 : 0.2}}
                                                                                                   aria-hidden="true"
                                                                                                   onClick={(e) => {
                                                                                                       e.preventDefault();
                                                                                                       if (!appointment.approved) {
                                                                                                           return;
                                                                                                       }
                                                                                                       const confirmResult = window.confirm('Möchten Sie wirklich die Anmeldung ablehnen?');
                                                                                                       if (confirmResult === true) {
                                                                                                           this.props.declineRegistrationForTraining({
                                                                                                               'trainingId': training.id,
                                                                                                               'index': appointmentIndex,
                                                                                                               appointment: appointment
                                                                                                           });
                                                                                                       }
                                                                                                   }}
                                                                                                />
                                                                                            }
                                                                                            {!this.can('old_training_participant_list_can_edit') &&
                                                                                                <i className={appointment.approved ? 'fa fa-ban' : 'fa fa-minus'}
                                                                                                   style={{'opacity': 0.2}}
                                                                                                   aria-hidden="true"></i>
                                                                                            }


                                                                                        </td>
                                                                                    </tr>
                                                                                )})
                                                                                }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </TabPanel>
                                            ))}
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {
                        (this.props.oldListView && (this.props.oldListCount > this.props.oldList.length)) && !this.props.yearFilter &&
                        <div
                            className={this.props.oldListCount ? "content-wrapper visible" : "content-wrapper hidden"}>
                            <div className="content-wrapper-box">
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <Button
                                            label="Weitere Veranstaltungen anzeigen"
                                            inverted
                                            onClick={() => this.props.loadTrainingList({'old': true, 'from': this.props.oldList.length})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>
                }
                {this.props.view === 3 &&
                <main>
                    <div className="content-wrapper margin-top">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline">
                                            <span><i className="fa fa-graduation-cap" aria-hidden="true"></i> Veranstaltungen Übersicht {this.props.loadingAnimation && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>}</span>
                                        </div>
                                        <div className="overflow-x">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th style={{minWidth: '400px'}} onClick={() => this.setSorting('title')}><span>Veranstaltung {this.showSorting('title')}</span></th>
                                                    <th onClick={() => this.setSorting('date')}><span>Datum {this.showSorting('date')}</span></th>
                                                    <th onClick={() => this.setSorting('time')}><span>Uhrzeit {this.showSorting('time')}</span></th>

                                                    {this.props.overviewList.length ?



                                                        Array(this.props.overviewList[0].users.length).fill().map((training, index) =>
                                                            <th style={{minWidth: '300px'}} key={index} onClick={() => this.setSorting('lastName:'+index)}><span>Teilnehmer{this.showSorting('lastName:'+index)}</span></th>
                                                        ) : null}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.props.overviewList
                                                    .sort((a, b) => {
                                                        let propa = a[this.state.sorting];
                                                        let propb = b[this.state.sorting];

                                                        if(this.state.sortingDirection === 'down') {
                                                            propa = b[this.state.sorting];
                                                            propb = a[this.state.sorting];
                                                        }
                                                        if(this.state.sorting.includes('lastName')) {
                                                            const tIndex = this.state.sorting.split(':')[1];
                                                            //const tIndex = a.trainings.map(t => t.id).indexOf(trainingId);
                                                            propa = a.users[tIndex].lastName
                                                            propb = b.users[tIndex].lastName
                                                            if(this.state.sortingDirection === 'down') {
                                                                propa = b.users[tIndex].lastName
                                                                propb = a.users[tIndex].lastName
                                                            }
                                                        }
                                                        if(this.state.sorting.includes('date')) {
                                                            propa = new Date(a[this.state.sorting]);
                                                            propb = new Date(b[this.state.sorting]);
                                                            if(this.state.sortingDirection === 'down') {
                                                                propa = new Date(b[this.state.sorting]);
                                                                propb = new Date(a[this.state.sorting]);
                                                            }
                                                        }
                                                        if (propa < propb) {
                                                            return 1;
                                                        } else if(propa > propb){
                                                            return -1;
                                                        }
                                                        return 0;
                                                    })
                                                    // .filter((item) => (item.user.searchName.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0)).map((item, index) => (
                                                    .filter((item) => item.users.some(user => user.lastName.toLowerCase().indexOf(this.props.userSearch.toLowerCase()) >= 0)).map((item, index) => (
                                                        <tr key={item.id} >
                                                            <td><span>{item.title}</span></td>
                                                            <td><span>{item.dateFormated}</span></td>
                                                            <td><span>{item.time}</span></td>

                                                            {item.users.map(user => <td key={user.id + item.id} className={'hoverable-user'}>
                                                                <u>{user.viewName}</u>
                                                                {
                                                                    user.viewName &&
                                                                    <div
                                                                        className="user-info-popup">
                                                                        {user.resourceUri ? (
                                                                            <img
                                                                                src={user.resourceUri}
                                                                                alt={`${user.fullName}`}
                                                                                className="user-avatar"
                                                                            />
                                                                        ) : (
                                                                            <i className="fa fa-user user-icon"></i> // Font Awesome icon
                                                                        )}
                                                                        <div
                                                                            className="user-details">
                                                                            <h4>{user.fullName}</h4>
                                                                            <p>Benutzergruppe: {user.team}</p>
                                                                            <p>Standort: {user.location}</p>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </td>)}
                                                        </tr>
                                                    ))
                                                }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div>
                                        <br/>
                                        <form action={this.getOverviewLink()}
                                              method="post"
                                              ref={(ref) => {
                                                  this.downloadOverviewForm[0] = ref;
                                              }}>
                                            <input type="hidden" name="authTok"
                                                   value={'Bearer ' + this.props.token}
                                            />
                                        </form>
                                        <IconLink type="download" onClick={(e) => {
                                            e.preventDefault();
                                            this.downloadOverviewForm[0].submit()
                                        }}>Übersicht Export XLSX</IconLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                }
                <footer id="footer-main">
                </footer>
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Training);
