import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class LocationInput extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        name: PropTypes.string,
        locationId: PropTypes.string,
        locationList: PropTypes.arrayOf(PropTypes.object),
    };

    locationName = () => {
        const location = this.props.locationList.find((loc) => loc.id === this.props.value);
        return location ? location.name : 'Unbekannt';
    };

    render() {
        const { className, locationList, value, ...childProps } = this.props;
        return (
            <input
                className={classNames(
                    this.props.className,
                    'location-input',
                    'location-input--readonly'
                )}
                {...childProps}
                value={this.locationName()}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    locationList: state.data.allLocations,
});

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationInput);
