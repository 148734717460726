import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* createClaimDropdownManufacturerSaga() {
    yield takeLatest(actionTypes.Data.SAVE_CLAIM_DROPDOWN_MANUFACTURER, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const claimDropdownManufacturer = {
                "claimDropdownManufacturer": {
                    "name": stateData.claimDropdownManufacturerToEdit.name,
                },
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownmanufacturer', {
                method: "POST",
                headers,
                body: JSON.stringify(claimDropdownManufacturer),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateClaimDropdownManufacturerToEdit({ id: '',name: ''}));
                yield put(actions.Ui.updateClaimDropdownManufacturerModal(false));
                yield put(actions.Data.loadClaimDropdownManufacturers(true));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* claimDropdownManufacturersSaga() {
    yield takeLatest([actionTypes.Data.LOAD_CLAIM_DROPDOWN_MANUFACTURERS, actionTypes.Ui.UPDATE_CLAIM_DROPDOWN_MANUFACTURER_LIST_PAGE], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const sorting = yield select(selectors.Settings.colorSort);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/claimdropdownmanufacturer/list?sort='+sorting, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const claimDropdownManufacturers = yield result.json();
                yield put(actions.Data.updateClaimDropdownManufacturers(claimDropdownManufacturers));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateClaimDropdownManufacturerSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_CLAIM_DROPDOWN_MANUFACTURER, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const claimDropdownManufacturer = {
                "claimDropdownManufacturer": {
                    name: stateData.claimDropdownManufacturerToEdit.name,
                },
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownmanufacturer/' + action.payload.claimDropdownManufacturer.id, {
                method: "PUT",
                headers,
                body: JSON.stringify(claimDropdownManufacturer),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateClaimDropdownManufacturerToEdit({id: '', name: ''}));
                yield put(actions.Ui.updateClaimDropdownManufacturerModal(false));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteClaimDropdownManufacturerSaga() {
    yield takeLatest(actionTypes.Data.DELETE_CLAIM_DROPDOWN_MANUFACTURER, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownmanufacturer/' + action.payload.claimDropdownManufacturer.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [createClaimDropdownManufacturerSaga, claimDropdownManufacturersSaga, updateClaimDropdownManufacturerSaga, deleteClaimDropdownManufacturerSaga];
