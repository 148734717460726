import * as PropTypes from 'prop-types';
import React from 'react';
import { Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { connect } from 'react-redux';
import { actions } from '../../Redux';
import Button from '../UI/Button';
import Card from '../UI/Card/Card';
//import FileList from '../UI/FileList/FileList';
import Icon from '../UI/Icon/Icon';
import { host } from '../../Sagas/host';

const mapStateToProps = (state) => ({
    token: state.ui.token,
    openedFolder: state.category.openedFolder,
    globalSettings: state.data.globalSettings,
});

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateStockFilter: (filter) => dispatch(actions.Data.updateStockFilter(filter)),
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        updateCurrentCategory: (category) => dispatch(actions.Category.updateCurrentCategory(category)),
        updateOpenedFolder: (folder) => dispatch(actions.Category.updateOpenedFolder(folder)),
        updateGlobalSettings: (globalSettings) => dispatch(actions.Data.updateGlobalSettings(globalSettings)),
    };
};

class CategoryTile extends React.Component {
    static propTypes = {
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        updateView: PropTypes.func,
    };

    contactPersons = () => {
        if (!this.props.category.contactPersons || !this.props.category.contactPersons.length) {
            return null;
        }
        return (
            <ul className="contact-persons">
                {this.props.category.contactPersons.map((contact) => (
                    <li key={contact.id}>
                        <p>
                            {contact.company}
                            {contact.responsibility && ' – ' + contact.responsibility}
                        </p>
                        <p className="contact-name">
                            <Icon type="user" />
                            {[contact.salutation, contact.firstName, contact.lastName].join(' ')}
                        </p>
                        {contact.phone && (
                            <p className="contact-phone">
                                <Icon type="phone" />
                                {contact.phone}
                            </p>
                        )}
                        {contact.email && (
                            <a href={'mailto:' + contact.email} className="contact-mail">
                                <Icon type="email" />
                                {contact.email}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    handleEdit = () => {
        this.props.onEdit && this.props.onEdit(this.props.category);
    };

    handleDelete = () => {
        this.props.onDelete && this.props.onDelete(this.props.category);
    };
    getLink(id) {
        return host + "/download/"+id;
    }

    downloadFile(id) {
        document.getElementById('downloadCategoryFile'+this.props.category.id).action = this.getLink(id);
        document.getElementById('downloadCategoryFile'+this.props.category.id).submit();
    }
    countFiles () {
        if (this.props.category.folders) {
            let count = this.props.category.folders[0].files.length
            this.props.category.folders[0].folders.map(folder => {
                count += folder.files.length
                folder.folders.map(subFolder => {
                    count += subFolder.files.length
                    return subFolder
                })
                return folder
            })
            return count
        }
        return 0

    }

    render() {
        return (
            <Card
                title={this.props.category.name}
                icon="category"
                halfWidth={false}
                extraClass={'medium-4 large-4 cat-tile'}
                header_extra={(
                    <span className="icon-list">
                        {this.props.onDelete &&
                        <Icon type="delete" onClick={() => this.handleDelete()} />}
                        {this.props.onEdit &&
                        <Icon type="edit" onClick={() => {this.handleEdit(); this.props.updateCurrentCategory(this.props.category)}} />}
                    </span>)}
            >
                <form id={'downloadCategoryFile'+this.props.category.id} action={this.getLink()} target="_blank"  method="post">
                    <input type="hidden" name="authTok" value={'Bearer ' + this.props.token}  />
                </form>
                <Accordion>
                    <AccordionItem>
                        <AccordionItemTitle>Allgemeine Informationen</AccordionItemTitle>
                        <AccordionItemBody>
                            <p className="single-item">
                                {this.props.category.info || 'Keine weiteren Informationen hinterlegt.'}</p>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            Technische Daten ({this.countFiles()})
                        </AccordionItemTitle>
                        { this.props.category.folders &&
                            <AccordionItemBody>
                                    {this.props.category.folders[0].folders.map((folder, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                            <div className="firstLevelF"
                                            onClick={() => {
                                                this.props.updateOpenedFolder({prop: 'parent', value: this.props.openedFolder.parent !== folder.id ? folder.id : ''});
                                                this.props.updateOpenedFolder({prop: 'folder', value: ''});
                                                }}
                                        > <i className={this.props.openedFolder.parent === folder.id ? 'fa fa-folder-open' : 'fa fa-folder'} /> {folder.name}</div>
                                        {this.props.openedFolder.parent === folder.id && folder.folders.map((subFolder, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                <div className="secondLevelF"
                                                    onClick={() => {
                                                        this.props.updateOpenedFolder({prop: 'folder', value: this.props.openedFolder.folder !== subFolder.id ? subFolder.id : ''})
                                                        }}
                                                > <i className={this.props.openedFolder.folder === subFolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} /> {subFolder.name}</div>
                                                    {this.props.openedFolder.folder === subFolder.id && subFolder.files.map((file, index) => {
                                                        return (<div className="thirdLevelF"  onClick={() => this.downloadFile(file.id)}> <i className={'fa fa-file'} /> {file.name}</div>)
                                                    })}
                                                </React.Fragment>
                                            )})}
                                            {this.props.openedFolder.parent === folder.id && folder.files.map((file, index) => {
                                                return (<div className="secondLevelF"  onClick={() => this.downloadFile(file.id)}> <i className={'fa fa-file'} /> {file.name}</div>)
                                            })}
                                        </React.Fragment>
                                    )
                                    })}
                                    {this.props.category.folders[0].files.map((file, index) => {
                                        return (<div key={index} className="firstLevelF"  onClick={() => this.downloadFile(file.id)}> <i className={'fa fa-file'} /> {file.name}</div>)
                                    })}
                            </AccordionItemBody>
                        }
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>Ansprechpartner
                            ({this.props.category.contactPersons.length})</AccordionItemTitle>
                        <AccordionItemBody>
                            {this.contactPersons() || <p className="single-item">
                                Kein Ansprechpartner hinterlegt.</p>}
                        </AccordionItemBody>
                    </AccordionItem>
                </Accordion>
                <div className="list-foo">
                    <Button label={'Zum Bestand'} inverted
                            onClick={() => {
                                if(this.props.category.link) {
                                    window.open(this.props.category.link);
                                } else {
                                    this.props.updateStockFilter({ prop: 'category', value: this.props.category.id });
                                    this.props.updateFilter({
                                        path: 'data.filter.stock.category',
                                        prop: 'selectedId',
                                        val: this.props.category.id,
                                    });
                                    this.props.updateFilter({
                                        path: 'data.filter.stock.category',
                                        prop: 'selectedLabel',
                                        val: this.props.category.name,
                                    });
                                    this.props.updateView('StockPage');
                                }

                                // this.props.updateGlobalSettings({
                                //     ...this.props.globalSettings,
                                //     googleSheetsLink: this.props.category.link
                                // });
                                //    this.props.updateView('GoogleSheetsPage');

                            }}
                    />
                </div>
            </Card>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTile);
