import * as PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import CardHeader from '../Card/CardHeader';
import Icon from '../Icon/Icon';
import './modal.scss';
import Alert from '../../../Containers/Alert';

export default class Modal extends React.Component {
    static propTypes = {
        icon: PropTypes.string,
        onClose: PropTypes.func,
        title: PropTypes.string,
    };

    render() {
        const { icon, title, style, ...childProps } = this.props;
        const styleOverride = {
            ...{
                overlay: {
                    zIndex: 12000,
                    backgroundColor: 'rgba(29, 29, 27, 0.85)',
                },
                content: {
                    maxWidth: '1000px',
                    margin: 'auto',
                    padding: 0,
                    border: 0,
                    backgroundColor: 'rgb(247, 247, 247)',
                    overflow: 'hidden',
                },
            },
            ...style,
        };
        return (
            <ReactModal
                style={styleOverride}
                appElement={document.getElementById('root')}
                {...childProps}
            >
                <Alert/>
                <CardHeader
                    className="modal__header"
                    icon={this.props.icon}
                    title={this.props.title}
                    extra={
                        this.props.onClose && (
                            <span className="modal__close">
                                <Icon type="close" onClick={this.props.onClose} />
                            </span>
                        )
                    }
                />
                <div className="modal__content">{this.props.children}</div>
            </ReactModal>
        );
    }
}
