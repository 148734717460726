import { select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* updateSeenSaga() {
    yield takeLatest(actionTypes.Ui.UPDATE_SEEN, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            yield fetch(`${host}/seen/${action.payload.page}`, { method: "PUT", headers});
        } catch (e) {
            console.log(e);
        }
    });
}

export default [updateSeenSaga];
