import React from 'react';

class FileListItemPlaceholder extends React.PureComponent {
    render() {
        return (
            <div className="dokument-item">
                <div className="default-list-item">
                    <span></span>
                </div>
            </div>
        );
    }
}

export default FileListItemPlaceholder;
