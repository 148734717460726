import { createAction } from 'redux-actions';
import { handleActions } from '../Utility/HandleActions';
import { $get, $set } from 'plow-js';

const UPDATE_TASK_TO_EDIT = 'Task/UpdateTaskToEdit';
const ADD_TASK = 'Task/AddTask';
const UPDATE_TASK = 'Task/UpdateTask';
const UPDATE_TASK_LIST = 'Task/UpdateTaskList';
const UPDATE_TASK_FINISHED = 'Task/UpdateTaskFinished';
const UPDATE_TASK_LIST_FINISHED = 'Task/UpdateTaskListFinished';
const LOAD_TASK_LIST = 'Task/LoadTaskList';
const UPDATE_TASK_LIST_FINISHED_COUNT = 'Task/UpdateTaskListFinishedCount';
const UPDATE_TASK_LIST_NOT_FINISHED_COUNT = 'Task/UpdateTaskListNotFinishedCount';
const DELETE_TASK = 'Task/DeleteTask';
const UPDATE_TASK_LIST_FINISHED_VIEW = 'Task/UpdateTaskListFinishedVies';
const ADD_TASK_FINISHED = 'Task/AddTaskFinished';
const RELOAD_TASK = 'Task/ReloadTask';
const RELOAD_TASK_LIST = 'Task/ReloadTaskList';
const RESET_STATE = 'Task/ResetState';
const ADD_SUBTASKS = 'Task/AddSubtasks';

const actionTypes = {
    UPDATE_TASK_TO_EDIT,
    ADD_TASK,
    UPDATE_TASK,
    UPDATE_TASK_LIST,
    UPDATE_TASK_FINISHED,
    UPDATE_TASK_LIST_FINISHED,
    LOAD_TASK_LIST,
    UPDATE_TASK_LIST_FINISHED_COUNT,
    UPDATE_TASK_LIST_NOT_FINISHED_COUNT,
    DELETE_TASK,
    UPDATE_TASK_LIST_FINISHED_VIEW,
    ADD_TASK_FINISHED,
    RELOAD_TASK,
    RELOAD_TASK_LIST,
    RESET_STATE,
    ADD_SUBTASKS
};

const updateTaskToEdit = createAction(UPDATE_TASK_TO_EDIT, (task) => ({task}));
const addTask = createAction(ADD_TASK, (task) => ({task}));
const updateTask = createAction(UPDATE_TASK, (task) => ({task}));
const updateTaskList = createAction(UPDATE_TASK_LIST, (list) => ({list}));
const updateTaskFinished = createAction(UPDATE_TASK_FINISHED, (task) => ({task}));
const updateTaskListFinished = createAction(UPDATE_TASK_LIST_FINISHED, (list) => ({list}));
const loadTaskList = createAction(LOAD_TASK_LIST, (list) => ({list}));
const updateTaskListFinishedCount = createAction(UPDATE_TASK_LIST_FINISHED_COUNT, (value) => ({value}));
const updateTaskListNotFinishedCount = createAction(UPDATE_TASK_LIST_NOT_FINISHED_COUNT, (value) => ({value}));
const deleteTask = createAction(DELETE_TASK, (task) => ({task}));
const updateTaskListFinishedView = createAction(UPDATE_TASK_LIST_FINISHED_VIEW, (value) => ({value}));
const addTaskFinished = createAction(ADD_TASK_FINISHED, (task) => ({task}));
const reloadTask = createAction(RELOAD_TASK, (task) => ({task}));
const reloadTaskList = createAction(RELOAD_TASK_LIST, (list) => ({list}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const addSubtasks = createAction(ADD_SUBTASKS, (task) => ({ task }));

const actions = {
    updateTaskToEdit,
    addTask,
    updateTask,
    updateTaskList,
    loadTaskList,
    updateTaskFinished,
    updateTaskListFinished,
    updateTaskListFinishedCount,
    updateTaskListNotFinishedCount,
    deleteTask,
    updateTaskListFinishedView,
    addTaskFinished,
    reloadTask,
    reloadTaskList,
    resetState,
    addSubtasks
};

const initialState = {
    taskToEdit: {},
    listFinishedCount: 0,
    listNotFinishedCount: 0,
    taskListFinishedView: true,
    list: [
        { 'id': 'defaultId', 'title': '', completed: false },
        { 'id': 'defaultId', 'title': '', completed: false },
        { 'id': 'defaultId', 'title': '', completed: false },
    ],
    listFinished: [
        { 'id': 'defaultId', 'title': '', completed: true },
        { 'id': 'defaultId', 'title': '', completed: true },
        { 'id': 'defaultId', 'title': '', completed: true },
    ],
};

const reducer = handleActions({
    [UPDATE_TASK_TO_EDIT]: (payload) => oldState => {
        if(payload.task.prop === 'resetObj') {
            return $set('task.taskToEdit', {}, oldState);
        } else {
            return $set('task.taskToEdit.'+payload.task.prop, payload.task.val, oldState);
        }
    },
    [ADD_TASK]: (payload) => oldState => {
        let list = [...oldState.task.list];
        list.unshift(payload.task);
        return $set('task.list', list, oldState);
    },
    [UPDATE_TASK_LIST]: (payload) => oldState => {
        if (payload.list.start === 0) {
            return $set('task.list', payload.list.list, oldState);
        }
        return $set('task.list', [...oldState.task.list, ...payload.list.list], oldState);
    },
    [UPDATE_TASK_LIST_FINISHED_COUNT]: (payload) => oldState => {
        return $set('task.listFinishedCount', payload.value, oldState);
    },
    [UPDATE_TASK_LIST_NOT_FINISHED_COUNT]: (payload) => oldState => {
        return $set('task.listNotFinishedCount', payload.value, oldState);
    },
    [UPDATE_TASK]: (payload) => oldState => {
        let ind = null;
        let list = [...oldState.task.list].map((task, index) => {
            if (payload.task.id === task.id) {
                task[payload.task.prop] = payload.task.val;
                ind = index;
            }
            return task;
        });
        if (payload.task.prop === "completed" && payload.task.val === true) {
            list.splice(ind, 1);
        }
        return $set('task.list', list, oldState);
    },
    [RELOAD_TASK]: (payload) => oldState => {
        let list = [...oldState.task.list].map((task, index) => {
            if (payload.task.id === task.id) {
                return payload.task.task;
            }
            return task;
        });
        return $set('task.list', list, oldState);
    },
    [UPDATE_TASK_FINISHED]: (payload) => oldState => {
        let list = [...oldState.task.listFinished].map(task => {
            if (payload.task.id === task.id) {
                task[payload.task.prop] = payload.task.val;
            }
            return task;
        });
        return $set('task.listFinished', list, oldState);
    },

    [UPDATE_TASK_LIST_FINISHED]: (payload) => oldState => {
        if (payload.list.start === 0) {
            return $set('task.listFinished', payload.list.list, oldState);
        }
        return $set('task.listFinished', [...oldState.task.listFinished, ...payload.list.list], oldState);
    },
    [DELETE_TASK]: (payload) => oldState => {
        let list = [...oldState.task.list];
        list.splice(list.indexOf(payload.task), 1);
        return $set('task.list', list, oldState);
    },
    [UPDATE_TASK_LIST_FINISHED_VIEW]: (payload) => oldState => {
        return $set('task.taskListFinishedView', payload.value, oldState);
    },
    [ADD_TASK_FINISHED]: (payload) => oldState => {
        let list = [...oldState.task.listFinished];
        list.unshift(payload.task);
        return $set('task.listFinished', list, oldState);
    },
    [RESET_STATE]: (payload) => oldState => {
        return $set('task', initialState, oldState);
    }

});

const selectors = {
    task: $get('task'),
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
