import { hasPermission, roles } from 'Containers/Util';
import { $all, $override, $set, $get } from 'plow-js';
import { createAction } from 'redux-actions';
import { handleActions } from 'Utility/HandleActions';

const initialState = {
    completedClaimsListIsLoading: false,
    openClaimsListIsLoading: false,
    listIsLoading: false,
    claimIsCreating: false,
    claimIsLoading: false,
    claimIsUpdating: false,
    claimIsUpdatingChecklist: false,
    map: {},
    openList: [],
    closedList: [],
    newClaimId: '',
    openClaimsPage: 0,
    articleDropdown: {
        view: false,
        index: 0,
        additional: false
    },
    sorting: '',
    loadAnimation: false,
    countOpen: 0,
    countClosed: 0,
};

const LOAD_LIST = 'Claims/LoadList';
const LOAD_LIST_SUCCESS = 'Claims/LoadList/Success';
const LOAD_LIST_FAILED = 'Claims/LoadList/Failed';
const CREATE = 'Claims/Create';
const CREATE_SUCCESS = 'Claims/Create/Success';
const CREATE_FAILED = 'Claims/Create/Failed';
const LOAD_CLAIM = 'Claims/Load';
const LOAD_CLAIM_SUCCESS = 'Claims/Load/Success';
const LOAD_CLAIM_FAILED = 'Claims/Load/Failed';
const UPDATE = 'Claims/Update';
const UPDATE_SUCCESS = 'Claims/Update/Success';
const UPDATE_FAILED = 'Claims/Update/Failed';
const UPDATE_CHECKLIST = 'Claims/Update/Checklist';
const UPDATE_CHECKLIST_SUCCESS = 'Claims/Update/Checklist/Success';
const UPDATE_CHECKLIST_FAILED = 'Claims/Update/Checklist/Failed';
const RESET_STATE = 'Claims/ResetState';
const UPDATE_ARTICLE_DROPDOWN = 'Claims/UpdateArticleDropdown';
const UPDATE_SORTING = 'Absence/UpdateSorting';
const RESET_SORTING = 'Absence/ResetSorting';
const UPDATE_LOAD_ANIMATION = 'Claims/UpdateLoadAnimation';
const LOAD_OPEN_LIST = 'Claims/LOAD_OPEN_LIST';
const LOAD_CLOSED_LIST = 'Claims/LOAD_CLOSED_LIST';
const UPDATE_OPEN_LIST = 'Claims/UPDATE_OPEN_LIST';
const UPDATE_CLOSED_LIST = 'Claims/UPDATE_CLOSED_LIST';

const UPDATE_COUNT_OPEN_LIST = 'Claims/UPDATE_COUNT_OPEN_LIST';
const UPDATE_COUNT_CLOSED_LIST = 'Claims/UPDATE_COUNT_CLOSED_LIST';


export const actionTypes = {
    LOAD_LIST,
    LOAD_LIST_SUCCESS,
    LOAD_LIST_FAILED,
    CREATE,
    CREATE_SUCCESS,
    CREATE_FAILED,
    LOAD_CLAIM,
    LOAD_CLAIM_SUCCESS,
    LOAD_CLAIM_FAILED,
    UPDATE,
    UPDATE_SUCCESS,
    UPDATE_FAILED,
    UPDATE_CHECKLIST,
    UPDATE_CHECKLIST_SUCCESS,
    UPDATE_CHECKLIST_FAILED,
    RESET_STATE,
    UPDATE_ARTICLE_DROPDOWN,
    UPDATE_SORTING,
    RESET_SORTING,
    UPDATE_LOAD_ANIMATION,
    LOAD_OPEN_LIST,
    LOAD_CLOSED_LIST,
    UPDATE_OPEN_LIST,
    UPDATE_CLOSED_LIST,
    UPDATE_COUNT_OPEN_LIST,
    UPDATE_COUNT_CLOSED_LIST
};

const loadList = createAction(LOAD_LIST, ({ filter, forceReload, fromNumber }) => ({
    filter: filter || {},
    forceReload: forceReload || false,
    fromNumber: fromNumber,
}));
const loadListSuccess = createAction(
    LOAD_LIST_SUCCESS,
    ({ claims, filter, forceReload, fromNumber }) => ({ claims, filter, forceReload, fromNumber })
);
const loadListFailed = createAction(LOAD_LIST_FAILED, ({ error }) => ({ error }));
const loadClaim = createAction(LOAD_CLAIM, (claimId) => ({ claimId }));
const loadClaimSuccess = createAction(LOAD_CLAIM_SUCCESS);
const loadClaimFailed = createAction(LOAD_CLAIM_FAILED);
const createClaim = createAction(CREATE, (claim) => ({ claim }));
const createClaimSuccess = createAction(CREATE_SUCCESS);
const createClaimFailed = createAction(CREATE_FAILED);
const updateClaim = createAction(UPDATE, (claim) => ({ claim }));
const updateClaimSuccess = createAction(UPDATE_SUCCESS);
const updateClaimFailed = createAction(UPDATE_FAILED);
const updateClaimChecklist = createAction(UPDATE_CHECKLIST, (checklist) => ({ checklist }));
const updateClaimChecklistSuccess = createAction(UPDATE_CHECKLIST_SUCCESS);
const updateClaimChecklistFailed = createAction(UPDATE_CHECKLIST_FAILED);
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const updateArticleDropdown = createAction(UPDATE_ARTICLE_DROPDOWN, (dropdown) => ({ dropdown }));
const updateSorting = createAction(UPDATE_SORTING, (value) => ({ value }));
const resetSorting = createAction(RESET_SORTING, (value) => ({ value }));
const updateLoadAnimation = createAction(UPDATE_LOAD_ANIMATION, (value) => ({ value }));

const loadOpenList = createAction(LOAD_OPEN_LIST, ({ filter, forceReload, fromNumber }) => ({
    filter: filter || {},
    forceReload: forceReload || false,
    fromNumber: fromNumber,
}));

const loadClosedList = createAction(LOAD_CLOSED_LIST, ({ filter, forceReload, fromNumber }) => ({
    filter: filter || {},
    forceReload: forceReload || false,
    fromNumber: fromNumber,
}));

const updateOpenList = createAction(UPDATE_OPEN_LIST, list => ({list}));
const updateClosedList = createAction(UPDATE_CLOSED_LIST, list => ({list}));
const updateCountOpenList = createAction(UPDATE_COUNT_OPEN_LIST, value => ({value}));
const updateCountClosedList = createAction(UPDATE_COUNT_CLOSED_LIST, value => ({value}));


export const actions = {
    loadList: loadList,
    loadListSuccess: loadListSuccess,
    loadListFailed: loadListFailed,
    createClaim: createClaim,
    createClaimSuccess: createClaimSuccess,
    createClaimFailed: createClaimFailed,
    loadClaim: loadClaim,
    loadClaimSuccess: loadClaimSuccess,
    loadClaimFailed: loadClaimFailed,
    updateClaim: updateClaim,
    updateClaimSuccess: updateClaimSuccess,
    updateClaimFailed: updateClaimFailed,
    updateClaimChecklist: updateClaimChecklist,
    updateClaimChecklistSuccess: updateClaimChecklistSuccess,
    updateClaimChecklistFailed: updateClaimChecklistFailed,
    resetState: resetState,
    updateArticleDropdown,
    updateSorting,
    resetSorting,
    updateLoadAnimation,
    loadOpenList,
    loadClosedList,
    updateOpenList,
    updateClosedList,
    updateCountOpenList,
    updateCountClosedList
};

const reducer = handleActions({
    [LOAD_LIST]: ({ filter }) => (oldState) => {
        return $set(
            filter.completed
                ? 'claims.completedClaimsListIsLoading'
                : 'claims.openClaimsListIsLoading',
            true,
            oldState
        );
    },
    [LOAD_LIST_SUCCESS]: ({ claims, filter, forceReload }) => (oldState) => {
        // Bug in inspector, these branches are certainly not equal.
        // noinspection ConditionalExpressionWithIdenticalBranchesJS
        const map = forceReload ? {} : { ...selectors.allClaims(oldState) };
        claims.forEach((claim) => {
            map[claim.id] = claim;
        });
        return $all(
            $set('claims.map', map),
            $set(
                filter.completed
                    ? 'claims.completedClaimsListIsLoading'
                    : 'claims.openClaimsListIsLoading',
                false
            ),
            oldState
        );
    },
    [LOAD_LIST_FAILED]: (error) => (oldState) => {
        console.error('Claims: LOAD_LIST_FAILED', error);
        return $set('claims.listIsLoading', false, oldState);
    },



    [CREATE]: () => (oldState) => {
        return $set('claims.claimIsCreating', true, oldState);
    },
    [CREATE_SUCCESS]: (claim) => (oldState) => {
        return $all(
            $set('claims.claimIsCreating', false),
            $set('claims.newClaimId', claim.id),
            $set('claims.map.' + claim.id, claim),
            oldState
        );
    },
    [CREATE_FAILED]: (error) => (oldState) => {
        console.error('Claims: CREATE_FAILED', error);
        return $set('claims.claimIsCreating', false, oldState);
    },

    [LOAD_CLAIM]: () => (oldState) => {
        return $set('claims.claimIsLoading', true, oldState);
    },
    [LOAD_CLAIM_SUCCESS]: (claim) => (oldState) => {
        return $all(
            $override('claims.map', { [claim.id]: claim }),
            $set('claims.claimIsLoading', false),
            oldState
        );
    },
    [LOAD_CLAIM_FAILED]: (error) => (oldState) => {
        console.error('Claims: LOAD_CLAIM_FAILED', error);
        return $set('claims.claimIsLoading', false, oldState);
    },

    [UPDATE]: () => (oldState) => {
        return $set('claims.claimIsUpdating', true, oldState);
    },
    [UPDATE_SUCCESS]: (claim) => (oldState) => {
        return $all(
            $set('claims.map.' + claim.id, claim),
            $set('claims.claimIsUpdating', false),
            oldState
        );
    },
    [UPDATE_FAILED]: (error) => (oldState) => {
        console.error('Claims: UPDATE_FAILED', error);
        return $set('claims.claimIsUpdating', false, oldState);
    },

    [UPDATE_CHECKLIST]: () => (oldState) => {
        return $set('claims.claimIsUpdatingChecklist', true, oldState);
    },
    [UPDATE_CHECKLIST_SUCCESS]: (checklist) => (oldState) => {
        return $all(
            $set('claims.map.' + checklist.claimId + '.checklist', checklist),
            $set('claims.claimIsUpdatingChecklist', false),
            oldState
        );
    },
    [UPDATE_CHECKLIST_FAILED]: (error) => (oldState) => {
        console.error('Claims: UPDATE_CHECKLIST_FAILED', error);
        return $set('claims.claimIsUpdatingChecklist', false, oldState);
    },
    [RESET_STATE]: () => (oldState) => {
        return $set('claims', initialState, oldState);
    },
    [UPDATE_ARTICLE_DROPDOWN]: ({dropdown}) => (oldState) => {
        return $set('claims.articleDropdown', dropdown, oldState);
    },
    [UPDATE_SORTING]: ({ value }) => oldState => {
        const s = value.replace('-', '');
        const list = oldState.claims.sorting.includes(`-${s}`) ? [] :  (oldState.claims.sorting.includes(s) ? [`-${s}`] : [s]);
        return $set('claims.sorting', list, oldState);

    },
    [RESET_SORTING]: (payload) => oldState => {
        return $set('claims.sorting', '', oldState);
    },
    [UPDATE_LOAD_ANIMATION]: (payload) => (oldState) => {
        return $set('claims.loadAnimation', payload.value, oldState);
    },
    [UPDATE_OPEN_LIST]: ({ list }) => oldState => $set('claims.openList', list, oldState),
    [UPDATE_CLOSED_LIST]: ({ list }) => oldState => $set('claims.closedList', list, oldState),
    [UPDATE_COUNT_OPEN_LIST]: ({ value }) => oldState => $set('claims.countOpen', value, oldState),
    [UPDATE_COUNT_CLOSED_LIST]: ({ value }) => oldState => $set('claims.countClosed', value, oldState),

});

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Selectors

const isClaimOpen = (state, claim) => {
    if (hasPermission(state.data.currentUser, [roles.Personalleitung, roles.Buchhaltung, roles.Vertriebsleitung, roles.Vertrieb, roles.Zentrallagerleitung])) {
        return !claim.checklist.baNumber || !claim.checklist.stockUpdated;
    } else {
        return !claim.checklist.completed;
    }

};

const claimById = (state, claimId) => state.claims.map[claimId];
const allClaims = (state, location = null) => {
    const map = {};
    Object.values(state.claims.map)
        .filter((claim) => !location || location === claim.locationId)
        .forEach((claim) => {
            map[claim.id] = claim;
        });
    return map;
};
const allClaimsCount = (state) =>  Object.values(allClaims(state)).length;

const openClaims = (state, location = null) =>
    Object.values(allClaims(state, location)).filter((claim) => isClaimOpen(state, claim));
const openClaimsCount = (state, location = null) => openClaims(state, location).length;

const completedClaims = (state, location = null) =>
    Object.values(allClaims(state, location)).filter((claim) => !isClaimOpen(state, claim));
const completedClaimsCount = (state, location = null) => completedClaims(state, location).length;

export const selectors = {
    allClaims: allClaims,
    allClaimsCount: allClaimsCount,
    claimById: claimById,
    completedClaims: completedClaims,
    openClaims: openClaims,
    openClaimsCount: openClaimsCount,
    completedClaimsCount: completedClaimsCount,
    claims: $get('claims'),
    sorting: $get('claims.sorting')
};

export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
