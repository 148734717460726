import LocationInput from 'Components/LocationInput';
import LocationSelect from 'Components/LocationSelect';
import FormikInput from 'Components/UI/Form/FormikInput';
import Select from 'Components/UI/Form/Select';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import {actions} from "../../Redux";


const mapStateToProps = (state) => {
    return {
        claimDropdownManufacturers: state.data.claimDropdownManufacturers,
        statuses: state.data.statuses
    };
};

class ClaimBaseForm extends React.Component {
    static propTypes = {
        isAccounting: PropTypes.bool,
        onUpdate: PropTypes.func,
    };

    state = {
        editable: false,
        dirty: false,
    };

    isReadOnly = () => !!(this.props.values.checklist && this.props.values.checklist.completed);

    getManufacturerList = () => {

        const currentSupplierNameIsInManufacturerList = this.props.claimDropdownManufacturers.find(it => it.name === this.props.values.supplierName)
        const manufacturers =  !currentSupplierNameIsInManufacturerList ? [
            {name: this.props.values.supplierName},
            ...this.props.claimDropdownManufacturers
        ] : [...this.props.claimDropdownManufacturers]
        return manufacturers.map(it => {
            return {label: it.name,
                value : it.name}
        })
    }
    getStatusList = () => {
        return this.props.statuses.map(it => {
            return {label: it.name,
                value : it.name}
        })
    }
    componentDidMount() {
        this.props.loadStatuses();
    }

    render() {
        return (
            <div className="content-wrapper content-form">
                <div className="content-wrapper-box">
                    <div className="grid-wrapper">
                        <FormikInput
                            label="Nummer"
                            name="number"
                            placeholder="– – – – – – –"
                            readOnly={true}
                            size="third"
                        />
                        <FormikInput
                            label="Datum"
                            name="createdAt"
                            value={moment(this.props.values.createdAt).format(
                                'DD.MM.YY, HH:mm [Uhr]'
                            )}
                            readOnly={true}
                            size="third"
                        />
                        {!this.props.values.id ? (
                            <FormikInput
                                label="Standort"
                                name="locationId"
                                component={LocationSelect}
                                size="small-12 medium-12 large-4"
                            />
                        ) : (
                            <FormikInput
                                label="Standort"
                                name="locationId"
                                readOnly={true}
                                component={LocationInput}
                                size="third"
                            />
                        )}

                        <FormikInput
                            label="Art"
                            name="type"
                            readOnly={this.isReadOnly()}
                            component={Select}
                            options={[
                                {label: 'Kundenware', value: 'customer-item'},
                                {label: 'Lagerware', value: 'stock-item'},
                                {label: 'Speditionsschaden', value: 'hauler-item'},
                            ]}
                            labelFunc={option => option.label}
                            valueFunc={option => option.value}
                            size="large"
                        />

                        <div style={{
                            width: '100%',
                            display: this.props.values.type === 'hauler-item' ? 'block' : 'none'
                        }}>
                            <FormikInput
                                label="Spediteur"
                                name="haulerName"
                                readOnly={this.isReadOnly()}
                                size="large"
                            />
                        </div>
                        <div style={{
                            width: '100%',
                            display: this.props.values.type !== 'hauler-item' ? 'block' : 'none'
                        }}>
                            <FormikInput
                                label="Hersteller"
                                name="supplierName"
                                readOnly={this.isReadOnly()}
                                component={Select}
                                options={this.getManufacturerList()}
                                labelFunc={option => option.label}
                                valueFunc={option => option.value}
                                size="large"
                            />
                        </div>
                        <div style={{width: '100%'}}>
                            <FormikInput
                                label="Status"
                                name="status"
                                readOnly={this.isReadOnly()}
                                component={Select}
                                options={this.getStatusList()}
                                labelFunc={option => option.label}
                                valueFunc={option => option.value}
                                size="large"
                            />
                        </div>

                        <div style={{
                            width: '100%',
                            display: ['hauler-item', 'customer-item'].includes(this.props.values.type) ? 'block' : 'none'
                        }}>
                            <FormikInput
                                label="Kunde"
                                name="customerName"
                                readOnly={this.isReadOnly()}
                                size="large"
                            />
                        </div>

                        <div style={{
                            width: '100%',
                            display: this.props.values.type === 'hauler-item' ? 'block' : 'none'
                        }}>
                            <FormikInput
                                label="Rechnungsnummer Onlineshop"
                                name="orderNumberOnlineshop"
                                readOnly={this.isReadOnly()}
                                size="large"
                            />
                        </div>

                        <div style={{
                            width: '100%',
                            display: this.props.values.type === 'customer-item' ? 'block' : 'none'
                        }}>
                            <FormikInput
                                label="Rechnungsnummer Warenwirtschaft"
                                name="customerInvoice"
                                readOnly={this.isReadOnly()}
                                size="large"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadStatuses: (value) => dispatch(actions.Data.loadStatuses(value)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClaimBaseForm);
