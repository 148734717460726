import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '../Redux/index';
import Button from '../Components/UI/Button';
import '../Components/UI/Modal/modal.scss';
import Modal from '../Components/UI/Modal/Modal';

const mapStateToProps = (state) => {
    return {
        confirmModal: state.ui.confirmModal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        deleteCategory: (value) => dispatch(actions.Category.deleteCategory(value)),
        deleteAbsence: (absence) => dispatch(actions.Absence.deleteAbsence(absence)),
        deleteAdvertising: (advertising) => dispatch(actions.Advertising.deleteAdvertising(advertising)),
        deleteFolder: (folder) => dispatch(actions.Data.deleteFolder(folder)),
        deleteDocument: (document) => dispatch(actions.Data.deleteDocument(document)),
        deleteLocation: (location) => dispatch(actions.Data.deleteLocation(location)),
        deleteUser: (user) => dispatch(actions.Data.deleteUser(user)),
        deleteManufacturer: (manufacturer) => dispatch(actions.Data.deleteManufacturer(manufacturer)),
        updateUserSearch: (search) => dispatch(actions.Ui.updateUserSearch(search)),
        deleteSickReport: (sickReport) => dispatch(actions.SickReport.deleteSickReport(sickReport)),
        deleteArticle: (article) => dispatch(actions.Data.deleteArticle(article)),
        deleteTraining: (training) => dispatch(actions.Training.deleteTraining(training)),
        deleteLocationSpecialAppointment: (locationSpecialAppointment) => dispatch(actions.Data.deleteLocationSpecialAppointment(locationSpecialAppointment)),
        addLocationSpecialAppointment: (locationSpecialAppointment) => dispatch(actions.Data.addLocationSpecialAppointment(locationSpecialAppointment)),
        saveLocationSpecialAppointment: (locationSpecialAppointment) => dispatch(actions.Data.saveLocationSpecialAppointment(locationSpecialAppointment)),
        deleteNews: (news) => dispatch(actions.News.deleteNews(news)),
        deleteColor: (color) => dispatch(actions.Data.deleteColor(color)),
        deleteClaimDropdownManufacturer: (claimDropdownManufacturer) => dispatch(actions.Data.deleteClaimDropdownManufacturer(claimDropdownManufacturer)),
        deleteClaimDropdownReason: (claimDropdownReason) => dispatch(actions.Data.deleteClaimDropdownReason(claimDropdownReason)),
        deleteClaimDropdownQuality: (claimDropdownQuality) => dispatch(actions.Data.deleteClaimDropdownQuality(claimDropdownQuality)),
        deleteComment: comment => dispatch(actions.Data.deleteComment(comment)),
    };
};

class Confirm extends PureComponent {
    static propTypes = {
        confirmModal: PropTypes.object,
        updateConfirmModal: PropTypes.func,
        deleteCategory: PropTypes.func,
    };

    handleCancel = (e) => {
        e.preventDefault();
        this.props.updateConfirmModal({prop: 'visible', value: false})
    }

    deleteFunc ({actionType, param}) {
        this.props.updateConfirmModal({prop: 'label', value: ""})
        this.props.updateConfirmModal({prop: 'visible', value: false})
        this.props.updateConfirmModal({prop: 'payload', value: {}})
        switch(actionType) {
            case 'deleteCategory':
                this.props.deleteCategory(param);
                break;
            case 'deleteAbsence':
                this.props.deleteAbsence(param);
                break;
            case 'deleteAdvertising':
                this.props.deleteAdvertising(param);
                break;
            case 'deleteFolder':
                this.props.deleteFolder(param);
                break;
            case 'deleteDocument':
                this.props.deleteDocument(param);
                break;
            case 'deleteLocation':
                this.props.deleteLocation(param);
                break;
            case 'deleteUser':
                this.props.deleteUser(param);
                break;
            case 'deleteManufacturer':
                this.props.deleteManufacturer(param);
                break;
            case 'deleteSickReport':
                this.props.deleteSickReport(param);
                break;
            case 'deleteArticle':
                this.props.deleteArticle(param);
                break;
            case 'deleteTraining':
                this.props.deleteTraining(param);
                break;
            case 'deleteLocationSpecialAppointment':
                this.props.deleteLocationSpecialAppointment(param);
                break;
            case 'addLocationSpecialAppointment':
                this.props.addLocationSpecialAppointment(param);
                break;
            case 'saveLocationSpecialAppointment':
                this.props.saveLocationSpecialAppointment(param);
                break;
            case 'deleteNews':
                this.props.deleteNews(param);
                break;
            case 'deleteColor':
                this.props.deleteColor(param);
                break;
            case 'deleteClaimDropdownManufacturer':
                this.props.deleteClaimDropdownManufacturer(param);
                break;
            case 'deleteClaimDropdownReason':
                this.props.deleteClaimDropdownReason(param);
                break;
            case 'deleteClaimDropdownQuality':
                this.props.deleteClaimDropdownQuality(param);
                break;
            case 'deleteComment':
                this.props.deleteComment(param);
                break;
            default:
                console.log('nothing to delete');
        }
    }

    render() {
        return (
            <Modal
                className={'ReactModal__Content-small'}
                style={{
                    content: {
                        maxWidth: '1000px',
                        margin: '15% auto',
                        padding: 0,
                        border: 0,
                        backgroundColor: 'rgb(247, 247, 247)',
                        overflow: 'hidden',
                    }
                }}
                icon={this.props.confirmModal.icon || 'delete'}
                isOpen={this.props.confirmModal ? this.props.confirmModal.visible : false}
                title={this.props.confirmModal ? this.props.confirmModal.title : ''}

                onClose={this.handleCancel}
            >
                    <div className="centeredContent">
                        <span className="centeredContent">{this.props.confirmModal ? this.props.confirmModal.text : ''}</span>
                    </div>
                    <div className="small-12 medium-12 large-12">
                        <div className="btnModalWrapper">
                            <a href="/" onClick={this.handleCancel}>Abbrechen</a>
                            <Button
                                label= {this.props.confirmModal.label ? this.props.confirmModal.label : 'Löschen'}
                                inverted
                                disabled={false}
                                onClick={this.props.confirmModal ? () => this.deleteFunc(this.props.confirmModal.payload) : null}
                            />
                        </div>
                        </div>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
