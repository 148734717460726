import className from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon/Icon';

export default class Button extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        inverted: PropTypes.bool,
        label: PropTypes.string,
        onClick: PropTypes.func,
    };

    getClassName = () =>
        className(this.props.className, 'btn', {
            'btn-2': this.props.inverted,
            'btn-3': !this.props.inverted,
            'btn-disabled': this.props.disabled,
        });

    getIcon = () =>
        typeof this.props.icon === 'string' ? <Icon type={this.props.icon} /> : this.props.icon;

    render() {
        return (
            <div className={this.getClassName()}>
                <a
                    href="/#-"
                    onClick={(e) => {
                        e.preventDefault();
                        !this.props.disabled && this.props.onClick && this.props.onClick(e);
                    }}
                >
                    <span>
                        {this.getIcon()} {this.props.label}
                    </span>
                </a>
            </div>
        );
    }
}
