import * as PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import { bytesToString } from 'Utility/UIHelper';
import Dropdown from './Dropdown';
import { host } from 'Sagas/host';
import 'react-accessible-accordion/dist/fancy-example.css';

const mapStateToProps = (state) => {
    return {
        user: state.data.user,
        token: state.ui.token,
        taskList: state.data.taskList,
        newsList: state.data.newsList,
        currentFolder: state.data.currentFolder,
        documentModal: state.data.modal.documents.document,
        folderModal: state.data.modal.documents.folder,
        folderToEdit: state.data.folderToEdit,
        documentToEdit: state.data.documentToEdit,
        folderDropdown: state.data.dropdown.documents.folder,
        teamDropdown: state.data.dropdown.documents.team,
        teamDropdownUpload: state.data.dropdown.documents.teamUpload,
        folderList: state.data.folderList,
        userGroupList: state.data.userGroupList,
        currentUser: state.data.currentUser,
        shortLink: state.data.shortLink,
        shortLinkModal: state.data.modal.documents.shortLink,
        documentSearch: state.ui.documentSearch,
        loadingAnimation: state.ui.stockLoadingAnimation,
        openedFolder: state.category.openedFolder,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateCurrentFolder: (rootFolder) => dispatch(actions.Data.updateCurrentFolder(rootFolder)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateFolderToEdit: (folder) => dispatch(actions.Data.updateFolderToEdit(folder)),
        updateFolderToEditData: (folder) => dispatch(actions.Data.updateFolderToEditData(folder)),
        updateDocumentToEdit: (document) => dispatch(actions.Data.updateDocumentToEdit(document)),
        updateDocumentToEditData: (document) =>
            dispatch(actions.Data.updateDocumentToEditData(document)),
        saveFolder: (folder) => dispatch(actions.Data.saveFolder(folder)),
        saveDocument: (document) => dispatch(actions.Data.saveDocument(document)),
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        loadCurrentFolder: (folder) => dispatch(actions.Data.loadCurrentFolder(folder)),
        deleteFolder: (folder) => dispatch(actions.Data.deleteFolder(folder)),
        deleteDocument: (document) => dispatch(actions.Data.deleteDocument(document)),
        updateShortLink: (shortLink) => dispatch(actions.Data.updateShortLink(shortLink)),
        saveShortLink: (shortLink) => dispatch(actions.Data.saveShortLink(shortLink)),
        updateDocumentSearch: (search) => dispatch(actions.Ui.updateDocumentSearch(search)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        updateOpenedFolder: (folder) => dispatch(actions.Category.updateOpenedFolder(folder)),
    };
};

class Documents extends PureComponent {
    constructor() {
        super();
        this.downloadForm = [];
    }

    static propTypes = {
        user: PropTypes.object,
        updateView: PropTypes.func.isRequired,
        token: PropTypes.string,
        updateCurrentFolder: PropTypes.func,
        updateModal: PropTypes.func,
        updateFolderToEdit: PropTypes.func,
        updateFolderToEditData: PropTypes.func,
        updateDocumentToEdit: PropTypes.func,
        updateDocumentToEditData: PropTypes.func,
        saveFolder: PropTypes.func,
        updateDropdown: PropTypes.func,
        loadCurrentFolder: PropTypes.func,
        deleteFolder: PropTypes.func,
        deleteDocument: PropTypes.func,
        currentUser: PropTypes.object,
        updateShortLink: PropTypes.func,
        saveShortLink: PropTypes.func,
        shortLinkModal: PropTypes.object,
        shortLink: PropTypes.object,
    };

    componentDidMount() {
        const url = window.location.href; // Get the current URL
        const keyword = '/slf/';

        // Check if URL contains '/slf/'
        if (url.includes(keyword)) {
            // Extract the part after '/slf/'
            const extractedPart = url.split(keyword)[1];
            const newUrl = url.split(keyword)[0];
            window.history.replaceState(null, '', newUrl);
            this.props.loadCurrentFolder(
                { shortLink: extractedPart }
            );

        } else {
            this.props.loadCurrentFolder(true)
        }

    }

    getBase64(filesFromInput) {
        if (!filesFromInput) {
            return null;
        }



        const files = Array.from(filesFromInput);
        const fileNames = [];

        /* Map each file to a promise that resolves to an array of image URI's */

        Promise.all(
            files.map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                    fileNames.push(file.name);
                });
            })
        ).then(
            (images) => {
                /* Once all promises are resolved, update state with image URI array */
                // this.setState({ imageArray : images })
                this.props.updateDocumentToEditData({ prop: 'file', val: images });
                this.props.updateDocumentToEditData({ prop: 'fileNames', val: fileNames });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    renderFile(file) {
        if (file.indexOf('application/pdf') !== -1) {
            return <embed src={file} width="210" height="297" />;
        } else if (file.indexOf('image/') !== -1) {
            return <img src={file} width="210" height="auto" alt="" />;
        } else if (file.indexOf('http') !== -1){
            return <img src={file} width="210" height="auto" alt="" />;
        } else {
            return (<i className="fa fa-file-o" width="210" aria-hidden="true" />);
        }
    }

    getModalLabel(type, action, part) {
        let labels = {
            folder: {
                newFolder: {
                    header: 'Neuen Ordner erstellen',
                    button: 'Erstellen',
                },
                existingFolder: {
                    header: 'Ordner bearbeiten',
                    button: 'Speichern',
                },
            },
            document: {
                newDocument: {
                    header: 'Neues Dokument hochladen',
                    button: 'Hochladen',
                },
                existingDocument: {
                    header: 'Dokument bearbeiten',
                    button: 'Speichern',
                },
            },
        };

        return labels[type][action][part];
    }

    saveDocument() {
        if (this.props.documentModal.action === 'newDocument' || this.props.documentModal.action === 'existingDocument') {
            if (
                !this.checkInput(
                    this.props.documentToEdit.fileNames,
                    'Wählen Sie ein Dokument zum hochladen'
                )
            ) {
                return;
            }
            let documentToSave = {
                    document: {
                        parent: this.props.folderDropdown.selectedId || null,
                        teams: this.props.teamDropdown.selectedId || [],
                    },
                    file: this.props.documentToEdit.file || [],
                    fileNames: this.props.documentToEdit.fileNames,
                    newFolderName: this.props.documentToEdit.newFolder,
            }
            if (this.props.documentToEdit.id) {

                documentToSave.document.__identity = this.props.documentToEdit.id;
                documentToSave.document.name = this.props.documentToEdit.name
            }
            if (this.props.folderDropdown.selectedId) {

                console.info(this.filterFolderListByTeam())

                const inputFolders = this.filterFolderListByTeam();

                const selectedFolder = this.findFolderById(inputFolders, this.props.folderDropdown.selectedId);
                if(!selectedFolder.teamsUpload.includes(this.props.currentUser.team) && selectedFolder.teamsUpload.length ) {
                    return alert('Upload in das Verzeichnis '+selectedFolder.name+ ' nicht gestattet')
                }
            }
            if (!this.props.folderDropdown.selectedId && !this.props.documentToEdit.newFolder) {
                return alert('Bitte wählen Sie ein Verzeichnis oder legen Sie ein neues an')
            }
            this.props.saveDocument(documentToSave);
        }
    }


    findFolderById(folders, targetId) {
        for (const folder of folders) {
            if (folder.id === targetId) {
                return folder; // Found the folder with the target ID
            }

            if (folder.folders && folder.folders.length > 0) {
                const foundInSubFolder = this.findFolderById(folder.folders, targetId);
                if (foundInSubFolder) {
                    return foundInSubFolder; // Found in a subfolder
                }
            }
        }

        return null; // Folder with the target ID not found
    }



    saveFolder() {
        if (this.props.folderModal.action === 'newFolder' || this.props.folderModal.action === 'existingFolder' ) {
            if (!this.checkInput(this.props.folderToEdit.name, 'Name darf nicht leer sein.')) {
                return;
            }
            const folderToSave = {
                folder: {
                    name: this.props.folderToEdit.name,
                    parent: this.props.folderDropdown.selectedId || null,
                    teams: this.props.teamDropdown.selectedId || [],
                    teamsUpload: this.props.teamDropdownUpload.selectedId || [],
                }
            }
            if (this.props.folderToEdit.id) {
                folderToSave.folder.__identity = this.props.folderToEdit.id;
            }
            this.props.saveFolder(folderToSave);
        }
    }

    checkInput(input, alertMessage) {
        if (input) {
            if (input.length >= 1) {
                return true;
            } else {
                alert(alertMessage);
                return false;
            }
        } else {
            alert(alertMessage);
            return false;
        }
    }
    triggerFileInput(fileInput) {
        fileInput.click();
    }
    getPermission(teams) {

        if (this.props.currentUser.roleIdentifier === undefined || teams === undefined) {
            return false;
        }

        if (!teams.length) {
            return true;
        }
        if (
            teams.indexOf(this.props.currentUser.roleIdentifier) !== -1 ||
            teams.indexOf(this.props.currentUser.parentTeam) !== -1 ||
            this.can('dokument_oder_ordner_alle_sehen')
        ) {
            return true;
        }
        return false;
    }
    getFileIcon(type) {
        if (type === 'jpeg' || type === 'jpg' || type === 'gif' || type === 'png') {
            return 'fa fa-file-image-o';
        }
        if (type === 'pdf') {
            return 'fa fa-file-pdf-o';
        }
        if (type === 'doc' || type === 'dot' || type === 'docx') {
            return 'fa fa-file-word-o';
        }
        if (type === 'xls' || type === 'xla' || type === 'xlsx') {
            return 'fa fa-file-excel-o';
        }
        if (type === 'ppt' || type === 'ppz' || type === 'pps' || type === 'pot') {
            return 'fa fa-file-powerpoint-o';
        }
        if (type === 'zip') {
            return 'fa fa-file-archive-o';
        }
        if (type === 'txt') {
            return 'fa fa-file-text-o';
        }
        return 'fa fa-file-o';
    }

    filterFolderListByTeam() {
        return this.props.folderList.filter((folder) => this.getPermission(folder.teams));
    }

    deleteItem(item, file) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        if (file) {

            this.props.updateConfirmModal({prop: 'title', value: 'Datei löschen'})
            this.props.updateConfirmModal({prop: 'text', value: "Datei '" + item.name + "' löschen?"})
            this.props.updateConfirmModal({prop: 'label', value: "löschen"})
            this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteDocument', param: item}})
        } else {
            this.props.updateConfirmModal({prop: 'title', value: 'Ordner löschen'})
            this.props.updateConfirmModal({prop: 'text', value: "Ordner '" + item.name + "' löschen?"})
            this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteFolder', param: item}})
        }
    }

    getLink(document) {
        return host + '/download/' + document.id;
    }

    validateInput(value) {
        let regex = /[^A-Za-z0-9-_]+/;

        this.props.updateShortLink({ prop: 'name', val: value });

        if (regex.test(value)) {
            this.props.updateShortLink({ prop: 'error', val: true });
        } else {
            this.props.updateShortLink({ prop: 'error', val: false });
        }
    }

    generateShortLink(filename) {
        // Replace special characters with underscores, replace umlauts and ß with corresponding characters, replace dots with underscores, replace spaces with underscores, convert to lowercase
        let shortName = filename
            .replace(/[^\w\säöüß]/gi, '_') // Replace special characters with underscores
            .replace(/[äÄ]/g, 'ae')
            .replace(/[öÖ]/g, 'oe')
            .replace(/[üÜ]/g, 'ue')
            .replace(/[ß]/g, 'ss')
            .replace(/\./g, '_') // Replace dots with underscores
            .replace(/\s+/g, '_')
        return shortName;
    }

    getErrorStyle() {
        if (this.props.shortLink.error) {
            return {
                backgroundColor: 'rgba(255, 0, 0, 0.15)',
                border: '1px solid rgb(226, 4, 20)',
            };
        } else {
            return { backgroundColor: 'white', border: '1px solid #ededed' };
        }
    }



    editFolder(folder)
    {
        this.props.updateFolderToEdit(folder);
        this.props.updateModal({
            path: 'documents.folder',
            prop: 'view',
            val: !this.props.documentModal.view,
        });
        this.props.updateModal({
            path: 'documents.folder',
            prop: 'action',
            val: 'existingFolder',
        });

        this.propsUpdateDropdown(folder);
    }

    propsUpdateDropdown(obj){
        this.props.updateDropdown({
            dropdown: this.props.folderDropdown.path,
            prop: 'selectedId',
            val: obj.parent ? obj.parent.id : '',
        });
        this.props.updateDropdown({
            dropdown: this.props.folderDropdown.path,
            prop: 'selectedLabel',
            val: obj.parent ? obj.parent.name : '',
        });
        this.props.updateDropdown({
            dropdown: this.props.teamDropdown.path,
            prop: 'selectedId',
            val: obj.teams,
        });
        this.props.updateDropdown({
            dropdown: this.props.teamDropdownUpload.path,
            prop: 'selectedId',
            val: obj.teamsUpload ? obj.teamsUpload : [],
        });
    }

    editDocument(document)
    {
        this.props.updateDocumentToEdit(document);
        this.props.updateDocumentToEditData({prop: 'fileNames', val: [document.name]})
        this.props.updateModal({
            path: 'documents.document',
            prop: 'view',
            val: !this.props.documentModal.view,
        });
        this.props.updateModal({
            path: 'documents.document',
            prop: 'action',
            val: 'existingDocument',
        });

        this.propsUpdateDropdown(document);
    }

    getFolderLabel() {
        const newFolderName = this.props.documentToEdit.newFolder ? '/' + this.props.documentToEdit.newFolder : ''
        if(this.props.folderDropdown.selectedLabel) {
            return this.props.folderDropdown.selectedLabel + newFolderName
        } else if(newFolderName !== '') {
            return newFolderName
        } else {
            return 'kein Ordner ausgewählt'
        }
    }

    render() {
        return (
            <div id="main-side">
                <div id="Modal"
                    className={this.props.documentModal.view ? 'modal visible' : 'modal hidden'}
                >
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                            <span>
                                <i className="fa fa-check-square" aria-hidden="true"></i>{' '}
                                {this.getModalLabel(
                                    'document',
                                    this.props.documentModal.action,
                                    'header'
                                )}
                            </span>{' '}
                            <span className="close">
                                <a
                                    href="/#-"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.updateModal({
                                            path: 'documents.document',
                                            prop: 'view',
                                            val: !this.props.documentModal.view,
                                        });
                                        this.props.updateDocumentToEdit({});
                                        this.props.updateFolderToEdit({});
                                        this.props.updateDropdown({
                                            dropdown: this.props.folderDropdown.path,
                                            prop: 'selectedLabel',
                                            val: '',
                                        });
                                        this.props.updateDropdown({
                                            dropdown: this.props.folderDropdown.path,
                                            prop: 'selectedId',
                                            val: '',
                                        });
                                    }}
                                >
                                    <i className="fa fa-times-circle-o"></i>
                                </a>
                            </span>
                        </div>
                        <form encType="multipart/form-data">
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            {!this.props.documentToEdit.file && this.props.documentToEdit.resourceUri &&
                                                <div className="group">
                                                    <input
                                                        type="text"
                                                        value={this.props.documentToEdit.name}
                                                        onChange={(e) =>
                                                            this.props.updateDocumentToEditData({prop: 'name', val: e.target.value})
                                                        }
                                                    />
                                                    <label>Dokument Bezeichnung</label>
                                                </div>
                                            }
                                            <div className="group">
                                                <input
                                                    type="text"
                                                    value={this.props.documentToEdit.newFolder}
                                                    onChange={(e) =>
                                                        this.props.updateDocumentToEditData({prop: 'newFolder', val: e.target.value})
                                                    }
                                                />
                                                <label>Ordner anlegen</label>
                                            </div>
                                            <div className="group">
                                                <input
                                                    type="text"
                                                    readOnly
                                                    value={
                                                        this.props.documentToEdit.fileNames ?
                                                        (this.props.documentToEdit.fileNames.length > 1 ?
                                                         this.props.documentToEdit.fileNames.length + ' Dateien' :
                                                         this.props.documentToEdit.fileNames[0]
                                                         ) : ''
                                                    }
                                                    onClick={() =>
                                                        this.triggerFileInput(this.refs.fileInput)
                                                    }
                                                />
                                                <label>Dokumente</label>
                                                <input
                                                    type="file"
                                                    ref="fileInput"
                                                    multiple={true}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        this.getBase64(e.target.files);
                                                    }}
                                                />
                                            </div>
                                            {this.props.documentToEdit.file && (
                                                <div className="FileInput__thumbnail-list">
                                                    {this.props.documentToEdit.file.map(
                                                        (file, index) => (
                                                            <div key={`itemPreview${index}`} className="itemPreview">
                                                                <div className="previewItemWrapper">
                                                                    {this.renderFile(file, index)}
                                                                </div>
                                                                {
                                                                    this.props.documentToEdit.fileNames &&
                                                                    <div className="previewItemName">
                                                                        {this.props.documentToEdit.fileNames[index]}
                                                                    </div>
                                                                }
                                                            </div>

                                                        )
                                                    )}
                                                </div>
                                            )}
                                            {
                                              !this.props.documentToEdit.file && this.props.documentToEdit.resourceUri &&
                                              <div className="FileInput__thumbnail-list">
                                                    <div className="itemPreview">
                                                          <div className="previewItemWrapper">
                                                              {this.renderFile(this.props.documentToEdit.resourceUri, 0)}
                                                          </div>
                                                          {
                                                              this.props.documentToEdit.fileNames &&
                                                              <div className="previewItemName">
                                                                  {this.props.documentToEdit.fileNames[0]}
                                                              </div>
                                                          }
                                                      </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box pt-0">
                                    <div className="grid-wrapper">
                                        <div className="small-12 pb-0">Ordner: {this.getFolderLabel()}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box pt-0">
                                    <div className="grid-wrapper">
                                        <div className="small-12">
                                            {this.filterFolderListByTeam().map((folder, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className="firstLevelF"
                                                         onClick={() => {
                                                             this.props.updateOpenedFolder({
                                                                 prop: 'parent',
                                                                 value: this.props.openedFolder.parent !== folder.id ? folder.id : ''
                                                             });
                                                             this.props.updateOpenedFolder({prop: 'folder', value: ''});
                                                             this.props.updateOpenedFolder({
                                                                 prop: 'subFolder',
                                                                 value: ''
                                                             });
                                                             this.props.updateOpenedFolder({
                                                                 prop: 'subSubFolder',
                                                                 value: ''
                                                             });
                                                         }}
                                                    >
                                                        <i className={this.props.openedFolder.parent === folder.id ? 'fa fa-folder-open' : 'fa fa-folder'}/>
                                                        <span className={folder.teamsUpload.includes(this.props.currentUser.team) || !folder.teamsUpload.length  ? 'folder-green': 'folder-red'}
                                                            onClick={() => {
                                                                this.props.updateDropdown({
                                                                    dropdown: this.props.folderDropdown.path,
                                                                    prop: 'selectedLabel',
                                                                    val: this.props.folderDropdown.selectedLabel !== folder.name ? folder.name : '',
                                                                });
                                                                this.props.updateDropdown({
                                                                    dropdown: this.props.folderDropdown.path,
                                                                    prop: 'selectedId',
                                                                    val: this.props.folderDropdown.selectedId !== folder.id ? folder.id : '',
                                                                });
                                                            }}
                                                        > {folder.name}</span>
                                                    </div>

                                                    {this.props.openedFolder.parent === folder.id && folder.folders.map((subFolder, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div className="secondLevelF"
                                                                     onClick={() => {
                                                                         this.props.updateOpenedFolder({prop: 'folder', value: this.props.openedFolder.folder !== subFolder.id ? subFolder.id : ''})
                                                                         this.props.updateOpenedFolder({prop: 'subFolder', value: ''});
                                                                         this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                                                                     }}
                                                                > <i className={this.props.openedFolder.folder === subFolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} />
                                                                    <span className={subFolder.teamsUpload.includes(this.props.currentUser.team) || !subFolder.teamsUpload.length  ? 'folder-green': 'folder-red'}
                                                                    onClick={() => {
                                                                        this.props.updateDropdown({
                                                                            dropdown: this.props.folderDropdown.path,
                                                                            prop: 'selectedLabel',
                                                                            val: this.props.folderDropdown.selectedLabel !== subFolder.name ? subFolder.name : '',
                                                                        });
                                                                        this.props.updateDropdown({
                                                                            dropdown: this.props.folderDropdown.path,
                                                                            prop: 'selectedId',
                                                                            val: this.props.folderDropdown.selectedId !== subFolder.id ? subFolder.id : '',
                                                                        });
                                                                    }}
                                                                >{subFolder.name}</span></div>
                                                                {this.props.openedFolder.folder === subFolder.id && subFolder.folders.map((subSubfolder, index2) => {
                                                                    return (
                                                                        <React.Fragment key={index2}>
                                                                            <div className="thirdLevelF"
                                                                                 onClick={() => {
                                                                                     this.props.updateOpenedFolder({prop: 'subFolder', value: this.props.openedFolder.subFolder !== subSubfolder.id ? subSubfolder.id : ''})
                                                                                     this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                                                                                 }}
                                                                            > <i className={this.props.openedFolder.subFolder === subSubfolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} />
                                                                                <span className={subSubfolder.teamsUpload.includes(this.props.currentUser.team) || !subSubfolder.teamsUpload.length  ? 'folder-green': 'folder-red'}
                                                                                onClick={() => {
                                                                                    this.props.updateDropdown({
                                                                                        dropdown: this.props.folderDropdown.path,
                                                                                        prop: 'selectedLabel',
                                                                                        val: this.props.folderDropdown.selectedLabel !== subSubfolder.name ? subSubfolder.name : '',
                                                                                    });
                                                                                    this.props.updateDropdown({
                                                                                        dropdown: this.props.folderDropdown.path,
                                                                                        prop: 'selectedId',
                                                                                        val: this.props.folderDropdown.selectedId !== subSubfolder.id ? subSubfolder.id : '',
                                                                                    });
                                                                                }}
                                                                            >{subSubfolder.name}</span></div>
                                                                            {this.props.openedFolder.subFolder === subSubfolder.id && subSubfolder.folders.map((subSubSubFolder, index3) => {
                                                                                return (
                                                                                    <React.Fragment key={index3}>
                                                                                        <div className="forthLevelF"
                                                                                             onClick={() => {
                                                                                                 this.props.updateOpenedFolder({prop: 'subSubFolder', value: this.props.openedFolder.subSubFolder !== subSubSubFolder.id ? subSubSubFolder.id : ''})
                                                                                             }}
                                                                                        > <i className={this.props.openedFolder.subSubFolder === subSubSubFolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} />
                                                                                            <span className={subSubSubFolder.teamsUpload.includes(this.props.currentUser.team) || !subSubSubFolder.teamsUpload.length ? 'folder-green': 'folder-red'}
                                                                                            onClick={() => {
                                                                                                this.props.updateDropdown({
                                                                                                    dropdown: this.props.folderDropdown.path,
                                                                                                    prop: 'selectedLabel',
                                                                                                    val: this.props.folderDropdown.selectedLabel !== subSubSubFolder.name ? subSubSubFolder.name : '',

                                                                                                });
                                                                                                this.props.updateDropdown({
                                                                                                    dropdown: this.props.folderDropdown.path,
                                                                                                    prop: 'selectedId',
                                                                                                    val: this.props.folderDropdown.selectedId !== subSubSubFolder.id ? subSubSubFolder.id : '',
                                                                                                });
                                                                                            }}
                                                                                        >{subSubSubFolder.name}</span></div>
                                                                                        {this.props.openedFolder.subSubFolder === subSubSubFolder.id && subSubSubFolder.folders.map((level5Folder, index) => {
                                                                                            return (
                                                                                                <div className="fifthLevelF" > <i className={'fa fa-file'} /> <span
                                                                                                    onClick={() => {
                                                                                                        this.props.updateDropdown({
                                                                                                            dropdown: this.props.folderDropdown.path,
                                                                                                            prop: 'selectedLabel',
                                                                                                            val: this.props.folderDropdown.selectedLabel !== level5Folder.name ? level5Folder.name : '',
                                                                                                        });
                                                                                                        this.props.updateDropdown({
                                                                                                            dropdown: this.props.folderDropdown.path,
                                                                                                            prop: 'selectedId',
                                                                                                            val: this.props.folderDropdown.selectedId !== level5Folder.id ? level5Folder.id : '',
                                                                                                        });
                                                                                                    }}
                                                                                                >{level5Folder.name}</span></div>
                                                                                            )
                                                                                        })}
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )})}

                                                </React.Fragment>
                                            )
                                        })}
                                        </div>
                                        <div className="small-12 medium-6 large-6 hidden">
                                            <Dropdown
                                                dropdown={this.props.teamDropdown}
                                                dropdownList={this.props.userGroupList}
                                                defaultSelectedLabel={'Alle'}
                                                itemIdProp={'name'}
                                                itemLabelProp={'label'}
                                                dropdownLabelOverride={'Benutzergruppe'}
                                                multiple={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <Button
                                                label={this.getModalLabel(
                                                    'document',
                                                    this.props.documentModal.action,
                                                    'button'
                                                )}
                                                inverted
                                                onClick={() => this.saveDocument()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div
                    id="Modal"
                    className={this.props.folderModal.view ? 'modal visible' : 'modal hidden'}
                >
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                            <span>
                                <i className="fa fa-check-square" aria-hidden="true"></i>{' '}
                                {this.getModalLabel(
                                    'folder',
                                    this.props.folderModal.action,
                                    'header'
                                )}
                            </span>{' '}
                            <span className="close">
                                <a
                                    href="/#-"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.updateModal({
                                            path: 'documents.folder',
                                            prop: 'view',
                                            val: !this.props.folderModal.view,
                                        });
                                        this.props.updateFolderToEdit({});
                                        this.props.updateDropdown({
                                            dropdown: this.props.folderDropdown.path,
                                            prop: 'selectedLabel',
                                            val: '',
                                        });
                                        this.props.updateDropdown({
                                            dropdown: this.props.folderDropdown.path,
                                            prop: 'selectedId',
                                            val: '',
                                        });
                                    }}
                                >
                                    <i className="fa fa-times-circle-o"></i>
                                </a>
                            </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <div className="group">
                                                <input
                                                    type="text"
                                                    value={this.props.folderToEdit.name || ''}
                                                    onChange={(e) =>
                                                        this.props.updateFolderToEditData({
                                                            prop: 'name',
                                                            val: e.target.value,
                                                        })
                                                    }
                                                />{' '}
                                                <label>Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <Dropdown
                                                dropdown={this.props.teamDropdown}
                                                dropdownList={this.props.userGroupList}
                                                defaultSelectedLabel={'Alle'}
                                                itemIdProp={'name'}
                                                itemLabelProp={'label'}
                                                dropdownLabelOverride={'Benutzergruppe Lesen'}
                                                multiple={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <Dropdown
                                                dropdown={this.props.teamDropdownUpload}
                                                dropdownList={this.props.userGroupList}
                                                defaultSelectedLabel={'Alle'}
                                                itemIdProp={'name'}
                                                itemLabelProp={'label'}
                                                dropdownLabelOverride={'Benutzergruppe Hochladen'}
                                                multiple={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box pt-0">
                                    <div className="grid-wrapper">
                                        <div className="small-12 pb-0">Ordner: {this.props.folderDropdown.selectedLabel ? this.props.folderDropdown.selectedLabel : 'kein Ordner ausgewählt'}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box pt-0">
                                    <div className="grid-wrapper">
                                        <div className="small-12">
                                            {this.filterFolderListByTeam().map((folder, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="firstLevelF"
                                                             onClick={() => {
                                                                 this.props.updateOpenedFolder({prop: 'parent', value: this.props.openedFolder.parent !== folder.id ? folder.id : ''});
                                                                 this.props.updateOpenedFolder({prop: 'folder', value: ''});
                                                                 this.props.updateOpenedFolder({prop: 'subFolder', value: ''});
                                                                 this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                                                             }}
                                                        > <i className={this.props.openedFolder.parent === folder.id ? 'fa fa-folder-open' : 'fa fa-folder'} />
                                                            <span
                                                            onClick={() => {
                                                                this.props.updateDropdown({
                                                                    dropdown: this.props.folderDropdown.path,
                                                                    prop: 'selectedLabel',
                                                                    val: this.props.folderDropdown.selectedLabel !== folder.name ? folder.name : '',
                                                                });
                                                                this.props.updateDropdown({
                                                                    dropdown: this.props.folderDropdown.path,
                                                                    prop: 'selectedId',
                                                                    val: this.props.folderDropdown.selectedId !== folder.id ? folder.id : '',
                                                                });
                                                            }}
                                                        >{folder.name}</span>
                                                        </div>
                                                        {this.props.openedFolder.parent === folder.id && folder.folders.map((subFolder, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <div className="secondLevelF"
                                                                         onClick={() => {
                                                                             this.props.updateOpenedFolder({prop: 'folder', value: this.props.openedFolder.folder !== subFolder.id ? subFolder.id : ''})
                                                                             this.props.updateOpenedFolder({prop: 'subFolder', value: ''});
                                                                             this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                                                                         }}
                                                                    > <i className={this.props.openedFolder.folder === subFolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} /> <span
                                                                        onClick={() => {
                                                                            this.props.updateDropdown({
                                                                                dropdown: this.props.folderDropdown.path,
                                                                                prop: 'selectedLabel',
                                                                                val: this.props.folderDropdown.selectedLabel !== subFolder.name ? subFolder.name : '',
                                                                            });
                                                                            this.props.updateDropdown({
                                                                                dropdown: this.props.folderDropdown.path,
                                                                                prop: 'selectedId',
                                                                                val: this.props.folderDropdown.selectedId !== subFolder.id ? subFolder.id : '',
                                                                            });
                                                                        }}
                                                                    >{subFolder.name}</span></div>
                                                                    {this.props.openedFolder.folder === subFolder.id && subFolder.folders.map((subSubfolder, index2) => {
                                                                        return (
                                                                            <React.Fragment key={index2}>
                                                                                <div className="thirdLevelF"
                                                                                     onClick={() => {
                                                                                         this.props.updateOpenedFolder({prop: 'subFolder', value: this.props.openedFolder.subFolder !== subSubfolder.id ? subSubfolder.id : ''})
                                                                                         this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                                                                                     }}
                                                                                > <i className={this.props.openedFolder.subFolder === subSubfolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} /> <span
                                                                                    onClick={() => {
                                                                                        this.props.updateDropdown({
                                                                                            dropdown: this.props.folderDropdown.path,
                                                                                            prop: 'selectedLabel',
                                                                                            val: this.props.folderDropdown.selectedLabel !== subSubfolder.name ? subSubfolder.name : '',
                                                                                        });
                                                                                        this.props.updateDropdown({
                                                                                            dropdown: this.props.folderDropdown.path,
                                                                                            prop: 'selectedId',
                                                                                            val: this.props.folderDropdown.selectedId !== subSubfolder.id ? subSubfolder.id : '',
                                                                                        });
                                                                                    }}
                                                                                >{subSubfolder.name}</span></div>
                                                                                {this.props.openedFolder.subFolder === subSubfolder.id && subSubfolder.folders.map((subSubSubFolder, index3) => {
                                                                                    return (
                                                                                        <React.Fragment key={index3}>
                                                                                            <div className="forthLevelF"
                                                                                                 onClick={() => {
                                                                                                     this.props.updateOpenedFolder({prop: 'subSubFolder', value: this.props.openedFolder.subSubFolder !== subSubSubFolder.id ? subSubSubFolder.id : ''})
                                                                                                 }}
                                                                                            > <i className={this.props.openedFolder.subSubFolder === subSubSubFolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} /> <span
                                                                                                onClick={() => {
                                                                                                    this.props.updateDropdown({
                                                                                                        dropdown: this.props.folderDropdown.path,
                                                                                                        prop: 'selectedLabel',
                                                                                                        val: this.props.folderDropdown.selectedLabel !== subSubSubFolder.name ? subSubSubFolder.name : '',

                                                                                                    });
                                                                                                    this.props.updateDropdown({
                                                                                                        dropdown: this.props.folderDropdown.path,
                                                                                                        prop: 'selectedId',
                                                                                                        val: this.props.folderDropdown.selectedId !== subSubSubFolder.id ? subSubSubFolder.id : '',
                                                                                                    });
                                                                                                }}
                                                                                            >{subSubSubFolder.name}</span></div>
                                                                                            {this.props.openedFolder.subSubFolder === subSubSubFolder.id && subSubSubFolder.folders.map((level5Folder, index) => {
                                                                                                return (
                                                                                                    <div className="fifthLevelF" > <i className={'fa fa-file'} /> <span
                                                                                                        onClick={() => {
                                                                                                            this.props.updateDropdown({
                                                                                                                dropdown: this.props.folderDropdown.path,
                                                                                                                prop: 'selectedLabel',
                                                                                                                val: this.props.folderDropdown.selectedLabel !== level5Folder.name ? level5Folder.name : '',
                                                                                                            });
                                                                                                            this.props.updateDropdown({
                                                                                                                dropdown: this.props.folderDropdown.path,
                                                                                                                prop: 'selectedId',
                                                                                                                val: this.props.folderDropdown.selectedId !== level5Folder.id ? level5Folder.id : '',
                                                                                                            });
                                                                                                        }}
                                                                                                    >{level5Folder.name}</span></div>
                                                                                                )
                                                                                            })}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })}
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </React.Fragment>
                                                            )})}

                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                        <div className="small-12 medium-6 large-6 hidden">
                                            <Dropdown
                                                dropdown={this.props.teamDropdown}
                                                dropdownList={this.props.userGroupList}
                                                defaultSelectedLabel={'Alle'}
                                                itemIdProp={'name'}
                                                itemLabelProp={'label'}
                                                dropdownLabelOverride={'Benutzergruppe'}
                                                multiple={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <Button
                                                label={this.getModalLabel(
                                                    'folder',
                                                    this.props.folderModal.action,
                                                    'button'
                                                )}
                                                inverted
                                                onClick={() => this.saveFolder()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div
                    id="ModalShortLink"
                    className={this.props.shortLinkModal.view ? 'modal visible' : 'modal hidden'}
                >
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                            <span>
                                <i className="fa fa-check-square" aria-hidden="true"></i> Shortlink{' '}
                                {window.location.protocol +
                                    '//' +
                                    window.location.host + '/'+this.props.shortLink.type + '/' +

                                    (this.props.shortLink.name || '')}
                            </span>{' '}
                            <span className="close">
                                <a
                                    href="/#-"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.updateModal({
                                            path: 'documents.shortLink',
                                            prop: 'view',
                                            val: !this.props.shortLinkModal.view,
                                        });

                                    }}
                                >
                                    <i className="fa fa-times-circle-o"></i>
                                </a>
                            </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <div className="group">
                                                <input
                                                    type="text"
                                                    style={this.getErrorStyle()}
                                                    value={this.props.shortLink.name || ''}
                                                    onChange={(e) => {
                                                        this.validateInput(e.target.value);
                                                    }}
                                                />{' '}
                                                <label>Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <Button
                                                label={'Speichern'}
                                                inverted
                                                onClick={() => {
                                                    this.validateInput(this.props.shortLink.name)
                                                    if (!this.props.shortLink.error) {
                                                        this.props.saveShortLink(true);
                                                    } else {
                                                        alert(
                                                            'Der link darf keine Umlaute oder Sonderzeichen enthalten.'
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Breadcrumb path="Dokumente">
                    <div className="btn-3 absence-date">
                        {this.can('dokument_oder_ordner_eigene_durchsuchen',
                                        'dokument_oder_ordner_alle_durchsuchen') &&
                            <input type="text" name="test"
                                value={this.props.documentSearch || ''}
                                placeholder={'Suche Dokumente'}
                                onChange={(e) => this.props.updateDocumentSearch(e.target.value)}
                        />}
                    </div>
                    {this.can('dokument_oder_ordner_erstellen') && (
                        <Button
                            label="Neues Dokument"
                            onClick={() => {
                                this.props.updateModal({
                                    path: 'documents.document',
                                    prop: 'view',
                                    val: !this.props.documentModal.view,
                                });
                                this.props.updateDocumentToEdit({});
                                this.props.updateOpenedFolder({prop: 'parent', value: ''});
                                this.props.updateOpenedFolder({prop: 'folder', value: ''});
                                this.props.updateOpenedFolder({prop: 'subFolder', value: ''});
                                this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                            }}
                        />
                    )}
                    {this.can('dokument_oder_ordner_erstellen') && (
                        <Button
                            label="Neuer Ordner"
                            onClick={() => {
                                this.props.updateModal({
                                    path: 'documents.folder',
                                    prop: 'view',
                                    val: !this.props.folderModal.view,
                                });
                                this.props.updateFolderToEdit({});
                                this.props.updateDocumentToEdit({});
                                this.props.updateOpenedFolder({prop: 'parent', value: ''});
                                this.props.updateOpenedFolder({prop: 'folder', value: ''});
                                this.props.updateOpenedFolder({prop: 'subFolder', value: ''});
                                this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                            }}
                        />
                    )}
                </Breadcrumb>

                <main>
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                                            <span>
                                                <i className="fa fa-file" aria-hidden="true"></i>
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={(e) => {
                                                        this.props.loadCurrentFolder(true);
                                                        this.props.updateDocumentSearch('');
                                                    }}
                                                >
                                                    {' '}
                                                    Dokumente &nbsp;
                                                </span>
                                                {this.props.currentFolder.breadCrumb &&
                                                    this.props.currentFolder.breadCrumb.map(
                                                        (item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <li

                                                                        className="fa fa-angle-right"
                                                                    />
                                                                    &nbsp;
                                                                    <span
                                                                        className="cursor-pointer"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.props.loadCurrentFolder(
                                                                                { id: item.id }
                                                                            );
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </span>
                                                                    &nbsp;
                                                                    {this.props.loadingAnimation && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/>}
                                                                </Fragment>
                                                            );
                                                        }
                                                    )}
                                            </span>
                                            {Object.prototype.hasOwnProperty.call(
                                                this.props.currentFolder,
                                                'parent'
                                            ) && (
                                                <a
                                                    href="/#-"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.updateDocumentSearch('');
                                                        this.props.loadCurrentFolder({
                                                            id:
                                                                this.props.currentFolder.parent !==
                                                                null
                                                                    ? this.props.currentFolder
                                                                          .parent.id
                                                                    : this.props.currentFolder
                                                                          .parent,
                                                        });
                                                    }}
                                                    className={'pull-right'}
                                                >
                                                    zurück
                                                </a>
                                            )}
                                        </div>

                                        {this.props.currentFolder.folders.map((folder, index) => {
                                            if (folder.id === 'defaultId') {
                                                return (
                                                    <a
                                                        href="/#-"
                                                        className="dokument-item"
                                                        key={`folderIndex${index}`}
                                                    >
                                                        <div className="default-list-item">
                                                            <span></span>
                                                        </div>
                                                    </a>
                                                );
                                            } else if (this.getPermission(folder.teams)) {
                                                return (
                                                    <a
                                                        href="/#-"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.loadCurrentFolder({
                                                                id: folder.id,
                                                            });
                                                        }}
                                                        className="dokument-item"
                                                        key={`folderIndex${index}`}
                                                    >
                                                        <div className="dokument-item-left">
                                                            <i
                                                                className="fa fa-folder"
                                                                aria-hidden="true"
                                                            ></i>
                                                            <span>{folder.name}</span>
                                                        </div>

                                                        <div className="dokument-item-right">
                                                            <span>{folder.countItems} Objekte</span>
                                                            {this.can('dokument_oder_ordner_bearbeiten') && (
                                                                <i
                                                                    className={folder.shortLink ? "fa fa-link" : "fa fa-link grey" }
                                                                    aria-hidden="true"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        this.props.updateModal({
                                                                            path:
                                                                                'documents.shortLink',
                                                                            prop: 'view',
                                                                            val: !this.props
                                                                                .shortLinkModal
                                                                                .view,
                                                                        });
                                                                        this.props.updateShortLink(
                                                                            {
                                                                                prop: 'id',
                                                                                val:
                                                                                folder.id,
                                                                            }
                                                                        );
                                                                        this.props.updateShortLink(
                                                                            {
                                                                                prop: 'name',
                                                                                val: folder.shortLink || this.generateShortLink(folder.name),
                                                                            }
                                                                        );
                                                                        this.props.updateShortLink(
                                                                            {
                                                                                prop: 'type',
                                                                                val: 'slf',
                                                                            }
                                                                        );
                                                                        this.props.updateShortLink(
                                                                            {
                                                                                prop: 'error',
                                                                                val: false,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                            {this.can('dokument_oder_ordner_bearbeiten') && (
                                                                    <i
                                                                        className="fa fa-pencil"
                                                                        aria-hidden="true"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.editFolder(folder);
                                                                            this.props.updateOpenedFolder({prop: 'parent', value: ''});
                                                                            this.props.updateOpenedFolder({prop: 'folder', value: ''});
                                                                            this.props.updateOpenedFolder({prop: 'subFolder', value: ''});
                                                                            this.props.updateOpenedFolder({prop: 'subSubFolder', value: ''});
                                                                        }}
                                                                    />
                                                                )}
                                                            {this.can('dokument_oder_ordner_l_schen') && (
                                                                <i
                                                                    className="fa fa-trash"
                                                                    aria-hidden="true"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.deleteItem(folder);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </a>
                                                );
                                            }

                                            return <div key={`${index}EmptyDiv`}></div>;
                                        })}

                                        {this.props.currentFolder.documents.map(
                                            (document, index) => {
                                                if (document.id === 'defaultId') {
                                                    return (
                                                        <a
                                                            href="/#-"
                                                            className="dokument-item"
                                                            key={index}
                                                        >
                                                            <div className="default-list-item">
                                                                <span></span>
                                                            </div>
                                                        </a>
                                                    );
                                                } else if (this.getPermission(this.props.currentFolder.teams)) {
                                                    return (
                                                        <a
                                                            key={'doclink-' + index}
                                                            className={'dokument-item'}
                                                            href="/"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.downloadForm[index].submit();
                                                            }}
                                                        >
                                                            <div className="dokument-item-left">
                                                                <i
                                                                    className={this.getFileIcon(
                                                                        document.type
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                                <span>{document.name}</span>
                                                                    {document.createdAtFormated === document.updatedAtFormated &&
                                                                        <span style={{'opacity':0.5}}>&nbsp;<small>hochgeladen am {document.createdAtFormated} </small></span>
                                                                    }

                                                                    {document.createdAtFormated !== document.updatedAtFormated && document.updatedAtFormated != null &&
                                                                        <span style={{'opacity':0.5}}>&nbsp;<small>bearbeitet am {document.updatedAtFormated} </small></span>
                                                                    }

                                                                    {document.createdAtFormated !== document.updatedAtFormated && document.updatedAtFormated == null &&
                                                                        <span style={{'opacity':0.5}}>&nbsp;<small>hochgeladen am {document.createdAtFormated} </small></span>
                                                                    }
                                                                    {document.createdBy &&
                                                                        <span style={{'opacity':0.5}}>&nbsp;<small>von {document.createdBy} </small></span>
                                                                    }

                                                            </div>
                                                            <div className="dokument-item-right">
                                                                <span>
                                                                    {document.type} -{' '}
                                                                    {bytesToString(document.size)}
                                                                </span>
                                                                <i
                                                                    className="fa fa-download"
                                                                    aria-hidden="true"
                                                                />
                                                                {this.can('dokument_oder_ordner_bearbeiten') && (
                                                                    <i
                                                                        className="fa fa-link"
                                                                        aria-hidden="true"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.props.updateModal({
                                                                                path:
                                                                                    'documents.shortLink',
                                                                                prop: 'view',
                                                                                val: !this.props
                                                                                    .shortLinkModal
                                                                                    .view,
                                                                            });
                                                                            this.props.updateShortLink(
                                                                                {
                                                                                    prop: 'id',
                                                                                    val: document.id,
                                                                                }
                                                                            );
                                                                            this.props.updateShortLink(
                                                                                {
                                                                                    prop: 'name',
                                                                                    val: document.shortLink || this.generateShortLink(document.name),
                                                                                }
                                                                            );
                                                                            this.props.updateShortLink(
                                                                                {
                                                                                    prop: 'type',
                                                                                    val: 'sl',
                                                                                }
                                                                            );
                                                                            this.props.updateShortLink(
                                                                                {
                                                                                    prop: 'error',
                                                                                    val: false,
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                                 {this.can('dokument_oder_ordner_bearbeiten') && (
                                                                    <i
                                                                        className="fa fa-pencil"
                                                                        aria-hidden="true"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.editDocument(document);
                                                                        }}
                                                                    />
                                                                )}
                                                                {this.can('dokument_oder_ordner_l_schen') && (
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.deleteItem(
                                                                                document,
                                                                                true
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </a>
                                                    );
                                                }
                                                return <div key={index}></div>;
                                            }
                                        )}
                                        {this.props.currentFolder.documents.map(
                                            (document, index) => {
                                                if (this.getPermission(document.teams)) {
                                                    return (
                                                        <form
                                                            key={'docform-' + index}
                                                            action={this.getLink(document)}
                                                            target="_blank"
                                                            method="post"
                                                            ref={(ref) => {
                                                                this.downloadForm[index] = ref;
                                                            }}
                                                        >
                                                            <input
                                                                type="hidden"
                                                                name="authTok"
                                                                value={'Bearer ' + this.props.token}
                                                            />
                                                        </form>
                                                    );
                                                }
                                                return <div></div>;
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <footer id="footer-main"></footer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Documents);
