import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '../Redux/index';
import '../assets/styles/_stock.scss';

const mapStateToProps = (state) => {
    return {
        dropdownPaths: state.data.dropdown.paths
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        updateAllLocationFilters: (filter) => dispatch(actions.Data.updateAllLocationFilters(filter)),
    };
};

class Dropdown extends PureComponent {
    static propTypes = {
        updateDropdown: PropTypes.func.isRequired,
        dropdownPaths: PropTypes.array
    };

    hideDropdowns(dropdownPath) {
        this.props.dropdownPaths.map((dropdown, index) => {
            if (dropdownPath !== dropdown) {
                this.props.updateDropdown({dropdown: dropdown, prop:'view', val:false});
            }
            return '';
        });
        return '';
    }

    getLabel()
    {
        let  newLabel = '';
            this.props.dropdownList.map((listItem) => {
                if (this.props.dropdown.selectedId.indexOf(listItem[this.props.itemIdProp]) !== -1) {
                    newLabel += listItem[this.props.itemLabelProp] + ' ';
                }
                return null;
            });
        return newLabel || this.props.defaultSelectedLabel || 'Bitte wählen';
    }

    getLevel(level)
    {
        let sign = '';
        for(let i = 0; i < level; i++) {
            sign+='-';
        }
        return sign;
    }
    getChecked(item) {
        if(!this.props.multiple) {
            if (this.props.dropdown.selectedId === item[this.props.itemIdProp]) {
                return true;
            }
        } else if(this.props.dropdown.selectedId.indexOf(item[this.props.itemIdProp]) !== -1) {
            return true;
        }
        return false;
    }
    selectMultiple(item) {
        this.props.updateDropdown({dropdown: this.props.dropdown.path, prop:'selectedId', val:item[this.props.itemIdProp], multiple: true, parent: this.props.dropdown.path.split(".")[1], dropdownName: this.props.dropdown.path.split(".")[2]});
        this.props.updateDropdown({dropdown: this.props.dropdown.path, prop:'selectedLabel', val:item.label});
    }

    createSelectItems(){
        const f = this.props.filterBy || '';
        return this.props.dropdownList.filter(
            (element) => (element[this.props.filterProp || this.props.itemLabelProp]!=null&&element[this.props.filterProp || this.props.itemLabelProp].toLowerCase().indexOf(f.toLowerCase()) >= 0))
    }

    render() {
        return (
            <div className={this.props.disabled === true ? 'custom-select disabled' : 'custom-select'}>
                <div className={this.props.dropdown.view ? 'select-selected select-arrow-active' : 'select-selected'}
                     onClick={() => {
                         if (this.props.disabled !== true) {
                            this.props.updateDropdown({dropdown: this.props.dropdown.path, prop:'view', val:!this.props.dropdown.view});
                            this.hideDropdowns(this.props.dropdown.path);
                         }
                     }}
                ><span>{this.props.dropdownLabelOverride || this.props.dropdown.label}</span> {this.props.multiple && this.getLabel()} {!this.props.multiple && (this.props.dropdown.selectedLabel || this.props.defaultSelectedLabel || 'Bitte wählen')}</div>
                <div className={this.props.dropdown.view ? 'select-items' : 'select-items select-hide'} >
                    {this.createSelectItems().map((item, index) => (
                        <div key={index}
                             className="select-item"
                             onClick={() => {
                                 if (!this.props.multiple) {
                                     this.props.updateDropdown({dropdown: this.props.dropdown.path, prop:'selectedId', val:item[this.props.itemIdProp]});
                                     this.props.updateDropdown({dropdown: this.props.dropdown.path, prop:'selectedLabel', val:item[this.props.itemLabelProp]});
                                     this.props.updateDropdown({dropdown: this.props.dropdown.path, prop:'view', val:!this.props.dropdown.view});
                                     this.props.updateAllLocationFilters({'path': this.props.dropdown.path, 'selectedLabel': item[this.props.itemLabelProp], 'selectedId': item[this.props.itemIdProp]})
                                 } else {
                                     this.selectMultiple(item);
                                 }
                                 if(this.props.syncDropdown) {
                                    this.props.updateDropdown({dropdown: this.props.syncDropdownPath, prop: 'selectedLabel',val: item[this.props.itemLabelProp],});
                                    this.props.updateDropdown({dropdown: this.props.syncDropdownPath, prop: 'selectedId',val: item[this.props.itemIdProp],});
                                 }
                                 if (this.props.onSelect) {
                                     this.props.onSelect(item[this.props.itemIdProp])
                                 }

                             }}>
                            {this.getLevel(item.level)} {item.label || item[this.props.itemLabelProp]} {this.getChecked(item) && <i className="pull-right fa fa-check-square"></i>}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
