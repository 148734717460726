import classNames from 'classnames';
import FileIcon, { getFileIcon } from 'Components/UI/FileIcon';
import 'Components/UI/Form/fileInput.scss';
import Icon from 'Components/UI/Icon/Icon';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import './fileInput.scss';

class FileInput extends React.Component {
    static propTypes = {
        accept: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string,
        multiple: PropTypes.bool,
        onChange: PropTypes.func,
        readOnly: PropTypes.bool,
        value: PropTypes.any,
    };

    state = {
        files: this.props.value || '',
    };

    handleDroppedFiles = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            file.preview = URL.createObjectURL(file);
            file.fileName = file.name;
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted', file, reader);
            reader.onerror = () => console.log('file reading has failed', file, reader);
            reader.onload = () => {
                file.data = reader.result;
                this.addFile(file);
            };
            reader.readAsDataURL(file);
        });
    };

    addFile = (file) => {
        if (!this.props.value) {
            this.handleOnChange([file]);
        }
        const newValue = [this.props.value].flat();
        if (!newValue.some((f) => f.data === file.data)) {
            newValue.push(file);
            this.handleOnChange(newValue);
        }
    };

    handleFileRemoval = (index) => {
        if (this.props.readOnly) {
            return;
        }
        const files = [this.props.value].flat();
        files.splice(index, 1);
        this.handleOnChange(files);
    };

    handleOnChange = (value) => {
        const newValue = this.props.multiple ? value || [] : value[value.length - 1] || '';
        const event = {
            target: {
                name: this.props.name,
                value: newValue,
            },
        };
        this.props.onChange && this.props.onChange(event);
    };

    thumbnailList = (value) => {
        if (!value) {
            return null;
        }
        const files = Array.isArray(value) ? value : [value];
        return files.map(this.renderThumbnail);
    };

    renderThumbnail = (file, idx) => (
        <div
            key={idx}
            className={
                'FileInput__thumbnail' +
                (getFileIcon(file.type) === 'file-image'
                    ? ' FileInput__thumbnail--image'
                    : ' FileInput__thumbnail--icon')
            }
            onClick={(e) => e.stopPropagation()}
        >
            <a href={file.resourceUri || file.preview} target="_blank" rel="noopener noreferrer">
                {' '}
                <span className="FileInput__thumbnail__meta">
                    {file.uploadedAt
                        ? moment(new Date(file.uploadedAt)).format('DD.MM.YY HH:mm[ Uhr]')
                        : 'noch nicht gespeichert'}
                </span>
                {getFileIcon(file.type) === 'file-image' ? (
                    <img src={file.resourceUri || file.preview} alt="Preview" />
                ) : (
                    <FileIcon fileType={file.type} />
                )}
            </a>
            {!this.props.readOnly && (
                <span className="FileInput__thumbnail__remove">
                    <Icon
                        type="delete-x"
                        onClick={(e) => {
                            e.stopPropagation();
                            this.handleFileRemoval(idx);
                        }}
                    />
                </span>
            )}
        </div>
    );

    render() {
        return (
            <Dropzone
                accept={this.props.accept}
                disabled={this.props.readOnly}
                multiple={this.props.multiple || false}
                onDropAccepted={this.handleDroppedFiles}
            >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept }) => {
                    return (
                        <div
                            className={classNames(this.props.className, 'FileInput', {
                                'FileInput--accept': isDragAccept,
                                'FileInput--active': isDragActive,
                                'FileInput--readonly': this.props.readOnly,
                                'FileInput--reject': isDragReject,
                                'FileInput--single': !this.props.multiple,
                            })}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <label>{this.props.label}</label>
                            <div className="FileInput__thumbnail-list">
                                {this.thumbnailList(this.props.value)}
                            </div>
                            <div className="FileInput__upload-icon">
                                <Icon type="upload" />
                            </div>
                        </div>
                    );
                }}
            </Dropzone>
        );
    }
}

export default FileInput;
