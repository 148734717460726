import React from 'react';

const Card = ({marginTop, pagination, children}) => (
    <div className={`content-wrapper ${marginTop ? 'margin-top' : ''}`}>
        <div className="content-wrapper-box">
            <div className="grid-wrapper">
                <div className="small-12 medium-12 large-12">
                    <div className="site-wrapper">
                        {children}
                    </div>
                </div>
                {pagination}
            </div>
        </div>
    </div>
)
Card.defaultProps = {
    marginTop: false
}
export default Card;
