import DateInput from 'Components/UI/Form/DateInput';
import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux';
import Icon from '../Components/UI/Icon/Icon';
import * as moment from 'moment';

const mapStateToProps = (state) => {
    return {
        absenceRequest: state.absence.absenceRequest,
        currentUser: state.data.currentUser,
        prevView: state.absence.prevView,
        loadingAnimation: state.advertising.loadingAnimation,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updatePrevView: (view) => dispatch(actions.Absence.updatePrevView(view)),
        updateAbsenceRequest: (absence) => dispatch(actions.Absence.updateAbsenceRequest(absence)),
        sendAbsenceRequest: (absence) => dispatch(actions.Absence.sendAbsenceRequest(absence)),
        updateLoadingAnimation: value => dispatch(actions.Advertising.updateLoadingAnimation(value))
    };
};

class AbsenceRequestForm extends PureComponent {
    static propTypes = {
        updateView: PropTypes.func.isRequired,
        updateAbsenceRequest: PropTypes.func,
        sendAbsenceRequest: PropTypes.func,
        currentUser: PropTypes.object,
        updatePrevView: PropTypes.func.isRequired,
        prevView: PropTypes.string,
        absenceRequest: PropTypes.object
    };

    componentDidMount() {
        this.props.updateLoadingAnimation(false)
        this.props.updateAbsenceRequest({prop: 'userId', val: this.props.currentUser.id});
    }

    sendAbsenceRequest() {
        if (this.props.absenceRequest.startDate === "" || this.props.absenceRequest.endDate === "") {
            alert("Urlaub von und Urlaub bis sind Pflichtfelder.");
            return;
        }
        if (this.props.absenceRequest.overtimeDeduction === true && this.props.absenceRequest.overtime === "") {
            alert("Bitte geben Sie die Anzahl der Überstunden ein.");
            return;
        }
        if (this.props.absenceRequest.specialHoliday === true && this.props.absenceRequest.reason === "") {
            alert("Bitte geben Sie den Grund für Sonderurlaub ein.");
            return;
        }
        if (!this.props.absenceRequest.userId) {
            alert("Ein Fehler ist aufgetreten bitte melden Sie sich erneut an");
            return;
        }

         this.props.sendAbsenceRequest(true);
        // this.props.updateView(this.props.prevView);
        // this.props.updateAbsenceRequest({prop: 'overtime', val: ""});
        // this.props.updateAbsenceRequest({prop: 'overtimeDeduction', val: false});
        // this.props.updateAbsenceRequest({prop: 'specialHoliday', val: false});
        // this.props.updateAbsenceRequest({prop: 'reason', val: ""});
        // this.props.updateAbsenceRequest({prop: 'startDate', val: ""});
        // this.props.updateAbsenceRequest({prop: 'endDate', val: ""});
    }
    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    render() {
        return (
            <div id="main-side">
                <Breadcrumb path={['An-/ & Abwesenheiten', 'Neuer Urlaubsantrag']}/>

                <main>
                    <div id="Modal" className="modal">
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-suitcase" aria-hidden="true"></i> Neuer Urlaubsantrag
                                </span>
                                <span className="close">
                                    <Icon type="close" onClick={() => this.props.updateView(this.props.prevView)}/>
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.currentUser.lastName} readOnly/>
                                                        <label>Name</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.currentUser.firstName} readOnly/>
                                                        <label>Vorname</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <input type="text" value={this.props.currentUser.currentLocation.name} readOnly/>
                                                        <label>Standort</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">

                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="group">
                                                    <DateInput
                                                        minDate={ this.can('urlaub_r_ckdatiert_beantragen') ? null : new Date()}
                                                        selected={this.props.absenceRequest.startDate
                                                            ? new Date(this.props.absenceRequest.startDate)
                                                            : null}
                                                        onChange={(date) => this.props.updateAbsenceRequest({
                                                            prop: 'startDate',
                                                            val: moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD'),
                                                        })}
                                                    />

                                                    <label>Urlaub von</label>
                                                </div>
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                {this.props.absenceRequest.startDate &&
                                                <div className="group">
                                                    <DateInput
                                                        minDate={new Date(this.props.absenceRequest.startDate)}
                                                        selected={this.props.absenceRequest.endDate
                                                            ? new Date(this.props.absenceRequest.endDate)
                                                            : null}
                                                        onChange={(date) => this.props.updateAbsenceRequest({
                                                            prop: 'endDate',
                                                            val: moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD'),
                                                        })}
                                                    />

                                                    <label>Urlaub bis</label>
                                                </div>
                                                }
                                            </div>
                                            <div className="small-12 medium-6 large-6">
                                                <div className="check-list" style={{boxShadow: 'none'}}>
                                                    <div className="check-list-main">
                                                        <div className="checkbox-wrapper" style={{backgroundColor: '#fff'}}>
                                                            <label>
                                                                <span className={this.props.absenceRequest.overtimeDeduction === true ? 'boxChecked' : 'boxUnchecked'} onClick={() => {
                                                                    this.props.updateAbsenceRequest({prop: 'overtimeDeduction', val: !this.props.absenceRequest.overtimeDeduction});
                                                                    if(this.props.absenceRequest.overtimeDeduction === false) {
                                                                        this.props.updateAbsenceRequest({prop: 'overtime', val: ""})
                                                                    }
                                                                    setTimeout(() => {
                                                                    if (this.props.absenceRequest.overtimeDeduction === true) {
                                                                        this.props.updateAbsenceRequest({prop: 'specialHoliday', val: false});
                                                                        this.props.updateAbsenceRequest({prop: 'reason', val: ""});
                                                                    }}, 10)
                                                                }} />
                                                            </label>
                                                            <div className="checkbox-text">
                                                                <span className={'prim-text'}>Abzug von Überstunden</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="small-12 medium-6 large-6">
                                                    {this.props.absenceRequest.overtimeDeduction &&
                                                        <div className="group">
                                                            <input type="text"  value={this.props.absenceRequest.overtime} onChange={(e) => {
                                                                if(e.target.value < 0) {
                                                                    e.target.value = 0;
                                                                } else if(e.target.value.indexOf('e') !== -1) {
                                                                    e.target.value =  e.target.value.replace("e", "");
                                                                } else if(e.target.value.indexOf('E') !== -1) {
                                                                    e.target.value =  e.target.value.replace("E", "");
                                                                } else if(e.target.value.length > 4) {
                                                                    e.target.value = e.target.value.substring(0, 4);
                                                                } else if(e.target.value.length > 3 && e.target.value.indexOf(',') === -1){
                                                                    e.target.value = e.target.value.substring(0, 3);
                                                                }
                                                                if (e.target.value.match("^[0-9,]+$")!== null || e.target.value === "") {
                                                                    this.props.updateAbsenceRequest({prop: 'overtime', val: e.target.value});
                                                                }
                                                            }}/>
                                                            <label>Überstunden</label>
                                                        </div>
                                                    }
                                                </div>

                                            <div className="small-12 medium-6 large-6">
                                                <div className="check-list" style={{boxShadow: 'none'}}>
                                                    <div className="check-list-main">
                                                        <div className="checkbox-wrapper" style={{backgroundColor: '#fff'}}>
                                                            <label>
                                                                <span className={this.props.absenceRequest.specialHoliday === true ? 'boxChecked' : 'boxUnchecked'} onClick={() => {
                                                                    this.props.updateAbsenceRequest({prop: 'specialHoliday', val: !this.props.absenceRequest.specialHoliday});
                                                                    if(this.props.absenceRequest.specialHoliday === false) {
                                                                        this.props.updateAbsenceRequest({prop: 'reason', val: ""})
                                                                    }
                                                                    setTimeout(() => {
                                                                    if(this.props.absenceRequest.specialHoliday === true) {
                                                                        this.props.updateAbsenceRequest({prop: 'overtimeDeduction', val: false});
                                                                        this.props.updateAbsenceRequest({prop: 'overtime', val: ""});
                                                                    }}, 10)
                                                                }} />
                                                            </label>
                                                            <div className="checkbox-text">
                                                                <span className={'prim-text'}>Sonderurlaub</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="small-12 medium-6 large-6">
                                                    {this.props.absenceRequest.specialHoliday &&
                                                        <div className="group">
                                                            <input type="text" maxLength="20" value={this.props.absenceRequest.reason} onChange={(e) => this.props.updateAbsenceRequest({prop: 'reason', val: e.target.value})}/>
                                                            <label>Grund</label>
                                                        </div>
                                                    }
                                                </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <Button
                                                    icon={this.props.loadingAnimation ? 'load-more-animated': false}
                                                    label={ this.props.loadingAnimation ? 'überprüfen ...' : 'Absenden'}
                                                    inverted
                                                    disabled={this.props.loadingAnimation}
                                                    onClick={() => this.sendAbsenceRequest()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>

                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceRequestForm);
