import React from 'react';
import {connect} from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import CategoryList from '../Components/Category/CategoryList';
import CategoryForm from '../Components/Category/CategoryForm';
import { actions } from '../Redux';

const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        currentCategory: state.category.currentCategory,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateCurrentCategory: (category) => dispatch(actions.Category.updateCurrentCategory(category)),
});



class CategoryPage extends React.Component {
    state = {
        showNewCategoryForm: false,
    };

    toggleCategoryForm = () => {
        this.setState({showNewCategoryForm: !this.state.showNewCategoryForm});
        this.props.updateCurrentCategory(null)
    };

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    render() {
        return (
            <div id="main-side">
                <Breadcrumb path={['Sortiment']}>
                    {this.can('sortiment_kategorie_erstellen') &&
                        <Button label="Neues Sortiment" onClick={this.toggleCategoryForm}/>
                    }
                    {this.state.showNewCategoryForm
                    && <CategoryForm onCancel={this.toggleCategoryForm}
                                     onSubmit={this.toggleCategoryForm}/>
                    }
                </Breadcrumb>
                <main>
                    <CategoryList/>
                </main>
                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
