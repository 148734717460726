import classNames from 'classnames';
import '../assets/styles/_task.scss';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import logo from '../assets/img/daten_depot.svg';
import IconLink from '../Components/UI/IconLink';
import { actions } from '../Redux/index';

const mapStateToProps = (state) => {
    return {
        taskList: state.data.taskList,
        view: state.ui.view,
        userInfoList: state.data.userInfoList,
        userListPage: state.ui.userListPage,
        currentUser: state.data.currentUser,
        taskListNotFinishedCount: state.task.listNotFinishedCount,
        vacationMenuClass: state.ui.vacationMenuClass,
        sickReportMenuClass: state.ui.sickReportMenuClass,
        trainingMenuClass: state.ui.trainingMenuClass,
        newsMenuClass: state.ui.newsMenuClass,
        taskMenuClass: state.ui.taskMenuClass,
        absenceView: state.absence.view,
        sickReportView: state.sickReport.view,
        taskView: state.ui.taskView,
        locationFilter: state.data.filter.task.location,
        locationFilterAbsence: state.data.filter.absencerecord.location,
        openListCount: state.absence.openListCount,
        countTrainings: state.ui.countTrainings,
        countAdvertisings: state.ui.countAdvertisings,
        countClaims: state.ui.countClaims,
        headerOpen: state.ui.headerOpen,
        trainingView: state.training.view,
        newsView: state.news.view,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateUserInfoList: (infoList) => dispatch(actions.Data.updateUserInfoList(infoList)),
        loadUserInfoList: (infolist) => dispatch(actions.Data.loadUserInfoList(infolist)),
        logout: (token) => dispatch(actions.Ui.updateToken(token)),
        updateUsername: (username) => dispatch(actions.Ui.updateUsername(username)),
        updatePassword: (password) => dispatch(actions.Ui.updatePassword(password)),
        loadTaskList: (list) => dispatch(actions.Task.loadTaskList(list)),
        loadAllUsers: (value) => dispatch(actions.Data.loadAllUsers(value)),
        updateVacationMenuClass: (value) => dispatch(actions.Ui.updateVacationMenuClass(value)),
        updateSickReportMenuClass: (value) => dispatch(actions.Ui.updateSickReportMenuClass(value)),
        updateTrainingMenuClass: (value) => dispatch(actions.Ui.updateTrainingMenuClass(value)),
        updateNewsMenuClass: (value) => dispatch(actions.Ui.updateNewsMenuClass(value)),
        updateTaskMenuClass: (value) => dispatch(actions.Ui.updateTaskMenuClass(value)),
        updateFilter: (filter) => dispatch(actions.Data.updateFilter(filter)),
        updateAbsenceView: (value) => dispatch(actions.Absence.updateView(value)),
        updateTaskView: (value) => dispatch(actions.Ui.updateTaskView(value)),
        loadAbsenceList: (list) => dispatch(actions.Absence.loadAbsenceList(list)),
        updateAbsenceList: (list) => dispatch(actions.Absence.updateAbsenceList(list)),
        updateHolidays: (holidays) => dispatch(actions.Data.updateHolidays(holidays)),
        loadArticles: (article) => dispatch(actions.Data.loadArticles(article)),
        loadTrainingList: (list) => dispatch(actions.Training.loadTrainingList(list)),
        updateTrainingList: (list) => dispatch(actions.Training.updateTrainingList(list)),
        updateSickReportView: (value) => dispatch(actions.SickReport.updateView(value)),
        loadSickReportList: (list) => dispatch(actions.SickReport.loadSickReportList(list)),
        updateSickReportList: (list) => dispatch(actions.SickReport.updateSickReportList(list)),
        loadAdvertisingList: (list) => dispatch(actions.Advertising.loadAdvertisingList(list)),
        updateUserToEdit: (user) => dispatch(actions.Data.updateUserToEdit(user)),
        updateUserToEditData: (userdata) => dispatch(actions.Data.updateUserToEditData(userdata)),
        loadCalendar: (value) => dispatch(actions.Data.loadCalendar(value)),
        resetUiState: (iState) => dispatch(actions.Ui.resetState(iState)),
        resetDataState: (iState) => dispatch(actions.Data.resetState(iState)),
        resetAbsenceState: (iState) => dispatch(actions.Absence.resetState(iState)),
        resetAdvertisingState: (iState) => dispatch(actions.Advertising.resetState(iState)),
        resetCategoryState: (iState) => dispatch(actions.Category.resetState(iState)),
        resetClaimsState: (iState) => dispatch(actions.Claims.resetState(iState)),
        resetNewsState: (iState) => dispatch(actions.News.resetState(iState)),
        resetSickReportState: (iState) => dispatch(actions.SickReport.resetState(iState)),
        resetTaskState: (iState) => dispatch(actions.Task.resetState(iState)),
        resetTrainingState: (iState) => dispatch(actions.Training.resetState(iState)),
        updateSeen: page => dispatch(actions.Ui.updateSeen(page)),
        updateCountSeen: seen => dispatch(actions.Ui.updateCountSeen(seen)),
        loadAllUsersForTraining: list => dispatch(actions.Training.loadAllUsersForTraining(list)),
        updateHeaderOpen: value => dispatch(actions.Ui.updateHeaderOpen(value)),
        loadAbsenceUserOverviewList: from => dispatch(actions.Absence.loadAbsenceUserOverviewList(from)),
        updateReference: (value) => dispatch(actions.Training.updateReference(value)),
        updateDocumentSearch: (search) => dispatch(actions.Ui.updateDocumentSearch(search)),
        resetAbsenceSorting: (value) => dispatch(actions.Absence.resetAbsenceSorting(value)),
        reloadTaskList: (list) => dispatch(actions.Task.reloadTaskList(list)),
        updateTrainingView: (value) => dispatch(actions.Training.updateTrainingView(value)),
        updateNewsView: (value) => dispatch(actions.News.updateNewsView(value)),
        loadTrainingOverviewList: (value) => dispatch(actions.Training.loadOverviewList(value)),
        loadNewsList: (list) => dispatch(actions.News.loadNewsList(list)),
        resetNews: () => dispatch(actions.News.resetState()),

    };
};

class LeftMenu extends React.PureComponent {
    static propTypes = {
        updateView: PropTypes.func.isRequired,
        taskList: PropTypes.array,
        updateTaskList: PropTypes.func,
        logout: PropTypes.func.isRequired,
        view: PropTypes.string.isRequired,
        updateUserInfoList: PropTypes.func,
        loadUserInfoList: PropTypes.func,
        userInfoList: PropTypes.array,
        locationList: PropTypes.array,
        currentUser: PropTypes.object,
        loadTaskList: PropTypes.func,
        loadAllUsers: PropTypes.func,
        vacationMenuClass: PropTypes.string,
        updateVacationMenuClass: PropTypes.func.isRequired,
        updateSickReportMenuClass: PropTypes.func.isRequired,
        updateFilter: PropTypes.func.isRequired,
        updateAbsenceView: PropTypes.func.isRequired,
        loadAbsenceList: PropTypes.func.isRequired,
        updateAbsenceList: PropTypes.func.isRequired,
        updateHolidays: PropTypes.func.isRequired,
        loadTrainingList: PropTypes.func.isRequired,
        updateTrainingList: PropTypes.func.isRequired,
        resetUiState: PropTypes.func,
        resetDataState: PropTypes.func,
        resetAbsenceState: PropTypes.func,
        resetAdvertisingState: PropTypes.func,
        resetCategoryState: PropTypes.func,
        resetClaimsState: PropTypes.func,
        resetNewsState: PropTypes.func,
        resetSickReportState: PropTypes.func,
        resetTaskState: PropTypes.func,
        resetTrainingState: PropTypes.func,
        updateSeen: PropTypes.func,
        updateCountSeen: PropTypes.func,
        updateHeaderOpen: PropTypes.func,
        updateNewsView: PropTypes.func,
    };

    state = {
        isMenuOpen: false,
    };


    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    getUrl() {
        if (this.props.currentUser.resourceUri) {
            return this.props.currentUser.resourceUri;
        } else {
            return ''
        }
    }

    resetAbsenceFilter() {
        this.props.resetAbsenceSorting('')
        this.props.updateFilter({
            path: 'data.filter.absencerecord.location',
            prop: 'selectedId',
            val: ''
        })
        this.props.updateFilter({
            path: 'data.filter.absencerecord.location',
            prop: 'selectedLabel',
            val: ''
        })
    }

    updateFilterProps(){
        this.props.resetAbsenceSorting('')
        if (this.props.locationFilter.selectedId === '') {
            this.props.updateFilter({path: 'data.filter.task.location', prop:'selectedId', val:this.props.currentUser.currentLocation.id});
            this.props.updateFilter({path: 'data.filter.task.location', prop:'selectedLabel', val:this.props.currentUser.currentLocation.label});
        }
        this.props.updateFilter({path: 'data.filter.task.team', prop:'selectedId', val:this.props.currentUser.roleIdentifier});
        this.props.updateFilter({path: 'data.filter.task.team', prop:'selectedLabel', val:this.props.currentUser.roleLabel});
    }



    menuItem = (props) =>
        <li className={classNames(props.className, {
            mainNav__itemActive: this.props.view === props.view && (props.isActive
                ? props.isActive()
                : true),
        })}
        >
            <IconLink type={props.icon}
                      title={props.label}
                      onClick={() => {
                          props.view && this.props.updateView(props.view);
                          props.onClick && props.onClick();
                      }}
            >
                <span>{props.label}</span>
                {props.counter ? <span className="counter counter-small counter-gray">{props.counter}</span> : null}
                {props.dropdownCounter ? <span className="counter dropdown-counter counter-small counter-gray">{props.dropdownCounter}</span> : null}
            </IconLink>
            {props.children}
        </li>;

    render() {
        return (
            <header id="header-main" className={classNames({ open: this.props.headerOpen })}>
                <div className="header-wrapper">
                    <div className={classNames('header-open', { active: this.props.headerOpen })}
                         onClick={() => this.props.updateHeaderOpen(!this.props.headerOpen)}
                    />
                    <div className="header-top">
                        <a className="header-logo-wrapper" href="/#-"
                           onClick={(e) => {
                               e.preventDefault();
                               this.props.updateView('Dashboard');
                               this.props.updateNewsView(1);
                               this.props.updateHolidays(true);
                           }}>
                            <img src={logo} alt="DatenDEPOT"/></a>
                        <div className="initials">
                            <a href="/"
                               style={{
                                   backgroundImage: "url("+this.getUrl()+")",
                                   backgroundPosition: "center",
                                   borderRadius: "50%",
                                   backgroundRepeat: "no-repeat",
                                   backgroundSize: "cover",
                               }}
                               onClick={(e) => {
                                   e.preventDefault();
                                   this.props.updateView('UserData');
                                   this.props.updateUserToEditData({'prop': 'id','val': this.props.currentUser.id});
                                   this.props.updateUserToEditData({'prop': 'firstName','val': this.props.currentUser.firstName});
                                   this.props.updateUserToEditData({'prop': 'lastName','val': this.props.currentUser.lastName});
                                   this.props.updateUserToEditData({'prop': 'phone','val': this.props.currentUser.phone});
                                   this.props.updateUserToEditData({'prop': 'selectedLocation','val': this.props.currentUser.selectedLocation});
                               }}>
                                <span>{this.props.currentUser.resourceUri ? '': this.props.currentUser.shortName}</span></a>
                        </div>
                    </div>
                    <div id="main-nav">
                        <div className="main-nav-wrapper">
                            <nav id="navigation" className="acc-menu">
                                <ul>
                                {this.can('dashboard_menu_sehen') &&
                                    <this.menuItem label="Dashboard" view="Dashboard" icon="dashboard"
                                    onClick={() => {
                                        this.props.updateNewsView(1);
                                    }}
                                    />
                                }
                                {this.can('aufgaben_menu_sehen') &&
                                    <this.menuItem
                                        label="Aufgaben" icon="task"
                                        className={this.props.taskMenuClass}
                                        dropdownCounter={this.props.taskListNotFinishedCount}
                                        onClick={() => {
                                            if(this.props.view !== 'Task') {
                                                this.props.updateView('Task');
                                                this.props.updateTaskView("2");
                                                if (this.props.currentUser.roleLabel === 'Admin' || this.props.currentUser.roleLabel === 'Personalleitung') {
                                                    this.updateFilterProps();
                                                    // if (this.props.locationFilter.selectedId === '') {
                                                    //     this.props.updateFilter({path: 'data.filter.task.location', prop:'selectedId', val:this.props.currentUser.currentLocation.id});
                                                    //     this.props.updateFilter({path: 'data.filter.task.location', prop:'selectedLabel', val:this.props.currentUser.currentLocation.label});
                                                    // }
                                                    // this.props.updateFilter({path: 'data.filter.task.team', prop:'selectedId', val:this.props.currentUser.roleIdentifier});
                                                    // this.props.updateFilter({path: 'data.filter.task.team', prop:'selectedLabel', val:this.props.currentUser.roleLabel});
                                                }
                                                //this.props.loadTaskList({ from: 0, initialLoad: true });
                                                this.props.reloadTaskList(true)
                                                    this.props.loadAllUsers(true);
                                                    this.props.loadAllUsersForTraining(true);
                                            }
                                            this.props.updateVacationMenuClass('has-subnav');
                                            this.props.updateSickReportMenuClass('has-subnav');
                                            this.props.updateTrainingMenuClass('has-subnav');
                                            this.props.updateNewsMenuClass('has-subnav');
                                            this.props.updateTaskMenuClass(
                                            this.props.taskMenuClass === 'has-subnav'
                                                ? 'has-subnav open-submenu active'
                                                : 'has-subnav',
                                        )}}
                                    >
                                        <ul>
                                            <this.menuItem
                                                label="Eigene Aufgaben" view="Task"
                                                icon="task"
                                                isActive={() => {
                                                    // eslint-disable-next-line eqeqeq
                                                    return this.props.taskView == 2;
                                                }}
                                                onClick={() => {
                                                    this.props.updateTaskView("2");
                                                    if (this.props.currentUser.roleLabel === 'Admin' || this.props.currentUser.roleLabel === 'Verwaltung') {
                                                        this.updateFilterProps();
                                                        // if (this.props.locationFilter.selectedId === '') {
                                                        //     this.props.updateFilter({path: 'data.filter.task.location', prop:'selectedId', val:this.props.currentUser.currentLocation.id});
                                                        //     this.props.updateFilter({path: 'data.filter.task.location', prop:'selectedLabel', val:this.props.currentUser.currentLocation.label});
                                                        // }
                                                        // this.props.updateFilter({path: 'data.filter.task.team', prop:'selectedId', val:this.props.currentUser.roleIdentifier});
                                                        // this.props.updateFilter({path: 'data.filter.task.team', prop:'selectedLabel', val:this.props.currentUser.roleLabel});
                                                    }
                                                    //this.props.loadTaskList({ from: 0, initialLoad: true });
                                                    this.props.reloadTaskList(true)
                                                        this.props.loadAllUsers(true);
                                                }}
                                            />
                                            {this.can('aufgaben_an_alle_stellen', 'aufgaben_an_mitarbeiter_aus_eigenen_filiale_stellen')
                                            &&
                                                <this.menuItem
                                                    label="Delegierte Aufgaben"
                                                    view="Task"
                                                    icon="task"
                                                    isActive={() => {
                                                        // eslint-disable-next-line eqeqeq
                                                        return this.props.taskView == 3;
                                                    }}
                                                    onClick={() => {
                                                        this.props.updateTaskView("3");
                                                        //this.props.loadTaskList({from: 0, initialLoad: true});
                                                        this.props.reloadTaskList(true)
                                                        this.props.loadAllUsers(true);
                                                    }}
                                                />
                                            }
                                        </ul>
                                    </this.menuItem>
                                    }
                                    { /**this.can('neuigkeiten_alle_sehen', 'neuigkeiten_eigene_sehen') &&
                                        <this.menuItem label="Neuigkeiten" view="News" icon="news" />
                                    **/}
                                    {this.can('neuigkeiten_alle_sehen', 'neuigkeiten_eigene_sehen') &&
                                    <this.menuItem
                                        label="Neuigkeiten" view="News" icon="news"
                                        className={this.can('news_archive_can_see') ? this.props.newsMenuClass : '' }
                                        onClick={() => {
                                            this.props.resetNewsState();
                                            this.props.updateNewsView(1);
                                            this.props.loadNewsList({ from: 0, view: 'news'});
                                            this.props.updateVacationMenuClass('has-subnav');
                                            this.props.updateSickReportMenuClass('has-subnav');
                                            this.props.updateTrainingMenuClass('has-subnav');
                                            this.props.updateNewsMenuClass(
                                                this.props.newsMenuClass === 'has-subnav'
                                                    ? 'has-subnav open-submenu active'
                                                    : 'has-subnav',
                                            )}}
                                        isActive={() => {
                                            return this.props.newsView === 1;
                                        }}>
                                        {this.can('news_archive_can_see') &&
                                            <ul>
                                                <this.menuItem
                                                    label="News Archiv"
                                                    view="News"
                                                    icon="archive"
                                                    isActive={() => {
                                                        return this.props.newsView === 2;
                                                    }}
                                                    onClick={() => {
                                                        this.props.resetNewsState();
                                                        this.props.updateNewsView(2);
                                                        this.props.loadNewsList({ from: 0, view: 'news'});
                                                    }}
                                                />
                                            </ul>}
                                    </this.menuItem>}
                                    {this.can('sortiment_menu_sehen') &&
                                        // <this.menuItem label="Sortiment" view="GoogleSheetsPage" icon="category"/>
                                        <this.menuItem label="Sortiment" view="CategoryPage" icon="category"/>
                                    }
                                    {this.can('an_abwesenheit_menu_sehen') &&
                                        <this.menuItem
                                            label="An-/Abwesenheit" view="AbsenceRecord" icon="absence"
                                            onClick={ () => {
                                                if (this.props.currentUser.roleLabel === 'Admin' || this.props.currentUser.roleLabel === 'Verwalung') {
                                                    if (this.props.locationFilterAbsence.selectedId === '') {
                                                        this.props.updateFilter({path: 'data.filter.absencerecord.location', prop:'selectedId', val:this.props.currentUser.currentLocation.id});
                                                        this.props.updateFilter({path: 'data.filter.absencerecord.location', prop:'selectedLabel', val:this.props.currentUser.currentLocation.label});
                                                    }
                                                }
                                                    this.props.loadCalendar(true);
                                                }
                                            }
                                        />
                                    }

                                    {this.can('dokument_oder_ordner_eigene_sehen','dokument_oder_ordner_alle_sehen') &&
                                        <this.menuItem label="Dokumente" view="Documents"
                                            onClick={() => {this.props.updateDocumentSearch('') }}
                                        icon="document"/>
                                    }
                                    {this.can('beanstandungen_menu_sehen') &&
                                    <this.menuItem
                                        label="Beanstandung"
                                        view="ClaimsPage"
                                        icon="claim"
                                        counter={this.props.countClaims}
                                        onClick={() => {
                                            this.props.updateSeen('claim')
                                            setTimeout(() => {
                                                this.props.updateCountSeen({page:'Claims', value: 0})
                                            },5000)

                                        }}
                                    />}

                                    {this.can('schulung_sehen') &&
                                    <this.menuItem
                                         label="Veranstaltungen" view="Training" icon="training"
                                                   // counter={this.props.countTrainings}
                                                   // onClick={() => {
                                                   //     this.props.updateReference('');
                                                   //     this.props.updateSeen('training')
                                                   //     setTimeout(() => {
                                                   //         this.props.updateCountSeen({page:'Trainings', value: 0})
                                                   //
                                                   //     },5000)
                                                   //
                                                   //     this.props.loadTrainingList({'old': false, 'from': 0})
                                                   //     this.props.loadTrainingList({'old': true, 'from': 0})
                                                   //     this.props.loadAllUsersForTraining(true);
                                                   //
                                                   // }}>
                                        className={this.props.trainingMenuClass}
                                         isActive={() => {
                                             // eslint-disable-next-line eqeqeq
                                             return this.props.trainingView == 2;
                                         }}
                                        onClick={() => {
                                                this.props.updateReference('');
                                               this.props.updateSeen('training')
                                               setTimeout(() => {
                                                   this.props.updateCountSeen({page:'Trainings', value: 0})

                                               },5000)

                                               this.props.loadTrainingList({'old': false, 'from': 0})
                                               this.props.loadTrainingList({'old': true, 'from': 0})

                                            this.props.loadAllUsersForTraining(true);
                                            this.props.updateTrainingView(2);
                                            this.props.updateVacationMenuClass('has-subnav');
                                            this.props.updateTaskMenuClass('has-subnav');
                                            this.props.updateSickReportMenuClass('has-subnav');
                                            this.props.updateNewsMenuClass('has-subnav');
                                            this.props.updateTrainingMenuClass(
                                                this.props.trainingMenuClass === 'has-subnav'
                                                    ? 'has-subnav open-submenu active'
                                                    : 'has-subnav',
                                            )}}>
                                        {this.can('training_overview_list_can_see') &&
                                        <ul>
                                            <this.menuItem
                                                label="Veranstaltungen Übersicht"
                                                view="Training"
                                                icon="stock"
                                                isActive={() => {
                                                    // eslint-disable-next-line eqeqeq
                                                    return this.props.trainingView === 3;
                                                }}
                                                onClick={() => {
                                                    this.props.updateTrainingView(3);
                                                    this.props.loadTrainingOverviewList(true)
                                                }}
                                            />
                                        </ul>}
                                    </this.menuItem>}
                                    {this.can('werbung_ver_ffentlichte_sehen', 'werbung_alle_sehen') &&
                                        <this.menuItem label="Werbung" view="Advertising" icon="news"
                                                    counter={this.props.countAdvertisings}
                                                    onClick={() => {
                                                        this.props.loadAdvertisingList({'from': 0})
                                                        this.props.updateSeen('advertising')
                                                        setTimeout(() => {
                                                            this.props.updateCountSeen({page:'Advertisings', value: 0})
                                                        },5000)
                                                    }
                                        }/>
                                    }

                                    {this.can('einstellungen_sehen') &&
                                    <this.menuItem
                                        label="Einstellungen" view="Settings" icon="stock"
                                        onClick={() => this.props.loadUserInfoList({
                                            'page': this.props.userListPage,
                                        })}
                                    />}
                                    <this.menuItem
                                        label="Abmelden" icon="logout"
                                        onClick={() => {
                                            window.localStorage.setItem('Laminat_Depot_JWT', '');
                                            this.props.logout('');
                                            this.props.updateUsername('');
                                            this.props.updatePassword('');
                                            this.props.resetUiState(true);
                                            this.props.resetDataState(true);
                                            this.props.resetAbsenceState(true);
                                            this.props.resetAdvertisingState(true);
                                            this.props.resetCategoryState(true);
                                            this.props.resetClaimsState(true);
                                            this.props.resetNewsState(true);
                                            this.props.resetSickReportState(true);
                                            this.props.resetTaskState(true);
                                            this.props.resetTrainingState(true);
                                        }}
                                    />

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
