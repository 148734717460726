import * as PropTypes from 'prop-types';
import Quagga from 'quagga';
import React, { Component } from 'react';

// https://github.com/serratus/quagga-react-example/blob/master/src/components/Scanner.js

export default class Scanner extends Component {
    static propTypes = {
        onDetected: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this._onDetected = this._onDetected.bind(this);
    }

    render() {
        return (
            <div id="interactive" className="viewport"/>
        );
    }

    componentDidMount() {
        Quagga.init({
            inputStream: {
                type: "LiveStream",
                constraints: {
                    width: 850,
                    height: 400,
                    facing: "environment", // or user
                },
            },
            locator: {
                patchSize: "medium",
                halfSample: true,
            },
            numOfWorkers: 2,
            decoder: {
                readers: ["ean_reader"],
            },
            locate: true,
        }, function (err) {
            if (err) {
                return console.log(err);
            }
            Quagga.start();
        });
        Quagga.onDetected(this._onDetected);
    }

    componentWillUnmount() {
        Quagga.offDetected(this._onDetected);
    }

    _onDetected(result) {
        this.props.onDetected(result);
    }
}
