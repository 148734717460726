import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon/Icon';

class IconLink extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        iconStyle: PropTypes.object,
        iconClass: PropTypes.string,
        onClick: PropTypes.func,
        solid: PropTypes.bool,
        type: PropTypes.string.isRequired,
    };

    render() {
        const {
            children, iconClassName = '', iconStyle = {}, solid, style = {}, type, ...linkProps
        } = this.props;
        if (typeof this.props.onClick === 'function') {
            linkProps.onClick = (e) => {
                e.preventDefault();
                this.props.onClick(e);
            };
        }
        // Always secure _blank targets: https://mathiasbynens.github.io/rel-noopener/#hax
        if (this.props.target === '_blank') {
            linkProps.rel = 'noopener noreferrer';
        }
        linkProps.style = {
            cursor: this.props.onClick ? 'pointer' : '',
            ...style,
        };

        return (
            <a {...linkProps}>
                <Icon
                    className={iconClassName}
                    solid={solid}
                    style={iconStyle}
                    type={type}
                    aria-hidden="true"
                />
                {children ? ' ' : ''}
                {children}
            </a>
        );
    }
}

export default IconLink;
