import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* newsSaga() {
    yield takeLatest(actionTypes.News.LOAD_NEWS_LIST, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const newsView = yield select(selectors.News.view);
            const headers = getHeaders(token);
            let view = 'news'
            view = action.payload.list.view ? action.payload.list.view : view;
            const result = yield fetch(`${host}/news/list/${action.payload.list.from}?view=${view}&archive=${newsView === 1 ? 0 : 1}`, { method: "GET", headers});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const list = yield result.json();
                yield put(actions.Ui.updateCountSeen({page: 'News', value: list.new}))
                yield put(actions.News.updateNewsListCount(list.count));
                yield put(actions.News.updateNewsList({list:list.newsList, start:action.payload.list.from}));
                if (parseInt(list.new) > 0) {
                    yield put(actions.Ui.updateView('News'));
                    yield put(actions.Ui.updateNewsModalAfterLogin(true));
                }
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* uploadNewsSaga() {
    yield takeLatest(actionTypes.News.ADD_NEWS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const newNews = {
                "news": {
                    "title": action.payload.news.title,
                    "locations": action.payload.news.locations,
                    "teams": action.payload.news.teams,
                    "text": action.payload.news.text,
                    "date": action.payload.news.date,
                    category: action.payload.news.category,
                },
                "image": action.payload.news.file
            };
            let method = 'POST';
            if (action.payload.news.__identity) {
                newNews.news.__identity = action.payload.news.__identity;
                if (!newNews.image) {
                    delete newNews.image
                }
                method = 'PUT';
            }
            const result = yield fetch(host+'/news', { method: method, headers, body: JSON.stringify(newNews)});
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const addedNews = yield result.json();
                yield put(actions.News.updateNews({'id': action.payload.news.id, 'news': addedNews}));
                yield put(actions.News.updateNewsToEdit({prop: 'resetObj',val: ''}));
                yield put(actions.Data.updateModal({path: 'news.news', prop: 'view', val: false}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.team', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.team', prop:'selectedId', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.category', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.news.category', prop:'selectedId', val:''}));
                yield put(actions.News.loadNewsList({ from: 0 }))
            }

        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* deleteNewsSaga() {
    yield takeLatest(actionTypes.News.DELETE_NEWS, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);

            const result = yield fetch(host+'/news/'+action.payload.news.id, { method: "DELETE", headers});

            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.News.loadNewsList({ from: 0 }))
            }

        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [newsSaga, uploadNewsSaga, deleteNewsSaga];
