import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';
import { delay } from 'redux-saga';

function* loginlogSaga() {
    yield takeLatest([actionTypes.Ui.UPDATE_LOGIN_LOG_LIST_PAGE, actionTypes.Data.UPDATE_LOGIN_SEARCH_TERM], function* (action) {
        try {
            yield delay(200)
            const token = yield select(selectors.Ui.token);
            const term = yield select(selectors.Data.loginSearchTerm)
            const page = yield select(selectors.Ui.loginLogListPage)
            const sorting = yield select(selectors.Settings.loginlogSort)
            const headers = getHeaders(token);

            const searchTerm = term ? '?term='+term : '';
            const sort = searchTerm !== '' ? '&sort='+ sorting : '?sort='+sorting
            const result = yield fetch(host + '/loginlog/list/' + page + searchTerm +sort, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const loginLogList = yield result.json();
                yield put(actions.Data.updateLoginHistoryList(loginLogList.list));
                yield put(actions.Ui.updateCountLoginLog(loginLogList.count));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [loginlogSaga];
