import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* createLocationSaga() {
    yield takeLatest(actionTypes.Data.ADD_LOCATION, function* () {
        try {
            const stateData = yield select(selectors.Data.data);
            const locationInput = stateData.locationModalInput;
            const token = yield select(selectors.Ui.token);

            const location = {
                "location": {...locationInput, region: stateData.dropdown.settings.location.regions.selectedLabel},
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/location', {
                method: "POST",
                headers: headers,
                body: JSON.stringify(location),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'name' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'street' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'zip' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'city' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'shortName' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'ip' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'position' }));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.location.regions', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.location.regions', prop:'selectedId', val:''}));
                yield put(actions.Data.loadLocationList(0));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* locationListSaga() {
    yield takeLatest([actionTypes.Data.LOAD_LOCATION_LIST, actionTypes.Ui.UPDATE_LOCATION_LIST_PAGE], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const sorting = yield select(selectors.Settings.locationSort);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/location/list/' + action.payload.value +'?sort='+sorting, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const locationList = yield result.json();
                yield put(actions.Data.updateLocationList(locationList));
            }

            const result2 = yield fetch(host + '/location/list/9999', {
                method: "GET",
                headers: headers,
            });
            if (result2) {
                const allLocations = yield result2.json();
                yield put(actions.Data.updateAllLocations(allLocations));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateLocationSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_LOCATION, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const locationListPage = yield select(selectors.Ui.locationListPage);
            let locationInput = stateData.locationModalInput;
            delete locationInput.id;
            const token = yield select(selectors.Ui.token);
            const location = {
                "location": {...locationInput, region: stateData.dropdown.settings.location.regions.selectedLabel},
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/location/' + action.payload.location.id, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(location),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.loadLocationList(locationListPage));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'name' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'street' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'zip' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'city' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'shortName' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'ip' }));
                yield put(actions.Data.updateLocationModalInput({ 'value': '', 'prop': 'position' }));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.location.regions', prop:'selectedLabel', val:''}));
                yield put(actions.Data.updateDropdown({dropdown: 'dropdown.settings.location.regions', prop:'selectedId', val:''}));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteLocationSaga() {
    yield takeLatest(actionTypes.Data.DELETE_LOCATION, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/location/' + action.payload.location.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* countLocationSaga() {
    yield takeLatest(actionTypes.Ui.COUNT_LOCATION, function* () {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/count/location/', {
                method: "GET",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const count = yield result.json();
                yield put(actions.Ui.updateLocationCount(count));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}


export default [createLocationSaga, locationListSaga, updateLocationSaga, deleteLocationSaga, countLocationSaga];
