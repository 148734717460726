import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* createColorSaga() {
    yield takeLatest(actionTypes.Data.SAVE_COLOR, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const color = {
                "color": {
                    "name": stateData.colorToEdit.name,
                    "hex": stateData.colorToEdit.hex,
                },
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/color', {
                method: "POST",
                headers,
                body: JSON.stringify(color),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateColorToEdit({ id: '',name: '',hex: ''}));
                yield put(actions.Ui.updateColorModal(false));
                yield put(actions.Data.loadColors(true));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* colorsSaga() {
    yield takeLatest([actionTypes.Data.LOAD_COLORS, actionTypes.Ui.UPDATE_COLOR_LIST_PAGE], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const sorting = yield select(selectors.Settings.colorSort);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/color/list?sort='+sorting, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const colors = yield result.json();
                yield put(actions.Data.updateColors(colors));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateColorSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_COLOR, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const color = {
                "color": {
                    name: stateData.colorToEdit.name,
                    hex: stateData.colorToEdit.hex
                },
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/color/' + action.payload.color.id, {
                method: "PUT",
                headers,
                body: JSON.stringify(color),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateColorToEdit({id: '', name: '', hex: ''}));
                yield put(actions.Ui.updateColorModal(false));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteColorSaga() {
    yield takeLatest(actionTypes.Data.DELETE_COLOR, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/color/' + action.payload.color.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [createColorSaga, colorsSaga, updateColorSaga, deleteColorSaga];
