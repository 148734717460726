import React from 'react';

const HeadlineWithBacklink = ({title, linkTitle, action, iconClass}) => (
    <div className="headline-counter">
        <span>
            <i className={iconClass} aria-hidden="true"/>
            {title}
        </span>
        {linkTitle &&
        <a href="/#-"
           className="pull-right"
           onClick={ e => {
            e.preventDefault();
            action()
        }}
        >{linkTitle}</a>
        }
    </div>
)

HeadlineWithBacklink.defaultProps = {
    iconClass: 'fa fa-users'
}

export default HeadlineWithBacklink;
