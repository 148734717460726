import classname from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import './checkbox.scss';

const TRUTHY_VALUE = 'on';

class Checkbox extends React.PureComponent {
    static propTypes = {
        defaultValue: PropTypes.string,
        displayFunc: PropTypes.func,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
    };

    state = {
        value: '',
        checked: this.props.value === undefined ? !!this.state.value : !!this.props.value,
    };

    checkbox = React.createRef();

    componentDidMount() {
        this.checkbox.current.checked =
            this.props.value === undefined ? !!this.state.value : !!this.props.value;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value === undefined && prevState.value !== this.state.value) {
            this.checkbox.current.checked = !!this.state.value;
            this.setState({ checked: !!this.state.value });
        }
        if (this.props.value !== undefined && prevProps.value !== this.props.value) {
            this.checkbox.current.checked = !!this.props.value;
            this.setState({ checked: !!this.props.value });
        }
    }

    dispatchOnChange = (value) => {
        this.props.onChange &&
            this.props.onChange({ target: { name: this.props.name, value: value } });
    };

    changeHandler = (e) => {
        //only if scenario is not set we pass a title property with value yes or no
        if(this.props.title === 'no') {
            alert('Scenario ist ein Pflichtfeld')
            return;
        }
        if (this.props.readOnly) {
            e.target.checked = this.state.checked;
        }
        this.setState({ checked: e.target.checked });
        if (e.target.checked) {
            this.dispatchOnChange(this.getValue());
        } else {
            this.dispatchOnChange('');
        }
    };

    getValue = () => {
        return this.props.value === undefined
            ? this.state.value || this.props.defaultValue || TRUTHY_VALUE
            : this.props.value || this.props.defaultValue || TRUTHY_VALUE;
    };

    getDisplayValue = () => {
        let value = this.state.checked ? this.getValue() : '';
        if (this.props.displayFunc) {
            value = this.props.displayFunc(value);
        }
        if (value === TRUTHY_VALUE) {
            return null;
        }
        return (
            value || <span className="checkbox__placeholder">{this.props.placeholder}</span> || null
        );
    };

    render() {
        const { className, displayFunc, onChange, defaultValue, value, ...childProps } = this.props;
        return (
            <div
                className={classname('checkbox', 'check-list', className, {
                    'checkbox--readonly': this.props.readOnly,
                })}
                style={{ boxShadow: 'none' }}
            >
                <div className="check-list-main">
                    <div className="checkbox-wrapper">
                        <label>
                            <span className={this.state.checked ? 'boxChecked' : 'boxUnchecked'} />
                            <input
                                {...childProps}
                                className="sr-only"
                                onChange={this.changeHandler}
                                ref={this.checkbox}
                                title={this.props.title}
                                type="checkbox"
                            />
                        </label>
                        <div className="checkbox-text">
                            <span className="">{this.getDisplayValue()}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Checkbox;
