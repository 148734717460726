import { $get, $set } from 'plow-js';
import { createAction } from 'redux-actions';
import { handleActions } from '../Utility/HandleActions';

const SEND_ABSENCE_REQUEST = 'Absence/SendAbsenceRequest';
const UPDATE_ABSENCE_REQUEST = 'Absence/UpdateAbsenceRequest';
const UPDATE_PREV_VIEW = 'Absence/UpdatePrevView';
const UPDATE_VIEW = 'Absence/UpdateView';
const LOAD_ABSENCE_LIST = 'Absence/LoadAbsenceList';
const UPDATE_ABSENCE_LIST = 'Absence/UpdateAbsenceList';
const UPDATE_LIST_COUNT = 'Absence/UpdateListCount';
const UPDATE_LOADING_LIST = 'Absence/UpdateLoadingList';
const UPDATE_ABSENCE_APPROVED = 'Absence/UpdateAbsenceApproved';
const DELETE_ABSENCE = 'Absence/DeleteAbsence';
const RESET_STATE = 'Absence/ResetState';
const UPDATE_OPEN_LIST_COUNT = 'Absence/UpdateOpenListCount';
const UPDATE_CALENDAR_LOADING = 'Absence/UpdeateCalendarLoading';
const UPDATE_ABSENCE_USER_OVERVIEW_LIST = 'Absence/UpdateAbsenceUserOverviewList';
const LOAD_ABSENCE_USER_OVERVIEW_LIST = 'Absence/LoadAbsenceUserOverviewList';
const UPDATE_WITH_CONTINGENT = 'Absence/UpdateWithContingent';
const UPDATE_ABSENCE_SORTING = 'Absence/UpdateAbsenceSorting';
const RESET_ABSENCE_SORTING = 'Absence/ResetAbsenceSorting';

const actionTypes = {
    SEND_ABSENCE_REQUEST,
    UPDATE_ABSENCE_REQUEST,
    UPDATE_PREV_VIEW,
    UPDATE_VIEW,
    LOAD_ABSENCE_LIST,
    UPDATE_ABSENCE_LIST,
    UPDATE_LIST_COUNT,
    UPDATE_ABSENCE_APPROVED,
    DELETE_ABSENCE,
    RESET_STATE,
    UPDATE_OPEN_LIST_COUNT,
    UPDATE_CALENDAR_LOADING,
    UPDATE_ABSENCE_USER_OVERVIEW_LIST,
    LOAD_ABSENCE_USER_OVERVIEW_LIST,
    UPDATE_WITH_CONTINGENT,
    UPDATE_ABSENCE_SORTING,
    RESET_ABSENCE_SORTING,
};

const sendAbsenceRequest = createAction(SEND_ABSENCE_REQUEST, (absence) => ({absence}));
const updateAbsenceRequest = createAction(UPDATE_ABSENCE_REQUEST, (absence) => ({absence}));
const updatePrevView = createAction(UPDATE_PREV_VIEW, (view) => ({view}));
const updateView = createAction(UPDATE_VIEW, (value) => ({value}));
const loadAbsenceList = createAction(LOAD_ABSENCE_LIST, (list) => ({list}));
const updateAbsenceList = createAction(UPDATE_ABSENCE_LIST, (list) => ({list}));
const updateListCount = createAction(UPDATE_LIST_COUNT, (value) => ({value}));
const updateLoadingList = createAction(UPDATE_LOADING_LIST, (value) => ({value}));
const updateAbsenceApproved = createAction(UPDATE_ABSENCE_APPROVED, (absence) => ({absence}));
const deleteAbsence = createAction(DELETE_ABSENCE, (absence) => ({absence}));
const resetState = createAction(RESET_STATE, (iState) => ({ iState }));
const updateOpenListCount = createAction(UPDATE_OPEN_LIST_COUNT, (value) => ({ value }));
const updateCalendarLoading = createAction(UPDATE_CALENDAR_LOADING, value => ({value}))
const updateAbsenceUserOverviewList = createAction(UPDATE_ABSENCE_USER_OVERVIEW_LIST, list => ({list}))
const loadAbsenceUserOverviewList = createAction(LOAD_ABSENCE_USER_OVERVIEW_LIST, from => ({from}))
const updateWithContingent = createAction(UPDATE_WITH_CONTINGENT, value => ({value}))
const updateAbsenceSorting = createAction(UPDATE_ABSENCE_SORTING, value => ({value}))
const resetAbsenceSorting = createAction(RESET_ABSENCE_SORTING, value => ({value}))

const actions = {
    sendAbsenceRequest,
    updateAbsenceRequest,
    updatePrevView,
    updateView,
    loadAbsenceList,
    updateAbsenceList,
    updateListCount,
    updateLoadingList,
    updateAbsenceApproved,
    deleteAbsence,
    resetState,
    updateOpenListCount,
    updateCalendarLoading,
    updateAbsenceUserOverviewList,
    loadAbsenceUserOverviewList,
    updateWithContingent,
    updateAbsenceSorting,
    resetAbsenceSorting,
};

const initialState = {
    absenceRequest: {
        "startDate": "",
        "endDate": "",
        "userId": "",
        "status": "3", //possible values 1 abwesend 2 krank 3 urlaub 7 anwesend
        "approved": false,
        "statusLabel": "Urlaub",
        "specialHoliday": false,
        "reason": "",
        "overtimeDeduction": false,
        "overtime": "",
    },
    prevView: 'AbsenceRecord',
    view: '1',
    list: [],
    absenceUserOverviewList: [],
    listCount: 0,
    openListCount: 0,
    loadingList: false,
    calenderLoading: false,
    withContingent: false,
    sorting: '',
};

const reducer = handleActions({
    [UPDATE_ABSENCE_REQUEST]: (payload) => oldState => {
        return $set('absence.absenceRequest.' + payload.absence.prop, payload.absence.val, oldState);
    },
    [UPDATE_PREV_VIEW]: (payload) => oldState => {
        return $set('absence.prevView', payload.view, oldState);
    },
    [UPDATE_VIEW]: (payload) => oldState => {
        return $set('absence.view', payload.value, oldState);
    },
    [UPDATE_ABSENCE_LIST]: (payload) => oldState => {
        if(payload.list.reset === true) {
            return $set('absence.list', [], oldState);
        } else {
            let list = [...oldState.absence.list, ...payload.list.list];
            return $set('absence.list', list, oldState);
        }
    },
    [UPDATE_LIST_COUNT]: (payload) => oldState => {
        return $set('absence.listCount', payload.value, oldState);
    },
    [UPDATE_LOADING_LIST]: (payload) => oldState => {
        return $set('absence.loadingList', payload.value, oldState);
    },
    [DELETE_ABSENCE]: (payload) => oldState => {
        let list = [...oldState.absence.list];
        list = list.filter(absence => absence.id !== payload.absence.id)
        return $set('absence.list', list, oldState);

    },
    [RESET_STATE]: (payload) => oldState => {
        return $set('absence', initialState, oldState);
    },
    [UPDATE_OPEN_LIST_COUNT]: (payload) => oldState => {
        return $set('absence.openListCount', payload.value, oldState);
    },
    [UPDATE_CALENDAR_LOADING]: (payload) => oldState => {
        return $set('absence.calendarLoading', payload.value, oldState);
    },
    [UPDATE_ABSENCE_USER_OVERVIEW_LIST]: (payload) => oldState => {
        return $set('absence.absenceUserOverviewList', payload.list, oldState);
    },
    [UPDATE_WITH_CONTINGENT]: (payload) => oldState => {
        return $set('absence.withContingent', payload.value, oldState);
    },

    [UPDATE_ABSENCE_SORTING]: ({ value }) => oldState => {
        const s = value.replace('-', '');
        const list = oldState.absence.sorting.includes(`-${s}`) ? [] :  (oldState.absence.sorting.includes(s) ? [`-${s}`] : [s]);
        return $set('absence.sorting', list, oldState);

    },
    [RESET_ABSENCE_SORTING]: (payload) => oldState => {
        return $set('absence.sorting', '', oldState);
    },
});

const selectors = {
    absence: $get('absence'),
    absenceUserOverviewList: $get('absence.absenceUserOverviewList'),
    sorting: $get('absence.sorting')
};


export default {
    actions,
    actionTypes,
    initialState,
    reducer,
    selectors,
};
