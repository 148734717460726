import Select from 'Components/UI/Form/Select';
import { FieldArray, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import '../../assets/styles/ExternalComponents/react-tabs.scss';
import { actions } from '../../Redux';
import Button from '../UI/Button';
import FileList from '../UI/FileList/FileList';
import FileListAddItem from '../UI/FileList/FileListAddItem';
import FormikInput from '../UI/Form/FormikInput';
import FormInput from '../UI/Form/FormInput';
import Icon from '../UI/Icon/Icon';
import IconLink from '../UI/IconLink';
import Modal from '../UI/Modal/Modal';
import './category.scss';
import { host } from '../../Sagas/host';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

const initialCategory = {
    contactPersons: [],
    files: [],
    info: '',
    name: '',
    sales1Name: '',
    sales2Name: '',
    sales3Name: '',
    position: '',
    link: ''
};

const initialContact = {
    company: '',
    responsibility: '',
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
};

const mapStateToProps = (state) => {
    return {
        subFolderDialog: state.category.subFolderDialog,
        categoryFileName: state.category.categoryFileName,
        categoryFile: state.category.categoryFile,
        categorySubfolderName: state.category.categorySubfolderName,
        currentCategoryFolder: state.category.currentCategoryFolder,
        currentCategory: state.category.currentCategory,
        openedFolder: state.category.openedFolder,
        token: state.ui.token,
        folderToRename: state.category.folderToRename,
        fileToMove: state.category.fileToMove
    };
};


const mapDispatchToProps = (dispatch) => ({
    createCategory: (value) => dispatch(actions.Category.createCategory(value)),
    loadCategoryList: (category) => dispatch(actions.Category.loadList(category)),
    updateCategory: (value) => dispatch(actions.Category.updateCategory(value)),
    updateSubFolderDialog: (value) => dispatch(actions.Category.updateSubFolderDialog(value)),
    updateCategoryFile: (file) => dispatch(actions.Category.updateCategoryFile(file)),
    updateCategoryFileName: (value) => dispatch(actions.Category.updateCategoryFileName(value)),
    updateCategorySubfolderName: (value) => dispatch(actions.Category.updateCategorySubfolderName(value)),
    updateCurrentCategoryFolder: (value) => dispatch(actions.Category.updateCurrentCategoryFolder(value)),
    addCategoryFolder: (folder) => dispatch(actions.Category.addCategoryFolder(folder)),
    addCategoryFile: (file) => dispatch(actions.Category.addCategoryFile(file)),
    deleteCategoryFileOrFolder: (item) => dispatch(actions.Category.deleteCategoryFileOrFolder(item)),
    updateOpenedFolder: (folder) => dispatch(actions.Category.updateOpenedFolder(folder)),
    updateFolderToRename: (folder) => dispatch(actions.Category.updateFolderToRename(folder)),
    updateFileToMove: (file) => dispatch(actions.Category.updateFileToMove(file)),
    moveFile: (file) => dispatch(actions.Category.moveFile(file)),
    updateCurrentCategory: (category) => dispatch(actions.Category.updateCurrentCategory(category)),
});

class CategoryForm extends React.Component {
    static propTypes = {
        category: PropTypes.object,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
    };


    state = {
        newContact: {...initialContact},
        dirty: false,
    };


    isEditMode = this.props.category && this.props.category.name;

    handleCancel = () => {
        if (this.formik && this.formik.getFormikComputedProps().dirty && !window.confirm('Alle Änderungen verwerfen?')) {
            return null;
        }
        return this.props.onCancel && this.props.onCancel();
    };

    handleSubmit = (values, {setSubmitting}) => {
        if (this.isEditMode) {
            this.props.updateCategory(values);
        } else {
            this.props.createCategory(values);
            setTimeout(() => {this.props.loadCategoryList({page : 0})}, 200)
            this.props.updateCurrentCategory(null)
        }
        setSubmitting(false);
        this.props.onSubmit && this.props.onSubmit();
    };

    handleNewContactFormChange = (event) => {
        this.setState({
            newContact: {
                ...this.state.newContact,
                [event.target.name]: event.target.value,
            },
        });
    };

    getDialogStyle(value) {
        if (value) {
            return { display: 'block' };
        } else {
            return { display: 'none' };
        }
    }

    validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Pflichtfeld!';
        }
        return errors;
    };

    triggerFileInput(fileInput) {
        fileInput.click()
    }

    triggerUpload() {
        document.getElementById('uploadCategoryFileInput').click();
    }
    getBase64(file) {
        if (!file) {
            return null;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.props.addCategoryFile({'file': reader.result, 'name': file.name.split(".")[0], 'parent': this.props.currentCategoryFolder});
        };
    }
    getLink(id) {
        return host + "/download/"+id;
    }

    downloadFile(id) {
        document.getElementById('downloadCateroyFile').action = this.getLink(id);
        document.getElementById('downloadCateroyFile').submit();
    }

    renderTechnicalDataForm(values) {
        return <FieldArray name="files">
            {arrayHelpers => (
                <FileList
                    files={values.files}
                    hideDownloadIcons={true}
                    onDelete={(_, index) => arrayHelpers.remove(index)}
                >
                    <FileListAddItem onChange={({file, data}) => {
                        arrayHelpers.push({
                            fileName: file.name,
                            name: file.name,
                            size: file.size,
                            type: file.type.split('/')[1],
                            resourceId: 'foo',
                            data: data,
                        });
                    }}
                    />
                </FileList>
            )}
        </FieldArray>;
    }

    renderContactsForm(values) {
        return <FieldArray name="contactPersons">
            {arrayHelpers => (
                <div className="small-12 medium-12 large-12">
                    <div className="dokumente-list">
                        <div className="dokumente-wrapper">
                            {values.contactPersons && values.contactPersons.map((contact, index) => (
                                <div className="dokument-item"
                                     key={contact.id || index}>
                                    <div className="dokument-item-left">
                                        <Icon type="contact"/>
                                        <span>
                                            <FormikInput label="Lieferant"
                                                         name={`contactPersons[${index}].company`}
                                                         type="text"
                                            />
                                            <FormikInput label="Anrede"
                                                         name={`contactPersons[${index}].salutation`}
                                                         component={Select}
                                                         options={['Frau', 'Herr']}
                                            />
                                            <FormikInput label="Vorname"
                                                         name={`contactPersons[${index}].firstName`}
                                                         type="text"/>
                                            <FormikInput label="E-Mail"
                                                         name={`contactPersons[${index}].email`}
                                                         type="text"
                                            />
                                        </span>
                                    </div>
                                    <div className="dokument-item-right">
                                        <span>
                                            <FormikInput label="Zuständigkeit"
                                                         name={`contactPersons[${index}].responsibility`}
                                                         type="text"
                                            />
                                            <div className="CategoryForm__input-placeholder"></div>
                                            <FormikInput label="Nachname"
                                                         name={`contactPersons[${index}].lastName`}
                                                         type="text"
                                            />
                                            <FormikInput label="Telefon"
                                                         name={`contactPersons[${index}].phone`}
                                                         type="text"/>
                                        </span>
                                        <IconLink type="delete" onClick={() => arrayHelpers.remove(index)}/>
                                    </div>
                                </div>
                            ))}
                            <div className="dokument-item">
                                <div className="dokument-item-left">
                                    <Icon type="add"/>
                                    <span>
                                        <FormInput label="Lieferant"
                                                   name="company"
                                                   value={this.state.newContact.company}
                                                   onChange={(e) => this.handleNewContactFormChange(e)}/>
                                        <FormInput label="Anrede"
                                                   name="salutation"
                                                   value={this.state.newContact.salutation}
                                                   component={Select}
                                                   options={['Frau', 'Herr']}
                                                   onChange={(e) => this.handleNewContactFormChange(e)}/>
                                        <FormInput label="Vorname"
                                                   name="firstName"
                                                   value={this.state.newContact.firstName}
                                                   onChange={(e) => this.handleNewContactFormChange(e)}
                                        />
                                        <FormInput label="E-Mail"
                                                   name="email"
                                                   value={this.state.newContact.email}
                                                   onChange={(e) => this.handleNewContactFormChange(e)}/>
                                        <div className="CategoryForm__input-placeholder"></div>
                                    </span>
                                </div>
                                <div className="dokument-item-right">
                                    <span>
                                        <FormInput label="Zuständigkeit"
                                                   name="responsibility"
                                                   value={this.state.newContact.responsibility}
                                                   onChange={(e) => this.handleNewContactFormChange(e)}
                                        />
                                        <div className="CategoryForm__input-placeholder"></div>
                                        <FormInput label="Nachname"
                                                   name="lastName"
                                                   value={this.state.newContact.lastName}
                                                   onChange={(e) => this.handleNewContactFormChange(e)}/>
                                        <FormInput label="Telefon"
                                                   name="phone"
                                                   value={this.state.newContact.phone}
                                                   onChange={(e) => this.handleNewContactFormChange(e)}/>
                                        <div className="btn-form-wrapper">
                                            <Button
                                                label="Kontakt hinzufügen"
                                                disabled={!this.state.newContact || !Object.values(this.state.newContact)
                                                    .some(v => v)}
                                                onClick={() => {
                                                    if (this.state.newContact && !Object.values(this.state.newContact)
                                                        .some(v => v)) {
                                                        return;
                                                    }
                                                    arrayHelpers.push({ ...this.state.newContact });
                                                    this.setState({ newContact: { ...initialContact } });
                                                }}
                                            />
                                        </div>
                                    </span>
                                    <Icon hidden={true} type="add"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </FieldArray>;
    }

    render() {
        return (
            <Modal
                icon="category"
                isOpen
                title={this.isEditMode
                    ? 'Sortiment "' + this.props.category.name + '" bearbeiten'
                    : 'Neues Sortiment anlegen'}
                onClose={this.handleCancel}
            >
                <Formik
                    initialValues={{...initialCategory, ...this.props.category}}
                    validate={this.validate}
                    onSubmit={this.handleSubmit}
                    ref={(node) => {
                        this.formik = node;
                    }}
                >
                    {({values, isSubmitting, submitForm}) => (
                        <Form className="CategoryForm">
                            <Tabs>
                                <TabList>
                                    <Tab>Allgemein</Tab>
                                    <Tab>Technische Daten</Tab>
                                    <Tab>Ansprechpartner</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <FormikInput label="Name"
                                                             name="name"
                                                             type="text"
                                                             size="large"/>
                                                <FormikInput label="Abverkauf Spalte 1 Name"
                                                             name="sales1Name"
                                                             type="text"
                                                             size="large"/>
                                                <FormikInput label="Abverkauf Spalte 2 Name"
                                                             name="sales2Name"
                                                             type="text"
                                                             size="large"/>
                                                <FormikInput label="Abverkauf Spalte 3 Name"
                                                             name="sales3Name"
                                                             type="text"
                                                             size="large"/>
                                                <FormikInput label="Position in der Liste"
                                                             name="position"
                                                             type="number"
                                                             size="large"/>
                                                <FormikInput label='URL Button "Zum Bestand" überschreiben'
                                                             name="link"
                                                             type="text"
                                                             size="large"/>
                                                <FormikInput label="Allgemeine Informationen"
                                                             name="info"
                                                             component="textarea"
                                                             size="large"/>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    {!this.props.currentCategory &&
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <p>Speichern Sie zuerst das Sortiment ab.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    { this.props.currentCategory &&
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div id="newSubfolder" style={this.getDialogStyle(this.props.subFolderDialog)}>
                                                        <i className="fa fa-times" onClick={() => this.props.updateSubFolderDialog(false)} />
                                                        <div className="group">
                                                            <input type="text" value={this.props.categorySubfolderName || ''}
                                                            onChange={(e) => this.props.updateCategorySubfolderName(e.target.value)}/>
                                                            <label>Name</label>
                                                        </div>
                                                        <div className="group btn-grp">
                                                            <Button
                                                                label={'Speichern'}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.props.updateSubFolderDialog(false);
                                                                    this.props.addCategoryFolder({name: this.props.categorySubfolderName, parent: this.props.currentCategoryFolder});
                                                                }}
                                                             />
                                                        </div>
                                                        <input type="file" id="uploadCategoryFileInput" accept="application/pdf, image/gif, image/jpeg, image/png" onChange={(e) => {e.preventDefault(); this.getBase64(e.target.files[0]);}} />
                                                        <form id="downloadCateroyFile" action={this.getLink()} target="_blank"  method="post">
                                                            <input type="hidden" name="authTok" value={'Bearer ' + this.props.token}  />
                                                        </form>
                                                    </div>

                                                <ContextMenuTrigger id={this.props.currentCategory.folders[0].id}> {/* NOTICE: id must be unique for EVERY instance */}
                                                    <div className="well"> <i className={'fa fa-folder-open'} /> {this.props.currentCategory.folders[0].name}</div>
                                                </ContextMenuTrigger>
                                                <ContextMenu id={this.props.currentCategory.folders[0].id}>
                                                    <MenuItem onClick={() => {this.props.updateSubFolderDialog(true); this.props.updateCurrentCategoryFolder(this.props.currentCategory.folders[0].id)}}>
                                                     neuer Ordner
                                                    </MenuItem>
                                                    <MenuItem divider />
                                                    <MenuItem  onClick={() => {this.props.updateCurrentCategoryFolder(this.props.currentCategory.folders[0].id);  this.triggerUpload()}}>
                                                     Datei Hochladen
                                                    </MenuItem>
                                                    {this.props.fileToMove !== '' &&
                                                    <MenuItem divider />
                                                    }
                                                    {this.props.fileToMove !== '' &&
                                                    <MenuItem  onClick={() => this.props.moveFile({parent: this.props.currentCategory.folders[0].id})}>
                                                        Einfügen
                                                    </MenuItem>
                                                    }

                                                </ContextMenu>
                                                {this.props.currentCategory.folders[0].folders.map((folder, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <ContextMenuTrigger id={folder.id}> {/* NOTICE: id must be unique for EVERY instance */}
                                                                <div className="firstLevelF"
                                                                    onClick={() => {
                                                                        this.props.updateOpenedFolder({prop: 'parent', value: this.props.openedFolder.parent !== folder.id ? folder.id : ''});
                                                                        this.props.updateOpenedFolder({prop: 'folder', value: ''});
                                                                        this.props.updateCurrentCategoryFolder(folder.id);}}
                                                                > <i className={this.props.openedFolder.parent === folder.id ? 'fa fa-folder-open' : 'fa fa-folder'} /> {folder.name}</div>
                                                            </ContextMenuTrigger>
                                                            <ContextMenu id={folder.id}>
                                                                <MenuItem onClick={() => {
                                                                    this.props.updateSubFolderDialog(true);
                                                                    this.props.updateCurrentCategoryFolder(folder.id);
                                                                    this.props.updateOpenedFolder({prop: 'parent', value: folder.id});
                                                                    }}>
                                                                neuer Ordner
                                                                </MenuItem>
                                                                <MenuItem divider />
                                                                <MenuItem onClick={() => {this.props.updateCurrentCategoryFolder(folder.id);  this.triggerUpload()}}>
                                                                Datei Hochladen
                                                                </MenuItem>
                                                                <MenuItem divider />
                                                                {this.props.fileToMove !== '' &&
                                                                <MenuItem  onClick={() => this.props.moveFile({parent: folder.id})}>
                                                                    Einfügen
                                                                </MenuItem>
                                                                }
                                                                {this.props.fileToMove !== '' &&
                                                                <MenuItem divider />
                                                                }
                                                                <MenuItem  onClick={() => {
                                                                    this.props.updateFolderToRename(folder.id);
                                                                    this.props.updateSubFolderDialog(true);
                                                                    this.props.updateCurrentCategoryFolder(folder.id);
                                                                    this.props.updateCategorySubfolderName(folder.name)
                                                                }}>
                                                                    Umbenennen
                                                                </MenuItem>
                                                                <MenuItem divider />
                                                                <MenuItem onClick={() => this.props.deleteCategoryFileOrFolder({id: folder.id, type: 'folder', parent: folder.parent.id})}>
                                                                    Löschen
                                                                </MenuItem>
                                                            </ContextMenu>
                                                            {this.props.openedFolder.parent === folder.id && folder.folders.map((subFolder, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <ContextMenuTrigger id={subFolder.id}> {/* NOTICE: id must be unique for EVERY instance */}
                                                                            <div className="secondLevelF"
                                                                            onClick={() => {this.props.updateOpenedFolder({prop: 'folder', value: this.props.openedFolder.folder !== subFolder.id ? subFolder.id : ''}); this.props.updateCurrentCategoryFolder(subFolder.id);}}
                                                                            ><i className={this.props.openedFolder.folder === subFolder.id ? 'fa fa-folder-open' : 'fa fa-folder'} /> {subFolder.name}</div>
                                                                        </ContextMenuTrigger>
                                                                        <ContextMenu id={subFolder.id}>
                                                                            <MenuItem onClick={() => {
                                                                                this.props.updateCurrentCategoryFolder(subFolder.id);  this.triggerUpload();
                                                                                this.props.updateOpenedFolder({prop: 'folder', value: subFolder.id});
                                                                                }}>
                                                                            Datei Hochladen
                                                                            </MenuItem>
                                                                            <MenuItem divider />
                                                                            {this.props.fileToMove !== '' &&
                                                                                <MenuItem  onClick={() => this.props.moveFile({parent: subFolder.id})}>
                                                                                    Einfügen
                                                                                </MenuItem>
                                                                            }
                                                                            {this.props.fileToMove !== '' &&
                                                                            <MenuItem divider />
                                                                            }
                                                                            <MenuItem  onClick={() => {
                                                                                this.props.updateFolderToRename(subFolder.id);
                                                                                this.props.updateSubFolderDialog(true);
                                                                                this.props.updateCurrentCategoryFolder(subFolder.id);
                                                                                this.props.updateCategorySubfolderName(subFolder.name)
                                                                            }}>
                                                                                Umbenennen
                                                                            </MenuItem>
                                                                            <MenuItem divider />
                                                                            <MenuItem onClick={() => this.props.deleteCategoryFileOrFolder({id: subFolder.id, type: 'folder', parent: subFolder.parent.id})}>
                                                                                Löschen
                                                                            </MenuItem>
                                                                        </ContextMenu>
                                                                        {this.props.openedFolder.folder === subFolder.id && subFolder.files.map((file, index) => {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    <ContextMenuTrigger id={'some_unique_identifier'+file.id}> {/* NOTICE: id must be unique for EVERY instance */}
                                                                                        <div className="thirdLevelF" style={{color: this.props.fileToMove === file.id ? '#ccc' : 'inherit'}} onClick={() => this.downloadFile(file.id)}> <i className={'fa fa-file'} /> {file.name}</div>
                                                                                    </ContextMenuTrigger>
                                                                                    <ContextMenu id={'some_unique_identifier'+file.id}>
                                                                                        <MenuItem onClick={() => this.props.deleteCategoryFileOrFolder({id: file.id, type: 'file', parent: file.parent.id})}>
                                                                                        Datei löschen
                                                                                        </MenuItem>
                                                                                        <MenuItem divider />
                                                                                        <MenuItem  onClick={() => this.props.updateFileToMove(file.id)}>
                                                                                        Datei Ausschneiden
                                                                                        </MenuItem>
                                                                                    </ContextMenu>
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                            {this.props.openedFolder.parent === folder.id && folder.files.map((file, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <ContextMenuTrigger id={'firstLevelFile'+file.id}> {/* NOTICE: id must be unique for EVERY instance */}
                                                                            <div className="secondLevelF" style={{color: this.props.fileToMove === file.id ? '#ccc' : 'inherit'}}  onClick={() => this.downloadFile(file.id)}> <i className={'fa fa-file'} /> {file.name}</div>
                                                                        </ContextMenuTrigger>
                                                                        <ContextMenu id={'firstLevelFile'+file.id}>
                                                                            <MenuItem onClick={() => this.props.deleteCategoryFileOrFolder({id: file.id, type: 'file', parent: file.parent.id})}>
                                                                            Datei löschen
                                                                            </MenuItem>
                                                                            <MenuItem divider />
                                                                            <MenuItem  onClick={() => this.props.updateFileToMove(file.id)}>
                                                                            Datei Ausschneiden
                                                                            </MenuItem>
                                                                        </ContextMenu>
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )
                                                })}
                                                {this.props.currentCategory.folders[0].files.map((file, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <ContextMenuTrigger id={'firstLevelFile'+file.id}> {/* NOTICE: id must be unique for EVERY instance */}
                                                                <div className="firstLevelF" style={{color: this.props.fileToMove === file.id ? '#ccc' : 'inherit'}}  onClick={() => this.downloadFile(file.id)}> <i className={'fa fa-file'} /> {file.name}</div>
                                                            </ContextMenuTrigger>
                                                            <ContextMenu id={'firstLevelFile'+file.id}>
                                                                <MenuItem  onClick={() => this.props.deleteCategoryFileOrFolder({id: file.id, type: 'file', parent: file.parent.id})}>
                                                                Datei löschen
                                                                </MenuItem>
                                                                <MenuItem divider />
                                                                <MenuItem  onClick={() => this.props.updateFileToMove(file.id)}>
                                                                Datei Ausschneiden
                                                                </MenuItem>
                                                            </ContextMenu>
                                                        </React.Fragment>
                                                    )
                                                })}

                                                { /*this.renderTechnicalDataForm(values) */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                {this.renderContactsForm(values)}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>

                            <div className="small-12 medium-12 large-12">
                                <Button
                                    label={this.isEditMode ? 'Änderungen speichern' : 'Sortiment anlegen'}
                                    inverted
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CategoryForm);
