import { put, select, takeLatest } from 'redux-saga/effects';
import getHeaders from 'Sagas/headers';
import { actions, actionTypes, selectors } from '../Redux/index';
import { host } from './host';

function* createClaimDropdownReasonSaga() {
    yield takeLatest(actionTypes.Data.SAVE_CLAIM_DROPDOWN_REASON, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const claimDropdownReason = {
                "claimDropdownReason": {
                    "name": stateData.claimDropdownReasonToEdit.name,
                },
            };
            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownreason', {
                method: "POST",
                headers,
                body: JSON.stringify(claimDropdownReason),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateClaimDropdownReasonToEdit({ id: '',name: ''}));
                yield put(actions.Ui.updateClaimDropdownReasonModal(false));
                yield put(actions.Data.loadClaimDropdownReasons(true));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* claimDropdownReasonsSaga() {
    yield takeLatest([actionTypes.Data.LOAD_CLAIM_DROPDOWN_REASONS, actionTypes.Ui.UPDATE_CLAIM_DROPDOWN_REASON_LIST_PAGE], function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const sorting = yield select(selectors.Settings.colorSort);
            const headers = getHeaders(token);

            const result = yield fetch(host + '/claimdropdownreason/list?sort='+sorting, {
                method: "GET",
                headers: headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                const claimDropdownReasons = yield result.json();
                yield put(actions.Data.updateClaimDropdownReasons(claimDropdownReasons));
            }
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

function* updateClaimDropdownReasonSaga() {
    yield takeLatest(actionTypes.Data.UPDATE_CLAIM_DROPDOWN_REASON, function* (action) {
        try {
            const stateData = yield select(selectors.Data.data);
            const token = yield select(selectors.Ui.token);
            const claimDropdownReason = {
                "claimDropdownReason": {
                    name: stateData.claimDropdownReasonToEdit.name,
                },
            };

            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownreason/' + action.payload.claimDropdownReason.id, {
                method: "PUT",
                headers,
                body: JSON.stringify(claimDropdownReason),
            });
            yield put(actions.Ui.checkLogin(result.status));
            if (result.status !== 406 && result.status !== 408) {
                yield put(actions.Data.updateClaimDropdownReasonToEdit({id: '', name: ''}));
                yield put(actions.Ui.updateClaimDropdownReasonModal(false));
            }
        } catch (e) {
            console.log("ERROR", e);
            yield put(actions.Ui.showLoginError(true));
        }
    });
}

function* deleteClaimDropdownReasonSaga() {
    yield takeLatest(actionTypes.Data.DELETE_CLAIM_DROPDOWN_REASON, function* (action) {
        try {
            const token = yield select(selectors.Ui.token);
            const headers = getHeaders(token);
            const result = yield fetch(host + '/claimdropdownreason/' + action.payload.claimDropdownReason.id, {
                method: "DELETE",
                headers,
            });
            yield put(actions.Ui.checkLogin(result.status));
        } catch (e) {
            console.log("ERROR", e);
        }
    });
}

export default [createClaimDropdownReasonSaga, claimDropdownReasonsSaga, updateClaimDropdownReasonSaga, deleteClaimDropdownReasonSaga];
