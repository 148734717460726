import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon/Icon';

export default class FileListAddItem extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
    };

    openFileInput = () => this.fileInput && this.fileInput.click();

    handleChange = (e) => {
        const file = e.currentTarget.files[0];
        const reader = new FileReader();
        reader.onload = (event) =>
            this.props.onChange &&
            this.props.onChange({
                file: file,
                data: event.target.result,
            });
        reader.readAsDataURL(file);
    };

    render() {
        return (
            <div
                className="dokument-item"
                style={{ cursor: 'pointer' }}
                onClick={this.openFileInput}
            >
                <div className="dokument-item-left">
                    <Icon type="add" />
                    <span>
                        <em>Datei hinzufügen...</em>
                    </span>
                </div>
                <div className="dokument-item-right">
                    <input
                        type="file"
                        ref={(element) => {
                            this.fileInput = element;
                        }}
                        onChange={this.handleChange}
                    />
                    <Icon hidden={true} type="upload" />
                </div>
            </div>
        );
    }
}
